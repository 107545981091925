import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Modal, Tooltip } from 'antd';
import { Button, Col, FieldItem, Grid, ImageItem, Row, TableForm, Text, UploadBlendFile, UploadFile } from 'components';
import { ActivityIndicator } from 'react-native';
import { COLOR } from 'const';
import { SVG } from 'assets';
import { EditorIframeModal } from 'components/container';
import { useProductForm } from './FormContext';

const FIELDS = [
  { label: 'Name', id: 'variantName' },
  { label: 'Size', id: 'size' },
  { label: 'Description', id: 'description' },
  { label: 'Price', id: 'price', left: <Text>£</Text> },
  { label: 'Store SKU', id: 'PPGSku' },
  { label: 'Custom Width', id: 'width' },
  { label: 'Custom Height', id: 'height' },
];

const CreateSKUModal = forwardRef((props: any, ref) => {
  const uploadRef = useRef<{ showDialog: Function }>(null);
  const uploadBlenderRef = useRef<{ showDialog: Function }>(null);
  const editorModalRef = useRef(null);
  const [show, setShow] = useState(false);
  const [state, setState] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [loadingBlender, setLoadingBlender] = useState(false);
  const [targetIdx, setTargetIdx] = useState(0);
  const { product } = useProductForm();

  useImperativeHandle(ref, () => ({
    open: (data, targetIdx) => {
      setState(data);
      setTargetIdx(targetIdx);
      setShow(true);
    },
    hide: () => {
      setShow(false);
    },
  }))

  const updateState = (key) => (value) => {
    setState(s => ({
      ...s,
      [key]: value,
    }))
  }

  const onFileUploaded = (urls) => {
    if (urls.length === 0) return setLoading(false);
    updateState("url")(urls?.[0]);
    setLoading(false);
  }

  const onFileBlenderUploaded = (urls) => {
    if (urls.length === 0) return setLoadingBlender(false);
    updateState("blender")(urls?.[0]);
    setLoadingBlender(false);
  }

  const onPressAddFile = () => {
    uploadRef.current?.showDialog();
  }

  const onPressAddBlenderFile = () => {
    uploadBlenderRef.current?.showDialog();
  }

  const onPressSave = () => {
    props?.onSave(state, targetIdx);
    setShow(false);
  }

  const onOpenEditor = () => {
    editorModalRef.current?.open();
  }

  const productWithCustomBlender = useMemo(() => {
    if (!state?.blender) return product;
    return {
      ...product,
      data: {
        ...product.data,
        blender: state.blender,
      }
    }
  }, [product, state]);

  if (!show) return null;

  return (
    <Modal
      open={show}
      title={targetIdx !== undefined ? "Edit Variant" : "Add Variant"}
      onOk={() => setShow(false)}
      onCancel={() => setShow(false)}
      width={1000}
      footer={false}
    >
      <Grid
        xs="100%"
        lg="50%"
        marginHorizontal={-12}
        alignItems="flex-start"
      >
        <Col mb2 marginHorizontal={12}>
          {FIELDS.map(field => (
            <FieldItem
              key={field.id}
              label={field.label}
              value={state?.[field.id]}
              inputProps={{ left: field.left }}
              onChangeText={updateState(field.id)}
            />
          ))}
          <FieldItem
            label="Custom PDF"
            value={state?.url}
            onChangeText={updateState("url")}
            inputProps={{
              right: (
                <Tooltip title="Upload PDF file">
                  <Col padding={10} marginRight={-10} onPress={onPressAddFile}>
                    {loading ?
                      <ActivityIndicator color={COLOR.MAIN} />
                      :
                      <SVG.ImagePlus width={24} height={24} />
                    }
                  </Col>
                </Tooltip>
              )
            }}
          />
          {!!state?.previewUrl && (
            <>
              <Text mt3 p1 bold color={COLOR.GRAY400}>Preview</Text>
              <Col alignItems="flex-start">
                <ImageItem
                  uri={state.previewUrl}
                  style={{ width: 100, height: 100, alignSelf: "flex-start" }}
                  canRemove={false}
                />
              </Col>
            </>
          )}
        </Col>
        <Col marginHorizontal={12}>
          <FieldItem
            key={'code'}
            label={'Siteflow Code'}
            value={state?.['code']}
            onChangeText={updateState('code')}
          />
          <FieldItem
            key={'PPGSku'}
            label={'Siteflow SKU'}
            value={state?.['sku']}
            onChangeText={updateState('sku')}
          />
          <FieldItem
            key={'blender'}
            label={'Blender URL'}
            value={state?.['blender']}
            onChangeText={updateState('blender')}
            mb2
            inputProps={{
              right: (
                <Tooltip title="Upload Blender file">
                  <Col padding={10} marginRight={-10} onPress={onPressAddBlenderFile}>
                    {loadingBlender ?
                      <ActivityIndicator color={COLOR.MAIN} />
                      :
                      <SVG.ImagePlus width={24} height={24} />
                    }
                  </Col>
                </Tooltip>
              )
            }}
          />
          <Text mt3 p1 bold color={COLOR.GRAY400}>Siteflow Attributes</Text>
          <TableForm
            addBtnTitle="Add attribute"
            data={state?.attributes}
            columns={[
              { key: 'key', label: 'Name' },
              { key: 'value', label: 'Value' },
            ]}
            editable
            onChange={updateState("attributes")}
            isBottomBtn
            marginBottom={24}
          />
          <Text p1 bold color={COLOR.GRAY400}>Other Siteflow fields</Text>
          <TableForm
            addBtnTitle="Add field"
            data={state?.others}
            columns={[
              { key: 'key', label: 'Path' },
              { key: 'value', label: 'Value' },
            ]}
            editable
            onChange={updateState("others")}
            isBottomBtn
          />
        </Col>
      </Grid>

      <Row marginTop={16} alignSelf={"flex-end"} justifyContent="space-between">

        <Row mt1>
          <Button
            height={50}
            title="Open editor"
            width={180}
            onPress={onOpenEditor}
          />
          {/* <Button
            height={50}
            title="Clear design"
            backgroundColor="transparent"
            textProps={{ color: COLOR.MAIN }}
            borderColor={COLOR.MAIN}
            borderWidth={1}
            width={180}
            ml2
            onPress={() => {
              updateState("previewUrl")("");
              updateState("canvas")("");
            }}
          /> */}
        </Row>

        <Row>
          <Button
            height={50}
            title={"Cancel"}
            backgroundColor="transparent"
            textProps={{ color: COLOR.MAIN }}
            borderColor={COLOR.MAIN}
            borderWidth={1}
            onPress={() => setShow(false)}
          />
          <Button
            height={50}
            title={"Save"}
            ml2
            onPress={onPressSave}
          />
        </Row>
      </Row>

      <UploadFile
        ref={uploadRef}
        onUploaded={onFileUploaded}
        onUploading={() => setLoading(true)}
      />
      <UploadBlendFile
        ref={uploadBlenderRef}
        onUploaded={onFileBlenderUploaded}
        onUploading={() => setLoadingBlender(true)}
      />
      <EditorIframeModal
        onPDFSaved={({ url, previewUrl }) => {
          updateState("url")(url);
          if (previewUrl) updateState("previewUrl")(previewUrl);
        }}
        ref={editorModalRef}
        artworkType={product?.data?.artworkType}
        productType={product?.data?.type}
        product={productWithCustomBlender}
        selectedVariant={state}
        physicalWidth={+state?.width || props.physicalWidth}
        physicalHeight={+state?.height || props.physicalHeight}
        canvas={state?.canvas || product?.data?.globalCanvas}
        onchangeCanvas={({ canvas, previewUrl }) => {
          updateState("canvas")(canvas);
          if (previewUrl) updateState("previewUrl")(previewUrl);
        }}
        onchangeVariantsCanvas={(params) => {
          if (params.selecteds?.includes(state.variantName)) {
            updateState("canvas")(params.canvas);
            if (params.previewUrl) updateState("previewUrl")(params.previewUrl);
          }
          props.onSaveVariants?.(params);
        }}
      />
    </Modal>
  )
});

export default CreateSKUModal;
