import { Button, Col, Grid, Row, Text } from 'components';
import { MainContentContainer, SecuredLayout } from 'components/container';
import React, { useMemo } from 'react';
import { IScreen } from 'type';
import SubMenu, { ITEMS } from './SubMenu';
import { COLOR, SCREEN } from 'const';
import { useNavFunc } from 'navigation';
import TabProfile from './TabProfile';
import TabBrands from './TabBrands';
import TabBilling from './TabBilling';
import TabPackingSlip from './TabPackingSlip';
import TabUsers from './TabUsers';
import TabStickers from './TabStickers';

const getTab = (id: string) => {
  switch (id) {
    case 'brands':
      return <TabBrands />
    case 'billing':
      return <TabBilling />
    case 'packing_slips':
      return <TabPackingSlip />
    case 'users':
      return <TabUsers />
    case 'profile':
    default:
      return <TabProfile />
  }
}

const SettingsScreen: IScreen = () => {
  const { route, navigate } = useNavFunc();
  const tabId = (route.params as any)?.id || 'profile';

  const tabInfo = useMemo(() => {
    return ITEMS.find(i => i.id === tabId) || ITEMS[0];
  }, [tabId]);

  const tab = useMemo(() => getTab(tabId), [tabId]);

  return (
    <SecuredLayout>
      <MainContentContainer>
        <Col pt4 ph4>
          <Text h2 fontWeight={'300'}>Settings</Text>
          <Grid
            lg="200px:1"
            xs="100%"
            marginLeft={-16}
            alignItems="flex-start"
          >
            <SubMenu
              marginTop={16}
              tabId={tabId}
              onPress={(id) => navigate(SCREEN.SettingsScreen, { id })}
            />
            <Col bgWhite marginTop={16} paddingBottom={16}>
              <Text h4 bold margin={24}>{tabInfo?.title}</Text>
              <Row height={1} backgroundColor={COLOR.GRAY100} />
              {tab}
            </Col>
          </Grid>
        </Col>
      </MainContentContainer>
    </SecuredLayout >
  )
};

SettingsScreen.routeInfo = {
  title: 'Settings',
  path: '/settings',
};

export default SettingsScreen;
