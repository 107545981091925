import React, { useContext } from "react";
import { createContext, memo, useMemo } from "react";
import Store from "store";
import { TProduct } from "type";

interface ConfigContextValues {
  product?: TProduct,
  setProduct?: (data: any) => void,
}

export const ProductFormContext = createContext<ConfigContextValues>({});

export const ProductFormProvider = memo((props: any) => {
  const { id, children } = props;
  const ProductStore = Store.useProductStore();
  const { product, setProduct } = ProductStore.useProduct(id);

  const contextValue = useMemo(() => ({
    product,
    setProduct,
  }), [product, setProduct]);

  return (
    <ProductFormContext.Provider value={contextValue}>
      {children}
    </ProductFormContext.Provider>
  );
});

export const useProductForm = () => {
  return useContext(ProductFormContext);
}
