import { Col, Row, Text } from "components";
import { COLOR, STYLES } from "const";
import React, { useMemo } from "react";
import { ScrollView, StyleSheet } from "react-native";

const Item = ({ name, value }) => {
  return (
    <Row paddingVertical={8} alignItems="center" justifyContent="space-between">
      <Text fontSize={16} fontWeight={'500'}>{name}</Text>
      <Text fontSize={16} fontWeight={'700'} color={'#E60064'}>{value}</Text>
    </Row>
  )
}

const OrderByCountry = ({ data }: any) => {
  const arr = useMemo(() => {
    return Object.keys(data || {}).map(country => ({
      name: country,
      value: data[country],
    })).sort((a, b) => a.value < b.value ? 1 : -1)
  }, [data]);

  return (
    <Col mr2 ph3 pt2 pb2 style={styles.container}>
      <Text fontSize={14} marginBottom={10} fontWeight={'700'}>Orders By Country</Text>
      <ScrollView style={{ height: 340 }}>
        {arr.map((i, idx) => (
          <Item key={idx} value={i.value} name={i.name} />
        ))}
      </ScrollView>
    </Col>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderColor: COLOR.GRAY100,
    borderWidth: 1,
    backgroundColor: COLOR.WHITE,
    ...STYLES.SHADOW,
  }
})

export default OrderByCountry;
