import { Breadcrumb } from 'antd';
import { Button, Col, Grid, Input, RatioCol, Row, Select, Text } from 'components';
import { SecuredLayout } from 'components/container';
import MainContentContainer from 'components/container/MainContentContainer';
import { COLOR, SCREEN } from 'const';
import { modalError, modalSuccess } from 'helpers';
import { useNavFunc } from 'navigation';
import React, { useEffect, useState } from 'react';
import { Image } from 'react-native';
import Store from 'store';
import { IScreen } from 'type';

const ConnectStoreScreen: IScreen = () => {
  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const { id } = route.params || {};
  const [loading, setLoading] = useState<any>({});
  const [state, setState] = useState<any>({
    type: 'shopify',
  });

  const getShopDetail = async () => {
    const res = await Store.Api.Shop.detail({ id });
    if (res) {
      setState(res.data.data);
    }
  }

  useEffect(() => {
    if (id) getShopDetail();
  }, [id]);

  const updateState = (key) => (value) => {
    setState(s => ({
      ...s,
      [key]: value,
    }))
  }

  const updateStateData = (key) => (value) => {
    setState(s => ({
      ...s,
      data: {
        ...s.data,
        [key]: value,
      }
    }))
  }

  const submit = async () => {
    setLoading({ submit: true });
    try {
      const cleanedUrl = state?.url?.endsWith('/') ? state?.url.slice(0, state?.url.length - 1) : state?.url
      const res = await Store.Api.Shop.upsert({
        id: !id ? undefined : id,
        name: state?.name,
        data: state?.data,
        url: cleanedUrl,
        type: state?.type,
      });
      if (res.data.error) {
        alert(res.data.error);
        return;
      }
      Store.Api.Shop.resellerHealthCheck();
      navigation.reset({
        index: 0,
        routes: [{ name: SCREEN.SettingsScreen, params: { id: 'brands' } }],
      });
    } catch (err) {
      alert(String(err));
    } finally {
      setLoading({ submit: false });
    }
  }

  const createDummyShopifyProduct = async () => {
    try {
      setLoading({ test: true });
      const res = await Store.Api.Shop.testShopifyConnection({
        url: state?.url,
        token: state?.data?.shopifyAccessToken,
      });
      if (res.data.error) {
        modalError({
          content: res.data.error
        })
      } else if (res.data.data.id) {
        modalSuccess({
          content: `Successfully create a dummy product: ${res.data.data.id}, please check it in the Shopify admin`
        })
      }
    } catch (err) {
      modalError({
        content: err?.message || JSON.stringify(err)
      })
    } finally {
      setLoading({ test: false });
    }
  }

  const installApp = async () => {
    // setSubmitUI({ loading: true });
    // try {
    //   const cleanedUrl = shop?.url?.endsWith('/') ? shop?.url.slice(0, shop?.url.length - 1) : shop?.url
    //   const slug = cleanedUrl.replace('.myshopify.com', '').replace('https://', '');
    //   const genNameFromSiteUrl = () => {
    //     return slug.split('-').map(v => `${v[0].toUpperCase()}${v.slice(1, v.length)}`).join(' ');
    //   }
    //   const name = shop.name || genNameFromSiteUrl();
    //   const res = await Store.Api.Shop.upsert({
    //     id: id === 'new' ? undefined : id,
    //     name: name,
    //     data: shop?.data,
    //     url: cleanedUrl,
    //     type: shop?.type,
    //   });
    //   if (res.data.success) {
    //     window.location.href = `${Request.host}/api/online-stores/shopify-app-connect?shop=${slug}`;
    //   }
    // } catch (err) {
    //   console.log(err);
    // }
    // setSubmitUI({ loading: false });
    window.location.href = `https://apps.shopify.com/connected-print-on-demand`;
  }

  return (
    <SecuredLayout>
      <MainContentContainer>
        <Col pt4 ph4>
          <Breadcrumb
            items={[
              {
                title: <a href="/settings?id=brands">Brands</a>,
              },
              {
                title: 'Store connection',
              },
            ]}
          />
          <Row justifyContent="space-between">
            <Text h2 fontWeight={'300'}>Store connection</Text>
            <Button
              title={id ? 'Save' : 'Connect'}
              onPress={submit}
              height={46}
              isLoading={loading?.submit}
              paddingHorizontal={18}
            />
          </Row>
          <Col bgWhite marginLeft={-16} paddingHorizontal={16} marginTop={24} paddingVertical={24}>
            <Grid lg="50%" xs="100%" marginHorizontal={-16} alignItems="flex-start">
              <Col marginHorizontal={16}>
                <Text h4 bold>Informations</Text>
                <Text p1 marginTop={16} color={COLOR.GRAY400}>Store name</Text>
                <Input
                  marginTop={8}
                  height={50}
                  value={state?.name}
                  onChangeText={updateState("name")}
                />
                <Text marginTop={16} p1 color={COLOR.GRAY400}>Store type</Text>
                <Select
                  marginTop={8}
                  height={50}
                  value={state?.type}
                  onChange={updateState("type")}
                  options={[
                    { label: 'Shopify', value: 'shopify' },
                    // { label: 'WooCommerce', value: 'wordpress' },
                  ]}
                />
                <Text p1 marginTop={16} color={COLOR.GRAY400}>Please install app PPG: Print on Demand. It will redirect back to here after the installation is done</Text>
                <Col alignItems="flex-start" marginTop={16}>
                  {state?.url && state?.data?.shopifyAccessToken ? (
                    <Button
                      title='Test connection (create dummy product)'
                      isLoading={loading.test}
                      backgroundColor="transparent"
                      textProps={{ color: COLOR.MAIN }}
                      borderColor={COLOR.MAIN}
                      borderWidth={1}
                      onPress={createDummyShopifyProduct}
                    />
                  ) : (
                    <Button
                      title='Install App'
                      backgroundColor="transparent"
                      textProps={{ color: COLOR.MAIN }}
                      borderColor={COLOR.MAIN}
                      borderWidth={1}
                      onPress={installApp}
                    />
                  )}
                </Col>
              </Col>
              <Col marginHorizontal={16}>
                {state?.data?.shopifyAccessToken && (
                  <>
                    <Text subtitle1 mb1>Store status: Connected</Text>
                    <Text mb2>Next step: make sure to include these blocks to your store theme</Text>

                    <RatioCol width={'100%'} ratio={1976 / 950} mb1>
                      <Image source={require("assets/img/customize_add_block_1.png")} style={{ width: '100%', height: '100%' }} />
                    </RatioCol>
                    <Text mv2>Add Personalised Button & Personalised Cart block to Product detail page using Customize</Text>
                    <RatioCol width={'100%'} ratio={1382 / 950} mb1>
                      <Image source={require("assets/img/customize_add_block_2.png")} style={{ width: '100%', height: '100%' }} />
                    </RatioCol>
                    <Text mv2>Add Personalised Cart block to Cart page using Customize</Text>
                    <RatioCol width={'100%'} ratio={1382 / 950} mb1>
                      <Image source={require("assets/img/customize_add_block_3.png")} style={{ width: '100%', height: '100%' }} />
                    </RatioCol>
                    <Text mt2>and Publish some product</Text>
                  </>
                )}
              </Col>
            </Grid>
          </Col>
        </Col>
      </MainContentContainer>
    </SecuredLayout >
  )
};

ConnectStoreScreen.routeInfo = {
  title: 'Connect store',
  path: '/settings/connect-store',
};

export default ConnectStoreScreen;
