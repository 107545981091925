import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, FieldItem, Grid, Row, Text } from 'components';
import { Switch, notification } from 'antd';
import { SVG } from 'assets';
import { View, useWindowDimensions } from 'react-native';
import { StyleSheet } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { COLOR } from 'const';
import { ValHelper } from 'helpers';
import { useUserStore } from 'store/User.Store';
import { get, isEmpty, isNil, omitBy, set } from 'lodash';
import apiClient from 'store/api-client';

const FIELDS: any = [
  [
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
  ],
  { id: 'email', label: 'Email', props: { disabled: true } },
  { id: 'password', label: 'Password' },
  { id: 'addressLine1', label: 'Address Line 1' },
  { id: 'addressLine2', label: 'Address Line 2' },
  [
    { id: 'town', label: 'Town' },
    { id: 'country', label: 'Country' },
  ],
  { id: 'postCode', label: 'Post Code' },
];

const TabProfile = () => {
  const { user, set: setUser } = useUserStore();
  const { width } = useWindowDimensions();
  const [isLoading, setIsLoading] = useState(false);
  const [profile, setProfile] = useState<any>({
    ...(user || {}),
    password: undefined,
  });

  const updateProfile = (key) => (value) => {
    if (key === "email") return;
    setProfile(s => set({ ...s }, key, value))
  }

  useEffect(() => {
    if (!isEmpty(user)) {
      setProfile({
        ...user,
        password: undefined,
      });
    }
  }, [user]);

  const onSubmit = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      const res = await apiClient.Api.User.updateProfile(omitBy({
        firstName: profile?.firstName,
        lastName: profile?.lastName,
        password: profile?.password,
        addressLine1: profile?.addressLine1,
        addressLine2: profile?.addressLine2,
        town: profile?.town,
        country: profile?.country,
        postCode: profile?.postCode,
      }, isNil));
      if (res.data.error) {
        notification.error({ message: res.data.error });
      } else if (res.data.data) {
        setUser({
          user: res.data.data,
        });
        notification.success({ message: 'Success' });
      }
    } catch (err) {
      notification.error({ message: err?.message || String(err) })
    } finally {
      setIsLoading(false);
    }
  }

  const pointWidth = Math.min(366, Math.floor(width / 4));

  const tierColor = useMemo(() => {
    if (!user) return COLOR.GRAY400;
    if (user.point >= 85000) return COLOR.GOLDEN;
    if (user.point >= 50000) return COLOR.SILVER;
    if (user.point >= 7500) return COLOR.BRONZE;
    return COLOR.GRAY400
  }, [user?.point]);

  return (
    <Grid lg={`1:${pointWidth}px`} xs="100%" marginHorizontal={-16} alignItems="flex-start">
      <Col padding={24} marginHorizontal={16}>
        {FIELDS.map((i, idx) => {
          if (i.length) {
            return (
              <Row marginRight={-16} key={idx} >
                {i.map(field => (
                  <FieldItem
                    flex1
                    key={field.id}
                    label={field.label}
                    marginRight={16}
                    value={get(profile, field.id)}
                    onChangeText={updateProfile(field.id)}
                    {...(field.props || {})}
                  />
                ))}
              </Row>
            )
          }
          return (
            <FieldItem
              key={i.id}
              label={i.label}
              value={get(profile, i.id)}
              onChangeText={updateProfile(i.id)}
              {...(i.props || {})}
            />
          )
        })}
        {/* <Text h4 marginTop={16} bold>Auto accept order</Text>
        <Col marginTop={16} alignItems="flex-start">
          <Switch />
        </Col>
        <Row
          backgroundColor={'#338BF233'} borderColor={'#338BF233'} marginTop={16}
          borderWidth={1} paddingVertical={12} paddingHorizontal={16}
        >
          <SVG.Info />
          <Text marginLeft={8} p1>If not selected, it will be a manual process</Text>
        </Row> */}
        <Button
          title='Save'
          onPress={onSubmit}
          height={46}
          marginTop={16}
          isLoading={isLoading}
          paddingHorizontal={18}
          alignSelf="flex-start"
        />
      </Col>
      <Col padding={24} marginHorizontal={16}>
        <Text marginTop={24} h4 bold>Point</Text>
        <Row marginTop={16}>
          <Text>Bronze {`(£ 7,500)`} - discount 5%</Text>
        </Row>
        <Row marginTop={8}>
          <Text>Silver {`(£ 50,000)`} - discount 10%</Text>
        </Row>
        <Row marginTop={8} marginBottom={12}>
          <Text>Gold {`(£ 85,000)`} - discount 15%</Text>
        </Row>
        <View style={[styles.balanceContainer, { backgroundColor: tierColor }]}>
          <Text ml2 subtitle1 mb2 color={COLOR.WHITE}>
            Current Point
          </Text>
          <Row ml2 minWidth={200}>
            <Ionicons name="medal-outline" size={44} color={COLOR.WHITE} />
            <Text marginLeft={6} h3 color={COLOR.WHITE}>
              {ValHelper.formatBalance(user?.point)}
            </Text>
          </Row>
        </View>
      </Col>
    </Grid>
  )
};

const styles = StyleSheet.create({
  balanceContainer: {
    shadowColor: "0000000F",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 30,
    paddingVertical: 10,
  }
})

export default TabProfile;
