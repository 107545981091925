import Request from '../Request.utils'
export interface IFromPolotnoRequestBody {
  json?: any;
}
export interface IFromPolotnoToS3RequestBody {
  json?: any;
  name?: string;
}


class ImageAPI {
  fromPolotno = async (body: IFromPolotnoRequestBody) => {
    const res = await Request.call('/api/images/from-polotno', 'POST', undefined, undefined, body, );
    return res;
  }
  fromPolotnoToS3 = async (body: IFromPolotnoToS3RequestBody) => {
    const res = await Request.call('/api/images/from-polotno-to-s3', 'POST', undefined, undefined, body, );
    return res;
  }
}
export default new ImageAPI()