import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Animated } from 'react-native';
import Col, { IColProps } from './Col';

interface ITouchFieldProps extends IColProps {
  cirle?: boolean;
  bgHovered?: string;
  withPropRender?: boolean;
  children: any;
}

const AnimationBackground = ({ isHovered, bgNormal, bgHovered }) => {
  const opacityOffset = useRef(new Animated.Value(0)).current;
  useEffect(() => {
    Animated.timing(opacityOffset, {
      toValue: isHovered ? 1 : 0,
      duration: 300,
      useNativeDriver: true,
    }).start();
  }, [isHovered]);

  const bgHoveredComputed = (() => {
    if (bgHovered) return bgHovered;
    return 'rgba(0,0,0,0.05)';
  })();

  return (
    <Col flex1 style={{ overflow: 'hidden' }}>
      <Col flex1 backgroundColor={bgNormal} />
      <Animated.View
        style={{
          position: 'absolute',
          backgroundColor: bgHoveredComputed,
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          opacity: opacityOffset,
        }}
      />
    </Col>
  );
};

const Touch = ({ bgHovered, children, increaseWidth, withPropRender, ...props }: ITouchFieldProps) => {
  const [size, setSize] = useState({
    width: props.cirle ? 40 : props.width,
    height: props.cirle ? 40 : props.height,
  });
  const [isHovered, setIsHovered] = useState(false);
  const borderRadius = props.cirle ? 20 : props.borderRadius;

  return (
    <Col
      overflow="hidden"
      borderRadius={borderRadius}
      onMouseEnter={() => {
        if (props.disabled) return;
        setIsHovered(true);
      }}
      onMouseLeave={() => {
        setIsHovered(false);
      }}
      {...props}
      height={size.height}
      width={size.width}
    >
      <Col absoluteFill>
        <AnimationBackground isHovered={isHovered} bgNormal={'transparent'} bgHovered={bgHovered} />
      </Col>
      {withPropRender ? children({ isHovered }) : children}
    </Col>
  );
};

export default Touch;
