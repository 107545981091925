import { Col, Text, Grid, Input, Button, Touch, Row } from 'components';
import React, { useState } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { Checkbox } from 'antd';
import { useDynamicResponsiveValue } from 'quickly-react';
import { SCREEN } from 'const';
import { Image } from 'react-native';
import { useUIState } from 'hooks';
import { get, set } from 'lodash';
import { logEvent } from 'helpers';

const COLOR = {
  MAIN: 'rgb(209,18,28)',
  SECOND: '#4B4E52',
  GRAY300: '#8D8D8D',
  WHITE: 'white',
}

const POLICY_CONTENT = `You confirm by ticking this box and uploading and applying Image (s) ("Image"), you warrant and represent that you own or otherwise control all of the rights to your Image (s), including all the rights necessary for you to provide, post, upload, reproduce, input or submit the Image. In addition to the warranty and representation set forth above, by uploading posting, and allowing the reproduction of an Image that contains images, photographs, pictures or that are otherwise graphical in whole or in part (“Images”), you warrant and represent that (a) you are the copyright owner of such Images, or that the copyright owner of such Images has granted you permission to use such Images or any content and/or images contained in such Images consistent with the manner and purpose of your use.`;

const Steps = ({ current, onChange }) => {
  return (
    <Row marginTop={16} marginBottom={12} alignSelf="center">
      {[0, 1, 2].map(i => (
        <Col onPress={() => onChange(i)} key={i} width={20} height={20} borderRadius={10} padding={2} borderWidth={1} borderColor={COLOR.GRAY300} marginHorizontal={16}>
          <Col flex1 borderRadius={10} backgroundColor={i === current ? COLOR.MAIN : 'rgb(241,185,202)'} />
        </Col>
      ))}
    </Row>
  )
}

const InlineSelect = ({ options, selected, onChange }) => {
  return (
    <>
      {options.map((i, idx) => (
        <Col m0 mt1 key={idx}>
          <Button
            title={i}
            height={50}
            borderWidth={1}
            backgroundColor={selected === i ? COLOR.SECOND : COLOR.WHITE}
            borderColor={COLOR.SECOND}
            textProps={{
              color: selected === i ? COLOR.WHITE : COLOR.SECOND,
              fontWeight: '400',
            }}
            onPress={() => onChange(i)}
          />
        </Col>
      ))}
    </>
  )
}

const FIELDS = [
  { label: "First Name", id: "firstName" },
  { label: "Last Name", id: "lastName" },
  { label: "Email Address", id: "email" },
  { label: "Password", id: "password" },
  { label: "Business Name", id: "data.businessName" },
  { label: "VAT Number", id: "data.vatNumber" },
  { label: "Address Line 1", id: "addressLine1" },
  { label: "Town", id: "town" },
  { label: "County", id: "country" },
  { label: "Postcode", id: "postCode" },
]

const ResellerRegister: IScreen = () => {
  const { navigate, reset, route } = useNavFunc();
  const { redirect }: any = route.params || {};
  const rV = useDynamicResponsiveValue();

  const [{ loading }, setUI] = useUIState();
  const [values, setValues] = useState<any>({});
  const [page, setPage] = useState(0);
  const [policyReaded, setPolicyReaded] = useState(false);
  const [readMore, setReadMore] = useState(false);

  const isMobile = rV({ xs: true, md: false });

  const updateValues = (key) => (value) => {
    setValues(s => set({ ...s }, key, value))
  }

  const submit = async () => {
    if (!values?.firstName || !values?.email || !values?.password) {
      alert('Please fill in at least the following info: Fist Name, Email, Password');
      return;
    }
    if (page < 2) return setPage(s => s + 1);
    if (!policyReaded) return alert('You must accept the terms and conditions');
    setUI({ loading: true });
    try {
      const res = await Store.Api.User.register({
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        password: values.password,
        addressLine1: values.addressLine1,
        town: values.town,
        country: values.country,
        postCode: values.postCode,
        role: 'reseller',
        otherData: values.data,
      });
      if (res.data.error) {
        setUI({ loading: false });
        logEvent({
          event: 'registration',
          eventAction: 'RegisterFailed',
          eventLabel: 'Failed Registration: ' + String(res.data.error)
        })
        return alert(String(res.data.error));
      }
      logEvent({
        event: 'registration',
        eventAction: 'Register',
        eventLabel: 'Successful Registration'
      })
      alert('Registration Successful...you are ready to login');
      reset(SCREEN.LoginScreen, { redirect });
    } catch (err) {
      logEvent({
        event: 'registration',
        eventAction: 'RegisterFailed',
        eventLabel: 'Failed Registration: ' + String(err)
      })
      setUI({ loading: false, errorMes: '' });
      alert(String(err));
    }
  };

  const onChangePage = (newPage) => {
    if (page < newPage) return;
    setPage(newPage);
  }

  const renderPage = () => {
    if (page === 2) {
      return (
        <>
          <Text bold fontSize={20} mb1>What's the total annual revenue of your business</Text>
          <Col alignSelf={"center"} width={"60%"}>
            <InlineSelect
              options={[
                "£0 - £7.5k",
                "£7.5k - £50k",
                "£50k - £75k",
                "£75k - £150k",
                "£150k+"
              ]}
              onChange={updateValues("data.revenue")}
              selected={get(values, "data.revenue")}
            />
          </Col>
        </>
      )
    }
    if (page === 1) {
      return (
        <>
          <Text bold fontSize={20} mb1>What describes your business best</Text>
          <Col alignSelf={"center"} width={"60%"}>
            <InlineSelect
              options={[
                "Agency",
                "Establish Business",
                "Individual Entrepreneur",
                "Global Brand",
                "Other"
              ]}
              onChange={updateValues("data.business")}
              selected={get(values, "data.business")}
            />
          </Col>
        </>
      )
    }
    return (
      <>
        <Text bold fontSize={20} mb1>Register here</Text>
        <Grid xs='100%' md='100%' lg='50%' marginHorizontal={-5}>
          {FIELDS.map(field => (
            <Col m0 mt1 key={field.id}>
              <Input
                value={get(values, field.id)}
                onChangeText={updateValues(field.id)}
                placeholder={field.label}
              />
            </Col>
          ))}
        </Grid>
      </>
    )
  }

  return (
    <Col flex1 middle={!isMobile} p2>
      <Col width={isMobile ? '100%' : '45%'} borderRadius={20} bgMain overflow={'hidden'}>
        <Col
          style={{
            backgroundColor: COLOR.MAIN,
            height: 100,
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Image source={require("assets/img/logo.png")} style={{ width: '65%', height: '70%', marginVertical: 5 }} resizeMode='contain' />
        </Col>
        <Col bgWhite p2>
          {renderPage()}
          <Steps current={page} onChange={onChangePage} />
          <Button
            isLoading={loading}
            onPress={submit}
            mt1
            title='Continue'
            solid
            width={isMobile ? '100%' : '60%'}
            alignSelf='center'
            height={45}
            backgroundColor={COLOR.MAIN}
          />
          {page === 2 && (
            <Row marginTop={8} alignItems="flex-start">
              <Checkbox checked={policyReaded} onChange={(e) => setPolicyReaded(e.target.checked)} />
              <Col flex1 marginLeft={8}>
                <Text caption numberOfLines={readMore ? undefined : 4}>{POLICY_CONTENT}</Text>
                {readMore ?
                  <Touch key="read" alignSelf={'flex-start'} onPress={() => setReadMore(s => !s)}>
                    <Text caption textDecorationLine={'underline'}>Hide</Text>
                  </Touch>
                  :
                  <Touch key="hide" alignSelf={'flex-start'} onPress={() => setReadMore(s => !s)}>
                    <Text caption textDecorationLine={'underline'}>Read more</Text>
                  </Touch>
                }
              </Col>
            </Row>
          )}
          <Touch mt1 alignSelf={'center'} p0
            onPress={() => reset(SCREEN.LoginScreen, { redirect })}
          >
            <Text textDecorationLine={'underline'}>Already registed? Login here</Text>
          </Touch>
        </Col>
      </Col>
    </Col>
  )
};

ResellerRegister.routeInfo = {
  title: 'Reseller register - Precision Proco',
  path: '/reseller-register',
};

export default ResellerRegister;
