import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width={25}
      height={24}
      fill="none"
      viewBox="0 0 25 24"
      {...props}
    >
      <Path
        fill={props.color || "#F12848"}
        d="M9.5 3v1h-5v2h1v13a2 2 0 002 2h10a2 2 0 002-2V6h1V4h-5V3h-6zm0 5h2v9h-2V8zm4 0h2v9h-2V8z"
      />
    </Svg>
  )
}

export default SvgComponent
