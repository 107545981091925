import React, { useCallback, useMemo, useState } from 'react';
import { IScreen } from 'type';
import { Col, Row, Text, Table, Touch, Select, } from 'components';
import { useNavFunc } from 'navigation';
import { COLOR, SCREEN } from 'const';
import { ValHelper, modalConfirm } from 'helpers';
import { Image, StyleSheet } from 'react-native';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { MainContentContainer, SecuredLayout } from 'components/container';
import { Pagination, Skeleton, notification } from 'antd';
import { useUserStore } from 'store/User.Store';
import { useDesignStore } from 'store/Design.Store';
import { useFocusEffect } from '@react-navigation/native';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { useShopStore } from 'store/Shop.Store';

const MyProductsScreen: IScreen = () => {
  const { navigation, route } = useNavFunc();
  const UserStore = useUserStore();
  const DesignStore = useDesignStore();
  const [filter, setFilter] = useState();
  const [loading, setLoading] = useState(false);
  const [designs, setDesigns] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(1);
  const [filterTag, setFilterTag] = useState("");
  const [filterBrand, setFilterBrand] = useState("");
  const { shops, getAll: getAllShop } = useShopStore();
  const { tags, getTags } = DesignStore;

  const getData = async (p) => {
    try {
      if (loading) return;
      setLoading(true);
      const u = await UserStore.onReady();
      const resellerId = UserStore.getResellerId(u);
      getAllShop(resellerId);
      const { list, total, error } = await DesignStore.getList(p, '', resellerId);
      if (error) {
        notification.error({ message: error });
        return;
      }
      setTotalRows(total);
      setDesigns(list);
    } catch (err) {
      notification.error({ message: err?.message || JSON.stringify(err) });
    } finally {
      setLoading(false);
    }
  };

  useFocusEffect(useCallback(() => {
    getData(1);
    getTags();
  }, []));

  const allShop = useMemo(() => {
    if (!shops) return [];
    const arr = [];
    Object.keys(shops).forEach(id => {
      arr.push({
        label: shops[id]?.name,
        value: id,
        data: shops[id],
      })
    })
    return arr;
  }, [shops]);

  const onChangeFilter = (data) => {
    setFilter(data?.value);
  }

  const statusFilterOpts = useMemo(() => [
    { label: "Published", value: "published" },
    { label: "Not Published", value: "not_published" },
  ], [])

  const filteredDesign = useMemo(() => {
    // if (!filter) return designs;
    let result = designs ? [...designs] : [];
    // if (filter === "published") result = result.filter(i => i.products?.length > 0);
    if (filterBrand) result = result.filter(i => i.brands?.some(i => i.storeId === filterBrand));
    if (filterTag) result = result.filter(i => i.tags?.includes(filterTag));
    return result;
  }, [designs, filterBrand, filterTag])

  const confirmDelete = (record) => {
    modalConfirm({
      title: 'Delete',
      content: 'Once deleted this product cannot be recovered. Do you wish to continue?',
      onOk: async () => {
        const res = await DesignStore.deleteDesign(record.id);
        if (res) setDesigns(s => s.filter(i => i.id !== record.id));
      },
    })
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Product Image',
      key: 'productImage',
      render: (record) => (
        <Image source={{ uri: record.image }} style={styles.productImg} resizeMode='contain' />
      )
    },
    {
      title: 'Product Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Store',
      key: 'store',
      render: (record) => (
        <Text style={{ color: record.products.length > 0 ? '#437C01' : undefined, fontWeight: 'bold' }}>
          {record?.products?.map(v => v.brand)?.join(', ')}
        </Text>
      )
    },
    {
      title: 'Resale Price',
      dataIndex: 'resalePrice',
      key: 'resalePrice',
      render: (value) => `£${ValHelper.formatMoney(value)}`,
    },
    {
      title: 'Date Created',
      key: 'created',
      render: (record) => (
        <Text>{moment(record.createdAt).format('DD/MM/YYYY')}</Text>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: (record) => (
        <Text style={{ color: record.products.length > 0 ? '#437C01' : undefined, fontWeight: 'bold' }}>{record.products.length > 0 ? 'Published' : 'Not published'}</Text>
      ),
    },
    {
      title: '',
      key: 'actions',
      width: 160,
      render: (record) => {
        const products = record?.products;
        return (
          <Row justifyContent="flex-end">
            <Touch
              opacity={products?.length > 0 ? 1 : 0.5}
              width={40} height={40} middle mr1
              disabled={!products?.length}
              onPress={() => {
                if (products?.[0]?.url) window.open(products?.[0]?.url);
              }}
            >
              <MaterialCommunityIcons name="store" size={26} color={COLOR.FONT} />
            </Touch>
            <Touch
              onPress={() => {
                navigation.navigate(SCREEN.CreateResellerProductScreen, {
                  designId: record.id,
                  productId: record.productId,
                });
              }}
              width={40} height={40} middle mr1
            >
              <MaterialCommunityIcons name="eye" size={26} color={COLOR.FONT} />
            </Touch>
            <Touch width={40} height={40} middle onPress={() => confirmDelete(record)}>
              <MaterialCommunityIcons name="trash-can" size={26} color={COLOR.FONT} />
            </Touch>
          </Row>
        )
      },
    },
  ];

  return (
    <SecuredLayout>
      <MainContentContainer>
        <Col pt4 pl4>
          <Row pr4>
            <Text h2 fontWeight={'300'} mr3>My Products</Text>
            <Text mr2>Filter by</Text>
            <Select
              height={40}
              minWidth={150}
              value={filterBrand ? [filterBrand] : undefined}
              onChange={setFilterBrand}
              options={allShop}
              placeholder="Brand"
              mr2
            />
            <Select
              height={40}
              minWidth={150}
              value={filterTag ? [filterTag] : undefined}
              onChange={setFilterTag}
              options={tags.map(i => ({ label: i, value: i }))}
              placeholder="Tags"
              mr2
            />
          </Row>
          {loading ?
            <Col bgWhite p2 mt3 marginLeft={-16}>
              <Skeleton active />
            </Col>
            :
            <Col bgWhite p2 mt3 marginLeft={-16}>
              <Table
                data={filteredDesign}
                columns={columns}
                rowKey={record => `${record.id}`}
                minWidth={800}
                onRow={(record, rowIndex) => {
                  return {
                    onClick: (event) => {
                      navigation.navigate(SCREEN.CreateResellerProductScreen, {
                        designId: record.id,
                        productId: record.productId,
                      });
                    },
                  }
                }}
                loading={loading}
              />
              {/* <Col marginTop={24}>
                <Pagination
                  current={currentPage}
                  pageSize={10}
                  total={totalRows}
                  onChange={(page) => setCurrentPage(page)}
                />
              </Col> */}
            </Col>
          }
        </Col>
      </MainContentContainer>
    </SecuredLayout >
  );
};

const styles = StyleSheet.create({
  productImg: {
    width: 100,
    height: 100,
    borderWidth: 1,
    borderColor: "#E8E8E8",
  }
})

MyProductsScreen.routeInfo = {
  title: 'My Products',
  path: '/my-products',
};

export default MyProductsScreen;
