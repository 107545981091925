import { SVG } from 'assets';
import { Button, Col, Grid, Row, Text, SummaryItem, ButtonGroup, DatePicker, WalletBalance, SummaryItemNew, RevenueChart, OrderByCountry } from 'components';
import { SecuredLayout } from 'components/container';
import MainContentContainer from 'components/container/MainContentContainer';
import { COLOR, SCREEN } from 'const';
import React, { useEffect, useMemo, useState } from 'react';
import { IScreen } from 'type';
import TableInvoices from './TableInvoices';
import { usePaymentStore } from 'store/Payment.Store';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavFunc } from 'navigation';
import apiClient from 'store/api-client';
import { useUserStore } from 'store/User.Store';
import { useOrderStore } from 'store/Order.Store';
import { ValHelper } from 'helpers';
import moment from 'moment';

const DashboardScreenReseller: IScreen = () => {
  const { navigate } = useNavFunc();
  const { user } = useUserStore();
  const { stripePublishKey, getStripeConfig, getExistedCard } = usePaymentStore();
  const { reportData, orderReportAll } = useOrderStore();
  const [data, setData] = useState({ reports: [], totalRevenue: 0, revenue: [], ordersByCountry: {} });

  const orderReportNew = async () => {
    const res = await apiClient.Api.Order.getReport({});
    if (res.data.success) {
      setData(res.data || {});
    }
  }

  useEffect(() => {
    // orderReportAll();
    orderReportNew();
  }, []);

  const summaries = [
    { label: "Job bag", value: reportData?.pendingOrderToday },
    { label: "Accepted jobs", value: reportData?.acceptedOrderToday },
    { label: "Fulfilled jobs", value: reportData?.fulfilledOrder },
    // { label: "Total value", value: `£${ValHelper.formatMoney(reportData?.totalProcessedValueMonthly)}` },
  ]

  useEffect(() => {
    getStripeConfig();
    getExistedCard();
  }, []);

  useEffect(() => {
    if (user?.role === "reseller") {
      apiClient.Api.Shop.resellerHealthCheck();
    }
  }, [user]);

  const stripePromise = useMemo(() => {
    if (!stripePublishKey) return;
    return loadStripe(stripePublishKey);
  }, [stripePublishKey]);

  const openWalletScreen = () => {
    navigate(SCREEN.SettingsScreen, { id: "billing" });
  }

  const todayReport = useMemo(() => {
    return data?.reports?.find(i => i.dateNo === moment().format("YYYYMMDD"))
  }, [data]);

  const thisMonthReport = useMemo(() => {
    const arr = data?.revenue?.filter(i =>
      Number(i.dateNo) >= Number(moment().startOf("month").format("YYYYMMDD"))
      && Number(i.dateNo) <= Number(moment().endOf("month").format("YYYYMMDD"))
    )
    return {
      orders: arr.reduce((t, report) => {
        return t + Number(report.orders);
      }, 0),
      products: arr.reduce((t, report) => {
        return t + Number(report.products);
      }, 0),
      value: arr.reduce((t, report) => {
        return t + Number(report.value);
      }, 0),
      paid: arr.reduce((t, report) => {
        return t + Number(report.paid);
      }, 0),
      paidOrders: arr.reduce((t, report) => {
        return report.paid ? t + 1 : t;
      }, 0),
    }
  }, [data]);

  return (
    <SecuredLayout>
      <MainContentContainer>
        <Col pt4 pl4>
          <Row justifyContent="space-between" paddingRight={32}>
            <Text h3 fontWeight={'300'}>Dashboard</Text>
          </Row>
          {/* <Row mt2 flexWrap={"wrap"}>
            {summaries.map(i => (
              <SummaryItem key={i.label} {...i} />
            ))}
            <Elements stripe={stripePromise}>
              <WalletBalance mr2 onPress={openWalletScreen} />
            </Elements>
          </Row> */}
          <Grid mt2 xs="100%" md="33.333%">
            <SummaryItemNew label="Todays Sales" value={`£${ValHelper.formatMoney(todayReport?.value || 0)}`} subTitle={`We have sold ${todayReport?.products || 0} items (${todayReport?.orders || 0} orders)`} />
            <SummaryItemNew label="Orders This Month" value={`£${ValHelper.formatMoney(thisMonthReport?.value || 0)}`} subTitle={`We have sold ${thisMonthReport?.products || 0} items (${thisMonthReport?.orders || 0} orders)`} />
            <SummaryItemNew label="Spent This Month" value={`£${ValHelper.formatMoney(thisMonthReport?.paid || 0)}`} subTitle={`${thisMonthReport?.paidOrders || 0} orders paid`} />
          </Grid>
          <Grid mt2 xs="100%" md="2:1">
            <RevenueChart label="Total Revenue" data={data} />
            <OrderByCountry label="Orders By Country" data={data?.ordersByCountry} />
          </Grid>
          <TableInvoices />
        </Col>
      </MainContentContainer>
    </SecuredLayout>
  )
};

DashboardScreenReseller.routeInfo = {
  title: 'Dashboard',
  path: '/dashboard-reseller',
};

export default DashboardScreenReseller;
