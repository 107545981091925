import Request from '../Request.utils'
export interface ILoginRequestBody {
  email: string;
  password: string;
}
export interface IRegisterRequestBody {
  __key?: string;
  addressLine1?: string;
  addressLine2?: string;
  country?: string;
  email: string;
  firstName: string;
  lastName?: string;
  otherData?: any;
  password: string;
  photoUrl?: string;
  postCode?: string;
  role: string;
  town?: string;
}
export interface IGetUploadPresignedUrlRequestBody {
  contentType: string;
  serverSidePath: string;
}
export interface IListResellersRequestQuery {
  page?: number;
}
export interface IDetailRequestParams {
  id: string;
}
export interface IUpsertResellerRequestBody {
  addressLine1?: string;
  addressLine2?: string;
  country?: string;
  currency?: string;
  email?: string;
  firstName?: string;
  id?: string;
  isAutoAccept?: boolean;
  lastName?: string;
  otherData?: any;
  password?: string;
  photoUrl?: string;
  postCode?: string;
  town?: string;
}
export interface IUpsertResellerUserRequestBody {
  addressLine1?: string;
  addressLine2?: string;
  country?: string;
  email?: string;
  firstName?: string;
  id?: string;
  lastName?: string;
  onlineStoreId?: string;
  otherData?: any;
  password?: string;
  photoUrl?: string;
  postCode?: string;
  town?: string;
}
export interface IListResellerUserRequestQuery {
  page?: number;
}
export interface IDetailResellerUserRequestParams {
  id: string;
}
export interface IUpdateProfileRequestBody {
  addressLine1?: string;
  addressLine2?: string;
  country?: string;
  firstName?: string;
  isAutoAccept?: '';
  lastName?: string;
  password?: string;
  postCode?: string;
  town?: string;
}
export interface IGetPackingSlipsRequestParams {
  id: string;
}
export interface IUpsertPackingSlipsRequestBody {
  address?: string;
  companyName?: string;
  email?: string;
  phone?: string;
}
export interface IUpsertPackingSlipsRequestParams {
  id: string;
}
export interface ICheckAutoAcceptOrderRequestParams {
  id: string;
}
export interface IGetUserEditorImagesRequestParams {
  userId: string;
}
export interface ISetUserEditorImagesRequestBody {
  images: string[];
}
export interface ISetUserEditorImagesRequestParams {
  userId: string;
}


class UserAPI {
  login = async (body: ILoginRequestBody) => {
    const res = await Request.call('/api/users/login', 'POST', undefined, undefined, body, );
    if (res.data.data && res.data.data.token) Request.setToken(res.data.data.token)
    return res;
  }
  logout = async () => {
    const res = await Request.call('/api/users/logout', 'POST', undefined, undefined, undefined, );
    Request.setToken('')
    return res;
  }
  register = async (body: IRegisterRequestBody) => {
    const res = await Request.call('/api/users/register', 'POST', undefined, undefined, body, );
    return res;
  }
  me = async () => {
    const res = await Request.call('/api/users/me', 'GET', undefined, undefined, undefined, );
    return res;
  }
  getUploadPresignedUrl = async (body: IGetUploadPresignedUrlRequestBody) => {
    const res = await Request.call('/api/users/me/get-upload-url', 'POST', undefined, undefined, body, );
    return res;
  }
  listResellers = async (query: IListResellersRequestQuery) => {
    const res = await Request.call('/api/users/resellers', 'GET', undefined, query, undefined, );
    return res;
  }
  detail = async (params: IDetailRequestParams) => {
    const res = await Request.call('/api/users/:id', 'GET', params, undefined, undefined, );
    return res;
  }
  upsertReseller = async (body: IUpsertResellerRequestBody) => {
    const res = await Request.call('/api/users/resellers', 'POST', undefined, undefined, body, );
    return res;
  }
  upsertResellerUser = async (body: IUpsertResellerUserRequestBody) => {
    const res = await Request.call('/api/users/resellers-user', 'POST', undefined, undefined, body, );
    return res;
  }
  listResellerUser = async (query: IListResellerUserRequestQuery) => {
    const res = await Request.call('/api/users/resellers-user', 'GET', undefined, query, undefined, );
    return res;
  }
  detailResellerUser = async (params: IDetailResellerUserRequestParams) => {
    const res = await Request.call('/api/users-reseller/:id', 'GET', params, undefined, undefined, );
    return res;
  }
  updateProfile = async (body: IUpdateProfileRequestBody) => {
    const res = await Request.call('/api/users/me/profile', 'POST', undefined, undefined, body, );
    return res;
  }
  getPackingSlips = async (params: IGetPackingSlipsRequestParams) => {
    const res = await Request.call('/api/resellers/:id/packing-slips', 'GET', params, undefined, undefined, );
    return res;
  }
  upsertPackingSlips = async (params: IUpsertPackingSlipsRequestParams, body: IUpsertPackingSlipsRequestBody) => {
    const res = await Request.call('/api/resellers/:id/packing-slips', 'POST', params, undefined, body, );
    return res;
  }
  checkAutoAcceptOrder = async (params: ICheckAutoAcceptOrderRequestParams) => {
    const res = await Request.call('/api/reseller/:id/check-auto-accept', 'GET', params, undefined, undefined, );
    return res;
  }
  getUserEditorImages = async (params: IGetUserEditorImagesRequestParams) => {
    const res = await Request.call('/api/users/:userId/editor-images', 'GET', params, undefined, undefined, );
    return res;
  }
  setUserEditorImages = async (params: ISetUserEditorImagesRequestParams, body: ISetUserEditorImagesRequestBody) => {
    const res = await Request.call('/api/users/:userId/editor-images', 'POST', params, undefined, body, );
    return res;
  }
}
export default new UserAPI()