import React from "react";
import { Text } from "components";

const STATUS_COLORS = {
  Accepted: "green",
  Pending: "orange",
  Returned: "blue",
  Failed: "red",
  Rejected: "grey",
}

const OrderStatus = ({ status }) => {
  return (
    <Text bold p2 color={STATUS_COLORS[status] || "grey"}>{status}</Text>
  )
}

export default OrderStatus;
