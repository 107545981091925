import { notification } from "antd";
import React, { useContext, useEffect, useState } from "react";
import { createContext, memo, useMemo } from "react";
import Store from "store";
import { TUser } from "type";

interface ConfigContextValues {
  reseller?: TUser,
  setReseller?: (data: any) => void,
}

export const ResellerFormContext = createContext<ConfigContextValues>({});

export const ResellerFormProvider = memo((props: any) => {
  const { id, children } = props;
  const [reseller, setReseller] = useState<TUser>();

  useEffect(() => {
    if (id) {
      Store.Api.User.detail({ id }).then(res => {
        if (res?.data?.data) setReseller(res.data.data);
        if (res?.data?.error) notification.error({ message: res.data.error });
      }).catch(err => {
        notification.error({ message: err?.message || JSON.stringify(err) });
      });
    }
  }, [id]);

  const contextValue = useMemo(() => ({
    reseller,
    setReseller,
  }), [reseller, setReseller]);

  return (
    <ResellerFormContext.Provider value={contextValue}>
      {children}
    </ResellerFormContext.Provider>
  );
});

export const useResellerForm = () => {
  return useContext(ResellerFormContext);
}
