import React, { ReactElement } from "react";
import { Col, Text, Touch } from "../base";
import { ActivityIndicator, StyleSheet } from "react-native";
import { COLOR } from "const";
import { IColProps } from "components/base/Col";
import { ITextProps } from "components/base/Text";

export interface ButtonProps extends IColProps {
  left?: ReactElement,
  right?: ReactElement,
  title?: string,
  textProps?: ITextProps,
  isLoading?: boolean,
}

const Button = (props: ButtonProps) => {
  const { height, left, right, backgroundColor, onPress, title, textProps, paddingHorizontal, isLoading, ...restProps } = props;

  return (
    <Touch
      height={height || 56}
      style={[styles.container, {
        backgroundColor: backgroundColor || COLOR.MAIN as any,
        paddingHorizontal: paddingHorizontal as any,
      }]}
      {...restProps}
      onPress={onPress}
    >
      {isLoading
        ? <Col pr1>
          <ActivityIndicator color={textProps?.color as string || COLOR.WHITE} />
        </Col>
        : null
      }
      {left}
      <Text
        marginHorizontal={8}
        color={COLOR.WHITE}
        fontWeight="bold"
        p1
        textAlign="center"
        {...textProps}
      >
        {title}
      </Text>
      {right}
    </Touch>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
})

export default Button;
