import { Col, Text, Button, Row } from 'components';
import React, { useState, useEffect, useCallback } from 'react';
import { IScreen } from 'type';
import { useNavFunc } from 'navigation';
import { useDynamicResponsiveValue } from 'quickly-react';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator } from 'react-native';
import Store from 'store';
import { useUIState } from 'hooks';
import apiClient from 'store/api-client';
import { useFocusEffect } from '@react-navigation/native';
import { logEvent } from 'helpers';

const ClaimMyStore: IScreen = () => {
  const { navigate, reset, route } = useNavFunc();
  const { storeId, resellerId } : any = route.params || {};
  const UserStore = Store.useUserStore();
  const rV = useDynamicResponsiveValue();
  const [{ loading }, setUI] = useUIState();
  const isMobile = rV({ xs: true, md: false });
  const [errorMes, setErrorMes] = useState('');
  const [needLogin, setNeedLogin] = useState(false);
  const [success, setSuccess] = useState(false);
  const [alreadyClaimedByAnotherReseller, setAlreadyClaimedByAnotherReseller] = useState(false);

  useFocusEffect(useCallback(() => {
    let myStoreId = storeId;
    let storeResellerId = resellerId;
    const saved = localStorage.getItem('ClaimMyStore');
    if ((!myStoreId || myStoreId === 'undefined') && !!saved) {
      myStoreId = saved;
    }
    const savedResellerId = localStorage.getItem('ClaimMyStore_ResellerId');
    if ((!resellerId || resellerId === 'undefined') && !!savedResellerId) {
      storeResellerId = savedResellerId;
    }
    setUI({ loading: true });
    UserStore.onReady().then((u) => {
      setUI({ loading: false });
      if (!u) {
        localStorage.setItem('ClaimMyStore', storeId);
        localStorage.setItem('ClaimMyStore_ResellerId', resellerId);
        setNeedLogin(true);
      } else if (u.role === 'admin') {
        localStorage.setItem('ClaimMyStore', storeId);
        localStorage.setItem('ClaimMyStore_ResellerId', resellerId);
        setErrorMes('You need to login using reseller account to continue');
      } else {

        if (storeResellerId !== 'await-claim' && storeResellerId !== UserStore.getResellerId(u)) {
          setAlreadyClaimedByAnotherReseller(true);
          setErrorMes('This store is already connected and claimed by another reseller. Please connect another store or contact us for more information.');
          return;
        }

        apiClient.Api.Shop.shopifyAppClaimMyStore({
          storeId: myStoreId,
        })
        .then(res => {
          if (res.data.success && res.data.data) {
            logEvent({
              event: 'connect-store',
              eventAction: 'Connected',
              eventLabel: 'Store Connected',
            })
            setSuccess(true);
            setTimeout(() => {
              navigate(SCREEN.ConnectStoreScreen, { id: myStoreId })
            }, 3000);
          } else if (res.data.error) {
            logEvent({
              event: 'connect-store',
              eventAction: 'ConnectFailed',
              eventLabel: res.data.error,
            })
            setErrorMes(res.data.error);
          }
        })
        .catch(err => {
          setErrorMes(String(err));
          logEvent({
            event: 'connect-store',
            eventAction: 'ConnectFailed',
            eventLabel: String(err),
          })
        })
      }
    });
  }, [storeId, resellerId]));

  return (
    <Col flex1 middle={!isMobile} p2>
      {errorMes ? (
        <>
          <Text center>{errorMes}</Text>
          {alreadyClaimedByAnotherReseller && (
            <Row>
              <Button
                solid
                title='Connect new store'
                height={40}
                borderRadius={10}
                mt1
                mr1
                onPress={() => {
                  window.location.href = `https://apps.shopify.com/connected-print-on-demand`;
                }}
              />
              <Button
                solid
                title='Dashboard Homepage'
                height={40}
                borderRadius={10}
                mt1
                onPress={() => {
                  reset(SCREEN.DashboardScreenReseller)
                }}
              />
            </Row>
          )}
        </>
      ) : needLogin ? (
        <>
          <Text center>Click to login or register and finish store connection process</Text>
          <Row>
            <Button
              solid
              title='Login'
              width={150}
              height={40}
              borderRadius={10}
              mt1
              mr1
              onPress={() => {
                navigate(SCREEN.LoginScreen, { redirect: SCREEN.ClaimMyStore })
              }}
            />
            <Button
              solid
              title='Register'
              width={150}
              height={40}
              borderRadius={10}
              mt1
              onPress={() => {
                navigate(SCREEN.ResellerRegister, { redirect: SCREEN.ClaimMyStore })
              }}
            />
          </Row>
          {/* <Button
            solid
            text='Login'
            width={300}
            height={40}
            borderRadius={10}
            mt1
            onPress={() => {
              navigate(SCREEN.Login, { redirect: SCREEN.ClaimMyStore })
            }}
          /> */}
        </>
      ) : loading ? (
        <ActivityIndicator size='large' color={COLOR.MAIN} />
      ) : success ? (
        <>
          <Text center mb1>You have successfully connected new store!</Text>
          <Text center>You will be redirected shortly...</Text>
        </>
      ) : null}
    </Col>
  )
};

ClaimMyStore.routeInfo = {
  title: 'Claim my store - Precision Proco - Print Manager',
  path: '/claim-my-stores/:storeId',
};

export default ClaimMyStore;
