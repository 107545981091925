import { Button, Col, Touch } from 'components';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal } from 'antd';
import { Image, ScrollView, StyleSheet, useWindowDimensions } from 'react-native';
import { COLOR } from 'const';
import apiClient from 'store/api-client';
import { isEmpty } from 'lodash';

const MOCK = [
  "https://picsum.photos/id/237/200/200",
  "https://picsum.photos/id/227/200/200",
  "https://picsum.photos/id/253/200/200",
  "https://picsum.photos/id/233/200/200",
  "https://picsum.photos/id/235/200/200",
]

const ProductImagesModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [images, setImages] = useState([]);
  const [selectedImg, setSelectedImg] = useState('');
  const { width } = useWindowDimensions();

  const imageWidth = Math.min(width - 32 * 2, 430);

  useImperativeHandle(ref, () => ({
    open: async (record) => {
      setShow(true);
      const line_items = record["Raw Data"]?.line_items || [];
      let arr = [];
      for (let i = 0; i < line_items.length; i++) {
        const variant = line_items[i];
        if (!variant.sku) continue;
        const findPrintJob = variant.properties.find(val => val.name === 'Print Job');
        const previewUrl = await(async () => {
          if (findPrintJob?.value) {
            const res = await apiClient.Api.PrintJob.detail({ id: findPrintJob.value });
            return res?.data?.data?.previewUrl;
          }
        })();
        if (previewUrl) arr.push(previewUrl);
      }
      if (isEmpty(arr)) {
        const items = record?.Pipelines?.[record?.Pipelines?.length - 1]?.SharedData?.canBeProcessedItems;
        arr = (items || []).map(i => i?.previewUrl).filter(Boolean);
      }
      setImages(arr);
      if (arr.length) setSelectedImg(arr[0]);
    },
    hide: () => {
      setShow(false);
    },
  }))

  return (
    <Modal
      open={show}
      title="Product Images"
      onOk={() => setShow(false)}
      onCancel={() => setShow(false)}
      footer={false}
    >
      <Col alignItems="center" marginTop={16}>
        <Image
          source={{ uri: selectedImg }}
          style={{ width: imageWidth, height: imageWidth, resizeMode: "contain" }}
        />
        <ScrollView horizontal style={{ marginTop: 16 }} contentContainerStyle={{ padding: 4 }}>
          {images.map(img => {
            return (
              <Touch marginRight={8} key={img} onPress={() => setSelectedImg(img)}>
                <Image
                  source={{ uri: img }}
                  style={styles.smallImg}
                />
              </Touch>
            )
          })}
        </ScrollView>
      </Col>
    </Modal>
  )
});

const styles = StyleSheet.create({
  smallImg: {
    width: 82,
    height: 82,
    borderWidth: 1,
    borderColor: COLOR.GRAY100,
    resizeMode: "contain",
  }
})

export default ProductImagesModal;
