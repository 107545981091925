import create from 'zustand';
import { useEffect, useState, Dispatch } from 'react';
import { IStateUI, useUIState } from 'hooks';
import { TOnlineStore } from 'type';
import Client from './api-client';
import Store from 'store';

interface IShopStore {
  shops: {
    [ShopId: string]: TOnlineStore,
  },
  list: {
    totalPage: number,
    page: number,
    data: TOnlineStore[],
    nextPage: number,
  },
  getList(p: number, resellerId?: string): Promise<{ hasNext: boolean, list: Array<TOnlineStore>, total: number, error?: string, }>,
  getAll(resellerId?: string): Promise<Array<TOnlineStore>>,
  useShop(id: string, useCache?: boolean): {
    shop: TOnlineStore | undefined,
    uiState: IStateUI,
    setShop: Dispatch<TOnlineStore>,
  },
  [otherKey: string]: any,
}

export const useShopStore = create<IShopStore>((set, get) => ({
  shops: {},
  list: {
    totalPage: null,
    page: 1,
    data: [],
    nextPage: null,
  },
  getList: async (page = 1, resellerId) => {
    const res = await Client.Api.Shop.list({ page, resellerId });

    if (res.data.success) {
      const { hasNext, list, total } = res.data.data;
      const obj: IShopStore['products'] = {}
      res.data.data.list.forEach((j: TOnlineStore) => {
        obj[j.id] = j;
      });
      set({
        shops: { ...get().shops, ...obj },
        list: {
          totalPage: Math.ceil(total / 10),
          data: list,
          page,
          nextPage: hasNext ? total + 1 : undefined,
        }
      });
    }
    return res.data.data;
  },
  getAll: async (resellerId) => {
    const res = await Client.Api.Shop.all({ resellerId });

    if (!res.data.success) {
      return {
        list: [],
        total: 0,
        error: res.data.error,
      }
    }

    if (res.data.data && Array.isArray(res.data.data)) {
      const obj = {}
      res.data.data.forEach((j: TOnlineStore) => {
        obj[j.id] = j;
      });
      set({ shops: { ...get().shops, ...obj } });
    }
    return res.data.data;
  },
  useShop: (id: string, useCache = true) => {

    const [uiState, setUI] = useUIState();
    const [shop, setShop] = useState<TOnlineStore>();

    useEffect(() => {
      if (!id || id === 'new') return;
      if (useCache && !!get().shops[id]) {
        setShop(get().shops[id]);
        return;
      }
      (async () => {
        setUI({ fetching: true });
        try {
          const res = await Store.Api.Shop.detail({ id });
          if (res.data.error) {
            setUI({ fetching: false, errorMes: res.data.error });
            return;
          }
          if (res.data.data) {
            setShop(res.data.data);
            setUI({ fetching: false, errorMes: '' });
          }
        } catch (err) {
          setUI({ fetching: false, errorMes: String(err) });
        }
      })();
    }, [id, useCache]);

    return { shop, setShop, uiState };
  }
}));
