import { Button as AButton } from "antd";
import { ColumnsType } from "antd/es/table";
import { Row, Table, Text } from "components";
import { SCREEN } from "const";
import moment from "moment";
import { useNavFunc } from "navigation";
import React from "react";

const TableResellers = ({ loading, data }) => {
  const { navigate } = useNavFunc();

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'email',
      key: 'name',
    },
    {
      title: 'Address',
      key: 'address',
      render: (record) => (
        <Text>{[record.addressLine1, record.town, record.country].filter(Boolean).join(', ')}</Text>
      )
    },
    {
      title: 'Date Created',
      key: 'created',
      render: (record) => (
        <Text>{moment(record.createdAt).format("YYYY-MM-DD")}</Text>
      )
    },
    {
      title: '',
      key: 'actions',
      width: 100,
      render: (record) => (
        <Row>
          <AButton
            onClick={() => {
              navigate(SCREEN.ResellerDetailScreen, { id: record.id });
            }}
          >
            View detail
          </AButton>
        </Row>
      ),
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      loading={loading}
    />
  )
}

export default TableResellers;
