import { Breadcrumb, notification } from 'antd';
import { Button, Col, FieldItem, Row, Text } from 'components';
import { SecuredLayout } from 'components/container';
import MainContentContainer from 'components/container/MainContentContainer';
import { useNavFunc } from 'navigation';
import { useDynamicResponsiveValue } from 'quickly-react';
import React, { useEffect, useState } from 'react';
import apiClient from 'store/api-client';
import { IScreen } from 'type';

const FIELDS: any = [
  { id: 'email', label: 'Email' },
  { id: 'password', label: 'Password' },
  [
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
  ],
  { id: 'address', label: 'Address' },
  [
    { id: 'town', label: 'Town' },
    { id: 'country', label: 'Country' },
  ],
  { id: 'postCode', label: 'Post Code' },
];

const AddUserScreen: IScreen = () => {
  const rV = useDynamicResponsiveValue(['xs', 'md', 'lg']);
  const size = rV({ xs: '100%', md: '80%', lg: '60%' });
  const [isLoading, setIsLoading] = useState(false);
  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const [user, setUser] = useState<any>(route.params?.user || {});


  useEffect(() => {

  }, []);

  const onSubmit = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      const res = await apiClient.Api.User.upsertResellerUser({
        id: user?.id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        password: user?.password,
        addressLine1: user?.address || '',
        town: user?.town || '',
        country: user?.country || '',
        postCode: user?.postCode || '',
      });
      if (res.data.error) {
        notification.error({ message: res.data.error });
      } else if (res.data.data) {
        navigation.goBack();
        notification.success({ message: 'Success' });
      }
    } catch (err) {
      notification.error({ message: err?.message || String(err) })
    } finally {
      setIsLoading(false);
    }
  }

  const updateUser = (key) => (value) => {
    setUser(s => ({
      ...s,
      [key]: value,
    }));
  }

  return (
    <SecuredLayout>
      <MainContentContainer>
        <Col pt4 ph4>
          <Breadcrumb
            items={[
              {
                title: <a href="/settings?id=users">Users</a>,
              },
              {
                title: user?.id ? 'Edit User' : 'Add New User',
              },
            ]}
          />
          <Row justifyContent="space-between" mt2>
            <Text h2 fontWeight={'300'}>{user?.id ? "Edit User" : "Add User"}</Text>
            <Button
              title='Save'
              onPress={onSubmit}
              height={46}
              paddingHorizontal={18}
            />
          </Row>
          <Col bgWhite p3 mt3 pb4>
            <Col maxWidth={700} width={size} alignSelf="center">
              {FIELDS.map((i, idx) => {
                if (i.length) {
                  return (
                    <Row marginRight={-16} key={idx} >
                      {i.map(field => (
                        <FieldItem
                          flex1
                          key={field.id}
                          label={field.label}
                          marginRight={16}
                          value={user[field.id]}
                          onChangeText={updateUser(field.id)}
                        />
                      ))}
                    </Row>
                  )
                }
                return (
                  <FieldItem
                    key={i.id}
                    label={i.label}
                    value={user[i.id]}
                    onChangeText={updateUser(i.id)}
                  />
                )
              })}
            </Col>
          </Col>
        </Col>
      </MainContentContainer>
    </SecuredLayout >
  )
};

AddUserScreen.routeInfo = {
  title: 'Add User',
  path: '/settings/add-user',
};

export default AddUserScreen;
