import { Pagination } from "antd";
import { Col, Row, Text } from "components/base";
import { COLOR } from "const";
import moment from "moment";
import React from "react";
import { StyleSheet } from "react-native";
import { useNotificationStore } from "store/Notification.Store";

const NotificationItem = ({ title, time, seen, onPress }) => {
  return (
    <Col padding={4} onPress={onPress} width={240}>
      <Text bold>{title}</Text>
      <Text caption marginTop={2} color={COLOR.GRAY400}>{time}</Text>
      {!Boolean(seen) && <Col style={styles.dot} />}
    </Col>
  )
}

const Notifications = () => {
  const { notifications, readNoti, total, page, getList } = useNotificationStore();

  return (
    <Col width={234} height={200} overflow="scroll">
      {notifications.map(noti => (
        <NotificationItem
          key={noti.id}
          title={noti.title}
          time={moment(noti.createdAt).format("DD/MM/YYYY HH:mm")}
          seen={noti.seen}
          onPress={() => {
            window.open(noti.url);
            readNoti(noti.id);
          }}
        />
      ))}
      {total >= 10 && (
        <Col alignItems="center" alignSelf="center">
          <Pagination
            current={page}
            pageSize={10}
            total={total}
            onChange={(page) => getList(page)}
          />
        </Col>
      )}
    </Col>
  )
}

const styles = StyleSheet.create({
  dot: {
    width: 6,
    height: 6,
    borderRadius: 3,
    backgroundColor: "red",
    position: "absolute",
    right: 8,
    top: 8,
  }
});

export default Notifications;
