import React, { useCallback, useState } from 'react';
import { Button, Col, Row, Table, Text } from 'components';
import { SCREEN } from 'const';
import { useNavFunc } from 'navigation';
import { TUser } from 'type';
import { useUserStore } from 'store/User.Store';
import { notification } from 'antd';
import { useFocusEffect } from '@react-navigation/native';
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { Button as AButton } from "antd";

const TabUsers = () => {
  const { navigate } = useNavFunc();
  const UserStore = useUserStore();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<Array<TUser>>([]);

  const getData = async (p) => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      const { list, error } = await UserStore.getListResellerUsers(p);
      if (error) {
        notification.error({ message: error });
      } else {
        setUsers(list);
      }
    } catch (err) {
      notification.error({ message: err?.message });
    } finally {
      setIsLoading(false);
    }
  };

  useFocusEffect(useCallback(() => {
    getData(1);
  }, []));


  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'email',
      key: 'name',
    },
    {
      title: 'Address',
      key: 'address',
      render: (record) => (
        <Text>{[record.addressLine1, record.town, record.country].filter(Boolean).join(', ')}</Text>
      )
    },
    {
      title: 'Date Created',
      key: 'created',
      render: (record) => (
        <Text>{moment(record.createdAt).format("YYYY-MM-DD")}</Text>
      )
    },
    {
      title: '',
      key: 'actions',
      width: 100,
      render: (record) => (
        <Row>
          <AButton
            onClick={() => {
              navigate(SCREEN.AddUserScreen, { user: {
                ...record,
                password: undefined,
              } });
            }}
          >
            View detail
          </AButton>
        </Row>
      ),
    },
  ];

  return (
    <Col padding={24}>
      <Table
        data={users}
        columns={columns}
      />
      <Button
        title='Add user'
        onPress={() => navigate(SCREEN.AddUserScreen)}
        height={46}
        marginTop={16}
        paddingHorizontal={18}
        alignSelf="flex-start"
      />
    </Col>
  )
};

export default TabUsers;
