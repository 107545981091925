import create from 'zustand';
import { TPrintJob } from 'type';
import Client from './api-client';

interface IPrintJobStore {
  jobs: {
    [jobId: string]: TPrintJob,
  },
  getList(p: number, isPrinted?: boolean): Promise<{ hasNext: boolean, list: Array<TPrintJob>, total: number, error?: string, }>,
  [otherKey: string]: any,
}

export const usePrintJobStore = create<IPrintJobStore>((set, get) => ({
  jobs: {},
  getList: async (page = 1, isPrinted = false) => {
    const boolNum = isPrinted ? 1 : 0;
    const res = await Client.Api.PrintJob.list({ page, isPrinted: boolNum, readyForPrint: 1 });
    if (!res.data.success) {
      return {
        hasNext: false,
        list: [],
        total: 0,
        error: res.data.error,
      }
    }
    if (res.data.data.list && Array.isArray(res.data.data.list)) {
      const obj : IPrintJobStore['jobs'] = {}
      res.data.data.list.forEach((j : TPrintJob) => {
        obj[j.id] = j;
      });
      set({ jobs: { ...get().jobs, ...obj } });
    }
    return res.data.data;
  }
}));
