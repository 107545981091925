import React, { useImperativeHandle, forwardRef, useRef } from 'react';
import Store from 'store';

interface IProps {
  isMulti?: boolean,
  onUploaded(urls: Array<string>): void,
  onUploading?: () => void,
  accept?: string,
  saveImage?: boolean,
  shortenUrl?: boolean,
}

const UploadBlendFile = forwardRef((props: IProps, ref) => {
  const inputRef = useRef(null);

  const clearFiles = () => {
    if (!inputRef.current) return;
    inputRef.current.value = '';
    inputRef.current.type = '';
    inputRef.current.type = 'file';
  }

  const onChange : React.InputHTMLAttributes<HTMLInputElement>['onChange'] = async e => {
    const files = Array.from(e.target.files);
    if (files.length === 0) return props.onUploaded([]);
    props.onUploading?.();
    
    const toBeProcessedFiles = props.isMulti ? files : [files[0]];

    const promiseArr = toBeProcessedFiles.map(async (val : File) => {
      const uploadName = val.name.replace(/\s/g, '');
      const serverSidePath = `ppg/blend-files/${uploadName}`;
      try {
        const res1 = await Store.Api.User.getUploadPresignedUrl({
          serverSidePath,
          contentType: val.type || 'application/octet-stream',
        });
        const awsUrl = res1.data.data
        if (!awsUrl) return undefined;

        const res2 = await fetch(awsUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': val.type || 'application/octet-stream',
          },
          body: val,
        });

        if (res2.status !== 200) return undefined;
        const u = new URL(awsUrl);
        return `${u.origin}${u.pathname}`;
      } catch(err){
        return undefined;
      }
    });

    const urls = await Promise.all(promiseArr);
    props.onUploaded(urls.filter(val => !!val));
    clearFiles();
  }

  useImperativeHandle(ref, () => ({
    showDialog: () => {
      inputRef.current?.click();
    },
  }));

  return (
    <input accept={props.accept} ref={inputRef} type="file" style={{ display: 'none' }} onChange={onChange} />
  );
});

export default UploadBlendFile;
