
import { SVG } from "assets";
import { Col, ImageItem, Row, Text, Touch, UploadFile, Button } from "components";
import { COLOR } from "const";
import React, { useRef, useState } from "react";
import { ActivityIndicator, Image, StyleSheet } from "react-native";
import { useProductForm } from "./FormContext";
import { useUserStore } from "store/User.Store";
import { EditorIframeModal } from "components/container";
import { modalConfirm } from "helpers";
import { debounce } from "lodash";

const debounceUpdatePreview = debounce(function (previewUrl, setProduct) {
    modalConfirm({
        title: 'Thumbnail',
        content: 'Do you want to use this product\'s preview as Main Image?',
        onOk: async () => {
            setProduct(s => ({
                ...s,
                image: previewUrl
            }))
        },
    })
}, 1000);

const ProductGlobalEditorButton = () => {
    const editorModalRef = useRef(null);
    const { product, setProduct } = useProductForm();
    const onclickEdit = () => {
        editorModalRef.current?.open();
    }

    const onSaveVariants = ({ canvas, previewUrl, selecteds }) => {
        if (!selecteds?.length) return;
        const skuPDFs = [...(product?.data?.skuPDFs || [])];
        selecteds.forEach(variantName => {
            const idx = skuPDFs.findIndex(i => i.variantName === variantName);
            if (idx >= 0) {
                skuPDFs[idx] = {
                    ...skuPDFs[idx],
                    canvas,
                }
                if (previewUrl) skuPDFs[idx].previewUrl = previewUrl;
            }
        })
        setProduct(p => ({
            ...p,
            data: {
                ...p?.data,
                skuPDFs: skuPDFs,
            }
        }));
    }

    return (
        <>
            <Button
                title={'Upload Your Design'}
                onPress={onclickEdit}
                height={46}
                width={'40%'}
                mt2
                alignSelf={'center'}
                paddingHorizontal={18}
            />
            <EditorIframeModal
                ref={editorModalRef}
                physicalWidth={product?.physicalWidth}
                physicalHeight={product?.physicalHeight}
                canvas={product?.data?.globalCanvas}
                product={product}
                artworkType={product?.data?.artworkType}
                productType={product?.data?.type}
                onchangeCanvas={({ canvas, previewUrl }) => {
                    if (previewUrl) {
                        debounceUpdatePreview(previewUrl, setProduct);
                    }
                    setProduct(s => ({
                        ...s,
                        data: {
                            ...s?.data,
                            globalCanvas: canvas,
                        }
                    }))
                }}
                onchangeVariantsCanvas={onSaveVariants}
            />
        </>
    )
};

export default ProductGlobalEditorButton; 
