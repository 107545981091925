import { Col, Row, Text } from "components";
import { COLOR } from "const";
import React from "react";
import { TCustomer } from "type";

export const genShopifyAddress = (data) => {
  if (!data) return '';
  const arr = [
    `${data.first_name || ""} ${data.last_name || ""}`,
    data.address1,
    data.address2,
    data.city,
    data.zip,
    data.country,
  ]
  return arr.filter(Boolean).join("\n");
}

export const genCustomerInfo = (data: TCustomer) => {
  if (!data) return '';
  const arr = [
    `${data.first_name || ""} ${data.last_name || ""}`,
    data.email,
    data.phone,
  ]
  return arr.filter(Boolean).join("\n");
}

export const InfoItem = ({ title, value }) => {
  return (
    <Col marginHorizontal={12} paddingVertical={16} borderBottomWidth={1} borderColor={COLOR.GRAY100}>
      <Text p2 bold color={COLOR.GRAY400}>{title}</Text>
      <Text p1 marginTop={16}>{value}</Text>
    </Col>
  )
}

export const HInfoItem = ({ title, children, value }: { title: string, children?: any, value?: string }) => {
  return (
    <Row paddingVertical={16} borderBottomWidth={1} borderColor={COLOR.GRAY100} justifyContent="space-between" paddingRight={24}>
      <Text p1>{title}</Text>
      {value ? <Text caption1 p1>{value}</Text> : children}
    </Row>
  )
}
