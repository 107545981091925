import { Col, Text } from "components";
import { COLOR, STYLES } from "const";
import React from "react";
import { StyleSheet, View } from "react-native";

const SummaryItemSm = ({ value, label }) => {
  return (
    <Col ph3 style={styles.container}>
      <Text h5 color={'#E60064'}>{value}</Text>
      <Text p2 color={COLOR.GRAY300}>{label}</Text>
    </Col>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    marginRight: 6,
    borderColor: COLOR.GRAY100,
    borderWidth: 1,
    backgroundColor: COLOR.WHITE,
    paddingTop: 2,
    paddingBottom: 4,
    ...STYLES.SHADOW,
  }
})

export default SummaryItemSm;
