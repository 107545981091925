import { Button, Col, ImageItem, Input, Row, Text, UploadFile } from "components";
import { COLOR } from "const";
import React, { useRef, useState } from "react";
import Store from "store";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useProductForm } from "./FormContext";

const TabPackaging = () => {
  const uploadRef = useRef<{ showDialog: Function }>(null);
  const [loading, setLoading] = useState(false);
  const { user } = Store.useUserStore();
  const { product, setProduct } = useProductForm();
  const canEdit = user?.role === 'admin';

  const onChangeProductData = (key) => (value) => {
    setProduct(p => ({
      ...p,
      data: {
        ...p.data,
        [key]: value,
      }
    }))
  }

  const onPressAddFile = () => {
    uploadRef.current?.showDialog();
  }

  const onImageUploaded = urls => {
    if (urls.length === 0) return setLoading(false);
    let packagingImages = [...(product.data?.packagingImages || [])];
    packagingImages.push(urls[0]);
    setProduct(p => ({
      ...p,
      data: {
        ...p.data,
        packagingImages,
      }
    }))
    setLoading(false);
  }

  const onPressRemove = (idx) => {
    let packagingImages = [...(product.data?.packagingImages || [])];
    packagingImages.splice(idx, 1);
    setProduct(p => ({
      ...p,
      data: {
        ...p.data,
        packagingImages,
      }
    }))
  }

  return (
    <Col paddingTop={16}>
      <Text p1 color={COLOR.GRAY400}>Weight {`(g)`}</Text>
      <Input
        marginTop={16}
        height={50}
        value={product?.data?.weight || ''}
        onChangeText={(v) => onChangeProductData("weight")(v)}
        inputProps={{ editable: canEdit }}
      />
      <Text marginTop={24} p1 color={COLOR.GRAY400}>Package Weight {`(g)`}</Text>
      <Input
        marginTop={16}
        height={50}
        value={product?.data?.packageWeight || ''}
        onChangeText={(v) => onChangeProductData("packageWeight")(v)}
        inputProps={{ editable: canEdit }}
      />
      <Text marginTop={24} p1 color={COLOR.GRAY400}>Packing Description</Text>
      <Col marginTop={16}>
        <ReactQuill
          value={product?.data?.packingDescription}
          modules={{
            clipboard: {
              matchVisual: false,
            },
          }}
          onChange={(v) => onChangeProductData("packingDescription")(v)}
        />
      </Col>
      <Button
        height={50}
        title="Upload packaging image"
        mt3
        isLoading={loading}
        onPress={onPressAddFile}
      />
      <Row marginHorizontal={-7} flexWrap="wrap" mt2>
        {product?.data?.packagingImages?.map((i, idx) => (
          <ImageItem
            uri={i}
            key={i}
            onPressRemove={() => onPressRemove(idx)}
            canRemove={canEdit}
            style={{ width: 150, height: 150 }}
          />
        ))}
      </Row>
      <UploadFile
        ref={uploadRef}
        onUploaded={onImageUploaded}
        onUploading={() => setLoading(true)}
      />
    </Col>
  );
}

export default TabPackaging;
