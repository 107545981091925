import React, { useMemo } from 'react';
import { Text } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as ScreenObjects from 'screens';
import { IScreen } from 'type';

// specify all navigation here
const Stack = createStackNavigator();

const Navigation = () => {
  const { linking, screenArr } = (() => {
    const screenUrls = {};
    const screenArr = [];
    Object.keys(ScreenObjects).forEach((screenName) => {
      const ScreenObj: IScreen = ScreenObjects[screenName];
      screenArr.push({ name: screenName, component: ScreenObj, title: ScreenObj.routeInfo.title });
      if (!screenUrls[screenName]) {
        screenUrls[screenName] = ScreenObj.routeInfo.path;
      }
    });
    return {
      linking: screenUrls,
      screenArr,
    };
  })();

  return (
    <NavigationContainer
      linking={{ config: { screens: linking }, prefixes: [] }}
      documentTitle={{
        formatter: (options, route) => `${options?.title ?? route?.name}`,
      }}
      fallback={<Text>Loading...</Text>}
    >
      <Stack.Navigator screenOptions={{ header: () => null }}>
        {useMemo(() => {
          return screenArr.map((val, i) => (
            <Stack.Screen key={'screen-' + val + i} name={val.name} component={val.component} options={{ title: val.title }} />
          ));
        }, [])}
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default Navigation;
