import React from "react";
import { Grid } from "components";
import { InfoItem, genCustomerInfo, genShopifyAddress } from "./InfoItem";
import { TCMSOrder } from "type";

const ShippingInfo = ({ order }: { order: TCMSOrder }) => {
  if (!order) return null;
  return (
    <>
      <Grid
        xs="100%"
        lg="50%"
        marginHorizontal={-12}
        marginBottom={24}
        alignItems={"flex-start"}
      >
        <InfoItem title="Order Details" value={genShopifyAddress(order?.['Raw Data']?.shipping_address)} />
        <InfoItem title="Billing Address" value={genShopifyAddress(order?.['Raw Data']?.billing_address)} />
      </Grid>
      <Grid
        xs="100%"
        lg="50%"
        marginHorizontal={-12}
        alignItems={"flex-start"}
      >
        <InfoItem title="Contact Details" value={genCustomerInfo(order?.['Raw Data']?.customer)} />
        <InfoItem title="Postage & Packaging" value={""} />
      </Grid>
    </>
  )
}

export default ShippingInfo;
