import { Col, Text } from "components";
import { COLOR, STYLES } from "const";
import { ValHelper } from "helpers";
import moment from "moment";
import React, { useCallback, useState } from "react";
import { StyleSheet } from "react-native";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

const RevenueChart = ({ data }: any) => {
  const [width, setWidth] = useState(0);

  const onLayout = useCallback((e) => {
    setWidth(e.nativeEvent.layout.width - 100);
  }, []);

  return (
    <Col mr2 ph3 pt2 pb2 style={styles.container} onLayout={onLayout}>
      <Text fontSize={14} marginBottom={8} fontWeight={'700'}>Total Revenue</Text>
      <Text fontSize={24} marginBottom={16} fontWeight={'700'} color={'#E60064'}>{`£${ValHelper.formatMoney(data?.totalRevenue || 0)}`}</Text>
      <BarChart
        width={width}
        height={300}
        data={data?.revenue?.reverse().map(i => ({ name: moment(i.month, "YYYYMM").format("MM/YYYY"), Value: i.value, Paid: i.paid }))}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="Value" fill={COLOR.MAIN} />
        <Bar dataKey="Paid" fill={COLOR.MAIN300} />
      </BarChart>
    </Col>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderColor: COLOR.GRAY100,
    borderWidth: 1,
    backgroundColor: COLOR.WHITE,
    ...STYLES.SHADOW,
  }
})

export default RevenueChart;
