import { ColumnsType } from "antd/es/table";
import { SVG } from "assets";
import { Button, Col, DatePicker, Grid, Input, Row, Table, Text } from "components";
import { COLOR } from "const";
import { ValHelper } from "helpers";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useUserStore } from "store/User.Store";
import apiClient, { TInvoice } from "store/api-client";
import { saveAs } from 'file-saver';

let XLSX;

const TableCustomer = () => {
  const [filterMonth, setFilterMonth] = useState(moment().format("MM-YYYY"));
  const [isLoading, setLoading] = useState(false);
  const { user, getResellerId } = useUserStore();
  const [data, setData] = useState<TInvoice[]>([]);
  const [searchKeyword, setSearchKeyword] = useState('');

  useEffect(() => {
    if (!XLSX) {
      import('xlsx').then(xlsx => {
        XLSX = xlsx;
      });
    }
  }, []);

  const getReportData = async ({
    month = filterMonth,
  }) => {
    const params: any = {
      month: moment(month, "MM-YYYY").format("YYYYMM"),
    };
    setLoading(true);
    if (user?.role !== "admin") {
      params.resellerId = getResellerId(user);
    }
    try {
      const res = await apiClient.Api.Order.orderInvoices(params);
      setData(res.data?.invoices?.filter(i => Boolean(i.lineItems)) || []);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const downloadExcel = () => {
    var wb = XLSX.utils.book_new();
    const formatedData = [];
    displayOrders.forEach((v: TInvoice) => {
      const prices = v.lineItems?.map(i => {
        const printJobID = i.properties.find(i => i.name === "Print Job")?.value;
        return printJobID;
      }).filter(Boolean)
        .map(i => `£${ValHelper.formatMoney(v.prices?.[i])}`)
        .join('\n');

      formatedData.push({
        'Job Number': `#${v.orderNumber}`,
        'Store / Brand': v.store,
        'Product SKU': v.lineItems?.map(i => i.name).join('\n'),
        'Quantity': v.lineItems?.map(i => i.quantity).join('\n'),
        'Our price ex vat': prices,
        // 'Postage cost': v.data?.shippingFee ? `£${ValHelper.formatMoney(v.data?.shippingFee)}` : '',
        'VAT': `£${ValHelper.formatMoney(v.taxes)}`,
        'Total': `£${ValHelper.formatMoney(v.total)}`,
        'Paid': v.paidAt ? moment(v.paidAt).format("DD-MM-YYYY") : "",
        'Forfilled': v.paidAt ? moment(v.fulfilledAt).format("DD-MM-YYYY") : "",
        'Customer Name': [v.customerInfo?.first_name, v.customerInfo?.last_name].filter(Boolean).join(" "),
        'Customer Email': v.customerInfo?.email,
      })
    });
    XLSX.utils.book_append_sheet(wb, XLSX.utils.json_to_sheet(formatedData), "Orders");

    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    saveAs(new Blob([wbout], { type: "application/octet-stream" }), `export--${moment(filterMonth).format('MM/yyyy')}.xlsx`);
  };

  useEffect(() => {
    if (user?.role) {
      getReportData({});
    }
  }, [user, filterMonth]);

  const displayOrders = useMemo(() => {
    if (!data?.length) return [];
    if (!searchKeyword) return data;
    return data.filter(i => {
      const _customer = i.customerInfo;
      return ValHelper.isKeywordInFields([
        i.orderId, i.orderNumber, i.store, JSON.stringify(i.lineItems),
        _customer?.first_name, _customer?.last_name, _customer?.email,
      ], searchKeyword)
    })
  }, [data, searchKeyword]);

  const columns: ColumnsType<TInvoice> = [
    {
      title: 'Job Number',
      key: 'jobNumber',
      render: (order: TInvoice) => (
        <Text>#{order.orderNumber}</Text>
      )
    },
    {
      title: 'Store',
      key: 'productName',
      render: (order: TInvoice) => (
        <Text>{order.store}</Text>
      )
    },
    {
      title: 'Product SKU',
      key: 'productSKU',
      render: (order: TInvoice) => {
        const str = order.lineItems?.map(i => i.name).join('\n\n');
        return (
          <Text>{str}</Text>
        )
      }
    },
    {
      title: 'Quantity',
      key: 'quantity',
      render: (order: TInvoice) => {
        const str = order.lineItems?.map(i => i.quantity).join('\n\n');
        return (
          <Text>{str}</Text>
        )
      }
    },
    {
      title: 'Our price ex vat',
      key: 'priceExVAT',
      render: (order: TInvoice) => {
        const str = order.lineItems?.map(i => i.id).filter(Boolean)
          .map(i => `£${ValHelper.formatMoney(order.prices?.[i])}`)
          .join('\n\n');
        return (
          <Text>{str}</Text>
        )
      }
    },
    {
      title: 'Postage cost',
      key: 'postageCost',
      render: (order: TInvoice) => (
        <Text>{order.data?.shippingFee ? `£${ValHelper.formatMoney(order.data?.shippingFee)}` : ''}</Text>
      )
    },
    {
      title: 'VAT',
      key: 'vat',
      render: (order: TInvoice) => (
        <Text>£{ValHelper.formatMoney(order.taxes)}</Text>
      )
    },
    {
      title: 'Total',
      key: 'total',
      render: (order: TInvoice) => (
        <Text>£{ValHelper.formatMoney(order.total)}</Text>
      )
    },
    {
      title: 'Paid',
      key: 'paid',
      width: 130,
      render: (order: TInvoice) => (
        <Text>{order.paidAt ? moment(order.paidAt).format("DD-MM-YYYY HH:mm") : ""}</Text>
      )
    },
    // {
    //   title: 'Fulfilled',
    //   key: 'forfilled',
    //   width: 130,
    //   render: (order: TInvoice) => (
    //     <Text>{order.fulfilledAt ? moment(order.fulfilledAt).format("DD-MM-YYYY HH:mm") : ""}</Text>
    //   )
    // },
    {
      title: 'Customer Name',
      key: 'customerName',
      render: (order: TInvoice) => (
        <Text>{[order.customerInfo?.first_name, order.customerInfo?.last_name].filter(Boolean).join(" ")}</Text>
      )
    },
    {
      title: 'Customer Email',
      key: 'customerEmail',
      render: (order: TInvoice) => (
        <Text>{order.customerInfo?.email}</Text>
      )
    },
  ];

  return (
    <Col bgWhite pv3 ph3 mt3>
      <Text fontSize={14} bold>Order List</Text>
      <Text p2 color={COLOR.GRAY300} marginTop={16}>Filter by</Text>
      <Grid
        xs="100%"
        lg="any:1:any"
        marginBottom={8}
      >
        <Col marginVertical={8} width={400}>
          <Input
            height={34}
            paddingHorizontal={8}
            value={searchKeyword}
            onChangeText={setSearchKeyword}
            left={<SVG.Search size={24} color={COLOR.GRAY300} />}
            placeholder="Search by Order No / Product / Customer"
            marginRight={12}
          />
        </Col>
        <Col marginVertical={8}>
          <Row>
            <Text p1>Month</Text>
            <DatePicker
              marginLeft={8}
              value={filterMonth}
              onChange={setFilterMonth}
              type="month"
            />
          </Row>
        </Col>
        <Col marginVertical={8} alignItems="flex-start">
          <Button
            left={<SVG.Excel color={COLOR.WHITE} />}
            title='Export'
            height={46}
            paddingHorizontal={18}
            onPress={downloadExcel}
          />
        </Col>
      </Grid>
      <Table
        data={displayOrders}
        loading={isLoading}
        rowKey={"key"}
        rowClassName={(record, index) => {
          return index % 2 === 0 ? 'table-row-light' : 'table-row-dark';
        }}
        columns={columns}
      />
    </Col>

  )
}

export default TableCustomer;
