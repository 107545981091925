export const logEvent = (data: {
  event?: string,
  eventCategory?: string,
  eventAction?: string,
  eventLabel: string,
}) => {
  try {
    // @ts-ignore
    if (window.dataLayer) {
      // @ts-ignore
      window.dataLayer.push({
        event: data.event || 'registration',
        eventCategory: data.event || 'User',
        eventAction: data.event || 'Register',
        eventLabel: data.eventLabel,
      })
    }
  } catch (error) {

  }
}
