import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M4.5 2A2.491 2.491 0 002 4.5v15C2 20.887 3.112 22 4.5 22h15c1.387 0 2.5-1.113 2.5-2.5v-15C22 3.112 20.887 2 19.5 2m-15 2.5h15v15h-15m6.25-13.75V7H12v6.25H7V12H5.75v3.75H7V14.5h5V17h-1.25v1.25h3.75V17h-1.25v-2.5H17v1.25h1.25V12H17v1.25h-3.75V7h1.25V5.75"
        fill={props.color || "#5F5F5F"}
      />
    </Svg>
  )
}

export default SvgComponent
