import Request from '../Request.utils'
export interface IListRequestQuery {
  page?: number;
}
export interface IReadRequestBody {
  id?: string;
}
export interface INewOrderRequestBody {
  clientId?: string;
  orderId?: string;
}


class NotificationAPI {
  list = async (query: IListRequestQuery) => {
    const res = await Request.call('/api/notifications', 'GET', undefined, query, undefined, );
    return res;
  }
  read = async (body: IReadRequestBody) => {
    const res = await Request.call('/api/notifications/read', 'POST', undefined, undefined, body, );
    return res;
  }
  newOrder = async (body: INewOrderRequestBody) => {
    const res = await Request.call('/api/notifications/new-order', 'POST', undefined, undefined, body, );
    return res;
  }
}
export default new NotificationAPI()