import React from "react";
import { Ionicons } from "@expo/vector-icons";
import { Col, IColProps, Row, Text } from "components/base";
import { COLOR } from "const";
import { ValHelper } from "helpers";
import { usePaymentStore } from "store/Payment.Store";
import { ImageBackground, StyleSheet } from "react-native";
import MoneyValue from "./MoneyValue";

interface Props extends IColProps {
  currency?: string,
  onRenderAmount?: (amount: any) => void,
}

const WalletBalance = (props: Props) => {
  const { currency, onRenderAmount, ...restProps } = props;
  const { balance } = usePaymentStore();
  if (!balance) return null;

  const _balance = (balance?.balance * -1) || 0;

  return (
    <MoneyValue
      baseAmountGBP={(_balance || 0) / 100}
      toCurrency={currency}
      renderingProp
    >
      {({ amount }) => {
        onRenderAmount?.(amount);
        return (
          <Col {...restProps}>
            <ImageBackground
              style={[
                styles.balanceContainer,
                { backgroundColor: amount < 0 ? '#BC0100' : COLOR.MAIN },
              ]}
              source={require("assets/img/bg-balance.png")}
            >
              <Text marginLeft={14} subtitle1 mb2 color={COLOR.WHITE}>
                Current Balance
              </Text>
              <Row marginLeft={14} minWidth={200}>
                <Ionicons name="wallet-outline" size={44} color={COLOR.WHITE} />
                <Text marginLeft={16} h3 color={COLOR.WHITE}>
                  {String(currency || "GBP").toUpperCase()}
                </Text>
                <Text marginLeft={6} h3 color={COLOR.WHITE}>
                  {ValHelper.formatBalance(amount)}
                </Text>
              </Row>
            </ImageBackground>
          </Col>
        )
      }}
    </MoneyValue>
  )
}

const styles = StyleSheet.create({
  balanceContainer: {
    shadowColor: "0000000F",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 30,
    paddingVertical: 10,
  }
})

export default React.memo(WalletBalance);
