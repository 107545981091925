
import { SVG } from "assets";
import { Col, ImageItem, Row, Text, UploadFile } from "components";
import { COLOR } from "const";
import React, { useRef, useState } from "react";
import { ActivityIndicator, StyleSheet } from "react-native";
import { useUserStore } from "store/User.Store";


const ProductImagesReseller = ({ design, setDesign }: any) => {
  const uploadRef = useRef<{ showDialog: Function }>(null);
  const [loading, setLoading] = useState(false);
  const [imageType, setImageType] = useState('main');
  const { user } = useUserStore();
  const isAdmin = user?.role === "admin";

  const onPressRemove = (idx) => {
    const arr = [...design?.data?.galleries];
    arr.splice(idx, 1);
    setDesign(p => ({
      ...p,
      data: {
        ...p.data,
        galleries: arr,
      }
    }))
  }

  const onImageUploaded = urls => {
    if (urls.length === 0) return setLoading(false);
    let newDesign = { ...design };
    switch (imageType) {
      case 'main':
        newDesign.image = urls[0];
        break;
      case 'galleries':
        const oldImgs = newDesign.data?.galleries || [];
        newDesign.data = {
          ...design.data,
          galleries: [
            ...urls,
            ...oldImgs,
          ],
        }
        break;
    }
    setDesign(newDesign);
    setLoading(false);
  }

  return (
    <Col alignItems="center">
      <Text p1 color={COLOR.GRAY300} marginTop={24}>Main Image</Text>
      {design?.image ?
        <Col style={styles.mainImageContainer}>
          <ImageItem
            uri={design?.image}
            style={styles.mainImage}
            onPressRemove={() => {
              setDesign(s => ({
                ...s,
                image: "",
              }))
            }}
            canRemove
          />
        </Col>
        :
        <Col style={styles.addImageBtn} onPress={() => {
          setImageType('main');
          uploadRef.current?.showDialog();
        }}>
          {loading && imageType === 'main' ?
            <ActivityIndicator color={COLOR.MAIN} />
            :
            <SVG.ImagePlus width={32} height={32} />
          }
          <Text marginTop={12} p2 textAlign='center'>
            Drag and drop file or
            <Text color='#E60064' bold>
              {` browse computer`}
            </Text>
          </Text>
        </Col>
      }
      <Text p1 color={COLOR.GRAY300} marginTop={40}>Gallery</Text>
      <Row marginTop={24} flexWrap="wrap" alignSelf="center" middle paddingHorizontal={6}>
        <Col style={styles.addImageSm} onPress={() => {
          setImageType('galleries');
          uploadRef.current?.showDialog();
        }}>
          {loading && imageType === 'galleries' ?
            <ActivityIndicator color={COLOR.MAIN} />
            :
            <SVG.ImagePlus />
          }
        </Col>
        {design?.data?.galleries?.map((i, idx) => (
          <ImageItem
            uri={i}
            key={i}
            onPressRemove={() => onPressRemove(idx)}
            canRemove={isAdmin}
          />
        ))}
      </Row>
      <UploadFile
        ref={uploadRef}
        onUploaded={onImageUploaded}
        onUploading={() => setLoading(true)}
      />
    </Col>
  )
}

const styles = StyleSheet.create({
  addImageBtn: {
    width: '40%',
    aspectRatio: 1,
    minWidth: 100,
    backgroundColor: COLOR.MAIN100,
    borderWidth: 1,
    borderColor: '#DFD6F9',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    marginTop: 14,
    padding: 16,
  },
  mainImageContainer: {
    width: '40%',
    aspectRatio: 1,
    minWidth: 100,
    marginTop: 14,
  },
  imageItem: {
    width: 72,
    aspectRatio: 1,
    borderColor: COLOR.GRAY100,
    borderWidth: 1,
  },
  addImageSm: {
    width: 72,
    height: 72,
    backgroundColor: COLOR.MAIN100,
    borderWidth: 1,
    borderColor: '#DFD6F9',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    marginHorizontal: 6,
  },
  mainImage: {
    width: '100%',
    height: '100%',
  }
})

export default ProductImagesReseller;
