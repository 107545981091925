import Client from "./index";
import Request from "./Request.utils";
class Editor {
  client: typeof Client;

  constructor(thisClient: typeof Client) {
    this.client = thisClient;
  }

  element: HTMLIFrameElement | null = null;
  didIframeLoad = false;

  init = (elementId: string) => {
    if (typeof window === "undefined") return;
    this.element = document.querySelector("#" + elementId);
    if (!this.element) throw new Error(`Iframe ${elementId} not found `);
    this.element.onload = this.initOnLoad;
  };

  initOnLoad = (event: any) => {
    this.element = event.target;
    if (!this.element) throw new Error(`Iframe not found `);
    const iframeUrl = new URL(this.element.getAttribute("src") as string);
    console.log("iframeUrl", iframeUrl);
    const sendToken = () => {
      this.element?.contentWindow?.postMessage(
        `token=${this.client.getToken()}&host=${Request.host}`,
        "*"
      );
    };
    function handlePingAndAuthenticate(event: any) {
      if (event.origin !== iframeUrl.origin) return;
      if (event.data === "ping from editor") {
        sendToken();
      }
      window.removeEventListener("message", handlePingAndAuthenticate);
    }
    window.addEventListener("message", handlePingAndAuthenticate);
    sendToken();
  };

  initOnLoadAndOpenCommunication = (fn : any) => (event: any) => {
    this.element = event.target;
    if (!this.element) throw new Error(`Iframe not found `);
    const iframeUrl = new URL(this.element.getAttribute("src") as string);
    console.log("iframeUrl", iframeUrl);
    const sendToken = () => {
      this.element?.contentWindow?.postMessage(
        `token=${this.client.getToken()}&host=${Request.host}`,
        "*"
      );
    };
    function handlePingAndAuthenticate(event: any) {
      if (event.origin !== iframeUrl.origin) return;
      if (event.data === "ping from editor") {
        sendToken();
      } else {
        typeof fn === 'function' && fn(event.data);
      }
      // window.removeEventListener("message", handlePingAndAuthenticate);
    }
    window.addEventListener("message", handlePingAndAuthenticate);
    sendToken();
  };

  authorizeParent = (origin = "*", callback?: (token: string, host: string) => void) => {
    if (typeof window === "undefined") return;
    const setToken = (t: string) => {
      this.client?.setToken(t);
    };
    window.addEventListener("message", function (event) {
      if (origin !== "*" && event.origin !== origin) return;
      if (typeof event.data === "string" && event.data.startsWith("token=")) {
        const [tokenString, hostString] = event.data.split('&');
        const token = tokenString.replace("token=", "")
        setToken(token);
        let host = '';
        if (hostString) {
          host = hostString.replace("host=", "");
          Request.setHost(host);
        }
        typeof callback === "function" && callback(token, host);
      }
    });
    window.parent.postMessage("ping from editor", "*");
  };

  sendToParent(data : { event: string, payload: any }) {
    window.parent.postMessage(data, "*");
  }

  sendMessage(event, payload: any) {
    this.element?.contentWindow?.postMessage({ event, payload }, "*");
  }
}

export default Editor;
