import Request from '../Request.utils'
export interface IListRequestQuery {
  customProduct?: number;
  page?: number;
  printOnDemand?: number;
  resellerId?: string;
  tag?: string;
  wholeSale?: number;
}
export interface IUpsertRequestBody {
  availableForResellerIds?: object;
  bluePrintImage?: string;
  category?: string;
  customProduct?: boolean;
  data?: any;
  description?: string;
  galleries?: string[];
  id?: string;
  image?: string;
  name?: string;
  packagingDescription?: string;
  physicalHeight?: number;
  physicalWidth?: number;
  previewData?: any;
  price?: number;
  printAreas?: {
    height?: number;
    left?: number;
    top?: number;
    width?: number;
  }[];
  printOnDemand?: boolean;
  tags?: string;
  variations?: {
    prices?: {
      amount?: number;
      price?: number;
    }[];
    variant?: string;
  }[];
  wholeSale?: boolean;
}
export interface IRemoveRequestParams {
  id: string;
}
export interface IDetailRequestParams {
  id: string;
}
export interface IListBySKUsRequestBody {
  skus: string[];
}
export interface IOrderASampleRequestBody {
  productId: string;
  productName: string;
  resellerId: string;
}


class ProductAPI {
  list = async (query: IListRequestQuery) => {
    const res = await Request.call('/api/products', 'GET', undefined, query, undefined, );
    return res;
  }
  upsert = async (body: IUpsertRequestBody) => {
    const res = await Request.call('/api/products', 'POST', undefined, undefined, body, );
    return res;
  }
  remove = async (params: IRemoveRequestParams) => {
    const res = await Request.call('/api/products/:id', 'DELETE', params, undefined, undefined, );
    return res;
  }
  detail = async (params: IDetailRequestParams) => {
    const res = await Request.call('/api/products/:id', 'GET', params, undefined, undefined, );
    return res;
  }
  listBySKUs = async (body: IListBySKUsRequestBody) => {
    const res = await Request.call('/api/products/list-by-skus', 'POST', undefined, undefined, body, );
    return res;
  }
  orderASample = async (body: IOrderASampleRequestBody) => {
    const res = await Request.call('/api/product-library/order-a-sample', 'POST', undefined, undefined, body, );
    return res;
  }
  productTags = async () => {
    const res = await Request.call('/api/product-tags', 'GET', undefined, undefined, undefined, );
    return res;
  }
}
export default new ProductAPI()