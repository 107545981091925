import { SVG } from "assets";
import { Col, Row, Text, Touch } from "components/base";
import { Input } from "components/elements";
import { COLOR, SCREEN } from "const";
import { useNavFunc } from "navigation";
import { useDynamicResponsiveValue } from "quickly-react";
import React, { useEffect, useMemo, useRef } from "react";
import Drawer from "./Drawer";
import Store from "store";
import { useNotificationStore } from "store/Notification.Store";
import apiClient from "store/api-client";
import { useShopifyEmbedAppFlow } from "hooks";

const SecuredLayout = ({ children }) => {
  const { navigate } = useNavFunc();
  const sideMenuRef = useRef(null);
  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const { getList: getNotifications } = useNotificationStore();
  const rV = useDynamicResponsiveValue(['xs', 'md', 'lg']);
  const isMobile = rV({ xs: true, md: true, lg: false });
  const { isEmbedAppFlow, shopify_embed_app_url, merchant_name } = useShopifyEmbedAppFlow();

  const userName = useMemo(() => {
    if (!user?.firstName && !user?.lastName) return user?.email;
    return [user?.firstName, user?.lastName].join(' ');
  }, [user])

  useEffect(() => {
    UserStore.onReady().then((u) => {
      if (!u) {
        navigate(SCREEN.LoginScreen);
      } else {
        getNotifications(1);
        if (u.role !== 'admin' && isEmbedAppFlow) {
          apiClient.Api.Shop.createNotExistShopifyEmbedApp({
            name: merchant_name,
            url: shopify_embed_app_url,
          });
        }
      }
    });
  }, [])

  const onPressLogout = () => {
    Store.Api.User.logout();
    UserStore.set({ user: null, token: "" });
    navigate(SCREEN.LoginScreen);
  };

  return (
    <Row flex1>
      {!isMobile &&
        <Drawer />
      }
      <Col flex1 height="100%">
        <Row height={70} bgWhite paddingLeft={8}>
          {isMobile &&
            <Touch padding={8} onPress={() => sideMenuRef.current?.show()}>
              <SVG.Menu width={24} height={24} />
            </Touch>
          }
          {/* <Input
            marginLeft={8}
            left={!isMobile && <SVG.Search size={24} color={COLOR.GRAY300} />}
            height={isMobile ? 40 : 50}
            inputProps={{
              placeholder: "Search by Order No",
            }}
          /> */}
          <Col flex1 />
          <Row paddingRight={8}>
            <Col marginRight={4} alignItems="flex-end">
              <Text h4 bold>{userName}</Text>
              <Text p2 style={{ textTransform: "capitalize" }} color={COLOR.GRAY300}>
                {user?.role}
              </Text>
            </Col>
            <Touch padding={8} onPress={onPressLogout}>
              <SVG.Logout />
            </Touch>
          </Row>
        </Row>
        <Col flex1>
          {children}
        </Col>
      </Col>
      {isMobile &&
        <Drawer isMobile ref={sideMenuRef} />
      }
    </Row>
  );
};

export default SecuredLayout;
