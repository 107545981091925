import React from 'react';
import { COLOR } from "./src/const";
import Navigation from './src/navigation/Navigation';
import './App.css';
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import 'react-tippy/dist/tippy.css';
import { ConfigProvider } from 'antd';
import { initalCheckShopifyEmbedAppFlow } from './src/hooks';

initalCheckShopifyEmbedAppFlow();

export default function AppWeb() {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorBorder: COLOR.GRAY200,
          colorPrimaryHover: COLOR.BLACK,
          colorPrimary: COLOR.MAIN,
          borderRadius: 0,
        },
        components: {
          Table: {
            headerBg: COLOR.GRAY100,
            headerSplitColor: COLOR.GRAY100,
            rowSelectedBg: '#FFF9ED',
            rowSelectedHoverBg: '#FFF9ED',
          },
        },
      }}
    >
      <Navigation />
    </ConfigProvider>
  );
}
