class ValHelper {
  jsUCFirst = (string) => string.charAt(0).toUpperCase() + string.slice(1);
  
  formatBalance = (balance: number) => (Math.round(balance * 100) / 100).toFixed(2);

  isKeywordInFields = (values: string[], keyword: string) => {
    if (!values?.length) return false;
    if (!keyword) return true;
    const txt = values.map(i => String(i).toLowerCase()).join('|');
    return txt.includes(keyword.toLowerCase());
  }

  formatMoney = (amount: string | number) => {
    const _amount = Number(amount || 0);
    if (Number.isNaN(_amount)) return amount || "0.00";
    return Number(amount || 0).toFixed(2)
  };

  parseNumber = (num: string | number) => {
    const _num = Number(num || 0);
    if (Number.isNaN(_num)) return 0;
    return _num
  }
}

export default new ValHelper();
