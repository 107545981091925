import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Modal } from 'antd';
import ShippingInfo from './components/ShippingInfo';

const ShippingInfoModal = forwardRef((props, ref) => {
  const [show, setShow] = useState(false);
  const [order, setOrder] = useState();

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setOrder(data);
      setShow(true);
    },
    hide: () => {
      setShow(false);
    },
  }))

  return (
    <Modal
      open={show}
      title="Shipping Address"
      onOk={() => setShow(false)}
      onCancel={() => setShow(false)}
      footer={false}
    >
      <ShippingInfo order={order} />
    </Modal>
  )
});

export default ShippingInfoModal;
