import { SVG } from "assets";
import { Button, Col, FieldItem, ImageItem, Input, Row, Table, TableForm, Text, UploadFile } from "components";
import { COLOR } from "const";
import React, { useRef, useState } from "react";
import Store from "store";
import { useProductForm } from "./FormContext";
import CreateSKUModal from "./CreateSKUModal";
import { isArray } from "lodash";
import { Tooltip } from "antd";
import { ActivityIndicator } from "react-native";
import { ValHelper } from "helpers";

const TabProductVariants = () => {
  const uploadRef = useRef<{ showDialog: Function }>(null);
  const skuModal = useRef(null);
  const { user } = Store.useUserStore();
  const { product, setProduct } = useProductForm();
  const canEdit = user?.role === 'admin';
  const [uploading, setUploading] = useState(false);

  const validateNumberInput = (label) => () => {
    if (!product) return;
    const value = (() => {
      if (!label.includes('.')) return product[label];
    })();
    if (!value) return;
    if (isNaN(+value)) {
      alert('Please input number value');
      setProduct({ ...product, [label]: 0 })
    } else {
      setProduct({ ...product, [label]: +value })
    }
  }

  const onChangeProductData = (key) => (value) => {
    setProduct(p => ({
      ...p,
      data: {
        ...p.data,
        [key]: value,
      }
    }))
  }

  const onPressAddSKU = () => {
    skuModal.current?.open();
  }

  const onRemoveSKU = (skuItem) => {
    const skuPDFs = product?.data?.skuPDFs || [];
    setProduct(p => ({
      ...p,
      data: {
        ...p?.data,
        skuPDFs: skuPDFs.filter(i => i.sku !== skuItem.sku)
      }
    }));
  }

  const onEditSKU = (skuItem, idx) => {
    skuModal.current?.open(skuItem, idx);
  }

  const onSaveSKU = (item, idx) => {
    const skuPDFs = product?.data?.skuPDFs ? [...product?.data?.skuPDFs] : [];
    if (idx === undefined) skuPDFs.push(item);
    else skuPDFs[idx] = item;
    setProduct(p => ({
      ...p,
      data: {
        ...p?.data,
        skuPDFs: skuPDFs,
      }
    }));
  }

  const onSaveVariants = ({ canvas, previewUrl, selecteds }) => {
    if (!selecteds?.length) return;
    const skuPDFs = [...(product?.data?.skuPDFs || [])];
    selecteds.forEach(variantName => {
      const idx = skuPDFs.findIndex(i => i.variantName === variantName);
      if (idx >= 0) {
        skuPDFs[idx] = {
          ...skuPDFs[idx],
          canvas,
        }
        if (previewUrl) skuPDFs[idx].previewUrl = previewUrl;
      }
    })
    setProduct(p => ({
      ...p,
      data: {
        ...p?.data,
        skuPDFs: skuPDFs,
      }
    }));
  }

  const onPressAddFile = () => {
    uploadRef.current?.showDialog();
  }

  const onFileUploaded = (urls) => {
    if (urls.length === 0) return setUploading(false);
    onChangeProductData("artworkBackground")(urls?.[0]);
    setUploading(false);
  }

  return (
    <Col>
      <Text mb2 p1 bold color={COLOR.GRAY400}>Variants</Text>
      <Table
        columns={[
          {
            title: 'Thumbnail',
            dataIndex: 'previewUrl',
            key: 'previewUrl',
            width: 80,
            render: (value) => value ? (
              <ImageItem
                uri={value}
                style={{ width: 50, height: 50 }}
                canRemove={false}
              />
            ) : null
          },
          {
            title: 'Name',
            dataIndex: 'variantName',
            render: (_, record, idx) => (
              <Row>
                <Text fontSize={17}>{record.variantName || `Variant ${idx + 1}`}</Text>
              </Row>
            )
          },
          {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
          },
          {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
          },
          {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (value) => value ? `£${ValHelper.formatMoney(value)}` : "",
          },
          canEdit ? {
            title: 'Actions',
            key: 'actions',
            width: 120,
            render: (_, record, idx) => canEdit ? (
              <Row>
                <Col width={50} height={50} onPress={() => onEditSKU(record, idx)} middle mr1>
                  <SVG.Pencil color={COLOR.MAIN} />
                </Col>
                <Col width={50} height={50} onPress={() => onRemoveSKU(record)} middle>
                  <SVG.Trash color={COLOR.MAIN} />
                </Col>
              </Row>
            ) : null
          } : undefined
        ].filter(i => i !== undefined)}
        data={product?.data?.skuPDFs}
      />
      {canEdit &&
        <Button
          height={56}
          marginTop={12}
          title="Add variant"
          backgroundColor={COLOR.SECOND}
          onPress={onPressAddSKU}
          alignSelf="flex-start"
        />
      }
      <CreateSKUModal
        ref={skuModal}
        physicalWidth={product?.physicalWidth}
        physicalHeight={product?.physicalHeight}
        onSave={onSaveSKU}
        onSaveVariants={onSaveVariants}
      />
      {/* <TableForm
        marginTop={24}
        addBtnTitle="Add SKU PDF"
        columns={[
          { key: 'sku', label: 'SKU' },
          { key: 'PPGSku', label: 'PPG SKU' },
          { key: 'code', label: 'Code' },
          { key: 'url', label: 'PDF', canUpload: true },
        ]}
        data={product?.data?.skuPDFs}
        onChange={(skuPDFs) => setProduct(p => ({
          ...p,
          data: {
            ...p?.data,
            skuPDFs
          }
        }))}
      /> */}
      <UploadFile
        ref={uploadRef}
        onUploaded={onFileUploaded}
        onUploading={() => setUploading(true)}
      />
    </Col>
  );
}

export default TabProductVariants;
