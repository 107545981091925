import React from "react";
import { usePaymentStore } from "store/Payment.Store";

type TRenderingProps = ({ balance, loading } : { balance : any, loading : boolean }) => any

const WalletBalanceRenderer = ({ children }: { children: TRenderingProps }) => {
  const { balance, loading } = usePaymentStore();
  return children({ balance, loading })
}

export default React.memo(WalletBalanceRenderer);
