import create from 'zustand';
import { useState, useEffect, Dispatch } from 'react';
import { TUser } from 'type';
import Client from './api-client';
import Store from 'store';
import { IStateUI, useUIState } from 'hooks';
interface IUserStore {
  users: {
    [userId: string]: TUser,
  },
  user: TUser | null,
  token: string,
  set: (data: any) => void,
  onReady: () => Promise<TUser | null>,
  getListResellers(p: number): Promise<{ hasNext: boolean, list: Array<TUser>, total: number, error?: string, }>,
  getListResellerUsers(p: number): Promise<{ hasNext: boolean, list: Array<TUser>, total: number, error?: string, }>,
  useUser(id: string, useCache?: boolean): {
    user: TUser | undefined,
    uiState: IStateUI,
    setUser: Dispatch<TUser>,
  },
  useResellerUser(id: string, useCache?: boolean): {
    user: TUser | undefined,
    uiState: IStateUI,
    setUser: Dispatch<TUser>,
  },
  getResellerId(u: TUser): string,
}

let u: TUser | null;

const useZustandUserStore = create<IUserStore>((set, get) => ({
  users: {},
  user: u,
  token: '',
  _ready: false,
  set,
  onReady: () => {
    // @ts-ignore
    if (get()._ready) return Promise.resolve(get().user);
    return new Promise((resolve) => {
      const intervalId = setInterval(() => {
        // @ts-ignore
        if (get()._ready) {
          clearInterval(intervalId);
          resolve(get().user);
        }
      }, 500);
    })
  },
  getListResellers: async (page = 1) => {
    const res = await Client.Api.User.listResellers({ page });
    if (!res.data.success) {
      return {
        hasNext: false,
        list: [],
        total: 0,
        error: res.data.error,
      }
    }
    if (res.data.data.list && Array.isArray(res.data.data.list)) {
      const obj: IUserStore['users'] = {}
      res.data.data.list.forEach((j: TUser) => {
        obj[j.id] = j;
      });
      set({ users: { ...get().users, ...obj } });
    }
    return res.data.data;
  },
  getListResellerUsers: async (page = 1) => {
    const res = await Client.Api.User.listResellerUser({ page });
    if (!res.data.success) {
      return {
        hasNext: false,
        list: [],
        total: 0,
        error: res.data.error,
      }
    }
    if (res.data.data.list && Array.isArray(res.data.data.list)) {
      const obj: IUserStore['users'] = {}
      res.data.data.list.forEach((j: TUser) => {
        obj[j.id] = j;
      });
      set({ users: { ...get().users, ...obj } });
    }
    return res.data.data;
  },
  useUser: (id: string, useCache = true) => {
    console.log('useUser', id);
    const [uiState, setUI] = useUIState();
    const [user, setUser] = useState<TUser>();

    useEffect(() => {
      if (id === 'new') return;
      if (useCache && !!get().users[id]) {
        setUser(get().users[id]);
        return;
      }
      (async () => {
        setUI({ fetching: true });
        try {
          const res = await Store.Api.User.detail({ id });
          if (res.data.error) {
            setUI({ fetching: false, errorMes: res.data.error });
            return;
          }
          if (res.data.data) {
            setUser(res.data.data);
            setUI({ fetching: false, errorMes: '' });
          }
        } catch (err) {
          setUI({ fetching: false, errorMes: String(err) });
        }
      })();
    }, [id, useCache]);
    // console.log('user', user);
    return { user, setUser, uiState };

  },
  useResellerUser: (id: string, useCache = true) => {
    const [uiState, setUI] = useUIState();
    const [user, setUser] = useState<TUser>();

    useEffect(() => {
      if (id === 'new') return;
      // if (useCache && !!get().users[id]) {
      //   setUser(get().users[id]);
      //   return;
      // }

      (async () => {
        setUI({ fetching: true });
        try {
          const res = await Store.Api.User.detailResellerUser({ id });
          if (res.data.error) {
            setUI({ fetching: false, errorMes: res.data.error });
            return;
          }
          if (res.data.data) {
            setUser({ ...res.data.data, ...{ onlineStoreSelect: { label: res.data.data?.onlineStore?.name, value: res.data.data?.onlineStore?.id } } });
            setUI({ fetching: false, errorMes: '' });
          }
        } catch (err) {
          setUI({ fetching: false, errorMes: String(err) });
        }
      })();
    }, [id, useCache]);
    return { user, setUser, uiState };
  },
  getResellerId: (u: TUser) => {
    console.log('getResellerId', u);
    if (!u || u.role === 'admin') return '';
    if (u.role === 'reseller') return u.id;
    return u.resellerId;
  }
}));

export const useUserStore = () => {
  const userStore = useZustandUserStore();
  useEffect(() => {
    const token = Client.getToken();
    if (!!token && !!u) {
      userStore.set({
        _ready: true
      });
      return;
    }
    if (!token || token === userStore.token) {
      userStore.set({ _ready: true });
      return;
    }
    (async () => {
      try {
        const res = await Client.Api.User.me();
        if (res.data.success) {
          userStore.set({
            token,
            user: res.data.data,
            _ready: true,
          });
          u = res.data.data;
          return;
        }
      } catch (error) {}
      userStore.set({ _ready: true });
    })();
  }, []);

  return userStore;
}
