import create from 'zustand';
import { useState } from 'react';
import { TCMSOrder, TCMSPipeline, TShopifyOrder } from 'type';
import Client from './api-client';
import moment from 'moment';
import { isNull, omitBy } from 'lodash';
const queryString = require('query-string');

const MICRO_SERVICES_HOST = 'https://services.personify.tech';

interface IOrderStore {
  clients: Array<{ 'Client Name': string, slug: string, 'Client ID': string, domain?: string }>
  orders: {
    [orderId: string]: TCMSOrder,
  },
  pipelines: {
    [pipelineId: string]: TCMSPipeline,
  },
  royalMailData: {
    [orderId: string]: any,
  },
  getListOrder(p: number, status: 'Pending' | 'Accepted' | 'Fulfilled', dateRange: { startDate: any, endDate: any }, clientId: string): Promise<{ hasNext: boolean, list: Array<TCMSOrder>, total: number, error?: string, }>,
  getListPipeline(p: number, dateRange: { startDate: any, endDate: any }): Promise<{ hasNext: boolean, list: Array<TCMSPipeline>, total: number, error?: string, }>,
  updateOrderStatus(ids: Array<string>, status: 'Pending' | 'Accepted' | 'Fulfilled'): Promise<void>,
  reportData: {
    acceptedOrderToday: number,
    pendingOrderToday: number,
    fulfilledOrder: number,
    fulfilledByMonth: {
      [month: string]: number,
    },
    orderByMonth: {
      [month: string]: number,
    },
    totalOrder: number,
    totalOrderPrice: number,
    totalProcessedItemsValue: number,
    totalOrderValueMonthly: number,
    totalProcessedValueMonthly: number,
    countByClient: {
      [clientId: string]: { clientId: string, clientName: string, total?: number, fulfilled?: number }
    }
  },
  reRunJob: (jobId: string | number, pipelineId: string | number) => Promise<void>,
  [otherKey: string]: any,
}

export const useOrderStore = create<IOrderStore>((set, get) => ({
  clients: [
    { 'Client ID': '', 'Client Name': 'Precision Proco', slug: 'ppg', domain: 'https://precisionprocogroup.co.uk/' },
  ],
  royalMailData: {},
  reportData: {
    acceptedOrderToday: 0,
    pendingOrderToday: 0,
    fulfilledOrder: 0,
    fulfilledByMonth: {},
    orderByMonth: {},
    totalOrder: 0,
    totalOrderPrice: 0,
    totalProcessedItemsValue: 0,
    totalOrderValueMonthly: 0,
    totalProcessedValueMonthly: 0,
    countByClient: {},
  },
  orders: {},
  pipelines: {},
  loadingReRun: false,
  getListOrder: async (page = 1, status, dateRange, clientId) => {
    const allClients = get().clients;
    const ppgClient = allClients[0];
    const dateQuery = `${dateRange.startDate && dateRange.endDate ? `&startDate=${moment(dateRange.startDate).format('YYYY-MM-DD')}&endDate=${moment(dateRange.endDate).format('YYYY-MM-DD')}` : ''}`
    let query = `?offset=${(page - 1) * 10}&limit=10&status=${status}${dateQuery}`;
    if (clientId) query += `&clientId=${clientId}`;
    
    const filterClients = [ppgClient];
    let resArr = await Promise.all(filterClients.map(async (c) => {
      try {
        const res = await fetch(`${MICRO_SERVICES_HOST}/api/${c.slug}/listOrder${query}`, {
          method: 'get',
        });
        const json = await res.json();
        const { list, pageInfo } = json.data;
        return {
          list: list.map(v => {
            return {
              ...v,
              ...c,
              ApiSlug: c.slug,
            }
          }),
          pageInfo
        };
      } catch (err) { }
    }));

    // save data and match pipelines
    const obj: IOrderStore['jobs'] = {};
    const pipelines = get().pipelines;
    const listAllPipelines = Object.keys(pipelines).map(OrderId => pipelines[OrderId]);

    console.log('listAllPipelines', listAllPipelines);

    resArr.forEach((res) => {
      res.list.forEach((j: TCMSOrder) => {
        obj[j['Order ID']] = j;
        const filterPipelines = listAllPipelines.filter(v => v.OrderId === j['Order ID']);
        j.Pipelines = filterPipelines;
      });
    })

    set({ orders: { ...get().orders, ...obj } });
    const mergedList = (() => {
      if (resArr.length === 1) return resArr[0].list;
      // @ts-ignore
      return (resArr.reduce((a, b) => ({ list: [...a.list, ...b.list] }))).list;
    })();

    return {
      hasNext: (() => {
        if (resArr.length === 1) return !resArr[0].pageInfo.isLastPage;
        // @ts-ignore
        return (resArr.reduce((a, b) => ({ pageInfo: { isLastPage: !a.pageInfo.isLastPage || !b.pageInfo.isLastPage ? false : true } }))).pageInfo.isLastPage === false;
      })(),
      total: (() => {
        if (resArr.length === 1) return resArr[0].pageInfo.totalRows;
        // @ts-ignore
        return (resArr.reduce((a, b) => ({ pageInfo: { totalRows: a.pageInfo.totalRows + b.pageInfo.totalRows } }))).pageInfo.totalRows;
      })(),
      list: mergedList,
    };
  },
  getListPipeline: async (page = 1, dateRange) => {
    const dateQuery = `${dateRange.startDate && dateRange.endDate ? `&startDate=${moment(dateRange.startDate).format('YYYY-MM-DD')}&endDate=${moment(dateRange.endDate).format('YYYY-MM-DD')}` : ''}`
    let query = `?offset=${(page - 1) * 100}&limit=100${dateQuery}`;
    const [res1] = await Promise.all([
      (async () => {
        const res = await fetch(`${MICRO_SERVICES_HOST}/api/ppg/pipeline/list${query}`, {
          method: 'get',
        });
        const json = await res.json();
        const { list, pageInfo } = json.data;
        return { list, pageInfo };
      })(),
    ])

    const obj: IOrderStore['pipelines'] = {}
    res1.list.forEach((j: TCMSPipeline) => {
      obj[j.OrderId] = j;
    });
    const newPipeliens = { ...get().pipelines, ...obj };
    set({ pipelines: newPipeliens });
    return {
      hasNext: !res1.pageInfo.isLastPage,
      total: res1.pageInfo.totalRows,
      list: res1.list,
    };
  },
  getPipelinesOfAnOrder: async (orderId) => {
    try {
      const res = await fetch(`${MICRO_SERVICES_HOST}/api/ppg/pipeline/list?orderId=${orderId}&limit=100&offset=0`, {
        method: 'get',
      });
      const data = await res.json();
      return data?.data?.list || [];
    } catch (error) {
      throw new Error(error);
    }
  },
  reRunJob: async (jobId, pipelineId) => {
    set({ loadingReRun: true });
    try {
      await fetch(`${MICRO_SERVICES_HOST}/api/ppg/pipeline/rerun-job`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          jobId, pipelineId
        }),
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      set({ loadingReRun: false });
    }
  },
  updateOrderStatus: async (ids, status) => {
    await Promise.all(ids.map(async (id) => {
      await fetch(`${MICRO_SERVICES_HOST}/api/ppg/updateOrderStatus?id=${id}`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          Status: status,
        }),
      });
    }));
  },
  orderReportAll: async (resellerId) => {
    const clients = (!resellerId || resellerId === 'all')
      ? get().clients
      : [get().clients.find(v => v["Client ID"] === String(resellerId) || v.slug === resellerId)];
    if (!clients?.length) return;

    const ress = await Promise.all(clients.map(async (client) => {
      try {
        const res = await fetch(`${MICRO_SERVICES_HOST}/api/${client.slug}/order-report`, {
          method: 'get',
        });
        const json = await res.json();
        return json.data;
      } catch (err) { }
    }));

    const n = (num) => (!num || isNaN(num)) ? 0 : +num;

    const reportData = {
      acceptedOrderToday: 0,
      pendingOrderToday: 0,
      fulfilledOrder: 0,
      fulfilledByMonth: {},
      orderByMonth: {},
      totalOrder: 0,
      totalOrderPrice: 0,
      totalProcessedItemsValue: 0,
      totalOrderValueMonthly: 0,
      totalProcessedValueMonthly: 0,
      countByClient: {},
    };

    ress.forEach(r => {
      reportData.acceptedOrderToday += n(r?.acceptedOrderToday);
      reportData.pendingOrderToday += n(r?.pendingOrderToday);
      reportData.fulfilledOrder += n(r?.fulfilledOrder);
      for (let month in (r?.fulfilledByMonth || {})) {
        if (r?.fulfilledByMonth?.[month]) {
          reportData.fulfilledByMonth[month] = n(reportData.fulfilledByMonth[month]) + n(r?.fulfilledByMonth?.[month]);
        }
      }

      for (let month in (r?.orderByMonth || {})) {
        if (r?.orderByMonth?.[month]) {
          reportData.orderByMonth[month] = n(reportData.orderByMonth[month]) + n(r?.orderByMonth?.[month]);
        }
      }
      reportData.fulfilledOrder += n(r?.fulfilledOrder);
      reportData.totalOrderPrice += n(r?.totalOrderPrice);
      reportData.totalProcessedItemsValue += n(r?.totalProcessedItemsValue);
      reportData.totalOrder += n(r?.totalOrder);
      reportData.totalOrderValueMonthly += n(r?.totalOrderValueMonthly);
      reportData.totalProcessedValueMonthly += n(r?.totalProcessedValueMonthly);

      Object.keys(r?.countByClient || {}).forEach(clientId => {
        const data = reportData?.countByClient?.[clientId] || {};
        const newData = r?.countByClient?.[clientId] || {};
        data.clientId = clientId;
        if (newData.clientName) data.clientName = newData.clientName;
        data.total = (data.total || 0) + (newData.total || 0);
        data.fulfilled = (data.fulfilled || 0) + (newData.fulfilled || 0);
        reportData.countByClient[clientId] = data;
      });

    })

    set({ reportData });
  },
  orderReportClientByMonth: async (slug, month, clientId) => {
    const clients = (!slug || slug === 'all')
      ? get().clients
      : [get().clients.find(v => v.slug === slug)];
    if (!clients) return;

    const shouldFilterClientId = clientId && !get().clients.some(i => i['Client ID'] === clientId);
    const ress = await Promise.all(clients.map(async (client) => {
      try {
        const res = await fetch(`${MICRO_SERVICES_HOST}/api/${client.slug}/order-report-month?month=${month}${shouldFilterClientId ? `&clientId=${clientId}` : ''}`, {
          method: 'get',
        });
        const json = await res.json();
        return {
          ...json.data,
          client,
        };
      } catch (err) { }
    }));
    const data = {
      customers: [],
      orders: [],
      products: [],
    };
    ress.forEach(r => {
      data.customers = [...data.customers, ...r.customers]
        .sort((a, b) => moment(a.created_at).unix() - moment(b.created_at).unix())
        .map(i => ({
          ...i,
          clientId: i.clientId || r.client?.['Client ID'],
          clientName: i.clientName || r.client?.['Client Name'],
        }));
      data.orders = [...data.orders, ...r.orders]
        .sort((a, b) => moment(a.created_at).unix() - moment(b.created_at).unix())
        .map(i => ({
          ...i,
          "Client ID": i['Client ID'] || r.client?.['Client ID'],
          "Client Name": i['Client Name'] || r.client?.['Client Name'],
        }));
      data.products = [...data.products, ...r.products]
        .map(i => ({
          ...i,
          clientId: i.clientId || r.client?.['Client ID'],
          clientName: i.clientName || r.client?.['Client Name'],
        }));
    });
    return data;
  },
  getPipelinesByOrderId: async (slug, orderId) => {
    const res = await fetch(`${MICRO_SERVICES_HOST}/api/${slug}/pipeline/list?limit=10&offset=0&orderId=${orderId}`, {
      method: 'get',
    });
    const json = await res.json();
    const obj: IOrderStore['pipelines'] = {}
    json.data.list.forEach((j: TCMSPipeline) => {
      obj[j.OrderId] = j;
    });
    const newPipeliens = { ...get().pipelines, ...obj };
    set({ pipelines: newPipeliens });
    return json.list;
  },
  findByOrderNo: async (params: { orderNo: string, clientId?: string, orderId?: string }) => {
    const { orderNo, orderId, clientId } = params;
    const findClient = get().clients.find(v => v['Client ID'] === clientId);
    const slugs = clientId === 'all' ? get().clients.map(v => v.slug) : [findClient?.slug || 'ppg'];
    const resArr = await Promise.all(slugs.map(async (slug) => {
      try {
        const queryPath = queryString.stringify(omitBy({
          orderNo,
          orderId
        }, isNull));

        const res = await fetch(`${MICRO_SERVICES_HOST}/api/${slug}/findByOrderId?${queryPath}`, {
          method: 'get',
        });
        const json = await res.json();
        if (json.data?.['Order ID']) {
          await get().getPipelinesByOrderId(slug, json.data?.['Order ID']);
        }
        return {
          ...json.data,
          ...findClient,
          ApiSlug: slug,
        }
      } catch (err) {
        console.log('findByOrderNo', err);
      }
    }));
    const list: Array<TCMSOrder> = resArr.filter(v => !!v && !!v.Id);
    const pipelines = get().pipelines;
    const listAllPipelines = Object.keys(pipelines).map(Id => pipelines[Id]);

    list.forEach((j: TCMSOrder) => {
      const filterPipelines = listAllPipelines.filter(v => v.OrderId === j['Order ID']);
      j.Pipelines = filterPipelines;
    });
    return list;
  },
  isInProduction: (order: TCMSOrder) => {
    const pipelines = order.Pipelines;
    if (pipelines.length === 0) return false;
    return !!pipelines[pipelines.length - 1].SharedData?.isInProduction;
  },
  updateShareData: async (order: TCMSOrder, data: any) => {
    const pipelines = order.Pipelines;
    if (pipelines.length === 0) return;
    const res = await fetch(`${MICRO_SERVICES_HOST}/api/ppg/pipeline/update-pipeline-share-data`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data, pipelineId: pipelines[pipelines.length - 1].Id }),
    });
    const json = await res.json();
    await get().getPipelinesByOrderId(order.ApiSlug || 'bg', order['Order ID']);
    return json.data;
  },
  deleteOrderByIds: async (orders: Array<TCMSOrder>) => {
    try {
      await fetch(`${MICRO_SERVICES_HOST}/api/ppg/deleteOrder`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ ids: orders.map(o => o.Id) }),
      });
    } catch (err) { }
  },
  getRoyalMailOrderData: async (orders: Array<TCMSOrder>) => {
    const obj = {};
    const orderIdentifiers = [];
    const match = {};
    orders.forEach(order => {
      if (order.Pipelines.length === 0) return;
      const last = order.Pipelines[order.Pipelines.length - 1];
      const royalMailOrderIdentifier = last.SharedData.royalMailOrderIdentifier;
      if (!royalMailOrderIdentifier) {
        obj[order['Order ID']] = null;
        return;
      }
      obj[order['Order ID']] = {
        royalMailOrderIdentifier,
        data: {},
      };
      match[royalMailOrderIdentifier] = order['Order ID'];
      orderIdentifiers.push(royalMailOrderIdentifier)
    });
    const res = await Client.Api.Shop.getRoyalMailOrderStatus({
      orderIdentifiers
    });
    if (!res.data.success) {
      console.log(res.data);
      return;
    }
    for (let orderIden in res.data.data) {
      const royalMailDetail = res.data.data?.[orderIden]?.[0];
      if (royalMailDetail) {
        const findOrderId = match[orderIden];
        if (findOrderId) {
          obj[findOrderId].data = royalMailDetail;
        }
      }
    }
    set({
      royalMailData: {
        ...get().royalMailData,
        ...obj,
      }
    });
  },
  getChargeAmount: async (orders: Array<TCMSOrder>) => {
    const res = await Client.Api.Payment.getChargeAmountFromLineItems({
      orders: orders.map(i => {
        const last = i.Pipelines[i.Pipelines.length - 1];
        return {
          line_items: last?.SharedData?.canBeProcessedItems || [],
          shipping_address: i['Raw Data'].shipping_address,
          orderId: i['Order ID'],
        };
      })
    });
    return res.data.data;
  },
  chargeFromWallet: async (orders: Array<TCMSOrder>, shipping: any) => {
    const res = await Client.Api.Payment.chargeFromWallet({
      orders: orders.map(v => {
        const last = v.Pipelines[v.Pipelines.length - 1]?.SharedData;
        return {
          orderId: v['Order ID'],
          orderNumber: v['Order Number'],
          line_items: last?.canBeProcessedItems, // last.reprint ? [] : (last?.canBeProcessedItems || []) as Array<any>,
          slug: v.ApiSlug,
          shipping_address: v['Raw Data'].shipping_address,
        };
      }),
      shippings: Object.keys(shipping).map(id => ({
        ...shipping[id],
        orderId: Number(id),
      }))
    });
    if (!res.data || res.data.error) {
      throw new Error(res.data.error);
    }

    await Promise.all(orders.map(async (order) => {
      await get().updateShareData(order, {
        isPaid: true,
        isAdminApproved: false,
      });
    }));
    return res.data.data;
  }
}));
