import create from 'zustand';
import { useEffect, useState, Dispatch, useMemo, useRef } from 'react';
import { IStateUI, useUIState } from 'hooks';
import { TProduct } from 'type';
import Client from './api-client';
import Store from 'store';

type TProductTypeQuery = {
  tag?: string,
}

interface IProductStore {
  products: {
    [productId: string]: TProduct,
  },
  tags: string[],
  list: {
    totalPage: number,
    page: number,
    data: TProduct[],
    nextPage: number,
  },
  getList(p: number, resellerId?: string, productType?: TProductTypeQuery): Promise<{ hasNext: boolean, list: Array<TProduct>, total: number, error?: string, }>,
  upsertProduct(data: any): Promise<any>,
  useProduct(id: string, useCache?: boolean): {
    product: TProduct | undefined,
    uiState: IStateUI,
    setProduct: Dispatch<TProduct>,
    hasDoneSomeEditing: boolean,
  },
}

export const useProductStore = create<IProductStore>((set, get) => ({
  products: {},
  tags: [],
  list: {
    totalPage: null,
    page: 1,
    data: [],
    nextPage: null,
  },
  getList: async (page = 1, resellerId, productType = {}) => {
    const productTypeQuery = (() => {
      const q : any = {};
      if (productType.tag !== undefined) q.tag = productType.tag;
      return q;
    })();
    const res = await Client.Api.Product.list({ page, resellerId, ...productTypeQuery });

    if (res.data.success) {
      const { hasNext, list, total } = res.data.data;
      const obj: IProductStore['products'] = {}
      res.data.data.list.forEach((j: TProduct) => {
        obj[j.id] = j;
      });
      set({
        products: { ...get().products, ...obj },
        list: {
          totalPage: Math.ceil(total / 1000),
          data: list,
          page,
          nextPage: hasNext ? total + 1 : undefined,
        }
      });
    }
    return res.data.data;
  },
  getTags: async () => {
    const res = await Client.Api.Product.productTags();

    if (res.data.success) {
      const tagStr = res.data.data;
      set({
        tags: tagStr ? tagStr.split(",") : [],
      });
    }
    return res.data.data;
  },
  upsertProduct: async (data: any) => {
    const res = await Store.Api.Product.upsert(data);
    if (res?.data?.data?.id) {
      set({
        products: {
          ...get().products,
          [res.data.data.id]: res.data.data,
        }
      })
    }
    return res;
  },
  useProduct: (id: string, useCache = true) => {

    const [uiState, setUI] = useUIState();
    // @ts-ignore
    const [product, setProduct] = useState<TProduct>({ availableForResellerIds: { all: true }});

    const countRef = useRef(0);
    const hasDoneSomeEditing = useMemo(() => {
      if (id === 'new') return true;
      if (!product) return true;
      countRef.current++;
      return countRef.current > 1 ? true : false;
    }, [id, product]);

    useEffect(() => {
      if (!id || id === 'new') return;
      if (useCache && !!get().products[id]) {
        setProduct(get().products[id]);
        return;
      }
      (async () => {
        setUI({ fetching: true });
        try {
          const res = await Store.Api.Product.detail({ id });
          if (res.data.error) {
            setUI({ fetching: false, errorMes: res.data.error });
            return;
          }
          if (res.data.data) {
            setProduct(res.data.data);
            setUI({ fetching: false, errorMes: '' });
          }
        } catch (err) {
          setUI({ fetching: false, errorMes: String(err) });
        }
      })();
    }, [id, useCache]);

    return { product, setProduct, hasDoneSomeEditing, uiState };
  }
}));
