import { Radio, Space } from "antd";
import { Col, Text } from "components";
import { ValHelper } from "helpers";
import React from "react";

const SelectShippingService = ({ onChange, value, order, options }) => {
  return (
    <Col mb2>
      <Text bold mb1>Order #{order}</Text>
      <Radio.Group onChange={(e) => onChange(e.target.value)} value={value}>
        <Space direction="vertical">
          {options.filter(i => i.code).map(i => (
            <Radio key={i.code} value={i.code}>{i.name} {`(£${ValHelper.formatMoney(i.price)})`}</Radio>
          ))}
        </Space>
      </Radio.Group>
    </Col>
  )
}

export default SelectShippingService;
