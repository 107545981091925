import React, { useState, useImperativeHandle, forwardRef, useCallback, useMemo } from 'react';
import { View, useWindowDimensions } from 'react-native';
import { Modal } from 'antd';
import Store from 'store';
import qs from 'qs';
import { useUserStore } from 'store/User.Store';

const EDITOR_DOMAIN = true ? "https://ppg-personify-editor.vercel.app" : "http://localhost:3009";

const EditorIframeModal = forwardRef((props: any, ref) => {
  const { user } = useUserStore();
  const { width, height } = useWindowDimensions();
  const [show, setShow] = useState(false);
  const [iframeKey, setIframeKey] = useState("");

  useImperativeHandle(ref, () => ({
    open: () => {
      setShow(true);
      setIframeKey(String(Math.floor(Math.random() * 10000)));
    },
    hide: () => {
      setShow(false);
    },
  }))

  const onEditorMessage = useCallback(async (
    data: { event: string; payload: any } | undefined
  ) => {
    if (!!data && data.event === "PDF_UPLOADED") {
      if (data.payload?.url) {
        props.onPDFSaved?.(data.payload);
        setShow(false);
      }
    } else if (!!data && data.event === "EDITOR_READY") {
      if ((props.canvas && Object.keys(props.canvas).length > 0) || props.product) {
        Store.Client.editor.sendMessage("INITIAL_DATA", {
          canvas: props.canvas,
          iframeKey,
          product: props.product,
          selectedVariant: props.selectedVariant?.variantName,
        });
      }
    } else if (!!data && data.event === "VARIANT_SAVED") {
      if (data.payload.canvas && Object.keys(data.payload.canvas).length > 0) {
        !!props.onchangeCanvas && props.onchangeCanvas(data.payload);
      }
    } else if (!!data && data.event === "VARIANTS_SAVED") {
      if (data.payload.canvas && Object.keys(data.payload.canvas).length > 0) {
        !!props.onchangeVariantsCanvas && props.onchangeVariantsCanvas(data.payload);
      }
    }
  }, [iframeKey, props.onchangeCanvas, props.product, props.onchangeVariantsCanvas]);

  const linkParams = useMemo(() => {
    const obj: any = {
      width: props.physicalWidth || 248,
      height: props.physicalHeight || 135,
      iframeKey,
      artworkType: props.artworkType,
      hideSavePDF: !!props.onPDFSaved ? 0 : 1,
      productType: props.productType,
      userId: user?.id,
      // god: 1,
    }
    if (user?.role === "admin") obj.admin = 1;
    if (user?.role === "reseller") obj.reseller = 1;
    return qs.stringify(obj);
  }, [user, props, iframeKey]);

  return (
    <Modal
      open={show}
      title=""
      onOk={() => setShow(false)}
      onCancel={() => setShow(false)}
      footer={false}
      width={width - 100}
      closeIcon={false}
      style={{ top: 50 }}
      className="editor-modal"
      destroyOnClose
    >
      <View
        style={{
          width: "100%",
          height: height - 100,
          margin: 0,
        }}
      >
        <iframe
          key={iframeKey}
          src={`${EDITOR_DOMAIN}/design/variant?${linkParams}`}
          style={{
            width: "100%",
            height: "100%",
            border: "none",
          }}
          onLoad={Store.Client.editor.initOnLoadAndOpenCommunication(
            onEditorMessage
          )}
        />
        <div onClick={() => setShow(false)} style={{ cursor: "pointer", width: 30, height: 30, position: "absolute", top: -30, right: -30 }}>
          <svg style={{ width: 30, height: 30 }} viewBox="0 0 24 24">
            <path fill="white" d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z" />
          </svg>
        </div>
      </View>
    </Modal>
  );
});

export default EditorIframeModal;
