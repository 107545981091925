import { Col, IColProps, Text } from "components/base";
import React from "react";
import Input, { InputProps } from "./Input";
import { COLOR } from "const";

interface Props extends IColProps {
  label: string,
  value?: string,
  onChangeText?: any,
  inputProps?: InputProps,
}

const FieldItem = ({ label, value, onChangeText, inputProps, ...props }: Props) => {
  return (
    <Col marginTop={16} {...props}>
      <Text p1 color={COLOR.GRAY400}>{label}</Text>
      <Input
        marginTop={8}
        height={50}
        value={value}
        onChangeText={onChangeText}
        {...inputProps}
      />
    </Col>
  )
}

export default FieldItem;
