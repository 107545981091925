import { SVG } from 'assets';
import { Button, Col, Grid, Row, Text, ButtonGroup, RangeDatePicker, DATE_FORMAT } from 'components';
import { SecuredLayout } from 'components/container';
import MainContentContainer from 'components/container/MainContentContainer';
import { COLOR } from 'const';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { IScreen, TCMSOrder } from 'type';
import TablePending from './TablePending';
import ProductImagesModal from './ProductImagesModal';
import ShippingInfoModal from './ShippingInfoModal';
import Store from 'store';
import moment from 'moment';
import { Pagination } from 'antd';
import { ExportHelper } from 'helpers';
import OrderActionButtons from './components/OrderActions';
import { useUserStore } from 'store/User.Store';
import AcceptOrderModal from './components/AcceptOrderModal';

const OrdersScreen: IScreen = () => {
  const imagesModalRef = useRef(null);
  const shippingModalRef = useRef(null);
  const acceptOrderModalRef = useRef(null);
  const OrderStore = Store.useOrderStore();
  const { user, getResellerId } = useUserStore();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(1);
  const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
  const [selectedTab, setSelectedTab] = useState('Pending');
  const [jobs, setJobs] = useState<Array<TCMSOrder>>([]);
  const [selectedRange, setSelectedRage] = useState([
    moment().startOf("month").format(DATE_FORMAT),
    moment().format(DATE_FORMAT),
  ]);
  const [changingStatus, setChangingStatus] = useState(false);
  const [changingStatusReject, setChangingStatusReject] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const selectedOrderData = useMemo(() => {
    let arr = [...jobs];
    if (selectedOrders?.length) {
      arr = arr.filter(i => selectedOrders.includes(String(i.Id)));
    }
    return arr;
  }, [jobs, selectedOrders]);

  const getData = async () => {
    try {
      setIsLoading(true);
      const filterTimeRange = {
        startDate: selectedRange?.[0],
        endDate: selectedRange?.[1],
      }
      const Status = selectedTab;
      await OrderStore.getListPipeline(currentPage, filterTimeRange);
      const { list, hasNext, total, error } = await OrderStore.getListOrder(currentPage, Status, filterTimeRange, getResellerId(user));
      setTotalRows(total);
      setJobs(list);
    } catch (err) {
    } finally {
      setIsLoading(false);
      setChangingStatus(false);
      setChangingStatusReject(false);
    }
  };

  useEffect(() => {
    if (selectedTab) getData();
  }, [selectedTab, selectedRange, currentPage])

  const exportData = () => {
    if (!selectedOrderData.length) return;
    ExportHelper.saveArrayToFile(selectedOrderData.map(record => ({
      "Order Number": record["Order Number"],
      "Product": record['Raw Data']?.line_items?.map(i => i.name)?.join(',\n'),
      "Date Created": moment(record.CreatedAt).format('DD/MM/YYYY HH:mm'),
      "Status": record["Status"],
    })), { fileName: "exported-ordes.xlsx" });
  }

  const resellerPayPendingOrder = async () => {
    acceptOrderModalRef.current?.show(selectedOrderData);
  }


  return (
    <SecuredLayout>
      <MainContentContainer>
        <Col pt4 pl4>
          <Text h2 fontWeight={'300'}>Orders</Text>
          {!selectedOrders?.length ? (
            <>
              <Text p2 color={COLOR.GRAY300} marginTop={16}>Filter by</Text>
              <Grid
                xs="100%"
                xxxl="any:1:any"
                marginBottom={24}
                pr2
              >
                <Col marginVertical={8}>
                  <ButtonGroup
                    selectedId={selectedTab}
                    onChange={setSelectedTab}
                    marginRight={32}
                    buttons={[
                      { id: 'Pending', title: 'Pending Orders' },
                      { id: 'Accepted', title: 'Accepted Orders' },
                      // { id: 'Fulfilled', title: 'Fulfilled Jobs' },
                      { id: 'error', title: 'Failed, Rejected & Returned Orders', isLight: true },
                    ]}
                  />
                </Col>
                <Col marginVertical={8}>
                  <Row flexWrap="wrap">
                    <Row marginRight={32}>
                      <Text p1>Date</Text>
                      <RangeDatePicker
                        marginLeft={8}
                        value={selectedRange}
                        onChange={setSelectedRage}
                      />
                    </Row>
                    {/* <Row>
                      <Text p1>Type</Text>
                      <Select
                        marginLeft={8}
                        onChange={(a) => console.log(a)}
                        options={[{ label: "Client 2", value: "piw" }]}
                        placeholder="Select type"
                      />
                    </Row> */}
                  </Row>
                </Col>
                <Col marginVertical={8} alignItems="flex-start">
                  <Button
                    left={<SVG.Excel color={COLOR.WHITE} />}
                    title='Export'
                    height={46}
                    paddingHorizontal={18}
                    onPress={exportData}
                  />
                </Col>
              </Grid>
            </>
          ) : (
            <>
              <Text p2 color={COLOR.GRAY300} marginTop={16}>{selectedOrders.length} selected</Text>
              <Row marginBottom={26} marginTop={8} justifyContent="flex-end" pr1>
                <OrderActionButtons
                  status={selectedTab}
                  isLoading={changingStatus}
                  selectedOrders={selectedOrderData}
                  isLoadingReject={changingStatusReject}
                  markAsAccepted={async () => {
                    if (user?.role === 'admin') {
                      setChangingStatus(true);
                      await OrderStore.updateOrderStatus(selectedOrders, 'Accepted');
                      setSelectedOrders([]);
                      getData();
                    } else {
                      resellerPayPendingOrder();
                    }
                  }}
                  rejectJob={async () => {
                    setChangingStatusReject(true);
                    await OrderStore.updateOrderStatus(selectedOrders, 'Rejected');
                    setSelectedOrders([]);
                    getData();
                  }}
                  returnJob={async () => {
                    setChangingStatus(true);
                    await OrderStore.updateOrderStatus(selectedOrders, 'Returned');
                    setSelectedOrders([]);
                    getData();
                  }}
                />
                <Button
                  title='Export'
                  height={36}
                  mr1
                  onPress={exportData}
                  paddingHorizontal={12}
                />
                {/* <Button
                  title='Delete'
                  height={36}
                  backgroundColor='red'
                  paddingHorizontal={12}
                  marginLeft={12}
                /> */}
              </Row>
            </>
          )}

          <Col bgWhite pv3 ph2 marginLeft={-16}>
            <TablePending
              isLoading={isLoading}
              data={jobs}
              selectedOrders={selectedOrders}
              setSelectedOrders={setSelectedOrders}
              onPressMarker={(record) => {
                shippingModalRef.current?.open(record);
              }}
              onPressProduct={(record) => {
                imagesModalRef.current?.open(record);
              }}
            />
            <Col marginTop={24}>
              <Pagination
                current={currentPage}
                pageSize={10}
                total={totalRows}
                onChange={(page) => setCurrentPage(page)}
              />
            </Col>
          </Col>
        </Col>
        <ProductImagesModal ref={imagesModalRef} />
        <ShippingInfoModal ref={shippingModalRef} />
        <AcceptOrderModal
          ref={acceptOrderModalRef}
          onRefresh={() => {
            getData();
            setSelectedOrders([]);
          }}
        />
      </MainContentContainer>
    </SecuredLayout >
  )
};

OrdersScreen.routeInfo = {
  title: 'Orders',
  path: '/orders',
};

export default OrdersScreen;
