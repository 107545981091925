import { SVG } from "assets";
import { Col, Touch } from "components/base";
import { COLOR } from "const";
import React, { useState } from "react";
import { ImageStyle } from "react-native";
import { Image, StyleSheet } from "react-native";
import { Image as AntImage } from "antd";

interface Props {
  uri: string,
  onPressRemove?: () => void,
  style?: ImageStyle,
  canRemove?: boolean,
}

const ImageItem = ({ uri, onPressRemove, style, canRemove = true }: Props) => {
  const [previewVisible, setPreviewVisible] = useState(false);
  return (
    <Col margin={6} onPress={() => setPreviewVisible(true)}>
      <Image
        source={{ uri }}
        style={[styles.imageItem, style]}
      />
      {canRemove &&
        <Touch onPress={onPressRemove} absolute right={-8} top={-8} bgWhite borderRadius="50%">
          <SVG.CloseCircle />
        </Touch>
      }
      <AntImage
        style={{ display: 'none' }}
        src={uri}
        preview={{
          visible: previewVisible,
          src: uri,
          onVisibleChange: setPreviewVisible,
        }}
      />
    </Col>
  )
}

const styles = StyleSheet.create({
  imageItem: {
    width: 72,
    aspectRatio: 1,
    borderColor: COLOR.GRAY100,
    borderWidth: 1,
  },
})

export default ImageItem;
