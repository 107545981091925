import { Col, Text } from "components";
import { COLOR, STYLES } from "const";
import React from "react";
import { StyleSheet, View } from "react-native";

const SummaryItem = ({ value, label }) => {
  return (
    <Col mr2 ph3 pt2 pb2 style={styles.container}>
      <Text h1 fontWeight={'700'} color={'#E60064'}>{value}</Text>
      <Text p1 fontWeight={'700'} color={COLOR.GRAY300} numberOfLines={1}>{label}</Text>
    </Col>
  )
}

const styles = StyleSheet.create({
  container: {
    maxWidth: 260,
    minWidth: 160,
    flex: 1,
    borderColor: COLOR.GRAY100,
    borderWidth: 1,
    backgroundColor: COLOR.WHITE,
    ...STYLES.SHADOW,
  }
})

export default SummaryItem;
