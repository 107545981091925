import React, { useEffect } from 'react';
import { Button, Col, Row, Table, Touch } from 'components';
import { SCREEN } from 'const';
import { Button as AButton } from "antd";
import { SVG } from 'assets';
import { useNavFunc } from 'navigation';
import { useShopStore } from 'store/Shop.Store';
import { useUserStore } from 'store/User.Store';

const TabBrands = () => {
  const { navigate } = useNavFunc();
  const { getList, list } = useShopStore();
  const { user, getResellerId } = useUserStore();

  useEffect(() => {
    if (user) {
      getList(1, getResellerId(user));
    }
  }, [user])

  return (
    <Col padding={24}>
      <Table
        data={list.data || []}
        columns={[
          {
            title: 'Store name',
            dataIndex: 'name',
            key: 'name',
          },
          {
            title: 'URL',
            dataIndex: 'url',
            key: 'url',
            render: (url) => (
              <AButton size="small" onClick={() => {
                window.open(url);
              }}>
                {url}
              </AButton>
            ),
          },
          {
            title: 'Type',
            dataIndex: 'type',
            key: 'type',
            render: (type) => type === 'shopify' ? 'Shopify' : 'WooCommerce',
          },
          {
            title: '',
            key: 'actions',
            width: 100,
            render: (record) => (
              <Row justifyContent="flex-end">
                <Touch padding={12} onPress={() => navigate(SCREEN.ConnectStoreScreen, { id: record?.id })}>
                  <SVG.Pencil />
                </Touch>
              </Row>
            ),
          }
        ]}
      />
      <Button
        title='Connect more store'
        onPress={() => navigate(SCREEN.ConnectStoreScreen)}
        height={46}
        marginTop={16}
        paddingHorizontal={18}
        alignSelf="flex-start"
      />
    </Col>
  )
};

export default TabBrands;
