import Request from '../Request.utils'
export interface IFromImageRequestBody {
  file?: any;
  height: number;
  width: number;
}
export interface IFromUrlRequestBody {
  height: number;
  printJobId?: string;
  url: string;
  width: number;
}
export interface IFromSampleProductRequestBody {
  physicalHeight: number;
  physicalWidth: number;
  printAreas?: {
    height: number;
    left: number;
    top: number;
    width: number;
  }[];
}
export interface IFromPrintJobRequestBody {
  data: {
    product: {
      physicalHeight: number;
      physicalWidth: number;
      printAreas?: {
        height: number;
        left: number;
        top: number;
        width: number;
      }[];
    };
  };
  id?: string;
  images: string[];
}
export interface IFromPrintJobPTSRequestBody {
  clientId?: string;
  data: {
    product: {
      physicalHeight: number;
      physicalWidth: number;
      printAreas?: {
        height: number;
        left: number;
        top: number;
        width: number;
      }[];
    };
  };
  designId?: string;
  headless?: boolean;
  id: string;
  images: string[];
}
export interface IFromPrintJobDataPTSRequestBody {
  data: {
    product: {
      physicalHeight: number;
      physicalWidth: number;
      printAreas?: {
        height: number;
        left: number;
        top: number;
        width: number;
      }[];
    };
  };
  images: string[];
}
export interface IArtwork2pdfRequestBody {
  productType: string;
  url: string;
}
export interface ISendRequestGeneratePdfRequestBody {
  callbackUrl: string;
  items?: {
    customArtworkData?: {
      customArtwork?: string;
      designId?: string;
    };
    id?: number;
    printJobId?: string;
  }[];
}
export interface ISendRequestGeneratePdfPIWRequestBody {
  callbackUrl: string;
  customer?: object;
  items?: any[];
}


class PdfAPI {
  fromImage = async (body: IFromImageRequestBody) => {
    const res = await Request.call('/api/pdf/from-image', 'POST', undefined, undefined, body, );
    return res;
  }
  fromUrl = async (body: IFromUrlRequestBody) => {
    const res = await Request.call('/api/pdf/from-url', 'POST', undefined, undefined, body, );
    return res;
  }
  fromSampleProduct = async (body: IFromSampleProductRequestBody) => {
    const res = await Request.call('/api/pdf/from-sample-prodcut', 'POST', undefined, undefined, body, );
    return res;
  }
  fromPrintJob = async (body: IFromPrintJobRequestBody) => {
    const res = await Request.call('/api/pdf/from-print-job', 'POST', undefined, undefined, body, );
    return res;
  }
  fromPrintJobPTS = async (body: IFromPrintJobPTSRequestBody) => {
    const res = await Request.call('/api/pdf/from-print-job-pts', 'POST', undefined, undefined, body, );
    return res;
  }
  fromPrintJobDataPTS = async (body: IFromPrintJobDataPTSRequestBody) => {
    const res = await Request.call('/api/pdf/from-print-job-data-pts', 'POST', undefined, undefined, body, );
    return res;
  }
  artwork2pdf = async (body: IArtwork2pdfRequestBody) => {
    const res = await Request.call('/api/pdf/artwork2pdf', 'POST', undefined, undefined, body, );
    return res;
  }
  sendRequestGeneratePdf = async (body: ISendRequestGeneratePdfRequestBody) => {
    const res = await Request.call('/api/pdf/send-request-generate-pdf', 'POST', undefined, undefined, body, );
    return res;
  }
  sendRequestGeneratePdfPIW = async (body: ISendRequestGeneratePdfPIWRequestBody) => {
    const res = await Request.call('/api/pdf/send-request-generate-pdf-piw', 'POST', undefined, undefined, body, );
    return res;
  }
}
export default new PdfAPI()