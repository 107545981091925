import AccountBox from "./svg/AccountBox";
import Lock from "./svg/Lock";
import Dashboard from "./svg/Dashboard";
import InboxFull from "./svg/InboxFull";
import InboxMultiple from "./svg/InboxMultiple";
import ListBox from "./svg/ListBox";
import Search from "./svg/Search";
import Excel from "./svg/Excel";
import NewTab from "./svg/NewTab";
import Trash from "./svg/Trash";
import Eye from "./svg/Eye";
import Marker from "./svg/Marker";
import Plus from "./svg/Plus";
import Info from "./svg/Info";
import Bell from "./svg/Bell";
import Gear from "./svg/Gear";
import Pencil from "./svg/Pencil";
import Radio from "./svg/Radio";
import CloseCircle from "./svg/CloseCircle";
import ImagePlus from "./svg/ImagePlus";
import AccountCircle from "./svg/AccountCircle";
import ChevronRight from "./svg/ChevronRight";
import Logout from "./svg/Logout";
import Menu from "./svg/Menu";
import Resize from "./svg/Resize";

export const SVG = {
  AccountBox,
  Lock,
  Dashboard,
  InboxFull,
  InboxMultiple,
  ListBox,
  Search,
  Excel,
  NewTab,
  Trash,
  Eye,
  Marker,
  Plus,
  Info,
  Bell,
  Gear,
  Pencil,
  Radio,
  CloseCircle,
  ImagePlus,
  AccountCircle,
  ChevronRight,
  Logout,
  Menu,
  Resize,
}
