import { Col, Row, Text, Button } from 'components';
import React, { useState, useEffect } from 'react';
import { IScreen } from 'type';
import Store from 'store';
import { useNavFunc } from 'navigation';
import { SCREEN, COLOR } from 'const';
import { ActivityIndicator } from 'react-native';
import { Feather } from '@expo/vector-icons';
import { MainContentContainer, SecuredLayout } from 'components/container';

const ResellerDepositCallback: IScreen = () => {
	const { route, reset, navigation } = useNavFunc();
	// @ts-ignore
	const { amount, currency, resellerId, f_amount, payment_intent } = route.params || {};
	const [isCompleted, setIsCompleted] = useState(false);
	const [autoRedirectSeconds, setAutoRedirectSeconds] = useState(3);

	useEffect(() => {
		if (!resellerId) return;
		if (!payment_intent) return;
		(async () => {
			const res = await Store.Api.Payment.markDepositComplete({
				amount: Number(amount),
				fulfillAmount: Number(f_amount),
				currency,
				payment_intent_id: payment_intent,
			});
			if (res.data.success) {
				setIsCompleted(true);
				setTimeout(() => {
					setAutoRedirectSeconds(2);
					setTimeout(() => {
						setAutoRedirectSeconds(1);
						setTimeout(() => {
							setAutoRedirectSeconds(0);
							navigation.reset({ index: 0, routes: [{ name: SCREEN.SettingsScreen, params: { id: 'billing' } }] });
						}, 1000);
					}, 1000);
				}, 1000);
			} else {
				alert('Something wrong, please try again. ' + res.data.error);
			}
		})();
	}, [resellerId, payment_intent]);

	return (
		<SecuredLayout>
			<MainContentContainer>
				<Col pt4 ph4>
					<Row m2 marginBottom={0} justifyContent={'space-between'}>
						<Text h3 >Thank you for your deposit</Text>
						<Button
							title="Back to Billing"
							width={200}
							height={40}
							onPress={async () => {
								navigation.reset({ index: 0, routes: [{ name: SCREEN.SettingsScreen, params: { id: 'billing' } }] });
							}}
						/>
					</Row>
					<Col flex1 m2 mv1 p2 round1 bgWhite middle>
						{!isCompleted ? (
							<ActivityIndicator color={COLOR.MAIN} size='large' />
						) : (
							<>
								<Feather name="check-circle" size={40} color="#07A04E" />
								<Text center mt2 fontSize={18}>You have successfully added {amount} {currency}.</Text>
								<Text center mt2 fontSize={18}>Redirecting back to Billing in {autoRedirectSeconds} seconds.</Text>
							</>
						)}
					</Col>
				</Col>
			</MainContentContainer>
		</SecuredLayout>
	)
};

ResellerDepositCallback.routeInfo = {
	title: 'Deposit Funds response',
	path: '/resellers/:resellerId/deposit-funds/complete',
};

export default ResellerDepositCallback;
