import { Col, Row, Text, Touch } from "components/base";
import { COLOR } from "const";
import { SIDE_MENU_ITEMS, SIDE_MENU_ITEMS_RESELLER } from "const/menu";
import { useNavFunc } from "navigation";
import React, { useMemo } from "react";
import Store from "store";

const SideMenu = ({ onPressItem }: { onPressItem?: any }) => {
  const { navigate, route } = useNavFunc();
  const { user } = Store.useUserStore();

  const onPress = async (screen) => {
    if (onPressItem) await onPressItem?.();
    navigate(screen);
  }

  const menuItems = useMemo(() => {
    if (user?.role !== 'admin') return SIDE_MENU_ITEMS_RESELLER;
    return SIDE_MENU_ITEMS;
  }, [user]);

  return (
    <Col paddingHorizontal={12} paddingVertical={16} bgWhite flex1>
      {menuItems.map(i => {
        const isActive = route.name === i.screen || i.childrens?.includes(route.name);
        return (
          <Touch
            paddingHorizontal={16} paddingVertical={12}
            marginBottom={12} key={`sm${i.id}`}
            onPress={() => onPress(i.screen)}
            backgroundColor={isActive ? COLOR.MAIN100 : undefined}
            // disabled={isActive}
          >
            <Row>
              <i.icon color={isActive ? COLOR.MAIN : COLOR.GRAY300} size={24} />
              <Text marginLeft={8} p2 bold numberOfLines={1} color={isActive ? COLOR.MAIN : COLOR.GRAY300}>
                {i.title}
              </Text>
            </Row>
          </Touch>
        )
      })}
    </Col>
  );
}

export default SideMenu;
