import React from 'react';
import { Button, Col, FieldItem } from 'components';

const FIELDS: any = [
  { id: 'company', label: 'Company' },
  { id: 'phone', label: 'Phone' },
  { id: 'email', label: 'Email' },
  { id: 'address', label: 'Address' },
];

const TabPackingSlip = () => {
  return (
    <Col padding={24} maxWidth={700}>
      {FIELDS.map((i) => (
        <FieldItem key={i.id} label={i.label} />
      ))}

      <Button
        title='Save'
        onPress={() => { }}
        height={46}
        marginTop={16}
        paddingHorizontal={18}
        alignSelf="flex-start"
      />
    </Col>
  )
};

export default TabPackingSlip;
