import { Switch } from "antd";
import { SVG } from "assets";
import { Button, Col, FieldItem, Grid, Input, Row, Text } from "components";
import { COLOR } from "const";
import React from "react";
import { useResellerForm } from "./FormContext";
import { get, set } from "lodash";

const FIELDS: any = [
  { id: 'email', label: 'Email' },
  // { id: 'password', label: 'Password' },
  [
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
  ],
  { id: 'addressLine1', label: 'Address Line 1' },
  { id: 'addressLine2', label: 'Address Line 2' },
  [
    { id: 'town', label: 'Town' },
    { id: 'country', label: 'Country' },
  ],
  { id: 'postCode', label: 'Post Code' },
  { label: "Business Name", id: "otherData.businessName" },
  { label: "VAT Number", id: "otherData.vatNumber" },
];

const TabInfo = () => {
  const { reseller, setReseller } = useResellerForm();

  const updateReseller = (key) => (value) => {
    setReseller(s => set({ ...s }, key, value))
  }

  return (
    <Col bgWhite marginLeft={-16} paddingHorizontal={16} marginTop={24} paddingVertical={24}>
      <Grid lg="50%" xs="100%" marginHorizontal={-16} alignItems="flex-start">
        <Col marginHorizontal={16}>
          <Text h4 bold>Informations</Text>
          {FIELDS.map((i, idx) => {
            if (i.length) {
              return (
                <Row marginRight={-16} key={idx} >
                  {i.map(field => (
                    <FieldItem
                      flex1
                      key={field.id}
                      label={field.label}
                      marginRight={16}
                      value={get(reseller, field.id)}
                      onChangeText={updateReseller(field.id)}
                    />
                  ))}
                </Row>
              )
            }
            return <FieldItem
              key={i.id}
              label={i.label}
              value={get(reseller, i.id)}
              onChangeText={updateReseller(i.id)}
            />
          })}
          {!!get(reseller, "otherData.business") && (
            <>
              <Text p1 marginTop={16} color={COLOR.GRAY400}>What describes your business best</Text>
              <Text p1 marginTop={8}>{get(reseller, "otherData.business")}</Text>
            </>
          )}
          {!!get(reseller, "otherData.revenue") && (
            <>
              <Text p1 marginTop={16} color={COLOR.GRAY400}>What's the total annual revenue of your business</Text>
              <Text p1 marginTop={8}>{get(reseller, "otherData.revenue")}</Text>
            </>
          )}
        </Col>
        <Col marginHorizontal={16}>
          {/* <Text h4 bold>BG Print & Ship service API:</Text>
          <Input
            marginTop={8}
            height={50}
          />
          <Text h4 bold marginTop={16}>Webhook for receive events</Text>
          <Input
            marginTop={16}
            height={50}
          />
          <Text h4 bold marginTop={16}>Webhook authentication headers</Text>
          <Row marginTop={8}>
            <Input
              flex1
              height={50}
              marginRight={16}
            />
            <Input
              flex1
              height={50}
            />
          </Row> */}
          {/* <Text h4 bold marginTop={16}>Auto accept order</Text>
          <Col marginTop={16} alignItems="flex-start">
            <Switch />
          </Col> */}
          <Text h4 bold marginTop={16}>Store link</Text>
          {get(reseller, "stores", []).map(store => (
            <Button
              key={store.url}
              title={store.name || store.url}
              height={36}
              marginTop={12}
              paddingHorizontal={16}
              onPress={() => window.open(store.url)}
              textProps={{ color: COLOR.BLACK, fontWeight: 'b400', fontSize: 16 }}
              backgroundColor={COLOR.GRAY100}
              alignSelf="flex-start"
              right={<SVG.ChevronRight />}
            />
          ))}
        </Col>
      </Grid>
    </Col>
  )
}

export default TabInfo;
