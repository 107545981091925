import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M19 15V5H5v10h4c0 1.66 1.34 3 3 3s3-1.34 3-3h4zm0-12c1.1 0 2 .9 2 2v14c0 1.1-.9 2-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h14zM7 13v-2h10v2H7zm0-4V7h10v2H7z"
        fill={props.color || "#8D8D8D"}
      />
    </Svg>
  )
}

export default SvgComponent
