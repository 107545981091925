export * from './screens';
export * from './countries';

export const FONT = {
  defaultFont: 'Helvetica, Arial, sans-serif',
  bold: 'Helvetica, Arial, sans-serif',
  light: 'Helvetica, Arial, sans-serif',
};

export const COLOR = {
  FONT: 'black',
  // MAIN: '#003846',
  MAIN: 'rgb(209,17,28)',
  MAIN100: '#FEEAEB',
  MAIN300: 'rgba(209,17,28, 0.3)',
  SECOND: '#4B4E52',
  BORDER: '#707070',
  BLUE: '#005EB8',
  INFO400: '#338BF2',
  YELLOW: '#FFF100',
  GREY_BG: '#F5F5F5',
  GREEN: '#49D009',
  GREY: '#B9B9B9',
  GREY_LIGHT: '#F0F4F6',
  WHITE: '#FFF',
  BLACK: '#000',
  GRAY100: '#E8E8E8',
  GRAY200: '#CBCBCB',
  GRAY300: '#8D8D8D',
  GRAY400: '#5F5F5F',
  SUCCESS: '#04914F',
  GOLDEN: '#D4AF37',
  SILVER: '#c7d1da',
  BRONZE: '#88540b',
}

export const STYLES = {
  SHADOW: {
    shadowColor: '#0000000F',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 30,
  },
  LARGE_SHADOW: {
    shadowColor: '#00000026',
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowRadius: 80,
  }
}

export const INCH_TO_MM = 25.4;

export const ARTWORK_TYPES = [
  { label: "1 frame", value: "1frame", pages: 1 },
  { label: "2 frames", value: "2frame", pages: 2 },
  { label: "3 frames", value: "3frame", pages: 3 },
  { label: "4 frames", value: "4frame", pages: 4 },
];
