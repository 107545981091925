import * as React from "react"
import Svg, { Rect, Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Rect x={3} y={3} width={18} height={18} rx={9} fill="#E60064" />
      <Path
        d="M16.858 9.597l-.463-.456a.499.499 0 00-.695 0l-4.856 4.78-2.543-2.503a.499.499 0 00-.695 0l-.463.456a.48.48 0 000 .684l3.352 3.3a.49.49 0 00.692 0l5.666-5.578a.476.476 0 00.005-.683z"
        fill="#fff"
      />
    </Svg>
  )
}

export default SvgComponent
