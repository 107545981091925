import React from "react";
import { Col, Row, Text } from "../base";
import { Image, StyleSheet } from "react-native";
import { COLOR } from "const";
import { IColProps } from "components/base/Col";
import { ValHelper } from "helpers";
import ImageItem from "./ImageItem";

export interface ProductBillItemProps extends IColProps {
  img: string,
  name: string,
  id: string,
  price: number | string,
  quantity: number | string,
  total?: number | string,
}

const ProductBillItem = (props: ProductBillItemProps) => {
  const { img, name, id, price, quantity, total, ...restProps } = props;

  return (
    <Row paddingBottom={16} alignItems="flex-start" {...restProps}>
      <ImageItem
        uri={img}
        style={styles.img}
        canRemove={false}
      />
      <Col flex1>
        <Text p2 bold marginBottom={6}>{name}</Text>
        <Text p1 color={COLOR.GRAY300}>{id}</Text>
        <Row marginTop={24} marginBottom={16}>
          <Col flex1>
            <Text p1 color={COLOR.GRAY300}>£{price}</Text>
          </Col>
          <Col flex1 alignItems="center">
            <Text p1 color={COLOR.GRAY300}>x{quantity}</Text>
          </Col>
          <Col flex1 alignItems="flex-end">
            <Text caption1>£{ValHelper.formatMoney(total || Number(quantity) * Number(price))}</Text>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 24,
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  img: {
    width: 80,
    height: 80,
    borderWidth: 1,
    borderColor: COLOR.GRAY100,
    marginRight: 16,
  }
})

export default ProductBillItem;
