import { SVG } from 'assets';
import { Button, Col, Grid, Row, Text, SummaryItem, SummaryItemSm, Table, ButtonGroup, DatePicker, Select, SummaryItemNew, RevenueChart, OrderByCountry } from 'components';
import { SecuredLayout } from 'components/container';
import MainContentContainer from 'components/container/MainContentContainer';
import { COLOR } from 'const';
import React, { useEffect, useMemo, useState } from 'react';
import { IScreen } from 'type';
import Chart from './Chart';
import TableInvoices from './TableInvoices';
import Store from 'store';
import moment from 'moment';
import apiClient from 'store/api-client';
import { ValHelper } from 'helpers';

const DashboardScreen: IScreen = () => {
  const { reportData, orderReportAll } = Store.useOrderStore();
  const [data, setData] = useState({ reports: [], totalRevenue: 0, revenue: [], ordersByCountry: {} });

  const orderReportNew = async () => {
    const res = await apiClient.Api.Order.getReport({});
    if (res.data.success) {
      setData(res.data || {});
    }
  }

  useEffect(() => {
    // orderReportAll();
    orderReportNew();
  }, []);

  const summaries = [
    { label: "Job bag", value: reportData?.pendingOrderToday },
    { label: "Accepted jobs", value: reportData?.acceptedOrderToday },
    { label: "Fulfilled jobs", value: reportData?.fulfilledOrder },
  ]

  const listClients = useMemo(() => {
    if (!Object.keys(reportData?.countByClient || {}).length) return [];
    const list: Array<{ id: number, client: string; jobs: number; printed: number }> = [];

    Object.keys(reportData.countByClient).forEach((clientId, idx) => {
      const v = reportData.countByClient[clientId];
      let client = v.clientName;

      list.push({
        id: idx,
        jobs: v.total,
        client: "Precision Proco",
        printed: v.fulfilled,
      })
    })

    return list.sort((a, b) => (a.jobs > b.jobs ? -1 : 1));
  }, [reportData]);

  const todayReport = useMemo(() => {
    return data?.reports?.find(i => i.dateNo === moment().format("YYYYMMDD"))
  }, [data]);

  const thisMonthReport = useMemo(() => {
    const arr = data?.reports?.filter(i =>
      Number(i.dateNo) >= Number(moment().startOf("month").format("YYYYMMDD"))
      && Number(i.dateNo) <= Number(moment().endOf("month").format("YYYYMMDD"))
    )
    return {
      orders: arr.reduce((t, report) => {
        return t + Number(report.orders);
      }, 0),
      products: arr.reduce((t, report) => {
        return t + Number(report.products);
      }, 0),
      value: arr.reduce((t, report) => {
        return t + Number(report.value);
      }, 0),
      paid: arr.reduce((t, report) => {
        return t + Number(report.paid);
      }, 0),
      paidOrders: arr.reduce((t, report) => {
        return report.paid ? t + 1 : t;
      }, 0),
    }
  }, [data]);

  return (
    <SecuredLayout>
      <MainContentContainer>
        <Col pt4 pl4>
          <Row justifyContent="space-between" pr4>
            <Text h3 fontWeight={'300'}>Dashboard</Text>
            {/* <Button
              left={<SVG.Excel color={COLOR.WHITE} />}
              title='Export'
              height={46}
              paddingHorizontal={18}
            /> */}
          </Row>
          <Grid mt2 xs="100%" md="33.333%">
            <SummaryItemNew label="Todays Sales" value={`£${ValHelper.formatMoney(todayReport?.value || 0)}`} subTitle={`We have sold ${todayReport?.products || 0} items (${todayReport?.orders || 0} orders)`} />
            <SummaryItemNew label="Orders This Month" value={`£${ValHelper.formatMoney(thisMonthReport?.value || 0)}`} subTitle={`We have sold ${thisMonthReport?.products || 0} items (${thisMonthReport?.orders || 0} orders)`} />
            <SummaryItemNew label="Spent This Month" value={`£${ValHelper.formatMoney(thisMonthReport?.paid || 0)}`} subTitle={`${thisMonthReport?.paidOrders || 0} orders paid`} />
          </Grid>
          <Grid mt2 xs="100%" md="2:1">
            <RevenueChart label="Total Revenue" data={data} />
            <OrderByCountry label="Orders By Country" data={data?.ordersByCountry} />
          </Grid>
          {/* <Col bgWhite paddingBottom={24}>
            <Grid
              xs="100%"
              md="50%"
              alignItems={"flex-start"}
            >
              <Chart />
              <Col ph2>
                <Row marginRight={-6} marginBottom={18}>
                  <SummaryItemSm value={reportData?.totalOrder} label={'Total Order'} />
                  <SummaryItemSm value={reportData?.totalProcessedItemsValue} label={'Total values of orders'} />
                </Row>
                <Table
                  data={listClients}
                  columns={[
                    {
                      title: 'Client',
                      dataIndex: 'client',
                      key: 'client',
                    },
                    {
                      title: 'Number of Jobs',
                      dataIndex: 'jobs',
                      key: 'jobs',
                    },
                    {
                      title: 'Number of Printed',
                      dataIndex: 'printed',
                      key: 'printed',
                    },
                  ]}
                />
              </Col>
            </Grid>
          </Col> */}
          <TableInvoices />
        </Col>
      </MainContentContainer>
    </SecuredLayout>
  )
};

DashboardScreen.routeInfo = {
  title: 'Dashboard',
  path: '/dashboard',
};

export default DashboardScreen;
