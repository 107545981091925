import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={25}
      height={24}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M14.5 3v2h3.59l-9.83 9.83 1.41 1.41 9.83-9.83V10h2V3m-2 16h-14V5h7V3h-7a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2v-7h-2v7z"
        fill={props.color || "#338BF2"}
      />
    </Svg>
  )
}

export default SvgComponent
