export const getDiscountByPoint = (point: number) => {
  if (!point) return 0;
  let discountPercent = 0;
  if (point >= 7500) discountPercent = 5;
  if (point >= 50000) discountPercent = 10;
  if (point >= 85000) discountPercent = 15;
  return discountPercent;
}

export const roundMoney = (money: number) => Number((Math.round(money * 100) / 100).toFixed(2));

export const calculateTotal = ({
  productsAmount,
  shouldIncludeVAT,
  userPoint,
}: {
  productsAmount: { [key: string]: number },
  shouldIncludeVAT: boolean,
  userPoint?: number,
}) => {
  const discountPercent = getDiscountByPoint(userPoint);
  let products = 0;
  let taxes = 0;
  Object.keys(productsAmount).map(id => {
    const thisProdPrice = productsAmount[id] * (100 - discountPercent) / 100;
    products += roundMoney(thisProdPrice);
    taxes += roundMoney(thisProdPrice * (shouldIncludeVAT ? 0.2 : 0));
  });

  return {
    total: products + taxes,
    products,
    taxes,
  }
}
