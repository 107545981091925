import { useState, useEffect } from 'react';
import Store from 'store';

export const useListCurrencies = (): Array<{ rate: number; cc: string; symbol: string; name: string; }> => {
  const [list, setList] = useState<Array<{ rate: number; cc: string; symbol: string; name: string; }>>([]);

  useEffect(() => {
    (async () => {
      const res = await Store.Api.Payment.listCurrencies();
      setList(res.data.data);
    })();
  }, []);

  return list;
}
