import React, { useCallback, useState } from 'react';
import { Button, Col, Row, Text } from 'components';
import { SecuredLayout } from 'components/container';
import MainContentContainer from 'components/container/MainContentContainer';
import { IScreen, TUser } from 'type';
import TableResellers from './TableReseller';
import { SVG } from 'assets';
import { COLOR, SCREEN } from 'const';
import { useNavFunc } from 'navigation';
import { useUserStore } from 'store/User.Store';
import { notification } from 'antd';
import { useFocusEffect } from '@react-navigation/native';

const ResellersScreen: IScreen = () => {
  const { navigate } = useNavFunc();
  const UserStore = useUserStore();
  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<Array<TUser>>([]);

  const getData = async (p) => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      const { list, error } = await UserStore.getListResellers(p);
      if (error) {
        notification.error({ message: error });
      } else {
        setUsers(list);
      }
    } catch (err) {
      notification.error({ message: err?.message });
    } finally {
      setIsLoading(false);
    }
  };

  useFocusEffect(useCallback(() => {
    getData(1);
  }, []));

  return (
    <SecuredLayout>
      <MainContentContainer>
        <Col pt4 ph4>
          <Row justifyContent="space-between">
            <Text h2 fontWeight={'300'}>Resellers</Text>
            <Button
              title='Add new reseller'
              left={<SVG.Plus color={COLOR.WHITE} />}
              onPress={() => navigate(SCREEN.CreateResellerScreen)}
              height={46}
              paddingHorizontal={12}
            />
          </Row>

          <Col
            bgWhite paddingVertical={24}
            paddingRight={24} marginTop={24}
            marginLeft={-16} paddingLeft={16}
            height="100%"
          >
            <TableResellers loading={isLoading} data={users} />
          </Col>
        </Col>
      </MainContentContainer>
    </SecuredLayout >
  )
};

ResellersScreen.routeInfo = {
  title: 'Resellers',
  path: '/resellers',
};

export default ResellersScreen;
