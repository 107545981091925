import React from "react";
import { Col } from "../base";
import { Select as AntSelect } from "antd";
import { IColProps } from "components/base/Col";

export interface SelectProps extends IColProps {
  placeholder?: string,
  isMultiple?: boolean,
  value?: any,
  onChange?: (data: any) => void,
  options?: { label: string, value: string }[],
  height?: number,
  selectProps?: any,
}

const Select = (props: SelectProps) => {
  const { value, onChange, isMultiple, placeholder, options, height, selectProps, ...restProps } = props;

  return (
    <Col {...restProps}>
      <AntSelect
        mode={isMultiple ? "multiple" : undefined}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        popupMatchSelectWidth={false}
        allowClear
        options={options}
        style={{ height: height || 36 }}
        {...selectProps}
      />
    </Col>
  );
};

export default Select;
