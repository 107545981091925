import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row, Table, Text } from 'components';
import { usePaymentStore } from 'store/Payment.Store';
import { ColumnsType } from 'antd/es/table';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import { COLOR } from 'const';
import { ValHelper } from 'helpers';
import moment from 'moment';
import { notification } from 'antd';
import { capitalize } from 'lodash';
import { useFocusEffect } from '@react-navigation/native';

const TransactionHistory = () => {
  const [data, setData] = useState({
    data: [],
    hasNext: false,
  });
  const [loadingMore, setLoadingMore] = useState(false);

  const { getBalanceTransactions } = usePaymentStore();

  useFocusEffect(useCallback(() => {
    getBalanceTransactions().then(d => setData(d));
  }, []))

  const onPressLoadMore = async () => {
    try {
      if (!data.data?.length) return;
      setLoadingMore(true);
      const lastItem = data.data[data.data.length - 1];
      const res = await getBalanceTransactions(lastItem.id);
      if (res.data) {
        setData(s => ({
          data: s.data.concat(res.data),
          hasNext: res.hasNext,
        }))
      }
      setLoadingMore(false);
    } catch (error) {
      notification.error({ message: error.message });
    }
  }

  const columns: ColumnsType<any> = [
    {
      title: 'Order Number',
      key: 'orderNumber',
      render: (record) => {
        const orderNo = record?.invoiceData?.metadata?.orderNumber;
        if (!orderNo) return null;
        return <Text>{orderNo.split(',').map(i => `#${i}`).join('\n')}</Text>;
      },
    },
    {
      title: 'Product',
      key: 'productName',
      render: (record) => {
        if (record.metadata?.type) {
          return capitalize(record.metadata?.type);
        }
        return <Text>{record?.invoiceData?.metadata?.productName}</Text>;
      },
    },
    {
      title: 'Amount Paid',
      key: 'amount',
      render: (record) => {
        return <Text color={record.amount < 0 ? "red" : undefined}>£{ValHelper.formatMoney(record.amount / 100)}</Text>
      },
    },
    {
      title: 'Date Paid',
      key: 'created',
      render: (record) => moment(record.created * 1000).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: 'Payment method',
      key: 'paymentMethod',
      render: (record) => record?.invoiceData?.metadata?.paymentMethod || record?.metadata?.paymentMethod,
    },
    {
      title: 'Invoice',
      key: 'actions',
      width: 60,
      render: (record) => {
        const pdfUrl = record?.invoiceData?.invoice_pdf;
        if (!pdfUrl) return null;
        return (
          <Row justifyContent="flex-end">
            <Col
              width={40} height={40} middle mr1
              onPress={() => {
                window.open(pdfUrl);
              }}
            >
              <MaterialCommunityIcons name="download" size={26} color={COLOR.FONT} />
            </Col>
          </Row>
        )
      },
    },
  ];

  return (
    <>
      <Table
        data={data.data}
        columns={columns}
        rowKey={record => `${record.id}`}
        minWidth={800}
      // loading={fetching}
      />
      {data?.hasNext && (
        <Col middle mt1>
          <Col
            onPress={onPressLoadMore}
            borderWidth={1}
            borderColor={COLOR.MAIN}
          >
            <Col width={200} height={40} middle>
              <Text>{loadingMore ? 'Loading more...' : 'Load more'}</Text>
            </Col>
          </Col>
        </Col>
      )}
      <Col height={100} />
    </>
  );
};

export default TransactionHistory;
