import React from "react";
import { Col } from "../base";
import { DatePicker } from "antd";
import { IColProps } from "components/base/Col";
import dayjs from 'dayjs';

const RangePicker = DatePicker.RangePicker;

export interface RangeDatePickerProps extends IColProps {
  onChange: (dates: string[]) => void,
  height?: number,
  value: string[],
}

export const DATE_FORMAT = 'YYYY-MM-DD';

const RangeDatePicker = (props: RangeDatePickerProps) => {
  const { onChange, height, value, ...restProps } = props;

  const onChangeDates = (values) => {
    onChange?.(values.map(i => i.format(DATE_FORMAT)));
  }

  return (
    <Col {...restProps}>
      <RangePicker
        onChange={onChangeDates}
        value={value?.map(i => dayjs(i, DATE_FORMAT))}
        style={{
          borderRadius: 0,
          height: height || 36
        }}
      />
    </Col>
  );
};

export default RangeDatePicker;
