import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useOrderStore } from "store/Order.Store";
import { usePaymentStore } from "store/Payment.Store";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Button, Col, Row, Text } from "components";
import WalletBalanceRenderer from "components/elements/wallet/WalletBalanceRenderer";
import { ActivityIndicator } from "react-native";
import { COLOR, SCREEN } from "const";
import { Modal, notification } from "antd";
import { useNavFunc } from "navigation";
import SelectShippingService from "./SelectShippingService";
import { useUserStore } from "store/User.Store";
import { getDiscountByPoint, roundMoney } from "helpers";

const ResellerAcceptOrdersModal = (props, ref) => {
  const { navigation } = useNavFunc();
  const { user } = useUserStore();
  const [show, setShow] = useState(false);
  const [orders, setOrders] = useState([]);
  const [shipping, setShipping] = useState({});
  const OrderStore = useOrderStore();
  const { stripePublishKey, getStripeConfig, refreshBalance } = usePaymentStore();
  const stripePromise = useMemo(() => {
    if (!stripePublishKey) return;
    return loadStripe(stripePublishKey);
  }, [stripePublishKey]);
  const [totalAmount, setTotalAmount] = useState<{ amount: number, currency: string, data: any }>();
  const [loading, setLoading] = useState(true);
  const [isPaying, setIsPaying] = useState(false);

  const unpaidOrders = useMemo(() => {
    return orders?.filter(v => !v.Pipelines[v.Pipelines.length - 1]?.SharedData.isPaid);
  }, [orders]);

  useImperativeHandle(ref, () => ({
    show: (data) => {
      setOrders(data);
      refreshBalance();
      setShow(true);
      setIsPaying(false);
    }
  }))

  useEffect(() => {
    (async () => {
      if (unpaidOrders.length === 0) return;
      const data = await OrderStore.getChargeAmount(unpaidOrders);
      setTotalAmount(data);
      setLoading(false);
    })();
  }, [unpaidOrders]);

  const totalIncludeShip = useMemo(() => {
    let total = 0;
    const discount = getDiscountByPoint(user?.point);
    Object.keys(shipping).forEach(id => {
      if (shipping[id]) {
        const amountData = totalAmount?.data?.[id];
        const taxRate = amountData?.taxes ? 1.2 : 1;
        total += roundMoney(amountData?.products * taxRate * (100 - discount) / 100);
        total += roundMoney(shipping[id]?.price * taxRate);
      }
    })
    return total;
  }, [totalAmount, shipping, user]);

  return (
    <Modal
      open={show}
      title="Accept order"
      onOk={() => setShow(false)}
      onCancel={() => setShow(false)}
      footer={false}
      width={600}
    >
      <Elements stripe={stripePromise}>
        <WalletBalanceRenderer>
          {({ balance }) => {

            const walletBalance = (balance?.balance * -1 || 0) / 100

            const renderBalance = () => {
              return (
                <Text bold>{walletBalance} {String(balance?.currency || "GBP").toUpperCase()}</Text>
              )
            }

            return unpaidOrders.length === 0 ? (
              <Col flex1 p2 middle>
                <Text>You have already paid these orders. Please wait for admin approval.</Text>
              </Col>
            ) : loading ? (
              <Col flex1 p2 middle>
                <ActivityIndicator color={COLOR.MAIN} size='small' />
              </Col>
            ) : (
              <Col flex1>
                <Text bold mb1 fontSize={18} textAlign={"center"}>Pay and proceed</Text>
                <Text bold mb2 fontSize={18} textAlign={"center"}>Balance: {renderBalance()}</Text>
                <Text fontSize={16} mb1>{"Select shipping service:"}</Text>
                {unpaidOrders.map((order, idx) => {
                  const opts = totalAmount?.data?.[order["Order ID"]]?.shippingOpts || [];
                  return (
                    <SelectShippingService
                      key={idx}
                      options={opts}
                      value={shipping[order["Order ID"]]?.code}
                      onChange={(v) => setShipping(s => ({ ...s, [order["Order ID"]]: opts.find(i => i.code === v) }))}
                      order={order["Order Number"]}
                    />
                  )
                })}
                <Text mb2>
                  Accepting these jobs will take <Text bold>{totalIncludeShip} {totalAmount?.currency}</Text> from your wallet. Once paid, these orders cannot be amended. PPG will review these orders before they go into production.
                  {'\n\n'}Do you wish to continue?
                </Text>
                <Row>
                  <Col flex1 marginRight={10}>
                    <Col>
                      <Button
                        backgroundColor={COLOR.GRAY100}
                        textProps={{
                          color: COLOR.GRAY400,
                          fontWeight: "400",
                        }}
                        height={36}
                        width='100%'
                        title='Cancel'
                        onPress={async () => setShow(false)}
                      />
                    </Col>
                  </Col>
                  {(walletBalance < totalIncludeShip || walletBalance === 0) && (
                    <Col flex1 marginRight={10}>
                      <Col>
                        <Button
                          backgroundColor={COLOR.GRAY100}
                          textProps={{
                            color: COLOR.GRAY400,
                            fontWeight: "400",
                          }}
                          height={36}
                          width='100%'
                          title='Add balance'
                          onPress={async () => {
                            navigation.navigate(SCREEN.SettingsScreen, { id: 'billing' });
                            setShow(false)
                          }}
                        />
                      </Col>
                    </Col>
                  )}
                  <Col flex1>
                    <Col>
                      <Button
                        opacity={(walletBalance < totalIncludeShip || Object.keys(shipping).length !== Object.keys(unpaidOrders).length) ? 0.5 : 1}
                        isLoading={isPaying}
                        height={36}
                        width='100%'
                        title='Accept'
                        onPress={async () => {
                          if (walletBalance < totalIncludeShip) return;
                          try {
                            setIsPaying(true);
                            await OrderStore.chargeFromWallet(orders, shipping);
                            notification.success({ message: 'You have successfully paid these orders. they are now awaiting admin approval' })
                            props.onRefresh?.();
                          } catch (err) {
                            notification.error({ message: err.message || JSON.stringify(err) })
                          }
                          setShow(false);
                        }}
                      />
                    </Col>
                  </Col>
                </Row>
              </Col>
            )
          }}
        </WalletBalanceRenderer>
      </Elements>
    </Modal>
  )
}

export default forwardRef(ResellerAcceptOrdersModal);
