import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M12 22.5c5.5 0 10-4.5 10-10s-4.5-10-10-10S2 7 2 12.5s4.5 10 10 10zm-1-15h2v2h-2v-2zm3 10h-4v-2h1v-2h-1v-2h3v4h1v2z"
        fill={props.color || "#338BF2"}
      />
    </Svg>
  )
}

export default SvgComponent
