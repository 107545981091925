import { SVG } from "assets";
import { Button, Col, FieldItem, Input, Row, Table, TableForm, Text, UploadBlendFile, UploadFile } from "components";
import { COLOR } from "const";
import React, { useRef, useState } from "react";
import Store from "store";
import { useProductForm } from "./FormContext";
import CreateSKUModal from "./CreateSKUModal";
import { isArray } from "lodash";
import { Tooltip } from "antd";
import { ActivityIndicator } from "react-native";

const TabPrint = () => {
  const uploadRef = useRef<{ showDialog: Function }>(null);
  const uploadBlenderRef = useRef<{ showDialog: Function }>(null);
  const skuModal = useRef(null);
  const { user } = Store.useUserStore();
  const { product, setProduct } = useProductForm();
  const canEdit = user?.role === 'admin';
  const [uploading, setUploading] = useState(false);
  const [uploadingBlender, setUploadingBlender] = useState(false);

  const validateNumberInput = (label) => () => {
    if (!product) return;
    const value = (() => {
      if (!label.includes('.')) return product[label];
    })();
    if (!value) return;
    if (isNaN(+value)) {
      alert('Please input number value');
      setProduct({ ...product, [label]: 0 })
    } else {
      setProduct({ ...product, [label]: +value })
    }
  }

  const onChangeProductData = (key) => (value) => {
    setProduct(p => ({
      ...p,
      data: {
        ...p.data,
        [key]: value,
      }
    }))
  }

  const onPressAddSKU = () => {
    skuModal.current?.open();
  }

  const onRemoveSKU = (skuItem) => {
    const skuPDFs = product?.data?.skuPDFs || [];
    setProduct(p => ({
      ...p,
      data: {
        ...p?.data,
        skuPDFs: skuPDFs.filter(i => i.sku !== skuItem.sku)
      }
    }));
  }

  const onEditSKU = (skuItem, idx) => {
    skuModal.current?.open(skuItem, idx);
  }

  const onSaveSKU = (item, idx) => {
    const skuPDFs = product?.data?.skuPDFs ? [...product?.data?.skuPDFs] : [];
    if (idx === undefined) skuPDFs.push(item);
    else skuPDFs[idx] = item;
    setProduct(p => ({
      ...p,
      data: {
        ...p?.data,
        skuPDFs: skuPDFs,
      }
    }));
  }

  const onPressAddFile = () => {
    uploadRef.current?.showDialog();
  }

  const onPressAddBlenderFile = () => {
    uploadBlenderRef.current?.showDialog();
  }

  const onFileBlenderUploaded = (urls) => {
    if (urls.length === 0) return setUploadingBlender(false);
    onChangeProductData("blender")(urls?.[0]);
    setUploadingBlender(false);
  }

  const onFileUploaded = (urls) => {
    if (urls.length === 0) return setUploading(false);
    onChangeProductData("artworkBackground")(urls?.[0]);
    setUploading(false);
  }

  return (
    <Col paddingTop={16}>
      <Text p1 color={COLOR.GRAY300} marginBottom={22}>
        Technical information for designing and printing.
      </Text>
      <Row
        backgroundColor={'#338BF233'} borderColor={'#338BF233'}
        borderWidth={1} justifyContent="center" paddingVertical={8}
      >
        <SVG.Info />
        <Text marginLeft={8} p1>Dimension in <Text bold color={COLOR.INFO400}>mm</Text></Text>
      </Row>
      <Text marginTop={24} p1 bold color={COLOR.GRAY400}>Print size</Text>
      <Row marginTop={16}>
        <Col flex1 marginRight={12}>
          <Text p1 color={COLOR.GRAY400}>Width</Text>
          <Input
            marginTop={8}
            height={50}
            value={String(product?.physicalWidth || '')}
            onChangeText={(v) => setProduct(p => ({ ...p, physicalWidth: v }))}
            inputProps={{
              onBlur: validateNumberInput('physicalWidthIn'),
              editable: canEdit,
            }}
          />
        </Col>
        <Col flex1>
          <Text p1 color={COLOR.GRAY400}>Height</Text>
          <Input
            marginTop={8}
            height={50}
            value={String(product?.physicalHeight || '')}
            onChangeText={(v) => setProduct(p => ({ ...p, physicalHeight: v }))}
            inputProps={{
              onBlur: validateNumberInput('physicalHeightIn'),
              editable: canEdit,
            }}
          />
        </Col>
      </Row>
      {/* <FieldItem
        label="Artwork background"
        value={product?.data?.artworkBackground}
        onChangeText={onChangeProductData("artworkBackground")}
        inputProps={{
          right: (
            <Tooltip title="Upload Background Image">
              <Col padding={10} marginRight={-10} onPress={onPressAddFile}>
                {uploading ?
                  <ActivityIndicator color={COLOR.MAIN} />
                  :
                  <SVG.ImagePlus width={24} height={24} />
                }
              </Col>
            </Tooltip>
          )
        }}
      />
      <TableForm
        marginTop={24}
        addBtnTitle="Add print area"
        data={product?.printAreas}
        editable={canEdit}
        columns={[
          { key: 'width', label: 'Width' },
          { key: 'height', label: 'Height' },
          { key: 'top', label: 'Top' },
          { key: 'left', label: 'Left' },
        ]}
        onChange={(printAreas) => setProduct(p => ({
          ...p,
          printAreas,
        }))}
      /> */}
      <Text marginTop={36} p1 bold color={COLOR.GRAY400}>Siteflow destination</Text>
      <Input
        marginTop={16}
        height={50}
        value={product?.data?.destination}
        onChangeText={onChangeProductData("destination")}
        inputProps={{ editable: canEdit }}
      />
      <Text marginTop={36} p1 bold color={COLOR.GRAY400}>Blender {`(for preview)`}</Text>
      <Input
        marginTop={16}
        height={50}
        value={product?.data?.blender}
        onChangeText={(v) => onChangeProductData("blender")(v)}
        inputProps={{ editable: canEdit }}
        right={canEdit ? (
          <Tooltip title="Upload Blender file">
            <Col padding={10} marginRight={-10} onPress={onPressAddBlenderFile}>
              {uploadingBlender ?
                <ActivityIndicator color={COLOR.MAIN} />
                :
                <SVG.ImagePlus width={24} height={24} />
              }
            </Col>
          </Tooltip>
        ) : undefined}
      />
      <Text marginTop={36} p1 bold color={COLOR.GRAY400}>Blender support file</Text>
      <Input
        marginTop={16}
        height={50}
        value={product?.data?.blenderSupport}
        onChangeText={(v) => onChangeProductData("blenderSupport")(v)}
        inputProps={{ editable: canEdit }}
      />
      <UploadFile
        ref={uploadRef}
        onUploaded={onFileUploaded}
        onUploading={() => setUploading(true)}
      />
      <UploadBlendFile
        ref={uploadBlenderRef}
        onUploaded={onFileBlenderUploaded}
        onUploading={() => setUploadingBlender(true)}
      />
    </Col>
  );
}

export default TabPrint;
