import { Button, Col, Grid, ProductItem, Row, Select, Text } from 'components';
import { SecuredLayout } from 'components/container';
import MainContentContainer from 'components/container/MainContentContainer';
import React, { useCallback, useEffect, useState } from 'react';
import { IScreen } from 'type';
import { SVG } from 'assets';
import { COLOR, SCREEN } from 'const';
import { useNavFunc } from 'navigation';
import { Pagination, Skeleton } from 'antd';
import { useProductStore } from 'store/Product.Store';
import { useUserStore } from 'store/User.Store';
import { isEmpty } from 'lodash';
import { useFocusEffect } from '@react-navigation/native';

const ProductLibrarySreen: IScreen = () => {
  const { navigate } = useNavFunc();
  const UserStore = useUserStore();
  const { getList, list, getTags, tags } = useProductStore();
  const [loading, setLoading] = useState(false);
  const [filterTag, setFilterTag] = useState("");
  const { user } = UserStore;

  const getData = async (p) => {
    try {
      setLoading(true);
      const u = await UserStore.onReady();
      const resellerId = UserStore.getResellerId(u);
      await getList(p, user?.role === "admin" ? undefined : resellerId, { tag: filterTag });
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(1);
  }, [filterTag]);

  useFocusEffect(useCallback(() => {
    getData(1);
    getTags();
  }, []));

  const openProductDetail = (product) => {
    navigate(SCREEN.CreateProductScreen, { id: product.id });
  }

  return (
    <SecuredLayout>
      <MainContentContainer>
        <Col pt4 pl4>
          <Row justifyContent="space-between" pr4>
            <Row>
              <Text h2 fontWeight={'300'} mr3>Products</Text>
              <Text mr1>Filter by</Text>
              <Select
                height={40}
                minWidth={150}
                value={filterTag ? [filterTag] : undefined}
                onChange={setFilterTag}
                options={tags.map(i => ({ label: i, value: i }))}
                placeholder="Tags"
              />
            </Row>
            {user?.role === 'admin' &&
              <Button
                title='Create'
                left={<SVG.Plus color={COLOR.WHITE} />}
                onPress={() => navigate(SCREEN.CreateProductScreen)}
                height={46}
                paddingHorizontal={18}
              />
            }
          </Row>
          {loading && isEmpty(list?.data) ?
            <Col bgWhite p2 mt3 marginLeft={-16}>
              <Skeleton active />
            </Col>
            :
            <Col bgWhite p2 mt3 marginLeft={-16}>
              <Grid xs='50%' md='25%' marginHorizontal={-12}>
                {list?.data?.map((val, i) => {
                  return (
                    <ProductItem onPress={() => openProductDetail(val)} key={`p${i}`} {...val} />
                  )
                })}
              </Grid>
              {Boolean(list?.totalPage) &&
                <Col marginTop={24}>
                  <Pagination current={list?.page} total={list?.totalPage} />
                </Col>
              }
            </Col>
          }
        </Col>
      </MainContentContainer>
    </SecuredLayout >
  )
};

ProductLibrarySreen.routeInfo = {
  title: 'Products',
  path: '/products',
};

export default ProductLibrarySreen;
