import { SVG } from "assets";
import { Button, Col, ImageItem, Row, Table, Text } from "components";
import { COLOR } from "const";
import React, { useRef, useState } from "react";
import CreateDesignVariantModal from "./CreateDesignVariantModal";
import SelectDesignVariantModal from "./SelectDesignVariantModal";
import { ValHelper } from "helpers";

const CreateResellerProductVariants = ({ design, setDesign, product }) => {
  const selectRef = useRef(null);
  const skuModal = useRef(null);

  const onPressAddSKU = () => {
    selectRef.current?.open();
  }

  const onRemoveSKU = (skuItem) => {
    const variants = design?.variants || [];
    setDesign(p => ({
      ...p,
      variants: variants.filter(i => i.variantName ? (i.variantName !== skuItem.variantName) : (i.sku !== skuItem.sku))
    }));
  }

  const onEditSKU = (skuItem, idx) => {
    skuModal.current?.open(skuItem, idx);
  }

  const onSaveSKU = (item, idx) => {
    const skuPDFs = design?.variants ? [...design?.variants] : [];
    if (idx === undefined) skuPDFs.push(item);
    else skuPDFs[idx] = item;
    setDesign(p => ({
      ...p,
      variants: skuPDFs,
    }));
  }

  const onSaveVariants = ({ canvas, previewUrl, selecteds }) => {
    if (!selecteds?.length) return;
    const variants = [...design?.variants];
    selecteds.forEach(variantName => {
      const idx = variants.findIndex(i => i.variantName === variantName);
      if (idx >= 0) {
        variants[idx] = {
          ...variants[idx],
          canvas,
        }
        if (previewUrl) variants[idx].previewUrl = previewUrl;
      }
    })
    setDesign(s => ({
      ...s,
      variants,
    }))
  }


  const onUpdateVariants = (variantNames) => {
    const newVariantNames = variantNames.filter(i => !design?.variants?.some(j => j.variantName === i));
    if (!newVariantNames?.length) return;
    const variants = design?.variants ? [...design?.variants] : [];
    newVariantNames.forEach(name => {
      variants.push(product.data?.skuPDFs?.find(j => j.variantName === name));
    })
    setDesign(p => ({
      ...p,
      variants,
    }));
  }

  return (
    <Col>
      <Text mb2 p1 bold color={COLOR.GRAY400}>Variants</Text>
      <Table
        columns={[
          {
            title: 'Thumbnail',
            dataIndex: 'previewUrl',
            key: 'previewUrl',
            width: 80,
            render: (value) => value ? (
              <ImageItem
                uri={value}
                style={{ width: 50, height: 50 }}
                canRemove={false}
              />
            ) : null
          },
          {
            title: 'Name',
            dataIndex: 'variantName',
            key: 'variantName',
          },
          {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
          },
          {
            title: 'Size',
            dataIndex: 'size',
            key: 'size',
          },
          {
            title: 'Price',
            dataIndex: 'price',
            key: 'price',
            render: (value) => value ? `£${ValHelper.formatMoney(value)}` : "",
          },
          // {
          //   title: 'SKU',
          //   dataIndex: 'sku',
          //   key: 'sku',
          // },
          {
            title: 'Actions',
            key: 'actions',
            width: 100,
            render: (_, record, idx) => (
              <Row>
                <Col width={50} height={50} onPress={() => onEditSKU(record, idx)} middle mr1>
                  <SVG.Pencil color={COLOR.MAIN} />
                </Col>
                <Col width={50} height={50} onPress={() => onRemoveSKU(record)} middle>
                  <SVG.Trash color={COLOR.MAIN} />
                </Col>
              </Row>
            )
          }
        ].filter(i => i !== undefined)}
        data={design?.variants}
      />
      <Button
        height={56}
        marginTop={12}
        title="Add variant"
        backgroundColor={COLOR.SECOND}
        onPress={onPressAddSKU}
        alignSelf="flex-start"
      />
      <CreateDesignVariantModal
        ref={skuModal}
        physicalWidth={design?.physicalWidth}
        physicalHeight={design?.physicalHeight}
        onSave={onSaveSKU}
        onSaveVariants={onSaveVariants}
        design={design}
        product={product}
        setDesign={setDesign}
      />
      <SelectDesignVariantModal
        product={product}
        variants={design?.variants}
        onUpdateVariants={onUpdateVariants}
        ref={selectRef}
      />
    </Col>
  );
}

export default CreateResellerProductVariants;
