import React, { forwardRef, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Modal, Tooltip } from 'antd';
import { Button, Col, FieldItem, Grid, ImageItem, Row, TableForm, Text, UploadFile } from 'components';
import { ActivityIndicator } from 'react-native';
import { COLOR } from 'const';
import { SVG } from 'assets';
import { EditorIframeModal } from 'components/container';

const FIELDS = [
  { label: 'Name', id: 'variantName' },
  { label: 'Description', id: 'description' },
  { label: 'Price', id: 'price', left: <Text>£</Text> },
  { label: 'Size', id: 'size' },
  // { label: 'Custom Width', id: 'width' },
  // { label: 'Custom Height', id: 'height' },
];

const CreateDesignVariantModal = forwardRef((props: any, ref) => {
  const uploadRef = useRef<{ showDialog: Function }>(null);
  const editorModalRef = useRef(null);
  const [show, setShow] = useState(false);
  const [state, setState] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [targetIdx, setTargetIdx] = useState(0);

  useImperativeHandle(ref, () => ({
    open: (data, targetIdx) => {
      setState(data || {});
      setTargetIdx(targetIdx);
      setShow(true);
    },
    hide: () => {
      setShow(false);
    },
  }))

  const updateState = (key) => (value) => {
    setState(s => ({
      ...s,
      [key]: value,
    }))
  }

  const onFileUploaded = (urls) => {
    if (urls.length === 0) return setLoading(false);
    updateState("url")(urls?.[0]);
    setLoading(false);
  }

  const onPressAddFile = () => {
    uploadRef.current?.showDialog();
  }

  const onPressSave = () => {
    props?.onSave(state, targetIdx);
    setShow(false);
  }

  const onOpenEditor = () => {
    editorModalRef.current?.open();
  }

  const productWithCustomBlender = useMemo(() => {
    if (!state?.blender) return props.product;

    const customData = { ...props.product.data };
    if (state?.blender) customData.blender = state.blender;

    const skuPDFs = props.product.data?.skuPDFs?.filter(i => props.design?.variants?.some(j => j.sku === i.sku));
    if (skuPDFs) customData.skuPDFs = skuPDFs;

    return {
      ...props.product,
      data: customData,
    }
  }, [props.product, state]);

  if (!show) return null;

  return (
    <Modal
      open={show}
      title={targetIdx !== undefined ? "Edit Variant" : "Add Variant"}
      onOk={() => setShow(false)}
      onCancel={() => setShow(false)}
      width={1000}
      footer={false}
    >
      <Grid
        xs="100%"
        lg="50%"
        marginHorizontal={-12}
        alignItems="flex-start"
      >
        <Col mb2 marginHorizontal={12}>
          {FIELDS.map(field => (
            <FieldItem
              key={field.id}
              label={field.label}
              value={state?.[field.id]}
              left={field.left}
              onChangeText={updateState(field.id)}
            />
          ))}
          {/* <FieldItem
            label="Custom PDF"
            value={state?.url}
            onChangeText={updateState("url")}
            inputProps={{
              right: (
                <Tooltip title="Upload PDF file">
                  <Col padding={10} marginRight={-10} onPress={onPressAddFile}>
                    {loading ?
                      <ActivityIndicator color={COLOR.MAIN} />
                      :
                      <SVG.ImagePlus width={24} height={24} />
                    }
                  </Col>
                </Tooltip>
              )
            }}
          /> */}
        </Col>
        <Col mb2 marginHorizontal={12}>
          <Text>SKU: <Text bold>{state.PPGSku}</Text></Text>
          {/* <Text>Width: <Text bold>{state.width}</Text></Text>
          <Text>Height: <Text bold>{state.height}</Text></Text> */}
          {!!state?.previewUrl && (
            <>
              <Text mt2>Preview</Text>
              <ImageItem
                uri={state.previewUrl}
                style={{ width: "100%", height: "100%" }}
                canRemove={false}
              />
            </>
          )}
        </Col>
      </Grid>

      <Row marginTop={16} alignSelf={"flex-end"} justifyContent="space-between">

        <Button
          height={50}
          title="Open editor"
          mt1
          width={180}
          onPress={onOpenEditor}
        />

        <Row>
          <Button
            height={50}
            title={"Cancel"}
            backgroundColor="transparent"
            textProps={{ color: COLOR.MAIN }}
            borderColor={COLOR.MAIN}
            borderWidth={1}
            onPress={() => setShow(false)}
          />
          <Button
            height={50}
            title={"Save"}
            ml2
            onPress={onPressSave}
          />
        </Row>
      </Row>

      <UploadFile
        ref={uploadRef}
        onUploaded={onFileUploaded}
        onUploading={() => setLoading(true)}
      />
      <EditorIframeModal
        onPDFSaved={({ url, previewUrl }) => {
          updateState("url")(url);
          if (previewUrl) updateState("previewUrl")(previewUrl);
        }}
        ref={editorModalRef}
        artworkType={props?.product?.data?.artworkType}
        productType={props?.product?.data?.type}
        selectedVariant={state}
        product={productWithCustomBlender}
        physicalWidth={+state?.width || props.physicalWidth}
        physicalHeight={+state?.height || props.physicalHeight}
        canvas={state?.canvas || props.design?.data?.globalCanvas}
        onchangeCanvas={({ canvas, previewUrl }) => {
          updateState("canvas")(canvas);
          if (previewUrl) updateState("previewUrl")(previewUrl);
        }}
        onchangeVariantsCanvas={(params) => {
          if (params.selecteds?.includes(state.variantName)) {
            updateState("canvas")(params.canvas);
            if (params.previewUrl) updateState("previewUrl")(params.previewUrl);
          }
          props.onSaveVariants?.(params);
        }}
      />
    </Modal>
  )
});

export default CreateDesignVariantModal;
