export const PREVIEW_HTML = `
<html lang="en" class="p-theme-light Polaris-Summer-Editions-2023"
    style="--pc-frame-global-ribbon-height: 0px; --pc-frame-offset: 0px; --pc-app-provider-scrollbar-width: 0px; --pc-checkbox-offset: 38px;">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover">
    <link rel="preconnect" href="https://cdn.shopify.com/" crossorigin="">
    <link rel="stylesheet" href="https://cdn.shopify.com/static/fonts/inter/v4/styles.css" crossorigin="">


    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/styles-fee9b9a595d3.css"
        crossorigin="" integrity="sha256-/um5pZXT+iMs3FtRAr+wlUS6YXkHRiA2PA46hbZAOC4=">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/styles-8d4432d14f85.css"
        crossorigin="" integrity="sha256-jUQy0U+F1Ujmp5hw9xWRidf1IwLYQI6SMFku+hRYqrg=">
    <script type="module" src="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/styles-96b91b98f3e0.js"
        crossorigin="" integrity="sha256-uitlF2MNqHWLcClYJWv01IjXe/wXBVUGQpYHJjRxCsI="></script>
    <script type="module" src="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/client-0593399736b0.js"
        crossorigin="" integrity="sha256-KtAyns7G2C0V6Fa6PV2p7VncDkssW2LMh+S3Qp+ATdw="></script>
    <script type="module" src="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/styles-96ea13e392dc.js"
        crossorigin="" integrity="sha256-vKrmkctsEixrlYAYDxiMnGIUBJGq6MGloRNQcGW1k+g="></script>
    <script type="module"
        src="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AuthenticationChallengeCallback-a411863f804c.js"
        crossorigin="" integrity="sha256-Io2sIOcczxaQirNUb9OgfH1/mT9+gW8NZ02lgEWZk6I="></script>
    <script type="module"
        src="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/mainUnified-8f72b7af.js"
        crossorigin="" integrity="sha256-MILhL/wiWSzs2ehQU/bbZn9ZFJkBC1Wxqi9Hq0RzBv8="></script>


    <script type="text/json"
        data-serialized-id="server-data">{"csrfToken":"mUXejlPN-dYXSigXdWIAWWERj3O2LzVr-lxg","sessionId":"df859949-e703-4a24-b7b5-5f306e2d2e49","locale":"en","cacheInfoArray":[],"bugsnag":{"apiKey":"8cea6c356df78fb8940a328ef6b6abad","endpoint":"https:\u002F\u002Fnotify.bugsnag.com","appVersion":"2522c6043fa3e2415f2824790098a7ade7d900c7","releaseStage":"production"},"isUnifiedApp":true,"homeResetLoadTest":false,"isShopifyStaff":false,"authTime":1708169786,"identityUser":{"sub":"6b6045cf-c1df-4be0-b124-7fdfcca96c6f","email":"accounts@codesigned.co.uk","givenName":null,"familyName":null,"displayName":"accounts@codesigned.co.uk","picture":"https:\u002F\u002Faccounts.shopify.com\u002Faccounts\u002F6b6045cf-c1df-4be0-b124-7fdfcca96c6f\u002Fgravatar","locale":"en","zoneinfo":"Europe\u002FLondon","emailVerified":true,"betaFeatures":[]},"urlDetails":{"hostname":"admin.shopify.com","webHost":"web.sfe.shopifyinternal.com","coreCdnHost":"cdn.shopify.com","coreHost":"app.shopify.com","adminDomain":"myshopify.com","sidekickHost":"sidekick.shopify.ai"},"browser":{"userAgent":"Mozilla\u002F5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit\u002F537.36 (KHTML, like Gecko) Chrome\u002F121.0.0.0 Safari\u002F537.36","supported":true}}</script>
    <script type="text/json" data-serialized-id="¡18n">{"locale":"en"}</script>


    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/UnifiedStore-579491b1bf25.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/styles-96b91b98f3e0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/styles-96ea13e392dc.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/client-0593399736b0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AdminRouterProvider-0c27f3c3a817.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ApolloProvider-4e2b48a1489b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/context-dec143dfa7f2.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useCookies-f733dd656e67.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-143fe4375a64.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/url-0b49f914b833.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/hooks-aab803dfd070.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useHomeMetricsDataLoader-30b61dc1bd1a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/customize-theme-dd17f8858c50.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/download-a4810f662232.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/halo-success-ffccffca4498.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/halo-pending-2e125e2cc4e4.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/next-credit-card-57633914b030.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/HomeIcon.svg-f504a2928233.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ChartVerticalIcon.svg-2cd11d3270d4.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/TargetIcon.svg-236eafd1a432.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/DiscountIcon.svg-d63465d06795.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PlusCircleIcon.svg-5761c8b897c9.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SettingsIcon.svg-00c8151f3686.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LayoutHeaderIcon.svg-76cc3ccff2e8.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CalendarIcon.svg-ea2e474445d0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/DomainIcon.svg-63556c777885.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ContractIcon.svg-211e875df932.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/EmailIcon.svg-ab8c7978e8e0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/GiftCardIcon.svg-3e01c45b1d2e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ImageIcon.svg-e8af096b8e65.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/NoteIcon.svg-48685540e2d0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MobileIcon.svg-d70fb3ee6b77.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CartIcon.svg-3f54eabc5ba1.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PaintBrushFlatIcon.svg-becaaafad34a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ProfileIcon.svg-7f375314a44e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ExitIcon.svg-303bf52a63b2.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ClockIcon.svg-938f9fc8bdaa.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CashDollarIcon.svg-2a3e80d7f09c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PaymentIcon.svg-a7aac0535f4e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CodeIcon.svg-ef00624da2e2.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LinkIcon.svg-4a3bccf6ce70.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/BookOpenIcon.svg-69de4785ba78.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LightbulbIcon.svg-24759e45f077.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CurrencyConvertIcon.svg-ba3fe9a420c4.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ShieldPendingIcon.svg-7a20758e0ea0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/StarIcon.svg-ca94b32899cc.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/StarFilledIcon.svg-041ffa925ec9.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/StoreOnlineIcon.svg-8cdb553dbc29.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ArchiveIcon.svg-93ae7ff56b66.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/BankIcon.svg-442598e2076e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PackageIcon.svg-d0f75c9e267e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/EnvelopeSoftPackIcon.svg-eef89a49d6b2.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/DeliveryIcon.svg-531882efdb53.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CartAbandonedIcon.svg-d77625e9ac0f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CreditCardReaderChipIcon.svg-0b91187a6560.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PhoneIcon.svg-c1c68bf89c2b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PointOfSaleIcon.svg-7e59515bd125.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/TaxIcon.svg-8c969cef89f4.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ReceiptRefundIcon.svg-b7da27c18d99.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ShareIcon.svg-2b8735a0876b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ThumbsUpIcon.svg-83bde5d48b55.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MagicIcon.svg-49ea212b5dd0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/normalizeQueryMeta-026f0672f4f0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/suspense-967713f30cea.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-c5ca6be612fe.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/attempt-a5dc80ca7b01.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/_baseValues-b22c25e7c194.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/get-best-over-period-f8d805397f76.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/parse-period-9c0c008e7c12.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/get-period-length-fc6159789469.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/formatReportifyQuery-080153e0a63c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-70981fbfdfbd.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/groupBy-ed4653dc72d6.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/_createAggregator-bb613cfb0c59.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/_baseEach-cc6781a56c24.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/_baseForOwn-ed3529b38d38.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AnalyticsDateRange-46a2c80dd185.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/unapply-time-zone-offset-13833d5015b2.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useHomeDataLoader-4c300b82cb97.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useGuidance-d3de4ea026b4.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-7f4ba1512201.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/hooks-00571a271290.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-b9756e6fc75c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/create-graphql-client-options-13ccbd28e33e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/storage-9cb1182e9790.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useEnableLocalExtensions-e7b636529e80.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/omit-7d871dfed042.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/_baseClone-6c93f7a51d82.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/_arrayEach-94ada1519335.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/_getAllKeysIn-1cae6a7bba19.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/last-be15b5abf7a9.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/_flatRest-4ad6147b1265.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/flatten-4c5eecd39ce9.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/_baseFlatten-31a3c22d96ea.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/pick-93107ff0e8b1.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/_basePickBy-65228f1ed04b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useController-16314295b4f0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/utilities-f8a6a6f73f0a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/receiver-b6936afd9383.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/get-legacy-store-handle-36b6ecd9bbc0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/GenerateSessionToken-5a401b8ade4c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-1017d0dbdd19.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/promiseResolver-73b1535db6be.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AdminPolarisVizProvider-2c8bf385dfa3.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/context-e99757397a60.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ErrorBoundary-0662692f9890.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/with-router-2b5875f1b1f7.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useHistoryBlocker-ca190c1a3777.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/with-loading-f319b35b3bc3.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-311f46ffcc7f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AppAuthPage-5731c970acf4.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useAppPermissionAuthorizationContext-23de9d053d39.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/context-error-6f1025720d06.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/circle-alert-a3ba6f1d4141.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AppAuthPermissionError-e80ba3a627fa.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useAppAuthPostGrantCallback-d1978b1d1d12.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useAppSupport-7acbe2b32440.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AppGetSupportModal-17006ad4db2f.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/AppGetSupportModal-7de43aba2402.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MediaListener-93db90580b1f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ResourceAlertBanner-aead9435235b.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/ResourceAlertBanner-79a7cbd355bf.css">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/AppAuthPage-5652612b035d.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-f5c942d20e7d.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useDestinationsData-e2c0bddaa527.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/context-0ffa110e5d74.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/context-b6411af20c47.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/media-54a2bca39d9a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-is-small-screen-7cb63c5dc4c8.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/Toast-43e7c33f40ce.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/context-bef6fe627fb4.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/context-a13e75145d67.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useAppAuthPostGrantLaunchAppRedirect-5ef8c6e5bbcc.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-is-nav-bar-collapsed-2fb219ddcbe1.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/timeout-dca9ba5c1fac.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SwitchTransition-3279c79298dd.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/utilities-c80c443fc28c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/utilities-394696b19ca0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/is-apollo-error-e79e58b0c538.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/hook-ed495eb88283.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useShopifyPaymentsActive-37920a152cc4.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AdjustIcon.svg-d557bffcd105.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AffiliateIcon.svg-24eb6f44c8b5.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SportsIcon.svg-c904d39166bf.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AlertBubbleIcon.svg-fbb1492a9294.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AppsFilledIcon.svg-561fa43e8bb8.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ArrowRightIcon.svg-21ee5cc51776.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ArrowsInHorizontalIcon.svg-64e860925697.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ArrowsOutHorizontalIcon.svg-d6de4880cb25.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AttachmentIcon.svg-6262839d13d6.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AutomationFilledIcon.svg-017786699848.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AutomationIcon.svg-b90d4b72a8b0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ViewportWideIcon.svg-ee9ba9ce724a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ReturnIcon.svg-f0ab8b7bfc82.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CalculatorIcon.svg-f00f7eca592f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CalendarCheckIcon.svg-db090cfb1fce.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CalendarTimeIcon.svg-82b74d1862fb.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CaretUpIcon.svg-b1556eb57a66.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/TextIcon.svg-baaa9c85bc08.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CategoriesIcon.svg-4fd38c5bdc77.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ChannelsIcon.svg-f12cfdb02adf.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ChartStackedIcon.svg-c1f61407f3cb.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ChartHistogramSecondLastIcon.svg-c6dbdcd1b7b7.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ChartHistogramFlatIcon.svg-55d8919ad823.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/EyeglassesIcon.svg-6d56805b46dd.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ChartHorizontalIcon.svg-d9bc0b43f633.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ChartLineIcon.svg-0773e9df6cd6.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ChatIcon.svg-125da056ae12.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ReferralCodeIcon.svg-b1a791941e31.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CheckSmallIcon.svg-d2224e970400.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CircleChevronDownIcon.svg-b5c31c1c6a66.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CircleChevronLeftIcon.svg-c78bc9665b09.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CircleChevronRightIcon.svg-cefcf9afd1a3.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LayoutSidebarRightIcon.svg-7a5520ad95cd.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CircleLeftIcon.svg-f941e615d668.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ClipboardCheckIcon.svg-107f103af0be.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ClipboardChecklistIcon.svg-51ed78a80843.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ClipboardIcon.svg-b3136bac4d84.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/TextWithImageIcon.svg-40caf40b3c45.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ColorIcon.svg-d4879fcac54f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ConfettiIcon.svg-7efed8ad4bc8.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/OrderRepeatIcon.svg-a615118eb441.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CreditCardIcon.svg-3a85c76a6082.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CreditCardPercentIcon.svg-c547a2bc10e2.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PersonExitIcon.svg-fa5969c25021.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CursorFilledIcon.svg-5085ca63f3e0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CursorIcon.svg-1300eba03496.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/DataPresentationIcon.svg-203e8b9e8282.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/DataTableIcon.svg-160e54172769.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ImageAddIcon.svg-9fa14e21801e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/DesktopIcon.svg-c3c7b07d8221.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/IncomingIcon.svg-b985a70a7b3c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PageDownIcon.svg-78c48f2e79ac.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/DragHandleIcon.svg-cccb0b6c92af.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/EmailNewsletterIcon.svg-1c429e6fa833.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/EnterIcon.svg-3ff79d9469d9.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/EnvelopeIcon.svg-e1174098fb1b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ExchangeIcon.svg-b7f71b820d7f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ExportIcon.svg-d2bceba454d7.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ExternalSmallIcon.svg-8dc526c0056e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/OrderFirstIcon.svg-4a804a6ee124.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/FileIcon.svg-333edde2c6ee.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/FlagIcon.svg-004dc705fe71.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/FolderIcon.svg-f7806a0eb13c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/GlobeIcon.svg-26308b7b99f8.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/HashtagIcon.svg-300bd67de194.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/HeartIcon.svg-b12796c16d65.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/IconsIcon.svg-c507af785a28.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/IdentityCardIcon.svg-037b6b0d6f55.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ImportIcon.svg-f132e363c0c1.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/InventoryIcon.svg-54fe51b48155.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/KeyboardIcon.svg-6f5b7d3c6e84.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LanguageIcon.svg-880b7825b128.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LayoutSidebarLeftIcon.svg-9955928cb8f2.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ListBulletedIcon.svg-76a85ef5eb02.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/TextItalicIcon.svg-1bff5b5408f5.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LocationNoneIcon.svg-c5075dcedc48.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LockFilledIcon.svg-4848040c7538.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MaximizeIcon.svg-16fe7338eeb5.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MeasurementVolumeIcon.svg-d9ea9b6cadaa.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MegaphoneIcon.svg-c2acca039536.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MenuVerticalIcon.svg-2161df55d91e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MergeIcon.svg-2a8c7e948fae.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MetafieldsIcon.svg-1b0ec3c3365a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MetaobjectIcon.svg-7c7f2b1129d7.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MinimizeIcon.svg-ddb05bbb5644.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MinusCircleIcon.svg-2f55a8376620.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MoneyIcon.svg-4edcf5a636c8.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/NatureIcon.svg-0b0efcd963c0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/NotificationFilledIcon.svg-103f01f2029c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/NotificationIcon.svg-42b87fe57d1e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PackageReturnedIcon.svg-b48f3bd6c803.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/TextUnderlineIcon.svg-83ae4129849f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PackageFilledIcon.svg-e8ef4d0b4315.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PackageOnHoldIcon.svg-264683bcf7c8.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PageAttachmentIcon.svg-8ea7a89979b4.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PageUpIcon.svg-c9a578c86fc2.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PasskeyIcon.svg-27c0c415fc33.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PauseCircleIcon.svg-816ce70d7393.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PaymentCaptureIcon.svg-18949392c1d2.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PersonAddIcon.svg-597d28717659.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PlanIcon.svg-672bd584816a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PlayCircleIcon.svg-04f09ee5a194.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PlayIcon.svg-7d84fd547b5e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PrintIcon.svg-c6cd7193fbe9.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ProductReferenceIcon.svg-f8c95c729a46.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ReceiptDollarIcon.svg-63c8f991a03b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ReceiptIcon.svg-efcf3d1555a0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ReceiptPaidIcon.svg-ed2641d48e5d.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/RedoIcon.svg-6c0f9ef746ae.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/RefreshIcon.svg-bdf75709191a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ReplaceIcon.svg-7eeb71a24f7d.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ReplayIcon.svg-202ffce581a7.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ResetIcon.svg-8e609c2156b0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SandboxIcon.svg-6c7960ff3223.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SaveIcon.svg-ea4df21efcad.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SearchResourceIcon.svg-22cea3829c8b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SendIcon.svg-45ba00c37b75.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ShieldCheckMarkIcon.svg-7189982ff696.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SocialPostIcon.svg-9599518e66df.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/StatusIcon.svg-c99b2c13e02c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/StopCircleIcon.svg-0a20827672bd.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/StoreIcon.svg-e67041acf3b1.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/TeamIcon.svg-c929af1c59ba.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/TextBlockIcon.svg-47432d40e422.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/TextFontIcon.svg-a1ddde744a1e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ThemeIcon.svg-782e466a7d5a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ThemeTemplateIcon.svg-73580ddccf84.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ThumbsDownIcon.svg-935bc13626d5.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ToggleOffIcon.svg-4f4820f6b721.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/TransferOutIcon.svg-739425188565.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/TransferInIcon.svg-9caada415d90.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/UndoIcon.svg-d8e1e3cc9393.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/UploadIcon.svg-e4771fe85b43.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/WalletIcon.svg-9996bf4c87b2.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/WandIcon.svg-5cd40a46b56f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/WrenchIcon.svg-7c273c3fa8ea.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/catalogs-auth-bb3628ef7f8a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ShopMilestoneCompleteMutation-ef2efba8bbd1.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/InboxBadge-21d1e3a8824c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/utils-b4847b409fed.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useFetch-9d6eed709f75.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/hooks-371436cac78a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useDragAndPinInteractionMonorailEvent-ba800643e473.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/core.esm-ac1bb2a8ee99.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/sortable.esm-982c34be833a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/modifiers.esm-d5b556a5ed72.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/settings-modal-event-emitter-3ff41c2bad7a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/isEmpty-20fa2dd86975.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/hooks-d1f967a30293.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/hook-2f37018dee86.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/Tag-179b9164d77f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/compact-ad44f8b01829.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/HighlightedTerms-d6d1c4cdcb52.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/HighlightedTerms-7b3e7efea5dd.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useAppPricingText-e53908f9225b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PaymentBadge-215cdf4cd600.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/FulfillmentBadge-52bca573b0d7.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/escapeRegExp-1fb5affa4386.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/generateCodeForShop-d5516e4d28a9.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-89dc0c59e593.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/hooks-0644a98e1a0d.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ArgusEventListener-a32d6ad1b7fc.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/css-3de12d3ea890.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/get-date-diff-1c7ec067cc4c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-ef848a0ea75e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-d55c471e9afb.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/hooks-0a2f265e66d6.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-is-universal-sidebar-open-f114c9399be4.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/Head-5d9a0651f69c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/cloneDeep-d525cabb2826.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-809173c5f1c4.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ExtensionsError-500e3005190f.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/ExtensionsError-b234e44bd2bc.css">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/AdminRouterProvider-6f0d5baa7992.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AuthenticationChallengeCallback-a411863f804c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/OnlineStore-d42ef68c6af3.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-5f6880debdd0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-1b639203af64.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-f334bc7d5a6b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/redux-7853e321e6de.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/defineProperty-e37e43803499.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/root-7d83a067a640.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/merge-c18c6e321e83.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/utils-ace3db5946d9.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/Toast-58b8e1ef5a69.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-0635f715fc2a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/color-transformers-c187e695d576.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-index-resource-state-3078e17a7630.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SettingToggle-6bf170653230.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/Autocomplete-d60a8175c16a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CalloutCard-3bc08c1fb81d.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ColorPicker-bf89442566c4.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/DatePicker-226a25b783ed.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/DescriptionList-bc22c5ee816c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/DropZone-990688587e8d.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ExceptionList-141133c5abcb.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/Truncate-958f014894e6.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/FooterHelp-f092ab5e8194.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/FullscreenBar-c04ffed96794.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/Grid-abf634c576bd.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useSetIndexFiltersMode-99f66ff52925.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/IndexTable-160dc053519a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/context-f3b50782e8aa.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/InlineCode-24ba7a7ceae5.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/KeyboardKey-fbeaea32b544.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LegacyFilters-eecf38335ad2.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/Sheet-776d21ce4c1b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LegacyTabs-38bb934b2b1e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MediaCard-ef9a18336fb1.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ProgressBar-de5b70321f49.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/RangeSlider-c678b45ddbbb.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SkeletonTabs-08cf3e6a5163.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/VideoThumbnail-b3e620a585f1.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/hooks-66e72a80be00.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/Loading-d95dcd2e9792.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-2b6b7d51b7d7.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/_commonjs-dynamic-modules-ee48bc745690.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useFeature-b6ed77681df1.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-dfad8ea373b3.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/Client-6f1d7a39748c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/withStatelessFeature-16330c165dd7.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SkeletonTwoColumn-34714407ff8d.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SkeletonOnlineStoreEditor-92063e8af5f9.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/SkeletonOnlineStoreEditor-e84c81fda3fa.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-fe2d012ec020.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AppTitleBar-d1c5c5bcfd9f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AppActionContainer-1765c35be399.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PinIcon-21951c3e4dee.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/PinIcon-3dc3d6df0a5f.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-76938d59866e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-b4f27531a59b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/types-784eab71a989.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/AppActionContainer-ce97b7583abc.css">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/AppTitleBar-6fa152c645a2.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-3e09d802fe30.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AuthCode-1f734ff33b65.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SessionToken-78f0716545fd.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useAppAccessRequestGrantAndLaunchModal-90bb35597345.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/jwtHelper-a4cb2285e193.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/helper-353fa086e572.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/Video-835a978f6d80.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/utilities-dfe570a43711.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/browser-75063704cb70.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/purify.es-dab1dbd2336d.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/types-8963753eac86.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/types-06c00ee1214f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/get-mime-type-from-filename-396e7eb23ac1.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-c6b7fe3deaf3.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-208755b1690d.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/Video-bfacad079922.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/FilePickerModal-acb90b409f92.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-list-formatter-129f7a9063b4.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-os-file-picker-e9f644ec8d62.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ErrorBoundary-44fc65eb0a0e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ChoiceListWithSearch-a87c87129a5a.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/ChoiceListWithSearch-08327d23378b.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LoadingBar-778a269ee1f6.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/LoadingBar-627ffbd23bf3.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-is-touch-device-41bdf03611f0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ConditionalWrap-4de8af8a2f6e.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/FilePickerModal-359cedcb6a3b.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/FilePreviewModal-c357c4b944cc.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/saved-searches-fa8256308f86.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/constants-54315fa1c35f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-poller-4b35a5792713.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/clamp-6720abfb4f69.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ImageIcon-82ed9bac02fb.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/ImageIcon-6e76a791eb9a.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/InlineLoading-26f735dc62eb.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/debounced-17a4dbc1a2fe.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/InlineLoading-423a4be29603.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/FileDownloader-5c0855e373e5.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/FilePreviewModal-be6be44546f9.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/WebVitals-6163911796f5.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useAppLoadSession-6082426e6d47.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/hooks-51bb6915ed66.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/editing-context-01e7ba1cc1ba.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MetaobjectStatusBadge-420adc548abb.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-default-variant-99e732bb84fd.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/utilities-a3cb189c75f6.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AutoFocus-cecf7b5d861e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-d1fdc06443c8.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ViewOnlyField-7ef4890e3e95.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/isString-de618e6b7787.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useCustomDataTaxonomyFeatures-d281ba688e2b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/constants-17a111053a37.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MarketFlag-c0da8a7308bf.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/MarketFlag-65ec45af843d.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useMetaobjectCreatedBy-1629e173fb80.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/constants-f3aac04aa143.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/xor-0b2a8854797c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/_baseDifference-c482b7b5c805.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-887c9e58827d.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-7b32d7e50d8c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/_castFunction-cb5f88856b34.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/toInteger-84223efc1e3f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/toFinite-1a5a230f6efc.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/negate-02c7133fbe47.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/not-empty-52397f4d9ef0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ColorSwatch-988373ae2cb3.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/color-e6e0fbd3234c.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/ColorSwatch-15e74ba5e168.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-sidekick-list-c9ba494da71f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/list-d54a0e4274ad.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/baselist-7f527c9b6e66.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useMetaobjectLimits-6ee8bd7ad33e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/TextPicker-f6a4a081adec.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/TextPicker-7ba33a42969c.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/DatePickerTextField-ffb62c5fbb4f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/DatePicker-8a08df3b8eb6.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/DatePickerTextField-009803eaa751.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/DateTimePicker-1315b6c1774e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/timezone-9ad137371c21.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/TimePicker-c7d757b01950.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/Autocomplete-f5052237533a.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/Autocomplete-e72bd79752df.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/HelpDocLink-ef767f7e6d7c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CurrencyField-2e568d8da2ed.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/FormattedNumberField-db4f4272468a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ProductResourcePicker-71ddc17f0958.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/helpers-11a164008012.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/css-unit-conversion-4ddb9880ca13.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/helpers-cb2f2d17f82b.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LoadingState-8b2d6df77680.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/LoadingState-f0728f16cdff.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ResourceThumbnail-39c8c140395c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ProductStatusBadge-0ef6901408ff.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/utilities-472e2190deb1.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-variants-resource-limits-bb02603fa683.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/ProductResourcePicker-fce54838306d.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/throttle-475ac40e9a3b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MediaQueryProvider-384646feac93.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useCommonAction-44c12f91b322.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/TagTextField-4c094c96a37f.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/TagTextField-ea56d54ee7da.css">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/editing-context-9f5ec1b590db.css">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/OnlineStore-190eacaca22f.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/GlobalToast-dddfb9b50a27.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LoginNotifications-c4a2932139a0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/GlobalShortcuts-1f9c20845901.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/GlobalShortcuts-3146519d6337.css">
    <link rel="mask-icon"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/favicon-monotone-black-83e6e9e306b3.svg">
    <link rel="shortcut icon" type="image/x-icon"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/favicon-default-6cbad9de243d.ico">
    <meta name="apple-mobile-web-app-capable" content="yes">
    <meta name="apple-mobile-web-app-status-bar-style" content="black">
    <link rel="apple-touch-icon" sizes="114x114"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/icon-114x114-39f7951b8462.png">
    <link rel="apple-touch-icon" sizes="72x72"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/icon-72x72-6f81c9b4434e.png">
    <link rel="apple-touch-icon" sizes="57x57"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/icon-57x57-c881ae4e9e92.png">
    <link rel="apple-touch-startup-image"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/startup-5bb2f2e8a113.png">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-c9898a98c70a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-f2387f6d0d8b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-7eda76ea30ae.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/appUrl-207f43344bc9.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/resolver-3e8fb684a5c9.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/getResourceUrl-4a669dc4b595.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-e41c0e1f3a5b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CustomerListNext-cdef0b91c62e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-09a8076834f9.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LearnMoreFooter-56a0b7291f70.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/snakeCase-8b7ddd8a8e05.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-38f462d56614.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SegmentEditorSkeleton-6de8fbac0236.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-segmentation-monorail-5195ce9b028b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/unionBy-cbf5ce5451c5.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/bulk-actions-9176088cd2ac.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/constants-340919d5b641.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/session-storage-061c9f6a20e3.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/types-415dfaebb369.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/types-47e217fe1db6.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ProtectedInfo-41b3b57a6b35.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/ProtectedInfo-6f531bff08fd.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useAppBulkActionLinks-c33afd7a2506.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useSortedActionExtensions-342536b95f04.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ShopCustomerTags-ba49b8870d25.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/TagModal-a69c9387f100.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/TagList-951820ac249e.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/TagModal-a6f27ab164ef.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-cdd092256e1a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/constants-35e84e765e60.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AsyncDialogContent-f3b1702ed813.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/SegmentEditorSkeleton-889973a21cf4.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-afc12bf0c6ff.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-unmask-route-3943b4d6db7c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-shop-customer-tags-f2c5492fe10f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/query-params-92fe6d9583f8.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/utilities-9ccafea56b1b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/JobPoller-dd25e8071adb.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/JobPollerQuery-d051cf441bfa.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/graphql-765fdd980a41.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/utilities-617979a70313.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useRefetchKey-0e2a9d54295f.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/SegmentDetails-9efb2df88008.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ProductIndex-90f038ae98f5.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/data-44623a06c5c5.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/decorator-b26e8a8e71e3.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/decorators-753880e442c4.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/decorators-85f2be82c1e1.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ConnectedIndexFilteringWithIndexFilterControl-6bda9ec8f564.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useIndexFilteringWithSavedSearches-42697fb6c9b6.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/IndexFilteringSavedSearchDeleteMutation-d4ef74678d59.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/IndexFilterControlFilter-29ce83d9f903.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/valueAsArray-d19a8905a52f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/constants-263381da3d17.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/valueAsString-b1d7bda349c7.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ChoiceListSubOptions-fb2321b4aa25.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/format-02b057a0afc4.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/ChoiceListSubOptions-0e8ae3c29dd9.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/format-filter-values-92806a1709cd.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/types-dff6de2183ca.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/difference-a108ff854213.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/IndexFilterControlFilter-9bdc70122a7d.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useTabsWithSavedSearches-b41e1120a0e7.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/IndexFiltering-35d7d3251b78.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/IndexFiltering-bfc629e49c63.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-publishing-context-drivers-b295393d9445.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/withExtensionPoints-7035c90a4bad.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/decorators-b77f7013ba6a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SkeletonTable-29f7a19dadff.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/SkeletonTable-e3cce0500f60.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/fuzzy-search-c2cb38297fac.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/is-list-choice-filter-c3acc9d7ae17.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/decorator-c3fe84aa9b89.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/badge-generator-9cf9b582de84.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/getProductCount-a57e46441fcb.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PublicationHelpText-d6e6bef94e46.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CatalogsEditableList-9a3f85036196.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CompanyLocationCatalogsSearchQuery-9f8cd949bb9e.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/CompanyLocationCatalogsSearchQuery-249288b3eb62.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/constants-5508eec03861.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-a2e32b5a35cc.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/publications-f46fa8247fc8.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-window-focus-83444ae99db4.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/CatalogsEditableList-fbebe5cbc09e.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-query-params-1b8790063377.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/b2b-catalog-descriptors-e33d5d2bcb4b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AnalyticsBarNext-0ab5afb77b9e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MetricCardContainer-380c340d4bdd.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/BaseMetricCardContainer-8f1d1551ec13.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useGetReport-964771dd8993.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ReportsAvailableQuery-8e380b4ed96b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/TrendIndicator-959f8b33e925.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index.m-96ab0c5efa61.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/TrendIndicator-0bc905f75b13.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useFormatChartNumber-86c059774a04.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MetricCard-fc0628968ad0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/DefinitionPopover-79e883379dbb.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/DefinitionPopover-e2365837c9be.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-570e0aee0051.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LineChart-8949d794a3c0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/shouldSkipLabel-fc99ead08561.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/getAxisOptions-7a3ca603e00e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LegendContainer-9167b83c46d5.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LinearGradientWithStops-6e25e5456742.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LineSeries-3965eaee6ad4.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/array-febaca68d2cb.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/HorizontalGroup-d3e246530f31.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/extent-689c8ce9245b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useThemeSeriesColors-498033e7b3c2.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/range-e7a2a1bf2e55.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ViewReportButton-a95d2f584d83.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/index-ea242e9b375e.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/DonutChart-449735895443.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SimpleBarChart-d613dcbca4d9.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/pie-3b020aa1ec78.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/constants-d172240f7b50.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SparkLineChart-4a89fea209c5.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/BarChart-16eaf446159f.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/MetricCard-65fada38808c.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MetricCardLoading-97f07e86e07f.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/MetricCardLoading-5c5fd5fc2256.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MetricCardEmpty-ec3e5c98de97.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/MetricCardEmpty-0b9ddbc8064e.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MetricCardError-96e4910ec2fc.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useFormatChartDate-e40cf8f05f0e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useFormatDate-0b07df3b167c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/types-df824ea5036d.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AnalyticsTooltip-f0a395f7c584.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/AnalyticsTooltip-662cc84c832d.css">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/BaseMetricCardContainerUA-c385bc490142.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-element-size-659c1b8418c5.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/AnalyticsBarNext-cbc53acddfc1.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/types-1041e6d502e1.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/get-index-table-sort-toggle-labels-1d80211ea96b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ChannelPublicationsPopoverContent-f0c2f42543e0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PipItem-2225ae3dc0ed.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/PipItem-ef514333457d.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/channel-descriptors-6c787a74fca4.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-truncated-list-73e3206d7374.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-truncate-list-73fb22802ee7.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SalesChannelsSelectionModal-50a48696b415.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MultiSelectChoiceList-e6b43cbc010b.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/MultiSelectChoiceList-fe11e1562d8f.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MarketAwareChannelText-da15c0f72890.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-formatted-country-list-277024a5cae1.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/sort-and-translate-countries-a350737adf45.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/constants-a2710bac009a.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/MarketAwareChannelText-8edfd87b0c25.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ExportModal-f1e2b8f18799.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-saved-search-15f0a8274142.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/debounceAsync-6d4dc905d74b.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/decorators-11d0cab61082.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/withApollo-8cd018ff53a5.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/ProductIndex-3f3b00551058.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ProductDetails-31a0b1d297ea.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SidekickFormState-85be0e5b4d04.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/utilities-adbcdaee49cf.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/validators-5555369367d9.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PagePresenceGuarded-1994492bc9b1.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/PagePresenceGuarded-828f53029024.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/is-inventory-unavailable-quantity-name-1d5944b26b2f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/camelCase-1f7cf21f2aec.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/capitalize-e94555a351d2.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ScrollAnchor-1491ef282604.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-metafields-7c7e56257f11.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/namespace-and-key-d28b3b43d097.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-metafield-definitions-counts-156e4aa85fb5.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/use-metafields-9f7635765054.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/newly-created-5dbb6893de61.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-10e89bf0ee26.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useLaunchBlockExtensions-7fc560865ab4.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useLaunchInstances-6fa0855a534d.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/useLaunchBlockExtensions-d560654ca38f.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-d920d95d3115.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useProductInteractionTracking-0fc27946b559.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useAppAndChannelRecommendation-2ca426b61dc6.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/DismissibleElement-4216266a88b8.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/index-afd8c78f5138.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/constants-d5b8761017fa.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ConfigurationCardExtensionPoint-4d2e2fd3309d.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ViewOnlyCheckbox-46fe0b1d9009.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/HsCodesAutocomplete-a67a29661d5d.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/HsCodesAutocomplete-b19731348237.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/WeightField-0c734572b647.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/WebAddressFormatter-2a1e8890531f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-create-partial-product-mutation-inputs-31e37629c472.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/make-partial-object-4f42b477069e.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/CoreGraphQLFormattedString-31f916f89667.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/CoreGraphQLFormattedString-a7aa0e492a91.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/BundleLineItemRow-2e37cfe21168.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LineItemRow-384a2a0b29f9.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/GroupTitle-e3b8a7ad099d.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ProductVariantText-1741ca0ad4e8.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/ProductVariantText-98c7f9dd5d7a.css">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/LineItemRow-ebac0d8a8dfa.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AppIdentifier-3be6083c7c03.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/AppIdentifier-98fd30461d12.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/get-inventory-quantities-43cb83ca1714.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AdjustmentField-8db67c4248ac.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/get-inventory-quantities-d14c9b8774fe.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useWindowWidth-97f86118bf36.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useMediaUploader-affb67df7631.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/get-extension-from-mime-type-0afb0d3e8699.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/UIExtensionsHeader-e20c5ba16eac.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/UIExtensionsHeader-adb355cc1f23.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/PurchaseOptionsCardContainer-5861915e9bf0.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/PurchaseOptionsCardContainer-e75185644770.css">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/ConfigurationCardExtensionPoint-b710bb7601cb.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-c0cdd74aedb1.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/initialMoneyValue-395915ce94a5.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/DeleteModal-a3b89b4c9b4a.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/DeleteModal-070dbfe492ca.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/get-dates-from-date-range-0b586bb171eb.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/get-dates-from-date-range-f03335f70d2d.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SkeletonGlimpse-f20e49ae8917.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/SkeletonGlimpse-15ce3d48ee09.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/hook-2067bc6d7789.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/hook-cce6362ecac1.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MediaContainer-f8c5510ec765.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-is-initial-mount-1d7b8d193564.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useFileSize-5a3da63efb59.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/components-ff15b6d33b96.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-8891ed024fbe.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/capitalize-6c1bfa94402f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/times-92446e22026a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/cursor-392f06ddd373.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/MediaContainer-62c33daebd01.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/NewlyCreated-dffced0c928f.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-2433e415c64c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MetafieldDefinitionEditMutation-4211b8e08859.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useStandardizedProductType-fb0d8b210905.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useProductTaxonomySearch-73625dea5bcc.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/useStandardizedProductType-96e32d9f3451.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/match-sorter-a034436f37f3.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/diacritics-af0d384b1535.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/TagCard-b21e35a13fc9.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/TagsAutocomplete-70663284242c.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/TagsAutocomplete-33eb5fbe811f.css">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/TagCard-d02f54f5fac5.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-deep-effect-15bd408ec9c1.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-deep-compare-ref-f297e1bd8c8a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ModalProvider-f9e217b8a112.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/MediaUploader-960b7ae04cca.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LocationPicker-314eac2f06cd.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/SearchableListboxList-4e9d108f4468.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/LiveRegion-70274db25dc4.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/SearchableListboxList-b104cf777a06.css">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/LocationPicker-0831d0062000.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useLocationPicker-cc1afadf3855.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-ef42588cb6aa.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/partition-31be7a2c4699.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-a4f21527a5a4.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/sanitize-html-5302e3d2e5f9.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AppActionModal-a8091a3dc0f8.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/twitter-logo-minor-2065e95e64e0.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AdjacentResources-037a05524003.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/hooks-2ea0c319386d.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ContextBar-08658a5e4ba8.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/RichTextEditor-ab28e599ff3a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-cc703febff91.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useTriggerMagicPopoverModal-1ef94d6a8812.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/types-923e0e4c7279.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/ImageGrid-4f509b6e2efe.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/ImageGrid-ddf57f5ad6b8.css">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/RichTextEditor-329c70d5cd9a.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/useExtensionsFormData-0824235b9ad8.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/ProductDetails-989b88a67870.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/DescriptionGeneratePopover-89f3307f5c24.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/use-job-poller-d5c5350ec646.js">
    <link rel="stylesheet"
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/assets/DescriptionGeneratePopover-1eef21e73286.css">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/AppBridgeModalHost-21ed19d9b21a.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/StaffMember-58182e0c7264.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/index-b8b1ae3f5c4c.js">
    <link rel="modulepreload" as="script" crossorigin=""
        href="https://cdn.shopify.com/shopifycloud/web/assets/v1/vite/client/en/web-19db4d4ab6ac.js">
</head>

<div class="">
    <form autocomplete="off" method="post"><span class="Polaris-Text--root Polaris-Text--visuallyHidden"><button
                type="submit" aria-hidden="true" tabindex="-1">Submit</button></span>
        <div class="Polaris-Layout">
            <div class="Polaris-Layout__Section">
                <div class="Polaris-LegacyCard">
                    <div
                        class="Polaris-LegacyCard__Section Polaris-LegacyCard__FirstSectionPadding Polaris-LegacyCard__LastSectionPadding">
                        <div class="Polaris-BlockStack"
                            style="--pc-block-stack-order: column; --pc-block-stack-gap-xs: var(--p-space-400);">
                            <div class="Polaris-FormLayout__Item Polaris-FormLayout--grouped">
                                <div class="">
                                    <div class="Polaris-Labelled__LabelWrapper">
                                        <div class="Polaris-Label"><label id=":raa:Label" for=":raa:"
                                                class="Polaris-Label__Text">Title</label></div>
                                    </div>
                                    <div class="Polaris-Connected">
                                        <div class="Polaris-Connected__Item Polaris-Connected__Item--primary">
                                            <div class="Polaris-TextField Polaris-TextField--hasValue"><input
                                                    name="title" id=":raa:" placeholder="Short sleeve t-shirt"
                                                    autocomplete="off" class="Polaris-TextField__Input" type="text"
                                                    aria-labelledby=":raa:Label" aria-invalid="false"
                                                    data-1p-ignore="true" data-lpignore="true" data-form-type="other"
                                                    value="{{.name}}">
                                                <div class="Polaris-TextField__Backdrop"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="Polaris-FormLayout__Item Polaris-FormLayout--grouped">
                                <div class="_RichTextEditor_ladfa_6"><span
                                        class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--visuallyHidden">Rich
                                        text editor</span>
                                    <div class="">
                                        <div class="Polaris-Labelled__LabelWrapper">
                                            <div class="Polaris-Label"><label id="product-descriptionLabel"
                                                    for="product-description"
                                                    class="Polaris-Label__Text">Description</label></div>
                                        </div>
                                        {{.description}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="_CardWrapper_g73jm_1">
                    <div class="Polaris-LegacyCard">
                        <div class="_CardContainer_6dgzr_1">
                            <div class="_Header_6dgzr_28">
                                <div class="_Title_6dgzr_44">
                                    <h2 class="Polaris-Text--root Polaris-Text--headingMd">Media</h2>
                                </div>
                            </div>
                            <div class="_CardBannerContainer_6dgzr_70"></div>
                            <div class="_SectionContainer_6dgzr_9">
                                <div
                                    class="Polaris-LegacyCard__Section Polaris-LegacyCard__FirstSectionPadding Polaris-LegacyCard__LastSectionPadding">
                                    <div class="Polaris-Labelled--hidden">
                                        <div class="Polaris-Labelled__LabelWrapper">
                                            <div class="Polaris-Label"><label id=":ri1:Label" for=":ri1:"
                                                    class="Polaris-Label__Text">Upload files</label></div>
                                        </div>
                                        <div class="Polaris-DropZone Polaris-DropZone--noOutline Polaris-DropZone--sizeLarge"
                                            aria-disabled="false"><span
                                                class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--visuallyHidden"><input
                                                    id=":ri1:" multiple="" type="file" autocomplete="off"></span>
                                            <div class="Polaris-DropZone__Container">
                                                <div class="_Container_1w9ad_1">
                                                    <div class="_Grid_1o4jr_4">
                                                        <div
                                                            style="transform-origin: 0px 0px; transition: transform 0ms linear 0s;">
                                                            <button class="_Container_n3uhf_1" type="button">
                                                                <div class="_ThumbnailWrapper_n3uhf_64"><img
                                                                        class="_ThumbnailElement_n3uhf_55"
                                                                        src="{{.thumbnail}}"
                                                                        alt=""></div>
                                                                <div class="_Checkbox_n3uhf_25"><label
                                                                        class="Polaris-Choice Polaris-Choice--labelHidden Polaris-Checkbox__ChoiceLabel"
                                                                        for=":ri2:"><span
                                                                            class="Polaris-Choice__Control"><span
                                                                                class="Polaris-Checkbox"><input
                                                                                    id=":ri2:" type="checkbox"
                                                                                    class="Polaris-Checkbox__Input"
                                                                                    aria-invalid="false" role="checkbox"
                                                                                    aria-checked="false" value=""><span
                                                                                    class="Polaris-Checkbox__Backdrop"></span><span
                                                                                    class="Polaris-Checkbox__Icon Polaris-Checkbox--animated"><svg
                                                                                        viewBox="0 0 16 16"
                                                                                        shape-rendering="geometricPrecision"
                                                                                        text-rendering="geometricPrecision">
                                                                                        <path class=""
                                                                                            d="M1.5,5.5L3.44655,8.22517C3.72862,8.62007,4.30578,8.64717,4.62362,8.28044L10.5,1.5"
                                                                                            transform="translate(2 2.980376)"
                                                                                            opacity="0" fill="none"
                                                                                            stroke="currentColor"
                                                                                            stroke-width="2"
                                                                                            stroke-linecap="round"
                                                                                            stroke-linejoin="round"
                                                                                            pathLength="1"></path>
                                                                                    </svg></span></span></span><span
                                                                            class="Polaris-Choice__Label"><span>Select</span></span></label>
                                                                </div>
                                                            </button></div>
                                                        
                                                    </div>
                                                    <div id="DndDescribedBy-4" style="display: none;">
                                                        To pick up a draggable item, press the space bar.
                                                        While dragging, use the arrow keys to move the item.
                                                        Press space again to drop the item in its new position, or press
                                                        escape to cancel.
                                                    </div>
                                                    <div id="DndLiveRegion-4" role="status" aria-live="assertive"
                                                        aria-atomic="true"
                                                        style="position: fixed; width: 1px; height: 1px; margin: -1px; border: 0px; padding: 0px; overflow: hidden; clip: rect(0px, 0px, 0px, 0px); clip-path: inset(100%); white-space: nowrap;">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="_CardWrapper_x2nii_1">
                    <div class="Polaris-LegacyCard">
                        <div class="Polaris-LegacyCard__Header Polaris-LegacyCard__FirstSectionPadding"><span
                                class="Polaris-InlineStack"
                                style="--pc-inline-stack-align: space-between; --pc-inline-stack-block-align: center; --pc-inline-stack-wrap: wrap; --pc-inline-stack-gap-xs: var(--p-space-200); --pc-inline-stack-flex-direction-xs: row;">
                                <h2 class="Polaris-Text--root Polaris-Text--headingMd">Variants</h2>
                            </span></div>
                        <div class="Polaris-Box"
                            style="--pc-box-padding-block-start-xs: var(--p-space-400); --pc-box-padding-block-end-xs: var(--p-space-400); --pc-box-padding-inline-start-xs: var(--p-space-400); --pc-box-padding-inline-end-xs: var(--p-space-400);">
                            <div class="Polaris-Box"
                                style="--pc-box-border-color: var(--p-color-border); --pc-box-border-style: solid; --pc-box-border-radius: var(--p-border-radius-200); --pc-box-border-width: var(--p-border-width-025);">
                                <div class="_CardSection_1kz97_17">
                                    <div class="_Header_1kz97_1">
                                        <div class="_GridWrapper_1kz97_25"><button type="button"
                                                class="_DragHandle_7bsl5_8 _NameDragHandle_1kz97_125" role="button"
                                                tabindex="0" aria-disabled="false" aria-roledescription="sortable"
                                                aria-describedby="DndDescribedBy-2"><span><span
                                                        class="Polaris-Icon"><svg viewBox="0 0 20 20"
                                                            class="Polaris-Icon__Svg" focusable="false"
                                                            aria-hidden="true">
                                                            <path
                                                                d="M7.5 4.5c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                            </path>
                                                            <path
                                                                d="M7.5 8.75c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                            </path>
                                                            <path
                                                                d="M6.5 14c0-.552.448-1 1-1h.5c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1v-.5Z">
                                                            </path>
                                                            <path
                                                                d="M12 4.5c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                            </path>
                                                            <path
                                                                d="M11 9.75c0-.552.448-1 1-1h.5c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1v-.5Z">
                                                            </path>
                                                            <path
                                                                d="M12 13c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                            </path>
                                                        </svg></span></span></button>
                                            <div>
                                                <div class="_CollapsedSectionGrid_1kz97_34">
                                                    <div class="_CollapsedOptionValues_1kz97_136">
                                                    {{.variantNames}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="option-gid://shopify/ProductOption/11393424785754"
                                        class="Polaris-Collapsible Polaris-Collapsible--isFullyClosed Polaris-Collapsible--expandOnPrint"
                                        aria-hidden="true" style="max-height: 0px; overflow: hidden;">
                                        <div class="">
                                            <div class="_HeaderGrid_1kz97_31">
                                                <div class="_ValuesFieldLabel_1kz97_132"><span
                                                        class="Polaris-Text--root Polaris-Text--bodyMd">Option
                                                        values</span></div>
                                            </div>
                                            <div>
                                                <div class="_SortableWrapper_1twcm_1">
                                                    <div class="_ExpandedSectionGrid_1kz97_35"><button type="button"
                                                            class="_DragHandle_7bsl5_8 _ValueDragHandle_1kz97_126"
                                                            role="button" tabindex="0" aria-disabled="false"
                                                            aria-roledescription="sortable"
                                                            aria-describedby="DndDescribedBy-3"><span><span
                                                                    class="Polaris-Icon"><svg viewBox="0 0 20 20"
                                                                        class="Polaris-Icon__Svg" focusable="false"
                                                                        aria-hidden="true">
                                                                        <path
                                                                            d="M7.5 4.5c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M7.5 8.75c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M6.5 14c0-.552.448-1 1-1h.5c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1v-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 4.5c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M11 9.75c0-.552.448-1 1-1h.5c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1v-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 13c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                    </svg></span></span></button>
                                                        <div class="_OptionValue_1kz97_131">
                                                            <div>
                                                                <div class="Polaris-Labelled--hidden">
                                                                    <div class="Polaris-Labelled__LabelWrapper">
                                                                        <div class="Polaris-Label"><label
                                                                                id=":rbr:Label" for=":rbr:"
                                                                                class="Polaris-Label__Text">Option
                                                                                value</label></div>
                                                                    </div>
                                                                    <div class="Polaris-Connected">
                                                                        <div
                                                                            class="Polaris-Connected__Item Polaris-Connected__Item--primary">
                                                                            <div
                                                                                class="Polaris-TextField Polaris-TextField--hasValue">
                                                                                <input name="optionValue[0][0]"
                                                                                    id=":rbr:" placeholder=""
                                                                                    autocomplete="off"
                                                                                    class="Polaris-TextField__Input"
                                                                                    type="text"
                                                                                    aria-labelledby=":rbr:Label"
                                                                                    aria-invalid="false"
                                                                                    data-1p-ignore="true"
                                                                                    data-lpignore="true"
                                                                                    data-form-type="other"
                                                                                    value="Black - S">
                                                                                <div
                                                                                    class="Polaris-TextField__Backdrop">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="_DeleteButton_1kz97_142"><button
                                                                class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                                                aria-label="Delete option" type="button"><span
                                                                    class="Polaris-Button__Icon"><span
                                                                        class="Polaris-Icon"><svg viewBox="0 0 20 20"
                                                                            class="Polaris-Icon__Svg" focusable="false"
                                                                            aria-hidden="true">
                                                                            <path
                                                                                d="M11.5 8.25a.75.75 0 0 1 .75.75v4.25a.75.75 0 0 1-1.5 0v-4.25a.75.75 0 0 1 .75-.75Z">
                                                                            </path>
                                                                            <path
                                                                                d="M9.25 9a.75.75 0 0 0-1.5 0v4.25a.75.75 0 0 0 1.5 0v-4.25Z">
                                                                            </path>
                                                                            <path fill-rule="evenodd"
                                                                                d="M7.25 5.25a2.75 2.75 0 0 1 5.5 0h3a.75.75 0 0 1 0 1.5h-.75v5.45c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311c-.642.327-1.482.327-3.162.327h-.4c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311c-.327-.642-.327-1.482-.327-3.162v-5.45h-.75a.75.75 0 0 1 0-1.5h3Zm1.5 0a1.25 1.25 0 1 1 2.5 0h-2.5Zm-2.25 1.5h7v5.45c0 .865-.001 1.423-.036 1.848-.033.408-.09.559-.128.633a1.5 1.5 0 0 1-.655.655c-.074.038-.225.095-.633.128-.425.035-.983.036-1.848.036h-.4c-.865 0-1.423-.001-1.848-.036-.408-.033-.559-.09-.633-.128a1.5 1.5 0 0 1-.656-.655c-.037-.074-.094-.225-.127-.633-.035-.425-.036-.983-.036-1.848v-5.45Z">
                                                                            </path>
                                                                        </svg></span></span></button></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="_SortableWrapper_1twcm_1">
                                                    <div class="_ExpandedSectionGrid_1kz97_35"><button type="button"
                                                            class="_DragHandle_7bsl5_8 _ValueDragHandle_1kz97_126"
                                                            role="button" tabindex="0" aria-disabled="false"
                                                            aria-roledescription="sortable"
                                                            aria-describedby="DndDescribedBy-3"><span><span
                                                                    class="Polaris-Icon"><svg viewBox="0 0 20 20"
                                                                        class="Polaris-Icon__Svg" focusable="false"
                                                                        aria-hidden="true">
                                                                        <path
                                                                            d="M7.5 4.5c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M7.5 8.75c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M6.5 14c0-.552.448-1 1-1h.5c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1v-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 4.5c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M11 9.75c0-.552.448-1 1-1h.5c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1v-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 13c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                    </svg></span></span></button>
                                                        <div class="_OptionValue_1kz97_131">
                                                            <div>
                                                                <div class="Polaris-Labelled--hidden">
                                                                    <div class="Polaris-Labelled__LabelWrapper">
                                                                        <div class="Polaris-Label"><label
                                                                                id=":rbs:Label" for=":rbs:"
                                                                                class="Polaris-Label__Text">Option
                                                                                value</label></div>
                                                                    </div>
                                                                    <div class="Polaris-Connected">
                                                                        <div
                                                                            class="Polaris-Connected__Item Polaris-Connected__Item--primary">
                                                                            <div
                                                                                class="Polaris-TextField Polaris-TextField--hasValue">
                                                                                <input name="optionValue[0][1]"
                                                                                    id=":rbs:" placeholder=""
                                                                                    autocomplete="off"
                                                                                    class="Polaris-TextField__Input"
                                                                                    type="text"
                                                                                    aria-labelledby=":rbs:Label"
                                                                                    aria-invalid="false"
                                                                                    data-1p-ignore="true"
                                                                                    data-lpignore="true"
                                                                                    data-form-type="other"
                                                                                    value="Black - M">
                                                                                <div
                                                                                    class="Polaris-TextField__Backdrop">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="_DeleteButton_1kz97_142"><button
                                                                class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                                                aria-label="Delete option" type="button"><span
                                                                    class="Polaris-Button__Icon"><span
                                                                        class="Polaris-Icon"><svg viewBox="0 0 20 20"
                                                                            class="Polaris-Icon__Svg" focusable="false"
                                                                            aria-hidden="true">
                                                                            <path
                                                                                d="M11.5 8.25a.75.75 0 0 1 .75.75v4.25a.75.75 0 0 1-1.5 0v-4.25a.75.75 0 0 1 .75-.75Z">
                                                                            </path>
                                                                            <path
                                                                                d="M9.25 9a.75.75 0 0 0-1.5 0v4.25a.75.75 0 0 0 1.5 0v-4.25Z">
                                                                            </path>
                                                                            <path fill-rule="evenodd"
                                                                                d="M7.25 5.25a2.75 2.75 0 0 1 5.5 0h3a.75.75 0 0 1 0 1.5h-.75v5.45c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311c-.642.327-1.482.327-3.162.327h-.4c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311c-.327-.642-.327-1.482-.327-3.162v-5.45h-.75a.75.75 0 0 1 0-1.5h3Zm1.5 0a1.25 1.25 0 1 1 2.5 0h-2.5Zm-2.25 1.5h7v5.45c0 .865-.001 1.423-.036 1.848-.033.408-.09.559-.128.633a1.5 1.5 0 0 1-.655.655c-.074.038-.225.095-.633.128-.425.035-.983.036-1.848.036h-.4c-.865 0-1.423-.001-1.848-.036-.408-.033-.559-.09-.633-.128a1.5 1.5 0 0 1-.656-.655c-.037-.074-.094-.225-.127-.633-.035-.425-.036-.983-.036-1.848v-5.45Z">
                                                                            </path>
                                                                        </svg></span></span></button></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="_SortableWrapper_1twcm_1">
                                                    <div class="_ExpandedSectionGrid_1kz97_35"><button type="button"
                                                            class="_DragHandle_7bsl5_8 _ValueDragHandle_1kz97_126"
                                                            role="button" tabindex="0" aria-disabled="false"
                                                            aria-roledescription="sortable"
                                                            aria-describedby="DndDescribedBy-3"><span><span
                                                                    class="Polaris-Icon"><svg viewBox="0 0 20 20"
                                                                        class="Polaris-Icon__Svg" focusable="false"
                                                                        aria-hidden="true">
                                                                        <path
                                                                            d="M7.5 4.5c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M7.5 8.75c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M6.5 14c0-.552.448-1 1-1h.5c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1v-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 4.5c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M11 9.75c0-.552.448-1 1-1h.5c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1v-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 13c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                    </svg></span></span></button>
                                                        <div class="_OptionValue_1kz97_131">
                                                            <div>
                                                                <div class="Polaris-Labelled--hidden">
                                                                    <div class="Polaris-Labelled__LabelWrapper">
                                                                        <div class="Polaris-Label"><label
                                                                                id=":rbt:Label" for=":rbt:"
                                                                                class="Polaris-Label__Text">Option
                                                                                value</label></div>
                                                                    </div>
                                                                    <div class="Polaris-Connected">
                                                                        <div
                                                                            class="Polaris-Connected__Item Polaris-Connected__Item--primary">
                                                                            <div
                                                                                class="Polaris-TextField Polaris-TextField--hasValue">
                                                                                <input name="optionValue[0][2]"
                                                                                    id=":rbt:" placeholder=""
                                                                                    autocomplete="off"
                                                                                    class="Polaris-TextField__Input"
                                                                                    type="text"
                                                                                    aria-labelledby=":rbt:Label"
                                                                                    aria-invalid="false"
                                                                                    data-1p-ignore="true"
                                                                                    data-lpignore="true"
                                                                                    data-form-type="other"
                                                                                    value="Black - L">
                                                                                <div
                                                                                    class="Polaris-TextField__Backdrop">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="_DeleteButton_1kz97_142"><button
                                                                class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                                                aria-label="Delete option" type="button"><span
                                                                    class="Polaris-Button__Icon"><span
                                                                        class="Polaris-Icon"><svg viewBox="0 0 20 20"
                                                                            class="Polaris-Icon__Svg" focusable="false"
                                                                            aria-hidden="true">
                                                                            <path
                                                                                d="M11.5 8.25a.75.75 0 0 1 .75.75v4.25a.75.75 0 0 1-1.5 0v-4.25a.75.75 0 0 1 .75-.75Z">
                                                                            </path>
                                                                            <path
                                                                                d="M9.25 9a.75.75 0 0 0-1.5 0v4.25a.75.75 0 0 0 1.5 0v-4.25Z">
                                                                            </path>
                                                                            <path fill-rule="evenodd"
                                                                                d="M7.25 5.25a2.75 2.75 0 0 1 5.5 0h3a.75.75 0 0 1 0 1.5h-.75v5.45c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311c-.642.327-1.482.327-3.162.327h-.4c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311c-.327-.642-.327-1.482-.327-3.162v-5.45h-.75a.75.75 0 0 1 0-1.5h3Zm1.5 0a1.25 1.25 0 1 1 2.5 0h-2.5Zm-2.25 1.5h7v5.45c0 .865-.001 1.423-.036 1.848-.033.408-.09.559-.128.633a1.5 1.5 0 0 1-.655.655c-.074.038-.225.095-.633.128-.425.035-.983.036-1.848.036h-.4c-.865 0-1.423-.001-1.848-.036-.408-.033-.559-.09-.633-.128a1.5 1.5 0 0 1-.656-.655c-.037-.074-.094-.225-.127-.633-.035-.425-.036-.983-.036-1.848v-5.45Z">
                                                                            </path>
                                                                        </svg></span></span></button></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="_SortableWrapper_1twcm_1">
                                                    <div class="_ExpandedSectionGrid_1kz97_35"><button type="button"
                                                            class="_DragHandle_7bsl5_8 _ValueDragHandle_1kz97_126"
                                                            role="button" tabindex="0" aria-disabled="false"
                                                            aria-roledescription="sortable"
                                                            aria-describedby="DndDescribedBy-3"><span><span
                                                                    class="Polaris-Icon"><svg viewBox="0 0 20 20"
                                                                        class="Polaris-Icon__Svg" focusable="false"
                                                                        aria-hidden="true">
                                                                        <path
                                                                            d="M7.5 4.5c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M7.5 8.75c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M6.5 14c0-.552.448-1 1-1h.5c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1v-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 4.5c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M11 9.75c0-.552.448-1 1-1h.5c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1v-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 13c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                    </svg></span></span></button>
                                                        <div class="_OptionValue_1kz97_131">
                                                            <div>
                                                                <div class="Polaris-Labelled--hidden">
                                                                    <div class="Polaris-Labelled__LabelWrapper">
                                                                        <div class="Polaris-Label"><label
                                                                                id=":rbu:Label" for=":rbu:"
                                                                                class="Polaris-Label__Text">Option
                                                                                value</label></div>
                                                                    </div>
                                                                    <div class="Polaris-Connected">
                                                                        <div
                                                                            class="Polaris-Connected__Item Polaris-Connected__Item--primary">
                                                                            <div
                                                                                class="Polaris-TextField Polaris-TextField--hasValue">
                                                                                <input name="optionValue[0][3]"
                                                                                    id=":rbu:" placeholder=""
                                                                                    autocomplete="off"
                                                                                    class="Polaris-TextField__Input"
                                                                                    type="text"
                                                                                    aria-labelledby=":rbu:Label"
                                                                                    aria-invalid="false"
                                                                                    data-1p-ignore="true"
                                                                                    data-lpignore="true"
                                                                                    data-form-type="other"
                                                                                    value="Black - XL">
                                                                                <div
                                                                                    class="Polaris-TextField__Backdrop">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="_DeleteButton_1kz97_142"><button
                                                                class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                                                aria-label="Delete option" type="button"><span
                                                                    class="Polaris-Button__Icon"><span
                                                                        class="Polaris-Icon"><svg viewBox="0 0 20 20"
                                                                            class="Polaris-Icon__Svg" focusable="false"
                                                                            aria-hidden="true">
                                                                            <path
                                                                                d="M11.5 8.25a.75.75 0 0 1 .75.75v4.25a.75.75 0 0 1-1.5 0v-4.25a.75.75 0 0 1 .75-.75Z">
                                                                            </path>
                                                                            <path
                                                                                d="M9.25 9a.75.75 0 0 0-1.5 0v4.25a.75.75 0 0 0 1.5 0v-4.25Z">
                                                                            </path>
                                                                            <path fill-rule="evenodd"
                                                                                d="M7.25 5.25a2.75 2.75 0 0 1 5.5 0h3a.75.75 0 0 1 0 1.5h-.75v5.45c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311c-.642.327-1.482.327-3.162.327h-.4c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311c-.327-.642-.327-1.482-.327-3.162v-5.45h-.75a.75.75 0 0 1 0-1.5h3Zm1.5 0a1.25 1.25 0 1 1 2.5 0h-2.5Zm-2.25 1.5h7v5.45c0 .865-.001 1.423-.036 1.848-.033.408-.09.559-.128.633a1.5 1.5 0 0 1-.655.655c-.074.038-.225.095-.633.128-.425.035-.983.036-1.848.036h-.4c-.865 0-1.423-.001-1.848-.036-.408-.033-.559-.09-.633-.128a1.5 1.5 0 0 1-.656-.655c-.037-.074-.094-.225-.127-.633-.035-.425-.036-.983-.036-1.848v-5.45Z">
                                                                            </path>
                                                                        </svg></span></span></button></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="_SortableWrapper_1twcm_1">
                                                    <div class="_ExpandedSectionGrid_1kz97_35"><button type="button"
                                                            class="_DragHandle_7bsl5_8 _ValueDragHandle_1kz97_126"
                                                            role="button" tabindex="0" aria-disabled="false"
                                                            aria-roledescription="sortable"
                                                            aria-describedby="DndDescribedBy-3"><span><span
                                                                    class="Polaris-Icon"><svg viewBox="0 0 20 20"
                                                                        class="Polaris-Icon__Svg" focusable="false"
                                                                        aria-hidden="true">
                                                                        <path
                                                                            d="M7.5 4.5c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M7.5 8.75c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M6.5 14c0-.552.448-1 1-1h.5c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1v-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 4.5c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M11 9.75c0-.552.448-1 1-1h.5c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1v-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 13c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                    </svg></span></span></button>
                                                        <div class="_OptionValue_1kz97_131">
                                                            <div>
                                                                <div class="Polaris-Labelled--hidden">
                                                                    <div class="Polaris-Labelled__LabelWrapper">
                                                                        <div class="Polaris-Label"><label
                                                                                id=":rbv:Label" for=":rbv:"
                                                                                class="Polaris-Label__Text">Option
                                                                                value</label></div>
                                                                    </div>
                                                                    <div class="Polaris-Connected">
                                                                        <div
                                                                            class="Polaris-Connected__Item Polaris-Connected__Item--primary">
                                                                            <div
                                                                                class="Polaris-TextField Polaris-TextField--hasValue">
                                                                                <input name="optionValue[0][4]"
                                                                                    id=":rbv:" placeholder=""
                                                                                    autocomplete="off"
                                                                                    class="Polaris-TextField__Input"
                                                                                    type="text"
                                                                                    aria-labelledby=":rbv:Label"
                                                                                    aria-invalid="false"
                                                                                    data-1p-ignore="true"
                                                                                    data-lpignore="true"
                                                                                    data-form-type="other"
                                                                                    value="White - S">
                                                                                <div
                                                                                    class="Polaris-TextField__Backdrop">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="_DeleteButton_1kz97_142"><button
                                                                class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                                                aria-label="Delete option" type="button"><span
                                                                    class="Polaris-Button__Icon"><span
                                                                        class="Polaris-Icon"><svg viewBox="0 0 20 20"
                                                                            class="Polaris-Icon__Svg" focusable="false"
                                                                            aria-hidden="true">
                                                                            <path
                                                                                d="M11.5 8.25a.75.75 0 0 1 .75.75v4.25a.75.75 0 0 1-1.5 0v-4.25a.75.75 0 0 1 .75-.75Z">
                                                                            </path>
                                                                            <path
                                                                                d="M9.25 9a.75.75 0 0 0-1.5 0v4.25a.75.75 0 0 0 1.5 0v-4.25Z">
                                                                            </path>
                                                                            <path fill-rule="evenodd"
                                                                                d="M7.25 5.25a2.75 2.75 0 0 1 5.5 0h3a.75.75 0 0 1 0 1.5h-.75v5.45c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311c-.642.327-1.482.327-3.162.327h-.4c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311c-.327-.642-.327-1.482-.327-3.162v-5.45h-.75a.75.75 0 0 1 0-1.5h3Zm1.5 0a1.25 1.25 0 1 1 2.5 0h-2.5Zm-2.25 1.5h7v5.45c0 .865-.001 1.423-.036 1.848-.033.408-.09.559-.128.633a1.5 1.5 0 0 1-.655.655c-.074.038-.225.095-.633.128-.425.035-.983.036-1.848.036h-.4c-.865 0-1.423-.001-1.848-.036-.408-.033-.559-.09-.633-.128a1.5 1.5 0 0 1-.656-.655c-.037-.074-.094-.225-.127-.633-.035-.425-.036-.983-.036-1.848v-5.45Z">
                                                                            </path>
                                                                        </svg></span></span></button></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="_SortableWrapper_1twcm_1">
                                                    <div class="_ExpandedSectionGrid_1kz97_35"><button type="button"
                                                            class="_DragHandle_7bsl5_8 _ValueDragHandle_1kz97_126"
                                                            role="button" tabindex="0" aria-disabled="false"
                                                            aria-roledescription="sortable"
                                                            aria-describedby="DndDescribedBy-3"><span><span
                                                                    class="Polaris-Icon"><svg viewBox="0 0 20 20"
                                                                        class="Polaris-Icon__Svg" focusable="false"
                                                                        aria-hidden="true">
                                                                        <path
                                                                            d="M7.5 4.5c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M7.5 8.75c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M6.5 14c0-.552.448-1 1-1h.5c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1v-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 4.5c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M11 9.75c0-.552.448-1 1-1h.5c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1v-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 13c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                    </svg></span></span></button>
                                                        <div class="_OptionValue_1kz97_131">
                                                            <div>
                                                                <div class="Polaris-Labelled--hidden">
                                                                    <div class="Polaris-Labelled__LabelWrapper">
                                                                        <div class="Polaris-Label"><label
                                                                                id=":rc0:Label" for=":rc0:"
                                                                                class="Polaris-Label__Text">Option
                                                                                value</label></div>
                                                                    </div>
                                                                    <div class="Polaris-Connected">
                                                                        <div
                                                                            class="Polaris-Connected__Item Polaris-Connected__Item--primary">
                                                                            <div
                                                                                class="Polaris-TextField Polaris-TextField--hasValue">
                                                                                <input name="optionValue[0][5]"
                                                                                    id=":rc0:" placeholder=""
                                                                                    autocomplete="off"
                                                                                    class="Polaris-TextField__Input"
                                                                                    type="text"
                                                                                    aria-labelledby=":rc0:Label"
                                                                                    aria-invalid="false"
                                                                                    data-1p-ignore="true"
                                                                                    data-lpignore="true"
                                                                                    data-form-type="other"
                                                                                    value="White - M">
                                                                                <div
                                                                                    class="Polaris-TextField__Backdrop">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="_DeleteButton_1kz97_142"><button
                                                                class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                                                aria-label="Delete option" type="button"><span
                                                                    class="Polaris-Button__Icon"><span
                                                                        class="Polaris-Icon"><svg viewBox="0 0 20 20"
                                                                            class="Polaris-Icon__Svg" focusable="false"
                                                                            aria-hidden="true">
                                                                            <path
                                                                                d="M11.5 8.25a.75.75 0 0 1 .75.75v4.25a.75.75 0 0 1-1.5 0v-4.25a.75.75 0 0 1 .75-.75Z">
                                                                            </path>
                                                                            <path
                                                                                d="M9.25 9a.75.75 0 0 0-1.5 0v4.25a.75.75 0 0 0 1.5 0v-4.25Z">
                                                                            </path>
                                                                            <path fill-rule="evenodd"
                                                                                d="M7.25 5.25a2.75 2.75 0 0 1 5.5 0h3a.75.75 0 0 1 0 1.5h-.75v5.45c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311c-.642.327-1.482.327-3.162.327h-.4c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311c-.327-.642-.327-1.482-.327-3.162v-5.45h-.75a.75.75 0 0 1 0-1.5h3Zm1.5 0a1.25 1.25 0 1 1 2.5 0h-2.5Zm-2.25 1.5h7v5.45c0 .865-.001 1.423-.036 1.848-.033.408-.09.559-.128.633a1.5 1.5 0 0 1-.655.655c-.074.038-.225.095-.633.128-.425.035-.983.036-1.848.036h-.4c-.865 0-1.423-.001-1.848-.036-.408-.033-.559-.09-.633-.128a1.5 1.5 0 0 1-.656-.655c-.037-.074-.094-.225-.127-.633-.035-.425-.036-.983-.036-1.848v-5.45Z">
                                                                            </path>
                                                                        </svg></span></span></button></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="_SortableWrapper_1twcm_1">
                                                    <div class="_ExpandedSectionGrid_1kz97_35"><button type="button"
                                                            class="_DragHandle_7bsl5_8 _ValueDragHandle_1kz97_126"
                                                            role="button" tabindex="0" aria-disabled="false"
                                                            aria-roledescription="sortable"
                                                            aria-describedby="DndDescribedBy-3"><span><span
                                                                    class="Polaris-Icon"><svg viewBox="0 0 20 20"
                                                                        class="Polaris-Icon__Svg" focusable="false"
                                                                        aria-hidden="true">
                                                                        <path
                                                                            d="M7.5 4.5c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M7.5 8.75c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M6.5 14c0-.552.448-1 1-1h.5c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1v-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 4.5c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M11 9.75c0-.552.448-1 1-1h.5c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1v-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 13c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                    </svg></span></span></button>
                                                        <div class="_OptionValue_1kz97_131">
                                                            <div>
                                                                <div class="Polaris-Labelled--hidden">
                                                                    <div class="Polaris-Labelled__LabelWrapper">
                                                                        <div class="Polaris-Label"><label
                                                                                id=":rc1:Label" for=":rc1:"
                                                                                class="Polaris-Label__Text">Option
                                                                                value</label></div>
                                                                    </div>
                                                                    <div class="Polaris-Connected">
                                                                        <div
                                                                            class="Polaris-Connected__Item Polaris-Connected__Item--primary">
                                                                            <div
                                                                                class="Polaris-TextField Polaris-TextField--hasValue">
                                                                                <input name="optionValue[0][6]"
                                                                                    id=":rc1:" placeholder=""
                                                                                    autocomplete="off"
                                                                                    class="Polaris-TextField__Input"
                                                                                    type="text"
                                                                                    aria-labelledby=":rc1:Label"
                                                                                    aria-invalid="false"
                                                                                    data-1p-ignore="true"
                                                                                    data-lpignore="true"
                                                                                    data-form-type="other"
                                                                                    value="White - L">
                                                                                <div
                                                                                    class="Polaris-TextField__Backdrop">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="_DeleteButton_1kz97_142"><button
                                                                class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                                                aria-label="Delete option" type="button"><span
                                                                    class="Polaris-Button__Icon"><span
                                                                        class="Polaris-Icon"><svg viewBox="0 0 20 20"
                                                                            class="Polaris-Icon__Svg" focusable="false"
                                                                            aria-hidden="true">
                                                                            <path
                                                                                d="M11.5 8.25a.75.75 0 0 1 .75.75v4.25a.75.75 0 0 1-1.5 0v-4.25a.75.75 0 0 1 .75-.75Z">
                                                                            </path>
                                                                            <path
                                                                                d="M9.25 9a.75.75 0 0 0-1.5 0v4.25a.75.75 0 0 0 1.5 0v-4.25Z">
                                                                            </path>
                                                                            <path fill-rule="evenodd"
                                                                                d="M7.25 5.25a2.75 2.75 0 0 1 5.5 0h3a.75.75 0 0 1 0 1.5h-.75v5.45c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311c-.642.327-1.482.327-3.162.327h-.4c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311c-.327-.642-.327-1.482-.327-3.162v-5.45h-.75a.75.75 0 0 1 0-1.5h3Zm1.5 0a1.25 1.25 0 1 1 2.5 0h-2.5Zm-2.25 1.5h7v5.45c0 .865-.001 1.423-.036 1.848-.033.408-.09.559-.128.633a1.5 1.5 0 0 1-.655.655c-.074.038-.225.095-.633.128-.425.035-.983.036-1.848.036h-.4c-.865 0-1.423-.001-1.848-.036-.408-.033-.559-.09-.633-.128a1.5 1.5 0 0 1-.656-.655c-.037-.074-.094-.225-.127-.633-.035-.425-.036-.983-.036-1.848v-5.45Z">
                                                                            </path>
                                                                        </svg></span></span></button></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="_SortableWrapper_1twcm_1">
                                                    <div class="_ExpandedSectionGrid_1kz97_35"><button type="button"
                                                            class="_DragHandle_7bsl5_8 _ValueDragHandle_1kz97_126"
                                                            role="button" tabindex="0" aria-disabled="false"
                                                            aria-roledescription="sortable"
                                                            aria-describedby="DndDescribedBy-3"><span><span
                                                                    class="Polaris-Icon"><svg viewBox="0 0 20 20"
                                                                        class="Polaris-Icon__Svg" focusable="false"
                                                                        aria-hidden="true">
                                                                        <path
                                                                            d="M7.5 4.5c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M7.5 8.75c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M6.5 14c0-.552.448-1 1-1h.5c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1v-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 4.5c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M11 9.75c0-.552.448-1 1-1h.5c.552 0 1 .448 1 1v.5c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1v-.5Z">
                                                                        </path>
                                                                        <path
                                                                            d="M12 13c-.552 0-1 .448-1 1v.5c0 .552.448 1 1 1h.5c.552 0 1-.448 1-1v-.5c0-.552-.448-1-1-1h-.5Z">
                                                                        </path>
                                                                    </svg></span></span></button>
                                                        <div class="_OptionValue_1kz97_131">
                                                            <div>
                                                                <div class="Polaris-Labelled--hidden">
                                                                    <div class="Polaris-Labelled__LabelWrapper">
                                                                        <div class="Polaris-Label"><label
                                                                                id=":rc2:Label" for=":rc2:"
                                                                                class="Polaris-Label__Text">Option
                                                                                value</label></div>
                                                                    </div>
                                                                    <div class="Polaris-Connected">
                                                                        <div
                                                                            class="Polaris-Connected__Item Polaris-Connected__Item--primary">
                                                                            <div
                                                                                class="Polaris-TextField Polaris-TextField--hasValue">
                                                                                <input name="optionValue[0][7]"
                                                                                    id=":rc2:" placeholder=""
                                                                                    autocomplete="off"
                                                                                    class="Polaris-TextField__Input"
                                                                                    type="text"
                                                                                    aria-labelledby=":rc2:Label"
                                                                                    aria-invalid="false"
                                                                                    data-1p-ignore="true"
                                                                                    data-lpignore="true"
                                                                                    data-form-type="other"
                                                                                    value="White - XL">
                                                                                <div
                                                                                    class="Polaris-TextField__Backdrop">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="_DeleteButton_1kz97_142"><button
                                                                class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantPlain Polaris-Button--sizeMedium Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                                                aria-label="Delete option" type="button"><span
                                                                    class="Polaris-Button__Icon"><span
                                                                        class="Polaris-Icon"><svg viewBox="0 0 20 20"
                                                                            class="Polaris-Icon__Svg" focusable="false"
                                                                            aria-hidden="true">
                                                                            <path
                                                                                d="M11.5 8.25a.75.75 0 0 1 .75.75v4.25a.75.75 0 0 1-1.5 0v-4.25a.75.75 0 0 1 .75-.75Z">
                                                                            </path>
                                                                            <path
                                                                                d="M9.25 9a.75.75 0 0 0-1.5 0v4.25a.75.75 0 0 0 1.5 0v-4.25Z">
                                                                            </path>
                                                                            <path fill-rule="evenodd"
                                                                                d="M7.25 5.25a2.75 2.75 0 0 1 5.5 0h3a.75.75 0 0 1 0 1.5h-.75v5.45c0 1.68 0 2.52-.327 3.162a3 3 0 0 1-1.311 1.311c-.642.327-1.482.327-3.162.327h-.4c-1.68 0-2.52 0-3.162-.327a3 3 0 0 1-1.311-1.311c-.327-.642-.327-1.482-.327-3.162v-5.45h-.75a.75.75 0 0 1 0-1.5h3Zm1.5 0a1.25 1.25 0 1 1 2.5 0h-2.5Zm-2.25 1.5h7v5.45c0 .865-.001 1.423-.036 1.848-.033.408-.09.559-.128.633a1.5 1.5 0 0 1-.655.655c-.074.038-.225.095-.633.128-.425.035-.983.036-1.848.036h-.4c-.865 0-1.423-.001-1.848-.036-.408-.033-.559-.09-.633-.128a1.5 1.5 0 0 1-.656-.655c-.037-.074-.094-.225-.127-.633-.035-.425-.036-.983-.036-1.848v-5.45Z">
                                                                            </path>
                                                                        </svg></span></span></button></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="_SortableWrapper_1twcm_1">
                                                    <div class="_ExpandedSectionGrid_1kz97_35">
                                                        <div class="_OptionValue_1kz97_131">
                                                            <div>
                                                                <div class="Polaris-Labelled--hidden">
                                                                    <div class="Polaris-Labelled__LabelWrapper">
                                                                        <div class="Polaris-Label"><label
                                                                                id=":rgu:Label" for=":rgu:"
                                                                                class="Polaris-Label__Text">Option
                                                                                value</label></div>
                                                                    </div>
                                                                    <div class="Polaris-Connected">
                                                                        <div
                                                                            class="Polaris-Connected__Item Polaris-Connected__Item--primary">
                                                                            <div class="Polaris-TextField"><input
                                                                                    name="optionValue[0][8]" id=":rgu:"
                                                                                    placeholder="Add another value"
                                                                                    autocomplete="off"
                                                                                    class="Polaris-TextField__Input"
                                                                                    type="text"
                                                                                    aria-labelledby=":rgu:Label"
                                                                                    aria-invalid="false"
                                                                                    data-1p-ignore="true"
                                                                                    data-lpignore="true"
                                                                                    data-form-type="other" value="">
                                                                                <div
                                                                                    class="Polaris-TextField__Backdrop">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="_FooterGrid_1kz97_37">
                                                <div class="_DoneButton_1kz97_137"><button
                                                        class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeMedium Polaris-Button--textAlignCenter"
                                                        type="button"
                                                        aria-controls="option-gid://shopify/ProductOption/11393424785754"
                                                        aria-expanded="false"><span class="">Done</span></button></div>
                                            </div>
                                            <div id="DndDescribedBy-3" style="display: none;">
                                                To pick up a draggable item, press the space bar.
                                                While dragging, use the arrow keys to move the item.
                                                Press space again to drop the item in its new position, or press escape
                                                to cancel.
                                            </div>
                                            <div id="DndLiveRegion-2" role="status" aria-live="assertive"
                                                aria-atomic="true"
                                                style="position: fixed; width: 1px; height: 1px; margin: -1px; border: 0px; padding: 0px; overflow: hidden; clip: rect(0px, 0px, 0px, 0px); clip-path: inset(100%); white-space: nowrap;">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="DndDescribedBy-2" style="display: none;">
                            To pick up a draggable item, press the space bar.
                            While dragging, use the arrow keys to move the item.
                            Press space again to drop the item in its new position, or press escape to cancel.
                        </div>
                        <div id="DndLiveRegion-3" role="status" aria-live="assertive" aria-atomic="true"
                            style="position: fixed; width: 1px; height: 1px; margin: -1px; border: 0px; padding: 0px; overflow: hidden; clip: rect(0px, 0px, 0px, 0px); clip-path: inset(100%); white-space: nowrap;">
                        </div>
                        <div class="Polaris-IndexTable">
                            <div class="Polaris-IndexTable__IndexTableWrapper">
                                <div class="Polaris-IndexTable__StickyTable" role="presentation">
                                    <div>
                                        <div></div>
                                        <div>
                                            <div class="Polaris-IndexTable__StickyTableHeader">
                                                <div class="Polaris-IndexTable__StickyTableColumnHeader">
                                                    <div class="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading--first"
                                                        data-index-table-sticky-heading="true"
                                                        style="min-width: 304px;">
                                                        <div
                                                            class="Polaris-LegacyStack Polaris-LegacyStack--spacingNone Polaris-LegacyStack--alignmentCenter Polaris-LegacyStack--noWrap">
                                                            <div class="Polaris-LegacyStack__Item">
                                                                <div
                                                                    class="Polaris-IndexTable__FirstStickyHeaderElement">
                                                                    <div
                                                                        class="Polaris-IndexTable__ColumnHeaderCheckboxWrapper">
                                                                        <label
                                                                            class="Polaris-Choice Polaris-Choice--labelHidden Polaris-Checkbox__ChoiceLabel"
                                                                            for=":rgi:"><span
                                                                                class="Polaris-Choice__Control"><span
                                                                                    class="Polaris-Checkbox"><input
                                                                                        id=":rgi:" type="checkbox"
                                                                                        class="Polaris-Checkbox__Input"
                                                                                        aria-invalid="false"
                                                                                        role="checkbox"
                                                                                        aria-checked="false"
                                                                                        value=""><span
                                                                                        class="Polaris-Checkbox__Backdrop"></span><span
                                                                                        class="Polaris-Checkbox__Icon Polaris-Checkbox--animated"><svg
                                                                                            viewBox="0 0 16 16"
                                                                                            shape-rendering="geometricPrecision"
                                                                                            text-rendering="geometricPrecision">
                                                                                            <path class=""
                                                                                                d="M1.5,5.5L3.44655,8.22517C3.72862,8.62007,4.30578,8.64717,4.62362,8.28044L10.5,1.5"
                                                                                                transform="translate(2 2.980376)"
                                                                                                opacity="0" fill="none"
                                                                                                stroke="currentColor"
                                                                                                stroke-width="2"
                                                                                                stroke-linecap="round"
                                                                                                stroke-linejoin="round"
                                                                                                pathLength="1"></path>
                                                                                        </svg></span></span></span><span
                                                                                class="Polaris-Choice__Label"><span>Select
                                                                                    all variants</span></span></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="Polaris-LegacyStack__Item">
                                                                <div
                                                                    class="Polaris-IndexTable--stickyTableHeadingSecondScrolling">
                                                                    <div class=""
                                                                        style="--pc-index-table-heading-extra-padding-right: 0;">
                                                                        Variant</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="Polaris-IndexTable__StickyTableHeadings">
                                                    <div class="Polaris-IndexTable__TableHeading Polaris-IndexTable__StickyTableHeading--second"
                                                        data-index-table-sticky-heading="true"
                                                        style="min-width: 266px;">
                                                        <div class=""
                                                            style="--pc-index-table-heading-extra-padding-right: 0;">
                                                            Variant</div>
                                                    </div>
                                                    <div class="Polaris-IndexTable__TableHeading"
                                                        data-index-table-sticky-heading="true"
                                                        style="min-width: 154px;">
                                                        <div class=""
                                                            style="--pc-index-table-heading-extra-padding-right: 0;">
                                                            Price</div>
                                                    </div>
                                                    <div class="Polaris-IndexTable__TableHeading"
                                                        data-index-table-sticky-heading="true" style="min-width: 97px;">
                                                        <div class=""
                                                            style="--pc-index-table-heading-extra-padding-right: 0;">
                                                            <span class="" tabindex="0" aria-describedby=":rgj:"
                                                                data-polaris-tooltip-activator="true"><span
                                                                    class="Polaris-IndexTable__TableHeadingUnderline Polaris-IndexTable__SortableTableHeaderWrapper">Available</span></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="Polaris-IndexTable__BulkActionsWrapper">
                                                <div>
                                                    <div
                                                        class="Polaris-BulkActions__Group Polaris-BulkActions--groupNotSticky Polaris-BulkActions__Group--exited">
                                                        <div class="Polaris-BulkActions__ButtonGroupWrapper">
                                                            <div>
                                                                <div class="Polaris-InlineStack"
                                                                    style="--pc-inline-stack-block-align: center; --pc-inline-stack-wrap: wrap; --pc-inline-stack-gap-xs: var(--p-space-300); --pc-inline-stack-flex-direction-xs: row;">
                                                                    <div class="Polaris-CheckableButton">
                                                                        <div class="Polaris-CheckableButton__Checkbox">
                                                                            <label
                                                                                class="Polaris-Choice Polaris-Choice--labelHidden Polaris-Checkbox__ChoiceLabel"
                                                                                for=":rgk:"><span
                                                                                    class="Polaris-Choice__Control"><span
                                                                                        class="Polaris-Checkbox"><input
                                                                                            id=":rgk:" type="checkbox"
                                                                                            class="Polaris-Checkbox__Input"
                                                                                            aria-invalid="false"
                                                                                            role="checkbox"
                                                                                            aria-checked="false"
                                                                                            value=""><span
                                                                                            class="Polaris-Checkbox__Backdrop"></span><span
                                                                                            class="Polaris-Checkbox__Icon Polaris-Checkbox--animated"><svg
                                                                                                viewBox="0 0 16 16"
                                                                                                shape-rendering="geometricPrecision"
                                                                                                text-rendering="geometricPrecision">
                                                                                                <path class=""
                                                                                                    d="M1.5,5.5L3.44655,8.22517C3.72862,8.62007,4.30578,8.64717,4.62362,8.28044L10.5,1.5"
                                                                                                    transform="translate(2 2.980376)"
                                                                                                    opacity="0"
                                                                                                    fill="none"
                                                                                                    stroke="currentColor"
                                                                                                    stroke-width="2"
                                                                                                    stroke-linecap="round"
                                                                                                    stroke-linejoin="round"
                                                                                                    pathLength="1">
                                                                                                </path>
                                                                                            </svg></span></span></span><span
                                                                                    class="Polaris-Choice__Label"><span>Select
                                                                                        all 8
                                                                                        variants</span></span></label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="Polaris-InlineStack"
                                                                        style="--pc-inline-stack-block-align: center; --pc-inline-stack-wrap: wrap; --pc-inline-stack-gap-xs: var(--p-space-100); --pc-inline-stack-flex-direction-xs: row;">
                                                                        <div
                                                                            class="Polaris-BulkActions__BulkActionButton">
                                                                            <button
                                                                                class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeMicro Polaris-Button--textAlignCenter"
                                                                                type="button"><span class="">Bulk
                                                                                    edit</span></button></div>
                                                                        <div>
                                                                            <div>
                                                                                <div
                                                                                    class="Polaris-BulkActions__BulkActionButton">
                                                                                    <span class=""><button
                                                                                            class="Polaris-Button Polaris-Button--pressable Polaris-Button--variantSecondary Polaris-Button--sizeMicro Polaris-Button--textAlignCenter Polaris-Button--iconOnly"
                                                                                            aria-label="More actions"
                                                                                            type="button" tabindex="0"
                                                                                            aria-describedby=":rgm:"
                                                                                            data-polaris-tooltip-activator="true"
                                                                                            aria-controls=":rgl:"
                                                                                            aria-owns=":rgl:"
                                                                                            aria-expanded="false"
                                                                                            data-state="closed"><span
                                                                                                class="Polaris-Button__Icon"><span
                                                                                                    class="Polaris-Icon Polaris-Icon--toneBase"><svg
                                                                                                        viewBox="0 0 20 20"
                                                                                                        class="Polaris-Icon__Svg"
                                                                                                        focusable="false"
                                                                                                        aria-hidden="true">
                                                                                                        <path
                                                                                                            d="M6 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z">
                                                                                                        </path>
                                                                                                        <path
                                                                                                            d="M11.5 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z">
                                                                                                        </path>
                                                                                                        <path
                                                                                                            d="M17 10a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z">
                                                                                                        </path>
                                                                                                    </svg></span></span></button></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="Polaris-IndexTable__SelectAllActionsWrapper Polaris-IndexTable__SelectAllActionsWrapperAtEnd"
                                        style="width: 555.336px; inset-block-start: 616px;">
                                        <div
                                            class="Polaris-SelectAllActions Polaris-SelectAllActions--selectAllActionsNotSticky Polaris-SelectAllActions__SelectAllActions--exited">
                                            <div class="Polaris-Box"
                                                style="--pc-box-background: var(--p-color-bg-surface-secondary); --pc-box-border-color: var(--p-color-border); --pc-box-border-style: solid; --pc-box-border-block-start-width: var(--p-border-width-025); --pc-box-padding-block-start-xs: var(--p-space-300); --pc-box-padding-block-end-xs: var(--p-space-300); --pc-box-padding-inline-start-xs: var(--p-space-300); --pc-box-padding-inline-end-xs: var(--p-space-400); --pc-box-width: 100%;">
                                                <div class="Polaris-InlineStack"
                                                    style="--pc-inline-stack-align: start; --pc-inline-stack-block-align: center; --pc-inline-stack-wrap: wrap; --pc-inline-stack-gap-xs: var(--p-space-200); --pc-inline-stack-flex-direction-xs: row;">
                                                    <span
                                                        class="Polaris-Text--root Polaris-Text--bodySm Polaris-Text--medium">0
                                                        selected</span></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="Polaris-IndexTable-ScrollContainer">
                                    <table class="Polaris-IndexTable__Table Polaris-IndexTable__Table--sticky">
                                        <thead>
                                            <tr>
                                                <th class="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading--second"
                                                    data-index-table-heading="true">
                                                    <div class=""
                                                        style="--pc-index-table-heading-extra-padding-right: 0;">Variant
                                                    </div>
                                                </th>
                                                <th class="Polaris-IndexTable__TableHeading"
                                                    data-index-table-heading="true">
                                                    <div class=""
                                                        style="--pc-index-table-heading-extra-padding-right: 0;">Price
                                                    </div>
                                                </th>
                                                <th class="Polaris-IndexTable__TableHeading Polaris-IndexTable__TableHeading--last"
                                                    data-index-table-heading="true">
                                                    <div class=""
                                                        style="--pc-index-table-heading-extra-padding-right: 0;"><span
                                                            class="" tabindex="0" aria-describedby=":rc6:"
                                                            data-polaris-tooltip-activator="true"><span
                                                                class="Polaris-IndexTable__TableHeadingUnderline Polaris-IndexTable__SortableTableHeaderWrapper">Available</span></span>
                                                    </div>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                          {{.variantRows}}
                                        </tbody>
                                    </table>
                                </div>
                                <div class="Polaris-IndexTable__ScrollBarContainer Polaris-IndexTable__ScrollBarContainerWithSelectAllActions Polaris-IndexTable--scrollBarContainerHidden Polaris-IndexTable__ScrollBarContainerScrolledPastTop"
                                    style="--pc-index-table-scroll-bar-top-offset: 32px;">
                                    <div class="Polaris-IndexTable__ScrollBar"
                                        style="--pc-index-table-scroll-bar-content-width: 551px;">
                                        <div class="Polaris-IndexTable__ScrollBarContent"></div>
                                    </div>
                                </div>
                            </div>
                            <div></div>
                        </div>
                        <form method="post"><span class="Polaris-Text--root Polaris-Text--visuallyHidden"><button
                                    type="submit" aria-hidden="true" tabindex="-1">Submit</button></span></form>
                        <form method="post"><span class="Polaris-Text--root Polaris-Text--visuallyHidden"><button
                                    type="submit" aria-hidden="true" tabindex="-1">Submit</button></span></form>
                        <form method="post"><span class="Polaris-Text--root Polaris-Text--visuallyHidden"><button
                                    type="submit" aria-hidden="true" tabindex="-1">Submit</button></span></form>
                        <form method="post"><span class="Polaris-Text--root Polaris-Text--visuallyHidden"><button
                                    type="submit" aria-hidden="true" tabindex="-1">Submit</button></span></form>
                        <form method="post"><span class="Polaris-Text--root Polaris-Text--visuallyHidden"><button
                                    type="submit" aria-hidden="true" tabindex="-1">Submit</button></span></form>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
</html>`

export const getVariantNamesHTML = (names) => {
  return names.map(i => `<span class="Polaris-Badge"><span class="Polaris-Text--root Polaris-Text--bodySm">${i}</span></span>`).join("");
}

export const getVariantRowHTML = (variants: any, resalePrice: any) => {
  return variants.map(variant => `<tr id="gid://shopify/ProductVariant/47670543581530"
  class="Polaris-IndexTable__TableRow">
  <td class="_VariantCell_1ajgl_1 Polaris-IndexTable__TableCell">
      <div class="Polaris-InlineStack"
          style="--pc-inline-stack-block-align: center; --pc-inline-stack-wrap: nowrap; --pc-inline-stack-gap-xs: var(--p-space-400); --pc-inline-stack-flex-direction-xs: row;">
          <span><button class="_UnstyledButton_14h5u_4"
                  aria-label="Select image for ${variant.variantName}"
                  type="button" aria-describedby="image-not-applied-:rc9:"
                  aria-haspopup="dialog">
                  <div class="_Icon_12h4v_1 _mediumIcon_12h4v_24"><span
                          class="Polaris-Text--root Polaris-Text--visuallyHidden"
                          id="image-not-applied-:rc9:">Image is not
                          applied</span><span
                          class="Polaris-Icon Polaris-Icon--toneInteractive"><svg
                              viewBox="0 0 20 20"
                              class="Polaris-Icon__Svg" focusable="false"
                              aria-hidden="true">
                              <path
                                  d="M9.018 3.5h1.964c.813 0 1.469 0 2 .043.546.045 1.026.14 1.47.366a3.75 3.75 0 0 1 1.64 1.639c.226.444.32.924.365 1.47.043.531.043 1.187.043 2v.232a.75.75 0 0 1-1.5 0v-.2c0-.852 0-1.447-.038-1.91-.037-.453-.107-.714-.207-.911a2.25 2.25 0 0 0-.984-.984c-.197-.1-.458-.17-.912-.207-.462-.037-1.056-.038-1.909-.038h-1.9c-.852 0-1.447 0-1.91.038-.453.037-.714.107-.911.207a2.25 2.25 0 0 0-.984.984c-.1.197-.17.458-.207.912-.037.462-.038 1.057-.038 1.909v1.39l1.013-1.013a1.75 1.75 0 0 1 2.474 0l2.763 2.762 1.47-1.47a.75.75 0 1 1 1.06 1.061l-2 2a.75.75 0 0 1-1.06 0l-3.293-3.293a.25.25 0 0 0-.354 0l-2.054 2.055c.005.113.011.218.02.317.036.454.106.715.206.912.216.424.56.768.984.984.197.1.458.17.912.207.462.037 1.057.038 1.909.038h1.2a.75.75 0 0 1 0 1.5h-1.232c-.813 0-1.469 0-2-.043-.546-.045-1.026-.14-1.47-.366a3.75 3.75 0 0 1-1.64-1.639c-.226-.444-.32-.924-.365-1.47a11.671 11.671 0 0 1-.03-.597.754.754 0 0 1-.006-.234c-.007-.348-.007-.736-.007-1.169v-1.964c0-.813 0-1.469.043-2 .045-.546.14-1.026.366-1.47a3.75 3.75 0 0 1 1.639-1.64c.444-.226.924-.32 1.47-.365.531-.043 1.187-.043 2-.043Z">
                              </path>
                              <path
                                  d="M12.5 9a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z">
                              </path>
                              <path
                                  d="M15.75 11.25a.75.75 0 0 1 .75.75v1.75h1.75a.75.75 0 0 1 0 1.5h-1.75v1.75a.75.75 0 0 1-1.5 0v-1.75h-1.75a.75.75 0 0 1 0-1.5h1.75v-1.75a.75.75 0 0 1 .75-.75Z">
                              </path>
                          </svg></span></div>
              </button></span>
          <div class="Polaris-BlockStack"
              style="--pc-block-stack-order: column; --pc-block-stack-gap-xs: var(--p-space-050);">
              <a data-polaris-unstyled="true" class="Polaris-Link Polaris-Link--monochrome Polaris-Link--removeUnderline">
              ${variant.variantName}
              </a>
              <span class="_Sku_qbdla_1">${variant.PPGSku || variant.sku}</span>
          </div>
      </div>
  </td>
  <td class="_PriceCell_1ajgl_9 Polaris-IndexTable__TableCell"><span
          class="Polaris-Text--root Polaris-Text--block Polaris-Text--end"><span>
              <div>
                  <div class="Polaris-Labelled--hidden">
                      <div class="Polaris-Labelled__LabelWrapper">
                          <div class="Polaris-Label"><label
                                  id=":rca:Label" for=":rca:"
                                  class="Polaris-Label__Text">Price</label>
                          </div>
                      </div>
                      <div class="Polaris-Connected">
                          <div
                              class="Polaris-Connected__Item Polaris-Connected__Item--primary">
                              <div
                                  class="Polaris-TextField Polaris-TextField--hasValue">
                                  <div class="Polaris-TextField__Prefix"
                                      id=":rca:-Prefix">£</div><input
                                      id=":rca:" placeholder=""
                                      autocomplete="off"
                                      class="Polaris-TextField__Input"
                                      maxlength="24" type="text"
                                      aria-labelledby=":rca:Label :rca:-Prefix"
                                      aria-invalid="false"
                                      data-1p-ignore="true"
                                      data-lpignore="true"
                                      data-form-type="other" value="${variant.price || resalePrice || "0.00"}">
                                  <div
                                      class="Polaris-TextField__Backdrop">
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </span></span></td>
  <td class="_InventoryCell_1ajgl_14 Polaris-IndexTable__TableCell"><span
          class="Polaris-Text--root Polaris-Text--block Polaris-Text--end">
          <div class="_Container_ldy9v_1"><span class="" tabindex="0"
                  aria-describedby=":rcb:"
                  data-polaris-tooltip-activator="true">
                  <div
                      class="Polaris-Labelled--hidden Polaris-Labelled--disabled">
                      <div class="Polaris-Labelled__LabelWrapper">
                          <div class="Polaris-Label"><label
                                  id=":rcc:Label" for=":rcc:"
                                  class="Polaris-Label__Text">Quantity</label>
                          </div>
                      </div>
                      <div class="Polaris-Connected">
                          <div
                              class="Polaris-Connected__Item Polaris-Connected__Item--primary">
                              <div
                                  class="Polaris-TextField Polaris-TextField--hasValue Polaris-TextField--disabled">
                                  <input id=":rcc:" disabled=""
                                      autocomplete="off"
                                      class="Polaris-TextField__Input Polaris-TextField__Input--suffixed"
                                      type="text"
                                      aria-labelledby=":rcc:Label :rcc:-Suffix"
                                      aria-invalid="false"
                                      data-1p-ignore="true"
                                      data-lpignore="true"
                                      data-form-type="other" value="-">
                                  <div class="Polaris-TextField__Suffix"
                                      id=":rcc:-Suffix"><span
                                          class="Polaris-Text--root Polaris-Text--visuallyHidden">Inventory
                                          is not tracked</span></div>
                                  <div
                                      class="Polaris-TextField__Backdrop">
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </span></div>
      </span></td>
</tr>`).join("");
}
