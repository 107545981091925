import create from 'zustand';
import Store from 'store';
import { TCard } from 'type/TStripe';
import apiClient from './api-client';

interface IPaymentStore {
  loading: boolean,
  card: any,
  balance: any,
  stripeClientSecret: string,
  stripePublishKey: string,
  removingCard: boolean,
  getStripeConfig: (createdIfNotExisted?: boolean) => void,
  refreshBalance: () => void,
  getExistedCard: () => Promise<TCard>,
  requestAddBalance: (amount: string | number, currency: string) => Promise<any>,
  removeCard: () => Promise<any>,
  getBalanceTransactions: (startingAfter?: string) => Promise<any>,
}

export const usePaymentStore = create<IPaymentStore>((set, get) => ({
  loading: true,
  card: null,
  balance: null,
  removingCard: false,
  stripeClientSecret: '',
  stripePublishKey: 'pk_test_51Ns4mXGUC3gL52aWKkOXyQHPvjk0tRn84VGL1uX0xbwL9zTHgExjDaTyYDNej74gujOqcoITzCNHCmSGZEOYwE9C00IbbUc5Le',
  getStripeConfig: async (createdIfNotExisted = true) => {
    try {
      const res = await Store.Api.Payment.publicKey()
      if (res?.data?.data) set({ stripePublishKey: res?.data?.data });

      const res2 = await Store.Api.Payment.createSetupIntent({ createdIfNotExisted });
      if (res2.data?.data?.client_secret) {
        set({ stripeClientSecret: res2.data.data.client_secret });
      }
    } catch (error) {
    } finally {
      set({ loading: false });
    }
  },
  refreshBalance: async () => {
    const res = await Store.Api.Payment.getBalance();
    if (res.data?.data) {
      set({ balance: res.data.data });
    }
  },
  getExistedCard: async () => {
    const res = await Store.Api.Payment.listCard();
    if (res?.data?.data?.data) {
      const card = res.data.data.data.find(i => i.type === "card");
      get().refreshBalance();
      set({ card });
      return card;
    }
  },
  requestAddBalance: async (amount, currency) => {

    const res = await Store.Api.Payment.createBalanceTransaction({
      amount: Number(amount),
      currency: !!currency ? currency.toLowerCase() : '',
    });
    if (!res.data.success) {
      alert(res.data.error);
      return;
    }
    get().refreshBalance();
    return res.data;
  },
  getBalanceTransactions: async (startingAfter) => {
    let obj: any = {};
    if (startingAfter) obj.starting_after = startingAfter;
    const res = await apiClient.Api.Payment.getBalanceTransactions(obj);
    if (res?.data?.data?.length) {
      return res?.data;
    }
    return {
      data: [],
      hasNext: false,
    };
  },
  removeCard: async () => {
    if (get().removingCard) return;
    set({ removingCard: true });
    try {
      const res = await Store.Api.Payment.removeCard();
      if (!res.data.success) {
        alert(res.data.error);
        return;
      }
      set({
        card: null,
        removingCard: false,
      });
      return res.data;
    } catch (error) {
      set({ removingCard: false });
    }
  },
}));
