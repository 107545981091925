import { Breadcrumb, notification } from 'antd';
import { Button, Col, Grid, ImageItem, Input, Row, Select, Text } from 'components';
import { SecuredLayout } from 'components/container';
import MainContentContainer from 'components/container/MainContentContainer';
import { COLOR, SCREEN } from 'const';
import React, { useEffect, useMemo, useRef } from 'react';
import { useNavFunc } from 'navigation';
import { useUIState } from 'hooks';
import { SVG } from 'assets';
import { ActivityIndicator } from 'react-native';
import { useProductStore } from 'store/Product.Store';
import { useUserStore } from 'store/User.Store';
import { useShopStore } from 'store/Shop.Store';
import { useDesignStore } from 'store/Design.Store';
import Store from 'store';
import { StyleSheet } from 'react-native';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PublishProductModal from './PublishProductModal';
import CreateResellerProductVariants from './CreateResellerProductVariants';
import DesignGlobalEditorButton from './DesignGlobalEditorButton';
import { ValHelper } from 'helpers';
import ProductImagesReseller from './ProductImagesReseller';

const CreateResellerProductScreen = () => {
  const { navigation, route } = useNavFunc();
  const publishModalRef = useRef(null);
  // @ts-ignore
  const { productId, designId } = route.params || {};
  const { useProduct } = useProductStore();
  const { tags } = useDesignStore();
  const { product } = useProduct(productId);

  const UserStore = useUserStore();
  const { useDesign } = useDesignStore();
  const { shops, getAll: getAllShop } = useShopStore();
  const [{ loading: submitting }, setSubmitUI] = useUIState();
  const { design, setDesign, uiState: uiStateDesign } = useDesign(designId, false, false);

  const getData = async () => {
    try {
      const u = await UserStore.onReady();
      const resellerId = UserStore.getResellerId(u);
      await getAllShop(resellerId);
    } catch (err) { }
  };

  const allShop = useMemo(() => {
    if (!shops) return [];
    const arr = [];
    Object.keys(shops).forEach(id => {
      arr.push({
        label: shops[id]?.name,
        value: id,
        data: shops[id],
      })
    })
    return arr;
  }, [shops]);

  useEffect(() => {
    getData();
  }, []);

  const onSubmit = async () => {
    setSubmitUI({ loading: true });
    try {
      const res = await Store.Api.Design.upsert({
        id: designId,
        name: design.name,
        image: design.image,
        resalePrice: +design.resalePrice,
        data: design.data,
        brands: design.brands,
        description: design.description,
        variants: design.variants,
        tags: design.tags || "",
      });
      if (res.data.success) {
        navigation.reset({
          index: 0,
          routes: [{ name: SCREEN.MyProductsScreen }],
        });
      }
      if (res.data.error) {
        notification.error({ message: res.data.error });
      }
    } catch (err) {
      notification.error({ message: err.message });
    }
    setSubmitUI({ loading: false });
  }

  const showPublishProductModal = async () => {
    setSubmitUI({ loading: true });
    try {
      const res = await Store.Api.Design.upsert({
        id: designId,
        name: design.name,
        image: design.image,
        resalePrice: +design.resalePrice,
        data: design.data,
        brands: design.brands,
        description: design.description,
        variants: design.variants,
        tags: design.tags || "",
      });
      if (res.data.error) {
        notification.error({ message: res.data.error });
      }
    } catch (err) {
      notification.error({ message: err.message });
    }
    setSubmitUI({ loading: false });
    publishModalRef.current?.show();
  }

  return (
    <SecuredLayout>
      <MainContentContainer>
        <Col pt4 ph4>
          <Breadcrumb
            items={[
              {
                title: <a href="/my-products">My Products</a>,
              },
              {
                title: 'Create Product',
              },
            ]}
          />
          <Row justifyContent="space-between" marginTop={16}>
            <Text h2 fontWeight={'300'}>{product?.name}</Text>
            <Row>
              <Button
                height={46}
                mr1
                title={design?.products?.length ? 'Unpublish' : 'Publish'}
                onPress={showPublishProductModal}
                paddingHorizontal={18}
              />
              <Button
                title={design?.id ? 'Save' : 'Create'}
                left={submitting
                  ? <ActivityIndicator color="white" />
                  : (product?.id ? <SVG.Pencil color={COLOR.WHITE} /> : <SVG.Plus color={COLOR.WHITE} />)
                }
                onPress={onSubmit}
                height={46}
                paddingHorizontal={18}
                disabled={submitting}
              />
            </Row>
          </Row>

          <Grid
            xs="100%"
            lg="50%"
            marginBottom={24}
            marginHorizontal={-32}
            alignItems="flex-start"
          >
            <Col bgWhite marginHorizontal={8} padding={24} marginTop={20}>
              <Col paddingTop={16}>
                <Text p1 color={COLOR.GRAY400}>Product name</Text>
                <Input
                  marginTop={16}
                  height={50}
                  value={design?.name || ''}
                  onChangeText={(v) => setDesign(p => ({ ...p, name: v }))}
                />
                <Text marginTop={24} p1 color={COLOR.GRAY400}>Description</Text>
                <Col marginTop={16}>
                  <ReactQuill
                    value={design?.description}
                    modules={{
                      clipboard: {
                        matchVisual: false,
                      },
                    }}
                    onChange={(v) => setDesign(p => ({ ...p, description: v }))}
                  />
                </Col>
                {/* <Input
                  marginTop={16}
                  isMulti
                  height={200}
                  value={design?.description}
                  onChangeText={(v) => setDesign(p => ({ ...p, description: v }))}
                /> */}
                {/* <Input
                  marginTop={16}
                  height={50}
                  value={String(product?.price || '')}
                  inputProps={{ editable: false }}
                /> */}
                <Text marginTop={24} p1 color={COLOR.GRAY400}>Resale Price</Text>
                <Input
                  marginTop={16}
                  height={50}
                  value={String(design?.resalePrice || '')}
                  onChangeText={(v) => setDesign(p => ({ ...p, resalePrice: v }))}
                  left={<Text>£</Text>}
                />
                <>
                  <Text marginTop={16} h6>Our Price: £{ValHelper.formatMoney(product?.price)}</Text>
                  <Text marginTop={8} h6>Profit: £{ValHelper.formatMoney(ValHelper.parseNumber(design?.resalePrice) - ValHelper.parseNumber(product?.price))}</Text>
                </>
                {/* <Text marginTop={24} p1 color={COLOR.GRAY400}>Store</Text>
                <Select
                  marginTop={16}
                  height={50}
                  isMultiple
                  onChange={(newValues) => {
                    setDesign(s => ({
                      ...s,
                      brands: newValues.map(id => ({ storeId: id, name: shops[id]?.name }))
                    }))
                  }}
                  value={design?.brands?.map(i => i.storeId)}
                  options={allShop}
                  placeholder="Select store"
                /> */}
                <Text marginTop={24} p1 color={COLOR.GRAY400}>Tags</Text>
                <Select
                  marginTop={16}
                  height={50}
                  value={design?.tags ? design?.tags?.trim().split(",") : []}
                  onChange={(a) => {
                    setDesign(p => ({
                      ...p,
                      tags: a?.map(i => String(i).trim()).filter(Boolean).join(","),
                    }))
                  }}
                  options={tags.map(i => ({ value: i, label: i }))}
                  selectProps={{
                    mode: "tags",
                  }}
                />
              </Col>
            </Col>
            <Col marginHorizontal={8} marginTop={20}>
              <Col minHeight={700} bgWhite paddingBottom={16}>
                <ProductImagesReseller design={design} setDesign={setDesign} />
                <DesignGlobalEditorButton design={design} setDesign={setDesign} product={product} />
              </Col>
              <Col bgWhite marginTop={20} padding={16}>
                <CreateResellerProductVariants
                  design={design}
                  setDesign={setDesign}
                  product={product}
                />
              </Col>
            </Col>
          </Grid>
        </Col>
        <PublishProductModal
          ref={publishModalRef}
          design={design}
          product={product}
          setDesign={setDesign}
          beforePublish={async () => {
            await Store.Api.Design.upsert({
              id: designId,
              name: design.name,
              image: design.image,
              resalePrice: +design.resalePrice,
              data: design.data,
              brands: design.brands,
              description: design.description,
            });
          }}
        />
      </MainContentContainer>
    </SecuredLayout >
  )
};

const styles = StyleSheet.create({
  mainImageContainer: {
    width: '40%',
    aspectRatio: 1,
    minWidth: 100,
    marginTop: 14,
  },
  mainImage: {
    width: '100%',
    height: '100%',
  }
})

CreateResellerProductScreen.routeInfo = {
  title: 'Create reseller product',
  path: '/products/create-design',
};

export default CreateResellerProductScreen;
