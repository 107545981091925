import { SVG } from "assets";
import { SCREEN } from "./screens";

export const SIDE_MENU_ITEMS = [
  { id: "dashboard", title: "Dashboard", icon: SVG.Dashboard, screen: SCREEN.DashboardScreen },
  {
    id: "orders", title: "Orders", icon: SVG.ListBox, screen: SCREEN.OrdersScreen, childrens: [
      SCREEN.OrderDetailScreen
    ]
  },
  {
    id: "product_library", title: "Products", icon: SVG.InboxMultiple, screen: SCREEN.ProductLibraryScreen, childrens: [
      SCREEN.CreateProductScreen
    ]
  },
  {
    id: "resellers", title: "Resellers", icon: SVG.AccountCircle, screen: SCREEN.ResellerScreen, childrens: [
      SCREEN.ResellerDetailScreen,
    ]
  },
]

export const SIDE_MENU_ITEMS_RESELLER = [
  { id: "dashboard_reseller", title: "Dashboard Reseller", icon: SVG.Dashboard, screen: SCREEN.DashboardScreenReseller },
  {
    id: "orders", title: "Orders", icon: SVG.ListBox, screen: SCREEN.OrdersScreen, childrens: [
      SCREEN.OrderDetailScreen
    ]
  },
  {
    id: "product_library", title: "Products", icon: SVG.InboxMultiple, screen: SCREEN.ProductLibraryScreen, childrens: [
      SCREEN.CreateProductScreen
    ]
  },
  {
    id: "my_products", title: "My Products", icon: SVG.InboxMultiple, screen: SCREEN.MyProductsScreen, childrens: [
      SCREEN.CreateResellerProductScreen
    ]
  },
  // { id: "services", title: "PPG Services", icon: SVG.Bell, screen: SCREEN.ServiceScreen },
  {
    id: "settings", title: "Settings", icon: SVG.Gear, screen: SCREEN.SettingsScreen, childrens: [
      SCREEN.ConnectStoreScreen,
    ]
  },
]
