import create from 'zustand';
import { TSticker } from 'type';
import Client from './api-client';

interface IStickerStore {
  stickers: {
    [stickerId: string]: TSticker,
  },
  getList(p: number, resellerId?: string): Promise<{ hasNext: boolean, list: Array<TSticker>, total: number, error?: string, }>,
  [otherKey: string]: any,
}

export const useStickerStore = create<IStickerStore>((set, get) => ({
  stickers: {},
  getList: async (page = 1) => {
    const res = await Client.Api.Sticker.list({ page});

    if (!res.data.success) {
      return {
        hasNext: false,
        list: [],
        total: 0,
        error: res.data.error,
      }
    }

    if (res.data.data.list && Array.isArray(res.data.data.list)) {
      const obj : IStickerStore['products'] = {}
      res.data.data.list.forEach((j : TSticker) => {
        obj[j.id] = j;
      });
      set({ products: { ...get().jobs, ...obj } });
    }
    return res.data.data;
  },
}));
