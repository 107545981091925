import { Breadcrumb, notification } from 'antd';
import { Button, Col, FieldItem, Row, Text } from 'components';
import { SecuredLayout } from 'components/container';
import MainContentContainer from 'components/container/MainContentContainer';
import { SCREEN } from 'const';
import { useNavFunc } from 'navigation';
import { useDynamicResponsiveValue } from 'quickly-react';
import React, { useState } from 'react';
import Store from 'store';
import { IScreen } from 'type';

const FIELDS: any = [
  { id: 'email', label: 'Email' },
  { id: 'password', label: 'Password' },
  [
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
  ],
  { id: 'address', label: 'Address' },
  [
    { id: 'town', label: 'Town' },
    { id: 'country', label: 'Country' },
  ],
  { id: 'postCode', label: 'Post Code' },
];

const CreateResellerScreen: IScreen = () => {
  const rV = useDynamicResponsiveValue(['xs', 'md', 'lg']);
  const size = rV({ xs: '100%', md: '80%', lg: '60%' });
  const { navigation, route } = useNavFunc();
  // @ts-ignore
  const { id, tab } = route.params || {};
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<any>({});

  const onSubmit = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      const res = await Store.Api.User.upsertReseller({
        id: id === 'new' ? undefined : id,
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        password: user?.password,
        addressLine1: user?.address || '',
        town: user?.town || '',
        country: user?.country || '',
        postCode: user?.postCode || '',
      });
      if (res.data.error) {
        notification.error({ message: res.data.error });
      } else if (res.data.data) {
        notification.success({ message: 'Reseller information successfully submitted' });
        navigation.navigate(SCREEN.ResellerDetailScreen, { id: res.data.data.id })
      }
    } catch (err) {
      notification.error({ message: err?.message || String(err) })
    } finally {
      setIsLoading(false);
    }

  }

  const updateUser = (key) => (value) => {
    setUser(s => ({
      ...s,
      [key]: value,
    }));
  }

  return (
    <SecuredLayout>
      <MainContentContainer>
        <Col pt4 ph4>
          <Breadcrumb
            items={[
              {
                title: <a href="/resellers">Resellers</a>,
              },
              {
                title: 'Create reseller',
              },
            ]}
          />
          <Row justifyContent="space-between" marginTop={12}>
            <Text h2 fontWeight={'300'}>Create Reseller</Text>
          </Row>
          <Col bgWhite padding={24} marginTop={24} paddingBottom={60}>
            <Col maxWidth={700} width={size} alignSelf="center">
              {FIELDS.map((i, idx) => {
                if (i.length) {
                  return (
                    <Row marginRight={-16} key={idx} >
                      {i.map(field => (
                        <FieldItem
                          flex1
                          key={field.id}
                          label={field.label}
                          marginRight={16}
                          value={user[field.id]}
                          onChangeText={updateUser(field.id)}
                        />
                      ))}
                    </Row>
                  )
                }
                return (
                  <FieldItem
                    key={i.id}
                    label={i.label}
                    value={user[i.id]}
                    onChangeText={updateUser(i.id)}
                  />
                )
              })}
              <Button
                title='Create'
                marginTop={24}
                onPress={onSubmit}
                height={46}
                alignSelf="flex-start"
                paddingHorizontal={90}
              />
            </Col>
          </Col>
        </Col>
      </MainContentContainer>
    </SecuredLayout >
  )
};

CreateResellerScreen.routeInfo = {
  title: 'Create Reseller',
  path: '/resellers/create',
};

export default CreateResellerScreen;
