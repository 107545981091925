import React from "react";
import { COLOR } from "const";
import { Table as AntTable, TableProps } from "antd";
import "./table.css";

export interface ITableProps extends TableProps<any> {
  data: any[],
  minWidth?: number,
}

const Table = (props: ITableProps) => {
  const { data, columns, expandable, rowClassName, minWidth, ...restProps } = props;

  return (
    <AntTable
      style={{ borderWidth: 1, borderColor: COLOR.GRAY100, borderStyle: 'solid' }}
      dataSource={data}
      columns={columns}
      pagination={false}
      expandable={expandable}
      rowClassName={rowClassName
        ? rowClassName
        : (record, index) => index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
      }
      scroll={{
        x: minWidth || 400,
      }}
      {...restProps}
    />
  );
};

export default Table;
