import React, { useMemo } from 'react';
import Store from 'store';
import { ValHelper } from 'helpers';
import { Text } from 'components';
import { useListCurrencies } from 'hooks/useListCurrencies';

const MoneyValue = ({ children, baseAmountGBP, toCurrency, renderingProp, showNumber } : any) => {
  const UserStore = Store.useUserStore();
  const { user } = UserStore;
  const listCurencies = useListCurrencies();

  const convertedAmount = useMemo(() => {
    const targetCurrency = toCurrency ? toCurrency : user?.currency;
    let cA;
    if (targetCurrency?.toUpperCase() === 'GBP') {
      cA = baseAmountGBP
    } else {
      const findRate = listCurencies.find(v => v.cc?.toUpperCase() === targetCurrency?.toUpperCase());
      if (!findRate) cA = baseAmountGBP;
      else cA = baseAmountGBP * findRate.rate
    }
    return showNumber? cA : ValHelper.formatBalance(cA);
  }, [baseAmountGBP, listCurencies, toCurrency, showNumber, user]);

  return renderingProp ? children({ amount: convertedAmount }) : <Text>{convertedAmount}</Text>;
}

export default MoneyValue;
