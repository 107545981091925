import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Checkbox, Modal } from 'antd';
import { Button, Col, ImageItem, Row, Table } from 'components';
import { COLOR } from 'const';
import { ValHelper } from 'helpers';

const SelectDesignVariantModal = forwardRef((props: any, ref) => {
  const { product, variants, onUpdateVariants } = props;
  const [show, setShow] = useState(false);
  const [selectedVariants, setSelectedVariants] = useState([]);

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setShow(true);
    },
    hide: () => {
      setShow(false);
    },
  }))

  useEffect(() => {
    setSelectedVariants(variants?.map(i => i.variantName));
  }, [variants])

  const onChange = useCallback((e) => {
    setSelectedVariants(e);
  }, []);

  const onPressSave = () => {
    onUpdateVariants?.(selectedVariants);
    setShow(false);
  }

  if (!show) return null;

  return (
    <Modal
      open={show}
      title={"Select variant"}
      onOk={() => setShow(false)}
      onCancel={() => setShow(false)}
      width={1000}
      footer={false}
    >
      <Checkbox.Group value={selectedVariants} style={{ width: '100%' }} onChange={onChange}>
        <Table
          columns={[
            {
              title: 'Selected',
              key: 'selected',
              width: 50,
              render: (_, record) => (
                <Checkbox
                  value={record.variantName}
                  disabled={variants?.some(i => i.variantName === record.variantName)}
                />
              ),
            },
            {
              title: 'Thumbnail',
              dataIndex: 'previewUrl',
              key: 'previewUrl',
              width: 80,
              render: (value) => value ? (
                <ImageItem
                  uri={value}
                  style={{ width: 50, height: 50 }}
                  canRemove={false}
                />
              ) : null,
            },
            {
              title: 'Name',
              dataIndex: 'variantName',
              key: 'variantName',
            },
            {
              title: 'Description',
              dataIndex: 'description',
              key: 'description',
            },
            {
              title: 'Size',
              dataIndex: 'size',
              key: 'size',
            },
            {
              title: 'Price',
              dataIndex: 'price',
              key: 'price',
              render: (value) => value ? `£${ValHelper.formatMoney(value)}` : "",
            },
          ].filter(i => i !== undefined)}
          style={{ width: "100%" }}
          data={product.data?.skuPDFs || []}
        />
      </Checkbox.Group>
      <Row mt2>
        <Button
          height={50}
          title={"Cancel"}
          backgroundColor="transparent"
          textProps={{ color: COLOR.MAIN }}
          borderColor={COLOR.MAIN}
          borderWidth={1}
          onPress={() => setShow(false)}
        />
        <Button
          height={50}
          title={props.saveButtonText || "Save"}
          ml2
          onPress={onPressSave}
        />
      </Row>
    </Modal>
  )
});

export default SelectDesignVariantModal;
