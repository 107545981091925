import { Col, Text, Touch } from "components";
import { COLOR } from "const";
import { useDynamicResponsiveValue } from "quickly-react";
import React from "react";
import { ScrollView } from "react-native";

export const ITEMS = [
  { id: 'profile', title: 'Profile' },
  { id: 'brands', title: 'Stores' },
  { id: 'billing', title: 'Billing Information' },
  // { id: 'packing_slips', title: 'Packing Slips' },
  { id: 'users', title: 'Users' },
]

const SubMenu = ({ tabId, onPress, ...props }) => {
  const rV = useDynamicResponsiveValue(['lg', 'md']);
  const breakpoint = rV({ lg: 'lg', md: 'md' });
  if (breakpoint === 'lg') {
    return (
      <Col paddingRight={4} {...props}>
        {ITEMS.map(i => {
          const isActive = tabId === i.id;
          return (
            <Touch
              paddingHorizontal={16} paddingVertical={12}
              marginBottom={8} key={`sub${i.id}`}
              onPress={isActive ? undefined : () => onPress?.(i.id)}
              backgroundColor={isActive ? COLOR.MAIN100 : undefined}
              disabled={isActive}
            >
              <Text p2 bold numberOfLines={1} color={isActive ? COLOR.MAIN : COLOR.GRAY300}>
                {i.title}
              </Text>
            </Touch>
          )
        })}
      </Col>
    )
  }
  return (
    <Col {...props}>
      <ScrollView horizontal contentContainerStyle={{ padding: 4 }}>
        {ITEMS.map(i => {
          const isActive = tabId === i.id;
          return (
            <Touch
              paddingHorizontal={16} paddingVertical={12}
              marginBottom={8} marginRight={8} key={`sub${i.id}`}
              onPress={() => onPress?.(i.id)}
              backgroundColor={isActive ? COLOR.MAIN100 : undefined}
              // disabled={isActive}
            >
              <Text p2 bold numberOfLines={1} color={isActive ? COLOR.MAIN : COLOR.GRAY300}>
                {i.title}
              </Text>
            </Touch>
          )
        })}
      </ScrollView>
    </Col>
  )
}

export default SubMenu;
