import Request from '../Request.utils'
export interface ICreateSetupIntentRequestBody {
  createdIfNotExisted?: boolean;
}
export interface ICreateBalanceTransactionRequestBody {
  amount?: number;
}
export interface IStripeCreateDepositRequestBody {
  amount?: number;
  currency?: string;
  paymentMethodId?: string;
}
export interface IMarkDepositCompleteRequestBody {
  amount?: number;
  currency?: string;
  fulfillAmount?: number;
  payment_intent_id?: string;
}
export interface IRequestWithdrawFundRequestBody {
  amount?: number;
  currency?: string;
}
export interface IGetChargeAmountFromLineItemsRequestBody {
  orders?: any[];
}
export interface IChargeFromWalletRequestBody {
  orders?: {
    line_items?: any[];
    orderId?: number;
    slug?: string;
  }[];
  shippings?: {
    code?: string;
    name?: string;
    orderId?: any;
    price?: number;
  }[];
}
export interface IGetBalanceTransactionsRequestQuery {
  starting_after?: string;
}
export interface IGetInvoiceRequestQuery {
  orderNumber: string;
}
export interface IGetOrderInvoiceRequestParams {
  orderId: string;
}


class PaymentAPI {
  publicKey = async () => {
    const res = await Request.call('/api/payment/public-key', 'GET', undefined, undefined, undefined, );
    return res;
  }
  createSetupIntent = async (body: ICreateSetupIntentRequestBody) => {
    const res = await Request.call('/api/payment/create-setup-intent', 'POST', undefined, undefined, body, );
    return res;
  }
  listCard = async () => {
    const res = await Request.call('/api/payment/cards', 'GET', undefined, undefined, undefined, );
    return res;
  }
  getBalance = async () => {
    const res = await Request.call('/api/payment/get-balance', 'GET', undefined, undefined, undefined, );
    return res;
  }
  createBalanceTransaction = async (body: ICreateBalanceTransactionRequestBody) => {
    const res = await Request.call('/api/payment/create-balance-transaction', 'POST', undefined, undefined, body, );
    return res;
  }
  removeCard = async () => {
    const res = await Request.call('/api/payment/remove-card', 'POST', undefined, undefined, undefined, );
    return res;
  }
  listCurrencies = async () => {
    const res = await Request.call('/api/payment/list-currencies', 'GET', undefined, undefined, undefined, );
    return res;
  }
  stripeCreateDeposit = async (body: IStripeCreateDepositRequestBody) => {
    const res = await Request.call('/api/payment/create-deposit', 'POST', undefined, undefined, body, );
    return res;
  }
  markDepositComplete = async (body: IMarkDepositCompleteRequestBody) => {
    const res = await Request.call('/api/payment/mark-deposit-complete', 'POST', undefined, undefined, body, );
    return res;
  }
  requestWithdrawFund = async (body: IRequestWithdrawFundRequestBody) => {
    const res = await Request.call('/api/payment/request-withdraw-fund', 'POST', undefined, undefined, body, );
    return res;
  }
  getChargeAmountFromLineItems = async (body: IGetChargeAmountFromLineItemsRequestBody) => {
    const res = await Request.call('/api/payment/get-charge-amount', 'POST', undefined, undefined, body, );
    return res;
  }
  chargeFromWallet = async (body: IChargeFromWalletRequestBody) => {
    const res = await Request.call('/api/payment/charge-from-wallet', 'POST', undefined, undefined, body, );
    return res;
  }
  getBalanceTransactions = async (query: IGetBalanceTransactionsRequestQuery) => {
    const res = await Request.call('/api/payment/get-balance-transactions', 'GET', undefined, query, undefined, );
    return res;
  }
  getInvoice = async (query: IGetInvoiceRequestQuery) => {
    const res = await Request.call('/api/payment/get-invoice', 'GET', undefined, query, undefined, );
    return res;
  }
  getOrderInvoice = async (params: IGetOrderInvoiceRequestParams) => {
    const res = await Request.call('/api/payment/get-order-invoice/:orderId', 'GET', params, undefined, undefined, );
    return res;
  }
}
export default new PaymentAPI()