import React, { useState, useMemo, forwardRef, useImperativeHandle, useCallback } from 'react';
import { Col, Text, Touch, Row, Button } from 'components';
import { useShopifyEmbedAppFlow, useUIState } from 'hooks';
import { COLOR } from 'const';
import Store from 'store';
import { ActivityIndicator } from 'react-native';
import { TDesign, TProduct } from 'type';
import { useShopStore } from 'store/Shop.Store';
import { Modal } from 'antd';
import { MaterialIcons } from '@expo/vector-icons';
import { PREVIEW_HTML, getVariantNamesHTML, getVariantRowHTML } from './preview_html';
interface Props {
  design: TDesign,
  setDesign: any,
  product: TProduct,
  beforePublish?: () => Promise<any>,
}

const PublishProductModal = ({ design, setDesign, beforePublish, product }: Props, ref) => {
  const [show, setShow] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [previewHTML, setPreviewHTML] = useState("");

  const { shops } = useShopStore();
  const [{ loading, errorMes, fetching }, setUI] = useUIState();
  const [productType, setProductType] = useState<'finished' | 'customizable'>('customizable');
  const [selectedShopIds, setSelectedShopIds] = useState([]);

  const { isEmbedAppFlow, shopify_embed_app_url } = useShopifyEmbedAppFlow();

  const shopifyEmbedApps = useMemo(() => {
    if (!shops) return [];
    const arr = [];
    Object.keys(shops).forEach(id => {
      if (shops[id]?.type === 'shopify-embed-app') {
        arr.push(shops[id])
      }
    })
    return arr;
  }, [shops]);

  useImperativeHandle(ref, () => ({
    show: () => setShow(true),
    hide: () => setShow(false),
  }))

  const publishToShopifyAppUsingIframe = () => {
    setUI({ loading: true });
    const listenerOnce = async (event) => {
      if (event?.data?.event === 'SHOPIFY_APP_CREATE_PRODUCT_RESPONSE') {
        window.removeEventListener('message', listenerOnce);
        const res = await Store.Api.Shop.addPublishedProductToDesign({
          designId: design.id,
          storeUrl: shopify_embed_app_url,
          product: event.data.payload
        });
        if (res.data.success && res.data.data) {
          setDesign(res.data.data);
          setUI({ loading: false });
        }
      }
    };
    window.addEventListener('message', listenerOnce);
    window.parent.postMessage({
      event: 'SHOPIFY_APP_CREATE_PRODUCT_REQUEST',
      payload: {
        designId: design.id,
        name: design.name,
        type: productType,
        price: design.resalePrice,
        description: design.description,
      }
    }, "*");
  }

  const publish = async () => {

    if (isEmbedAppFlow) return publishToShopifyAppUsingIframe();

    if (!selectedShopIds?.length) return alert('Please choose at least one store');
    setUI({ loading: true });
    if (beforePublish) await beforePublish?.();

    const res = await Store.Api.Shop.publishProduct({
      designId: design.id,
      name: design.name,
      type: productType,
      price: Number(design.resalePrice),
      // updateExisting: shouldUpdateExisting,
      updateExisting: design?.products?.length > 0,
      storeIds: selectedShopIds,
      cost: product?.price || 0,
    })
    if (res.data.error) {
      setUI({ errorMes: res.data.error, loading: false });
      return;
    }
    alert('Publish product Successfully');

    setUI({ loading: false });
    setDesign(res.data.data);
  }

  const unpublish = async (p) => {
    const shouldDelete = confirm(
      "Are you sure you want to unpublish this product?"
    );
    if (!shouldDelete) return;
    await Store.Api.Shop.unpublishProduct({
      designId: design.id,
      productId: +p.productId,
      storeId: p.storeId,
    });
    // update UI
    const newProducts = design.products.filter(
      (item) => item.productId !== p.productId
    );
    if (!newProducts?.length) setShow(false);
    setDesign({
      ...design,
      products: newProducts,
    });
  }

  const preview = useCallback(() => {
    if (!design) return;
    const info = {
      name: design.name,
      thumbnail: design.image,
      description: design.description,
      variantNames: getVariantNamesHTML(design.variants.map(i => i.variantName)),
      variantRows: getVariantRowHTML(design.variants, design.resalePrice),
    };
    let html = PREVIEW_HTML;
    Object.keys(info).forEach((key) => {
      const regex = new RegExp(`{{.${key}}}`, 'g');
      html = html.replace(regex, info[key]);
    });
    setPreviewHTML(html);
    setShowPreview(true);
  }, [design]);

  return (
    <Modal
      open={show}
      title="Publish product"
      onOk={() => setShow(false)}
      onCancel={() => setShow(false)}
      footer={false}
      width={1000}
    >
      {!!design?.products && design?.products?.length > 0 ? (
        <Col mt2>
          <Text bold>List of products on my stores</Text>
          <Text caption mt0>
            * Note that if the product is in draft state in the stores, the
            urls wont be accessible. Try using product admin url instead
          </Text>
          <Row
            mt2
            height={50}
            stretch
            borderBottomColor={COLOR.GREY_BG}
            borderBottomWidth={1}
          >
            <Col width={200} m1>
              <Text color={COLOR.GREY}>Brand</Text>
            </Col>
            <Col flex1 m1>
              <Text color={COLOR.GREY}>Product URLs</Text>
            </Col>
            <Col width={100} m1>
              <Text color={COLOR.GREY}></Text>
            </Col>
          </Row>
          <Col>
            {design?.products.map((p, i) => {
              return (
                <Row minHeight={50} stretch key={p.productId} borderBottomColor={COLOR.GREY_BG} borderBottomWidth={0.5} mb1>
                  <Col width={200} m1 mr0>
                    <Text>{shops[p.storeId]?.name}</Text>
                  </Col>
                  <Col flex1 m1>
                    <a href={p.url} target="_blank" style={{ marginBottom: 10 }}>
                      <Row>
                        <Text bold caption>Public:</Text>
                        <Text ml1 caption>{p.url}</Text>
                      </Row>
                    </a>
                    <a href={p.productAdminUrl} target="_blank">
                      <Row>
                        <Text bold caption>Admin:</Text>
                        <Text ml1 caption>{p.productAdminUrl}</Text>
                      </Row>
                    </a>
                  </Col>
                  <Col width={100} m1>
                    <Button
                      backgroundColor={'#ff0000'}
                      height={30}
                      width={100}
                      borderRadius={15}
                      title='Unpublish'
                      onPress={() => unpublish(p)}
                    />
                  </Col>
                </Row>
              );
            })}
          </Col>
        </Col>
      ) : (
        <>
          <Text body1 semiBold mv2>Are you sure you want to publish this product?</Text>
          {isEmbedAppFlow ? (
            <Text mv2 mt={0}>Note: You're inside Shopify App this will only publish to the Shopify store only</Text>
          ) : shopifyEmbedApps.length > 0 ? (
            <Text mv2 mt={0} caption>Note: This wont publish products to stores that are connected via Shopify App. In order to publish product to those stores, go to Shopify admin and click PPG: Print on deman App</Text>
          ) : null}
          {!!errorMes && (
            <Text mb2 color="red" subtitle1>{errorMes}</Text>
          )}
          <Col mt2>
            <Row mb1>
              <Touch cirle middle onPress={() => setProductType('customizable')}>
                <MaterialIcons name={productType === 'customizable' ? "radio-button-on" : 'radio-button-off'} size={20} color="black" />
              </Touch>
              <Text ml1 onPress={() => setProductType('customizable')}>Customizable product - customers can open editor and customize</Text>
            </Row>
            <Row>
              <Touch cirle middle onPress={() => setProductType('finished')}>
                <MaterialIcons name={productType === 'finished' ? "radio-button-on" : 'radio-button-off'} size={20} color="black" />
              </Touch>
              <Text ml1 onPress={() => setProductType('finished')}>Finished product</Text>
            </Row>
          </Col>
          <Col mt1>
            <Text body1 semiBold mb2>Choose stores</Text>
            {Object.keys(shops).map((shopId, i) => {
              const isSelected = selectedShopIds.includes(shopId);
              return (
                <Row key={`shop${i}`} mb1>
                  <Touch cirle middle
                    onPress={() => {
                      if (isSelected) setSelectedShopIds(v => v.filter(s => s !== shopId))
                      else setSelectedShopIds(v => [...v, shopId])
                    }}
                  >
                    <MaterialIcons name={isSelected ? "radio-button-on" : 'radio-button-off'} size={20} color="black" />
                  </Touch>
                  <Text ml1>{shops?.[shopId]?.name || shopId}</Text>
                </Row>
              )
            })}
          </Col>
          <Row mt2 alignSelf="flex-end">
            <Touch
              height={30}
              width={70}
              middle
              borderColor={COLOR.FONT}
              borderRadius={15}
              borderWidth={1}
              m0
              ph2
              onPress={() => {
                if (errorMes) return setUI({ errorMes: '' });
                return setShow(false);
              }}
            >
              <Text color={COLOR.FONT}>Cancel</Text>
            </Touch>
            <Touch
              height={30}
              ph2
              middle
              borderColor={COLOR.FONT}
              borderRadius={15}
              borderWidth={1}
              backgroundColor={COLOR.FONT}
              m0
              onPress={preview}
            >
              <Row>
                <Text color={'white'}>Preview</Text>
              </Row>
            </Touch>
            <Touch
              height={30}
              ph2
              middle
              borderRadius={15}
              backgroundColor={COLOR.MAIN}
              m0
              opacity={!selectedShopIds.length ? 0.5 : 1}
              disabled={!selectedShopIds.length}
              onPress={publish}
            >
              <Row>
                {loading && <ActivityIndicator size='small' color={'white'} style={{ marginRight: 5 }} />}
                <Text color={'white'}>Publish</Text>
              </Row>
            </Touch>

          </Row>
        </>
      )}
      <Modal
        open={showPreview}
        title="Preview product on shopify"
        onOk={() => setShowPreview(false)}
        onCancel={() => setShowPreview(false)}
        footer={false}
        width={1000}
      >
        <Col backgroundColor={COLOR.GREY_BG} padding={16}>
          <div dangerouslySetInnerHTML={{ __html: previewHTML }} />
        </Col>
      </Modal>
    </Modal>
  );
};

export default forwardRef(PublishProductModal);
