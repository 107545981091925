import React from "react";
import { Col } from "../base";
import { Radio as AntRadio, Space } from "antd";
import { IColProps } from "components/base/Col";

export interface RadioProps extends IColProps {
  placeholder?: string,
  value?: any,
  onChange?: (data: any) => void,
  options?: { label: string, value: string }[],
}

const Radio = (props: RadioProps) => {
  const { value, onChange, placeholder, options, ...restProps } = props;

  return (
    <Col {...restProps}>
      <AntRadio.Group onChange={(e) => onChange(e.target.value)} value={value}>
        <Space direction="vertical">
          {options.map(i => (
            <AntRadio key={i.value} value={i.value}>{i.label}</AntRadio>
          ))}
        </Space>
      </AntRadio.Group>
    </Col>
  );
};

export default Radio;
