import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M2.859 3.378l12.57-1.796a.5.5 0 01.571.495v20.847a.5.5 0 01-.57.495L2.858 21.623a1 1 0 01-.859-.99V4.368a1 1 0 01.86-.99zM17 3.5h4a1 1 0 011 1v16a1 1 0 01-1 1h-4v-18zm-6.8 9l2.8-4h-2.4L9 10.786 7.4 8.5H5l2.8 4-2.8 4h2.4L9 14.214l1.6 2.286H13l-2.8-4z"
        fill={props.color || "#fff"}
      />
    </Svg>
  )
}

export default SvgComponent
