import React from "react";
import { Col } from "../base";
import { DatePicker as AntDatePicker } from "antd";
import { IColProps } from "components/base/Col";
import dayjs from 'dayjs';

export interface DatePickerProps extends IColProps {
  onChange: (date: any) => void,
  height?: number,
  type?: "month" | "date",
  value?: any,
}

const DatePicker = (props: DatePickerProps) => {
  const { onChange, height, type = "date", value, ...restProps } = props;
  const dateFormat = type === "month" ? "MM-YYYY" : "DD-MM-YYYY";

  const onChangeDate = (value) => {
    onChange?.(value.format(dateFormat));
  }

  return (
    <Col {...restProps}>
      <AntDatePicker
        onChange={onChangeDate}
        picker={type}
        format={dateFormat}
        value={value ? dayjs(value, dateFormat) : undefined}
        style={{
          borderRadius: 0,
          height: height || 36
        }}
      />
    </Col>
  );
};

export default DatePicker;
