import { Col, Input, Radio, Select, Text } from "components";
import { COLOR } from "const";
import React, { useMemo, useState } from "react";
import Store from "store";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useProductForm } from "./FormContext";
import { useProductStore } from "store/Product.Store";

const PRODUCT_TYPES = [
  { value: "flat-card", label: "Flat Card" },
  { value: "folded-card", label: "Folded Card" },
  { value: "wall-art", label: "Wall Art" },
  { value: "notebook", label: "Notebook" },
  { value: "apparel", label: "Apparel" },
  { value: "mug", label: "Mug" },
];

const TabGeneral = () => {
  const [other, setOther] = useState('ws');
  const { user, users } = Store.useUserStore();
  const { tags } = useProductStore();
  const { product, setProduct } = useProductForm();

  const canEdit = user?.role === 'admin';

  const listResellers = useMemo(() => {
    const arr = [{ label: 'All resellers', value: 'all' }];
    Object.keys(users).forEach(id => {
      const v = users[id];
      arr.push({
        label: [v.firstName, v.lastName].filter(Boolean).join(' ') || id,
        value: v.id,
      })
    })
    return arr;
  }, [users])

  return (
    <Col paddingTop={16}>
      <Text p1 color={COLOR.GRAY300} marginBottom={36}>
        The information of the product that will be displayed in public (eg: Reseller website, Shopify website)
      </Text>
      <Text p1 color={COLOR.GRAY400}>Title</Text>
      <Input
        marginTop={16}
        height={50}
        value={product?.name || ''}
        onChangeText={(v) => setProduct(p => ({ ...p, name: v }))}
        inputProps={{ editable: canEdit }}
      />
      <Text marginTop={24} p1 color={COLOR.GRAY400}>Price</Text>
      <Input
        marginTop={16}
        height={50}
        value={String(product?.price || '')}
        onChangeText={(v) => setProduct(p => ({ ...p, price: v }))}
        inputProps={{ editable: canEdit }}
        left={<Text>£</Text>}
      />
      <Text marginTop={24} p1 color={COLOR.GRAY400}>Description</Text>
      <Col marginTop={16}>
        <ReactQuill
          value={product?.description}
          modules={{
            clipboard: {
              matchVisual: false,
            },
          }}
          onChange={(v) => setProduct(p => ({ ...p, description: v }))}
        />
      </Col>
      {/* <Input
        marginTop={16}
        isMulti
        height={200}
        value={product?.description}
        onChangeText={(v) => setProduct(p => ({ ...p, description: v }))}
        inputProps={{ editable: canEdit }}
      /> */}
      {user?.role === 'admin' && (
        <>
          <Text marginTop={24} p1 color={COLOR.GRAY400}>Resellers</Text>
          <Select
            marginTop={16}
            height={50}
            isMultiple
            value={Object.keys(product?.availableForResellerIds || {})}
            onChange={(a) => {
              const obj: any = {};
              (a || []).forEach(id => {
                obj[id] = true;
              })
              setProduct(p => ({
                ...p,
                availableForResellerIds: obj,
              }))
            }}
            options={listResellers}
            placeholder="Select"
          />
        </>
      )}

      <Text marginTop={24} p1 color={COLOR.GRAY400}>Tags</Text>
      <Select
        marginTop={16}
        height={50}
        value={product?.tags ? product?.tags?.trim().split(",") : []}
        onChange={(a) => {
          setProduct(p => ({
            ...p,
            tags: a?.map(i => String(i).trim()).filter(Boolean).join(","),
          }))
        }}
        disabled={user?.role !== "admin"}
        options={tags.map(i => ({ value: i, label: i }))}
        selectProps={{
          mode: "tags",
        }}
      />
      <Text marginTop={24} p1 color={COLOR.GRAY400}>Product type</Text>
      <Select
        marginTop={16}
        height={50}
        value={product?.data?.type}
        onChange={(a) => {
          setProduct(p => ({
            ...p,
            data: { ...p.data, type: a },
          }))
        }}
        disabled={user?.role !== "admin"}
        options={PRODUCT_TYPES}
        placeholder="Select"
      />
      {/* <Text marginTop={24} p1 bold color={COLOR.GRAY400}>Other</Text>
      <Radio
        marginTop={16}
        value={other}
        onChange={setOther}
        options={[
          { value: 'ws', label: 'Wholesale' },
          { value: 'po', label: 'Print on demand' },
          { value: 'cp', label: 'Custom Product' },
        ]}
      /> */}
    </Col>
  );
}

export default TabGeneral;
