import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Text } from "components/base";
import { IColProps } from "components/base/Col";
import { COLOR } from "const";
import { SVG } from "assets";
import { get, set } from "lodash";
import Button from "./Button";
import Input from "./Input";
import UploadFile from "./UploadFile";
import { ActivityIndicator } from "react-native";

export interface ITableFormProps extends IColProps {
  columns: { label: string, key: string, canUpload?: boolean }[],
  onChange?: (data: any[]) => void,
  addBtnTitle?: string,
  isBottomBtn?: boolean,
  data?: any[],
  editable?: boolean,
}

const TableForm = (props: ITableFormProps) => {
  const uploadRef = useRef<{ showDialog: Function }>(null);
  const [data, setData] = useState<any[]>(props.data || [{}]);
  const { columns, onChange, addBtnTitle, isBottomBtn, editable = true, ...restProps } = props;
  const [fileSaveTarget, setFileSaveTarget] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const onAddRow = () => {
    setData(s => [...s, {}]);
  }

  const onRemove = (i) => {
    const arr = [...data];
    arr.splice(i, 1);
    setData(arr);
    onChange?.(arr);
  }

  const onChangeRow = (value, key, idx) => {
    const arr = [...data];
    set(arr, `[${idx}].${key}`, value);
    setData(arr);
    onChange?.(arr);
  }

  const onFileUploaded = (urls) => {
    if (urls.length === 0) return setLoading(false);
    const arr = [...data];
    const { field, index } = fileSaveTarget;
    set(arr, `[${index}].${field}`, urls?.[0]);
    setData(arr);
    onChange?.(arr);
    setLoading(false);
  }

  const onPressAddFile = (field, index) => {
    setFileSaveTarget({ field, index });
    uploadRef.current?.showDialog();
  }

  return (
    <Col {...restProps}>
      {!isBottomBtn && editable &&
        <Button
          height={56}
          title={addBtnTitle || "Add row"}
          backgroundColor={COLOR.SECOND}
          onPress={onAddRow}
          alignSelf="flex-start"
        />
      }
      <Row marginTop={16}>
        <Row flex1>
          {columns.map(col => (
            <Col flex1 key={`h${col.label}`}>
              <Text p1>{col.label}</Text>
            </Col>
          ))}
        </Row>
        <Col width={50}>
        </Col>
      </Row>
      {data.map((i, idx) => (
        <Row marginTop={8} key={`row${idx}`}>
          <Row flex1>
            {columns.map((col, cIdx) => (
              <Col flex1 paddingRight={8} key={`c${cIdx}${idx}`}>
                <Input
                  height={50}
                  paddingHorizontal={8}
                  value={get(i, col.key)}
                  inputProps={{ editable }}
                  onChangeText={(v) => onChangeRow(v, col.key, idx)}
                  right={col.canUpload && editable && (
                    <Col onPress={() => onPressAddFile(col.key, idx)}>
                      {loading ?
                        <ActivityIndicator color={COLOR.MAIN} />
                        :
                        <SVG.ImagePlus />
                      }
                    </Col>
                  )}
                />
              </Col>
            ))}
          </Row>
          {editable &&
            <Col width={50} height={50} onPress={() => onRemove(idx)} backgroundColor={COLOR.MAIN100} middle>
              <SVG.Trash color={COLOR.MAIN} />
            </Col>
          }
        </Row>
      ))}
      {isBottomBtn && editable &&
        <Button
          height={50}
          marginTop={12}
          title={addBtnTitle || "Add row"}
          onPress={onAddRow}
          backgroundColor="transparent"
          textProps={{ color: COLOR.MAIN }}
          borderColor={COLOR.MAIN}
          borderWidth={1}
        />
      }
      <UploadFile
        ref={uploadRef}
        onUploaded={onFileUploaded}
        onUploading={() => setLoading(true)}
      />
    </Col>
  );
};

export default TableForm;
