import { Breadcrumb, Switch } from 'antd';
import { Button, ButtonGroup, Col, Grid, Row, Select, Text } from 'components';
import { SecuredLayout } from 'components/container';
import MainContentContainer from 'components/container/MainContentContainer';
import { COLOR, SCREEN, ARTWORK_TYPES } from 'const';
import React, { useEffect, useRef, useState } from 'react';
import TabGeneral from './TabGeneral';
import TabPrint from './TabPrint';
import ProductImages from './ProductImages';
import { useNavFunc } from 'navigation';
import { useUIState } from 'hooks';
import { SVG } from 'assets';
import { ProductFormProvider, useProductForm } from './FormContext';
import { ActivityIndicator } from 'react-native';
import { omitBy, isNull } from 'lodash';
import { useProductStore } from 'store/Product.Store';
import { useUserStore } from 'store/User.Store';
import Store from 'store';
import TabProductVariants from './TabProductVariants';
import ProductGlobalEditorButton from './ProductGlobalEditorButton';
import TabPackaging from './TabPackaging';
import apiClient from 'store/api-client';
import SelectDesignVariantModal from './SelectDesignVariantModal';

const CreateProductScreen = () => {
  const selectRef = useRef(null);
  const [selectedTab, setSelectedTab] = useState('general');
  const { navigation } = useNavFunc();
  const { upsertProduct, getList } = useProductStore();
  const [{ loading: submitting }, setSubmitUI] = useUIState();
  const [{ loading: creating }, setCreatingUI] = useUIState();
  const { product, setProduct } = useProductForm();
  const { user, getListResellers } = useUserStore();
  const isAdmin = user?.role === "admin";

  useEffect(() => {
    getListResellers(1);
  }, [])

  const onSubmit = async () => {
    if (!product) return;
    if (!product.image) {
      alert('Please upload main image');
      return;
    }
    setSubmitUI({ loading: true });
    const res = await upsertProduct(omitBy({
      id: product.id,
      name: product.name,
      description: product.description,
      image: product.image,
      price: +product.price,
      galleries: product.galleries,
      packagingDescription: product.packagingDescription,
      physicalWidth: product.physicalWidth,
      physicalHeight: product.physicalHeight,
      printAreas: product.printAreas,
      availableForResellerIds: product.availableForResellerIds,
      tags: product.tags || "",
      data: {
        ...product.data,
      },
      previewData: product.previewData,
      // variations: variantOptions.map(v => {
      //   v.prices = v.prices.map(vP => {
      //     vP.amount = Number(vP.amount);
      //     vP.price = Number(vP.price);
      //     return vP;
      //   });
      //   return v;
      // }),
      // wholeSale: !!product.wholeSale,
      // printOnDemand: !!product.printOnDemand,
      // customProduct: !!product.customProduct,
    }, isNull));
    if (res.data.error) {
      alert(res.data.error)
    } else if (res.data.data.id) {
      navigation.navigate(SCREEN.CreateProductScreen, { id: res.data.data.id });
      alert(`${product.id ? 'Save' : 'Create'} product successfully`);
      getList(1);
    }
    setSubmitUI({ loading: false });
  }

  const onDelete = async () => {
    await apiClient.Api.Product.remove({ id: product?.id });
  }

  const onInitDesign = async (variantNames) => {
    setCreatingUI({ loading: true });
    const res = await Store.Api.Design.upsert({
      name: product.name,
      isCustomizable: true,
      productId: product.id,
      width: product.physicalWidth,
      height: product.physicalHeight,
      printAreas: product.printAreas,
      customProduct: !!product.customProduct,
      printOnDemand: !!product.printOnDemand,
      wholeSale: !!product.wholeSale,
      image: product.image,
      data: {
        globalCanvas: product.data.globalCanvas,
      },
      description: product.description || "",
      variants: product.data.skuPDFs?.map((v, vI) => (variantNames.includes(v.variantName) ? {
        variantName: !v.variantName ? 'Variant ' + (vI + 1) : v.variantName,
        width: +v.width || undefined,
        height: +v.height || undefined,
        ...v,
      } : undefined)).filter(Boolean),
    });
    if (res.data.error) {
      setCreatingUI({ loading: false });
      return alert(res.data.error);
    } else if (res.data.data.id) {
      setCreatingUI({ loading: false });
      navigation.reset({
        index: 0,
        routes: [{
          name: SCREEN.CreateResellerProductScreen, params: {
            designId: res.data.data.id,
            productId: product.id
          }
        }],
      });
    }
  }

  return (
    <SecuredLayout>
      <MainContentContainer>
        <Col pt4 ph4>
          <Breadcrumb
            items={[
              {
                title: <a href="/products">Products</a>,
              },
              {
                title: product?.id ? (isAdmin ? 'Edit Product' : 'Product detail') : 'Create Product',
              },
            ]}
          />
          <Row justifyContent="space-between" marginTop={16}>
            <Text h2 fontWeight={'300'}>{product?.id && product?.name ? product.name : 'Create Product'}</Text>
            {isAdmin ?
              <Button
                title={product?.id ? 'Save' : 'Create'}
                left={submitting
                  ? <ActivityIndicator color="white" />
                  : (product?.id ? <SVG.Pencil color={COLOR.WHITE} /> : <SVG.Plus color={COLOR.WHITE} />)
                }
                onPress={onSubmit}
                height={46}
                paddingHorizontal={18}
                disabled={submitting}
              />
              :
              <Button
                title={"Create my own"}
                onPress={() => selectRef.current?.open()}
                height={46}
                paddingHorizontal={18}
                isLoading={creating}
              />
            }
          </Row>

          <Grid
            xs="100%"
            lg="50%"
            marginBottom={24}
            marginHorizontal={-32}
            alignItems="flex-start"
          >
            <Col bgWhite marginHorizontal={8} padding={24} marginTop={20}>
              <ButtonGroup
                selectedId={selectedTab}
                onChange={setSelectedTab}
                marginRight={32}
                buttons={isAdmin ? [
                  { id: 'general', title: 'General Information' },
                  { id: 'print', title: 'Print Info' },
                  { id: 'packing', title: 'Packing Info' },
                ] : [
                  { id: 'general', title: 'General Information' },
                  { id: 'packing', title: 'Packing Info' },
                ]}
              />
              {selectedTab === 'general' && <TabGeneral />}
              {selectedTab === 'print' && <TabPrint />}
              {selectedTab === 'packing' && <TabPackaging />}
            </Col>
            <Col marginHorizontal={8} paddingBottom={16} marginTop={20}>
              <Col bgWhite minHeight={700}>
                <Row paddingHorizontal={24} height={68} justifyContent="space-between">
                  <Text h4 bold>Product Preview</Text>
                  {/* <Row>
                  <Text p1 marginRight={8}>Toggle Preview</Text>
                  <Switch />
                </Row> */}
                </Row>
                <Row height={1} backgroundColor={COLOR.GRAY100} marginBottom={16} />
                <ProductImages />
                {isAdmin && (
                  <>
                    <Col paddingHorizontal={24}>
                      <Text marginTop={24} p1 color={COLOR.GRAY400}>Artwork type</Text>
                      <Select
                        marginTop={16}
                        height={50}
                        value={product?.data?.artworkType ? [product?.data?.artworkType] : undefined}
                        onChange={(a) => {
                          setProduct(p => ({
                            ...p,
                            data: { ...p.data, artworkType: a },
                          }))
                        }}
                        options={ARTWORK_TYPES}
                        placeholder="Select"
                      />
                    </Col>
                    <ProductGlobalEditorButton />
                  </>
                )}
              </Col>
              <Col padding={16} bgWhite marginTop={20}>
                <TabProductVariants />
              </Col>
            </Col>
          </Grid>
        </Col>
        {/* {isAdmin && !!product?.id && (
          <Button
            title={"Delete"}
            onPress={onDelete}
            height={46}
            paddingHorizontal={18}
            disabled={submitting}
          />
        )} */}
        {!isAdmin && (
          <SelectDesignVariantModal
            product={product}
            variants={[]}
            onUpdateVariants={onInitDesign}
            saveButtonText="Create"
            ref={selectRef}
          />
        )}
      </MainContentContainer>
    </SecuredLayout >
  )
};

const CreateProductScreenWithContext = (props) => {
  const { route } = useNavFunc();
  // @ts-ignore
  const { id } = route.params || {};
  return (
    <ProductFormProvider id={id}>
      <CreateProductScreen {...props} />
    </ProductFormProvider>
  )
}

CreateProductScreenWithContext.routeInfo = {
  title: 'Create product',
  path: '/products/create',
};

export default CreateProductScreenWithContext;
