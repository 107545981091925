import { Skeleton, notification } from 'antd';
import { Col, Grid, ProductItem } from 'components';
import { SCREEN } from 'const';
import { isEmpty } from 'lodash';
import { useNavFunc } from 'navigation';
import React, { useEffect, useState } from 'react';
import { useDesignStore } from 'store/Design.Store';
import { useUserStore } from 'store/User.Store';
import { useResellerForm } from './FormContext';

const TabProducts = () => {
  const { navigate } = useNavFunc();
  const UserStore = useUserStore();
  const DesignStore = useDesignStore();
  const [loading, setLoading] = useState(false);
  const [designs, setDesigns] = useState([]);
  const { reseller } = useResellerForm();

  const getData = async (p) => {
    try {
      if (loading) return;
      setLoading(true);
      const u = await UserStore.onReady();
      const resellerId = UserStore.getResellerId(u);
      const { list, total, error } = await DesignStore.getList(p, '', reseller?.resellerId || reseller?.id || resellerId);
      if (error) {
        notification.error({ message: error });
        return;
      }
      setDesigns(list);
    } catch (err) {
      notification.error({ message: err?.message || JSON.stringify(err) });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData(1);
  }, []);

  const openProductDetail = (design) => {
    navigate(SCREEN.CreateResellerProductScreen, {
      designId: design.id,
      productId: design.productId,
    });
  }

  return (
    <Col bgWhite padding={24} paddingTop={12} marginTop={24} marginLeft={-24}>
      {loading && isEmpty(designs) ?
        <Col bgWhite p2 mt3 marginLeft={-16}>
          <Skeleton active />
        </Col>
        :
        <Grid xs='50%' md='25%' marginHorizontal={-12}>
          {designs.map((val, i) => {
            return (
              <ProductItem key={`p${i}`} {...val} onPress={() => openProductDetail(val)} />
            )
          })}
        </Grid>
      }
    </Col>
  )
};

export default TabProducts;
