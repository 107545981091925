import { Breadcrumb } from 'antd';
import { ButtonGroup, Col, Row, Text } from 'components';
import { SecuredLayout } from 'components/container';
import MainContentContainer from 'components/container/MainContentContainer';
import React, { useState } from 'react';
import TabInfo from './TabInfo';
import TabProducts from './TabProducts';
import { useNavFunc } from 'navigation';
import { ResellerFormProvider, useResellerForm } from './FormContext';

const ResellerDetailScreen = () => {
  const [selectedTab, setSelectedTab] = useState('info');
  const { reseller } = useResellerForm();

  return (
    <SecuredLayout>
      <MainContentContainer>
        <Col pt4 ph4>
          <Breadcrumb
            items={[
              {
                title: <a href="/resellers">Resellers</a>,
              },
              {
                title: 'Reseller detail',
              },
            ]}
          />
          <Row justifyContent="space-between" marginTop={12}>
            <Text h2 fontWeight={'300'}>{reseller?.firstName || reseller?.email}</Text>
          </Row>
          <ButtonGroup
            selectedId={selectedTab}
            onChange={setSelectedTab}
            marginTop={16}
            buttons={[
              { id: 'info', title: 'Information' },
              { id: 'product', title: 'List of product' },
            ]}
          />
          {selectedTab === 'info' ?
            <TabInfo />
            :
            <TabProducts />
          }
        </Col>
      </MainContentContainer>
    </SecuredLayout >
  )
};

const ResellerDetailScreenWithContext = (props) => {
  const { route } = useNavFunc();
  // @ts-ignore
  const { id } = route.params || {};
  return (
    <ResellerFormProvider id={id}>
      <ResellerDetailScreen {...props} />
    </ResellerFormProvider>
  )
}

ResellerDetailScreenWithContext.routeInfo = {
  title: 'Reseller detail',
  path: '/resellers/:id',
};

export default ResellerDetailScreenWithContext;
