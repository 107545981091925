import React, { forwardRef, useImperativeHandle, useMemo, useState } from "react";
import { Col, Row, Text, Touch } from "components/base";
import { SideMenu } from "components/elements";
import { useNavFunc } from "navigation";
import { Image, Pressable, StyleSheet, View } from "react-native";
import * as Animatable from 'react-native-animatable';
import { useUserStore } from "store/User.Store";
import { SIDE_MENU_ITEMS, SIDE_MENU_ITEMS_RESELLER } from "const/menu";
import { COLOR, SCREEN } from "const";
import { MaterialIcons } from '@expo/vector-icons';
import { Tooltip } from "antd";
import Notifications from "./Notifications";
import { useNotificationStore } from "store/Notification.Store";

const MobileWrapper = ({ show, setShow, children }) => {
  return (
    <Col
      style={{
        backgroundColor: 'rgba(0,0,0,0.5)',
        position: 'absolute',
        left: 0,
        bottom: 0,
        right: 0,
        top: 0,
      }}
    >
      <Row flex1>
        <Animatable.View
          animation={show ? 'fadeInLeft' : 'fadeOutLeft'}
          duration={300}
          style={{ height: '100%' }}
        >
          {children}
        </Animatable.View>
        <Col flex1 height="100%" onPress={() => setShow(false)} />
      </Row>
    </Col>
  )
}

const Drawer = forwardRef(({ isMobile }: { isMobile?: boolean }, ref: any) => {
  const { navigate } = useNavFunc();
  const [show, setShow] = useState(false);
  const { notifications } = useNotificationStore();
  const { user, set: setUserStore } = useUserStore();
  const { set: setNotificationStore } = useNotificationStore();

  const homeScreen = useMemo(() => {
    const menus = user?.role === "admin" ? SIDE_MENU_ITEMS : SIDE_MENU_ITEMS_RESELLER;
    return menus[0]?.screen;
  }, [user]);

  useImperativeHandle(ref, () => ({
    show: () => setShow(true),
    hide: () => setShow(false),
  }))

  const onPressLogout = () => {
    // Store.Api.User.logout();
    setUserStore({ user: null, token: "" });
    setNotificationStore({ notifications: [], total: 0, page: 0 });
    navigate(SCREEN.LoginScreen);
  };

  const userName = useMemo(() => {
    if (!user?.firstName && !user?.lastName) return user?.email;
    return [user?.firstName, user?.lastName].join(' ');
  }, [user])

  if (isMobile) {
    if (!show) return null;
    return (
      <MobileWrapper show={show} setShow={setShow}>
        <Col width={300} height="100%" bgWhite>
          <Pressable style={styles.logoContainer} onPress={() => navigate(homeScreen)}>
            <Image source={require("assets/img/logo.png")} style={styles.logo} />
          </Pressable>
          <SideMenu />
        </Col>
      </MobileWrapper>
    )
  }

  return (
    <Col width={300} height="100%" bgWhite>
      <Pressable style={styles.logoContainer} onPress={() => navigate(homeScreen)}>
        <Image source={require("assets/img/logo.png")} style={styles.logo} />
      </Pressable>
      <SideMenu />
      <Col paddingLeft={12} paddingVertical={16}>
        <Row paddingRight={8}>
          <Col marginRight={2} flex1>
            <Text h5 bold numberOfLines={1}>{userName}</Text>
            <Text
              onPress={onPressLogout}
              style={{ textTransform: "capitalize", textDecorationLine: "underline" }}
              color={COLOR.GRAY300}
            >
              Logout
            </Text>
          </Col>
          <Tooltip trigger="click" color="white" title={<Notifications />}>
            <Col padding={8} borderRadius="50%" backgroundColor="black">
              <MaterialIcons name="notifications" size={24} color="white" />
              {notifications.some(i => !i.seen) &&
                <Col style={styles.dot} />
              }
            </Col>
          </Tooltip>
        </Row>
      </Col>
    </Col>
  )
})

const styles = StyleSheet.create({
  logoContainer: {
    width: 300,
    height: 70,
    backgroundColor: COLOR.MAIN,
    alignItems: "center",
    justifyContent: "center",
  },
  logo: {
    width: "80%",
    height: "80%",
    resizeMode: "contain",
  },
  dot: {
    width: 8,
    height: 8,
    borderRadius: 4,
    backgroundColor: "red",
    position: "absolute",
    right: 11,
    top: 11,
  }
});

export default Drawer;
