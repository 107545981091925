import { SVG } from "assets";
import { Col, Row, Text } from "components";
import { COLOR, STYLES } from "const";
import { ValHelper } from "helpers";
import { useHover } from "hooks/useHover";
import React from "react";
import { Image } from "react-native";

const ProductItem = (props) => {
  const [hoverProps = {}, isHovered] = useHover();
  return (
    <Col
      {...hoverProps}
      bgWhite
      margin={12}
      borderWidth={1}
      borderColor={COLOR.GRAY100}
      style={isHovered ? STYLES.LARGE_SHADOW : {}}
      onPress={props.onPress}
    >
      <Image source={{ uri: props.image }} style={{ width: '100%', aspectRatio: 1 }} resizeMode='contain' />
      <Col padding={16} paddingTop={8}>
        <Text p2 bold numberOfLines={2}>{props.name}</Text>
        <Row marginTop={6} justifyContent="space-between">
          <Row alignItems="center">
            <SVG.Resize />
            <Text p1 color={COLOR.GRAY300} marginLeft={8}>{props.physicalWidth || "-"} x {props.physicalHeight || "-"} ({props.unit})</Text>
          </Row>
          <Text p1>£{ValHelper.formatMoney(props.price)}</Text>
        </Row>
      </Col>
      {/* <Col absolute top={0} right={0} padding={8} alignItems="flex-end">
        <Col padding={8} backgroundColor={COLOR.SUCCESS}>
          <Text color={COLOR.WHITE} bold>PUBLISHED</Text>
        </Col>
        <Col marginTop={8} padding={8} backgroundColor={COLOR.INFO400}>
          <Text color={COLOR.WHITE} bold>CUSTOMIZABLE</Text>
        </Col>
      </Col> */}
    </Col>
  )
}

export default ProductItem;
