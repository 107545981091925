import Request from '../Request.utils'
export interface ICheckDownloadBillingRequestParams {
  productId?: string;
  quantity?: number;
  variantName?: string;
}
export interface IStripeCreatePaymentRequestBody {
  amount?: number;
  paymentMethodId?: string;
  productId?: string;
  variationAmount?: number;
  variationName?: string;
  variationPrice?: number;
}
export interface IMarkOrderCompleteRequestBody {
  amount?: number;
  fulfillAmount?: number;
  price?: number;
  printJobId?: string;
}
export interface ICreateInvoiceRequestBody {
  order?: any;
}
export interface IOrderInvoicesRequestQuery {
  month?: string;
  resellerId?: string;
}
export interface IUpdateInvoiceRequestBody {
  fulfilledAt?: string;
  orderId: string;
  paidAt?: string;
}
export interface IReportRequestBody {
  allTime?: boolean;
  endDate?: string;
  startDate?: string;
}
export interface IGetReportRequestQuery {
  month?: string;
}
export interface ISiteflowPostbackRequestBody {}


class OrderAPI {
  checkDownloadBilling = async (params: ICheckDownloadBillingRequestParams) => {
    const res = await Request.call('/api/orders/check-download-billings/:productId', 'POST', params, undefined, undefined, );
    return res;
  }
  stripeCreatePayment = async (body: IStripeCreatePaymentRequestBody) => {
    const res = await Request.call('/api/orders/create-payment', 'POST', undefined, undefined, body, );
    return res;
  }
  markOrderComplete = async (body: IMarkOrderCompleteRequestBody) => {
    const res = await Request.call('/api/orders/mark-order-complete', 'POST', undefined, undefined, body, );
    return res;
  }
  createInvoice = async (body: ICreateInvoiceRequestBody) => {
    const res = await Request.call('/api/order/invoice', 'POST', undefined, undefined, body, );
    return res;
  }
  orderInvoices = async (query: IOrderInvoicesRequestQuery) => {
    const res = await Request.call('/api/order/invoices', 'GET', undefined, query, undefined, );
    return res;
  }
  updateInvoice = async (body: IUpdateInvoiceRequestBody) => {
    const res = await Request.call('/api/order/update-invoice', 'POST', undefined, undefined, body, );
    return res;
  }
  report = async (body: IReportRequestBody) => {
    const res = await Request.call('/api/orders/report', 'POST', undefined, undefined, body, );
    return res;
  }
  getReport = async (query: IGetReportRequestQuery) => {
    const res = await Request.call('/api/orders/report', 'GET', undefined, query, undefined, );
    return res;
  }
  siteflowPostback = async (body: ISiteflowPostbackRequestBody) => {
    const res = await Request.call('/api/orders/siteflow-postback', 'POST', undefined, undefined, body, );
    return res;
  }
}
export default new OrderAPI()