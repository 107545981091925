export const SCREEN = {
  Sample: 'Sample',
  LoginScreen: 'LoginScreen',
  DashboardScreen: 'DashboardScreen',
  DashboardScreenReseller: 'DashboardScreenReseller',
  OrdersScreen: 'OrdersScreen',
  OrderDetailScreen: 'OrderDetailScreen',
  ProductLibraryScreen: 'ProductLibraryScreen',
  CreateProductScreen: 'CreateProductScreen',
  ServiceScreen: 'ServiceScreen',
  SettingsScreen: 'SettingsScreen',
  ConnectStoreScreen: 'ConnectStoreScreen',
  AddUserScreen: 'AddUserScreen',
  ResellerScreen: 'ResellerScreen',
  CreateResellerScreen: 'CreateResellerScreen',
  ResellerDetailScreen: 'ResellerDetailScreen',
  MyProductsScreen: 'MyProductsScreen',
  CreateResellerProductScreen: 'CreateResellerProductScreen',
  ResellerRegister: 'ResellerRegister',
  ResellerRegisterModal: 'ResellerRegisterModal',
  ClaimMyStore: 'ClaimMyStore',
};
