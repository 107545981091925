import { write, utils } from "xlsx";
import { saveAs } from 'file-saver';

const fieldNames = {
  name: "Name",
  email: "Email",
  pathfinderName: "Pathfinder Name",
  statement: "Statement",
  likert: "Likert",
  createdAt: "Date Created",
  learnings: "Learnings",
};

class ExportHelper {

  saveArrayToFile = (data: any[], options?: any) => {
    const formatedData = data.map(i => {
      const newObj = {};
      Object.keys(i).forEach(key => {
        newObj[fieldNames[key] || key] = i[key];
      })
      return newObj;
    })
    const worksheet = utils.json_to_sheet(formatedData);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = write(workbook, { type: 'array' });
    const excelBlob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(excelBlob, options?.fileName || 'exported.xlsx');
  }
}

export default new ExportHelper();
