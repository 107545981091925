import Request from '../Request.utils'
export interface IListRequestQuery {
  clientId?: string;
  isPrinted?: number;
  page?: number;
  readyForPrint?: number;
}
export interface IUpsertRequestBody {
  artworkUrls?: string[];
  clientId?: string;
  data?: {
    design?: any;
    pdfUrl?: string;
    product?: {
      physicalHeight?: number;
      physicalWidth?: number;
      printAreas?: {
        height?: number;
        left?: number;
        top?: number;
        width?: number;
      }[];
    };
    storeProductId?: string;
    storeUrl?: string;
  };
  designId?: string;
  id?: string;
  isPDFDownloaded?: boolean;
  isPrinted?: boolean;
  isRePrinted?: boolean;
  previewUrl?: string;
  productId?: string;
  productName?: string;
  quantity?: number;
}
export interface IRemoveRequestParams {
  id: string;
}
export interface IDetailRequestParams {
  id: string;
}
export interface IMarkPrintedMultipleRequestBody {
  ids?: string[];
}


class PrintJobAPI {
  list = async (query: IListRequestQuery) => {
    const res = await Request.call('/api/print-jobs', 'GET', undefined, query, undefined, );
    return res;
  }
  upsert = async (body: IUpsertRequestBody) => {
    const res = await Request.call('/api/print-jobs', 'POST', undefined, undefined, body, );
    return res;
  }
  remove = async (params: IRemoveRequestParams) => {
    const res = await Request.call('/api/print-jobs/:id', 'DELETE', params, undefined, undefined, );
    return res;
  }
  detail = async (params: IDetailRequestParams) => {
    const res = await Request.call('/api/print-jobs/:id', 'GET', params, undefined, undefined, );
    return res;
  }
  stats = async () => {
    const res = await Request.call('/api/print-jobs/stats', 'GET', undefined, undefined, undefined, );
    return res;
  }
  listToday = async () => {
    const res = await Request.call('/api/print-jobs/today', 'GET', undefined, undefined, undefined, );
    return res;
  }
  markPrintedMultiple = async (body: IMarkPrintedMultipleRequestBody) => {
    const res = await Request.call('/api/print-jobs/mark-printed-multiple', 'POST', undefined, undefined, body, );
    return res;
  }
}
export default new PrintJobAPI()