import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Col, FieldItem, Grid, Input, Row, Select, Text, WalletBalance } from 'components';
import { COUNTRIES } from 'const';
import { SVG } from 'assets';
import { StyleSheet, useWindowDimensions } from 'react-native';
import { usePaymentStore } from 'store/Payment.Store';
import { useListCurrencies } from 'hooks/useListCurrencies';
import { DepositFundPopup } from 'components/container';
import { useUserStore } from 'store/User.Store';
import Store from 'store';
import { modalError, modalSuccess } from 'helpers/AlertHelper';
import { notification } from 'antd';
import TransactionHistory from './billing/TransactionHistory';
import { isNil, omitBy } from 'lodash';

const FIELDS: any = [
  { id: 'number', label: 'Card Number' },
  { id: 'holder', label: 'Card Holder' },
  [
    { id: 'town', label: 'CVC' },
    { id: 'country', label: 'Expired Date' },
  ],
];

const FIELDS2: any = [
  { id: 'addressLine1', label: 'Address Line 1' },
  { id: 'addressLine2', label: 'Address Line 2' },
  [
    { id: 'city', label: 'City' },
    { id: 'postCode', label: 'Post Code' },
  ],
];

const TabBilling = () => {
  const depositPopupRef = useRef(null);
  const { width } = useWindowDimensions();
  const listCurencies = useListCurrencies();
  const { user, getResellerId } = useUserStore();
  const { getExistedCard, refreshBalance } = usePaymentStore();
  const [state, setState] = useState<any>({});
  const [loading, setLoading] = useState(false);

  const listCountries = useMemo(() => (
    COUNTRIES.map(v => ({ label: v.name, value: v.name }))
  ), []);

  const currencyOptions = useMemo(() => {
    return listCurencies.map(v => ({ label: `${v.cc} (${v.symbol})`, value: v.cc }));
  }, [listCurencies])

  useEffect(() => {
    if (user) {
      Store.Api.User.detail({ id: getResellerId(user) }).then(res => {
        setState(s => ({
          ...s,
          addressLine1: res.data.data?.addressLine1,
          addressLine2: res.data.data?.addressLine2,
          city: res.data.data?.town,
          postCode: res.data.data?.postCode,
          country: res.data.data?.country,
          currency: "GBP",
        }))
      })
    }
  }, [user]);

  useEffect(() => {
    getExistedCard();
  }, []);

  const onSubmit = async () => {
    try {
      if (loading) return;
      setLoading(true);
      const { addressLine1, addressLine2, city, postCode, country, currency } = state;
      const res = await Store.Api.User.upsertReseller(omitBy({
        id: getResellerId(user),
        addressLine1,
        addressLine2,
        town: city,
        postCode,
        country,
        currency,
      }, isNil));
      if (res.data.error) {
        modalError({
          content: res.data.error,
        });
      } else if (res.data.data) {
        notification.success({
          message: "Billing info successfully updated",
        })
      }
    } catch (e) {
      modalError({
        content: e.error || JSON.stringify(e),
      });
    } finally {
      setLoading(false);
    }
  }

  const totalBalanceRef = useRef(0);

  const withdrawFunds = async () => {
    if (!state.withdrawAmount) return;
    if (+state.withdrawAmount > totalBalanceRef.current) {
      modalError({
        content: "You can not withdraw the amount that is larger than your current balance",
      })
      return;
    }
    const res = await Store.Api.Payment.requestWithdrawFund({
      amount: +state.withdrawAmount,
      currency: state.currency || 'GBP',
    });
    if (res.data.success) {
      modalSuccess({
        content: "Your withdraw request has been sent",
      })
      setState({ ...state, withdrawAmount: '' });
      refreshBalance();
    }
  }

  const updateState = (key) => (value) => {
    setState(s => ({
      ...s,
      [key]: value,
    }))
  }

  const onPressAddBalance = () => {
    if (!state.depositBalance) return;
    depositPopupRef.current?.open(state.depositBalance);
  }

  const balanceWidth = Math.min(366, Math.floor(width / 4));
  return (
    <Col bgWhite paddingHorizontal={16}>
      <Grid lg={`1:${balanceWidth}px`} xs="100%" marginHorizontal={-16} alignItems="flex-start" mb2>
        <Col marginHorizontal={16}>
          {/* <Text marginTop={24} h4 bold>Card Information</Text>
          {FIELDS.map((i, idx) => {
            if (i.length) {
              return (
                <Row marginRight={-16} key={`form1${idx}`} >
                  {i.map(field => (
                    <FieldItem
                      flex1
                      key={field.id}
                      label={field.label}
                      marginRight={16}
                    />
                  ))}
                </Row>
              )
            }
            return <FieldItem key={`form1${i.id}`} label={i.label} />
          })} */}
          <Text h4 marginTop={24} bold>Billing Address</Text>
          {FIELDS2.map((i, idx) => {
            if (i.length) {
              return (
                <Row marginRight={-16} key={`form1${idx}`} >
                  {i.map(field => (
                    <FieldItem
                      flex1
                      key={field.id}
                      label={field.label}
                      marginRight={16}
                      value={state[field.id]}
                      onChangeText={updateState(field.id)}
                    />
                  ))}
                </Row>
              )
            }
            return (
              <FieldItem
                key={`form1${i.id}`}
                label={i.label}
                value={state[i.id]}
                onChangeText={updateState(i.id)}
              />
            )
          })}
          <Select
            marginTop={16}
            height={50}
            onChange={updateState("country")}
            options={listCountries}
            value={state.country}
            placeholder="Select country"
          />
          {/* <Select
            marginTop={16}
            height={50}
            onChange={updateState("currency")}
            value={state.currency}
            options={currencyOptions}
            placeholder="Select currency"
          /> */}
          <Button
            title='Save'
            onPress={onSubmit}
            height={46}
            marginTop={16}
            paddingHorizontal={18}
            alignSelf="flex-start"
          />
        </Col>
        <Col marginHorizontal={16}>
          <Text marginTop={24} h4 bold>Balance</Text>
          <Row marginTop={16}>
            <SVG.Radio />
            <Col flex1>
              <Text p1 marginLeft={8}>Easily cover order and production costs</Text>
            </Col>
          </Row>
          <Row marginTop={16}>
            <SVG.Radio />
            <Col flex1>
              <Text p1 marginLeft={8}>Order processing without the need for card payment</Text>
            </Col>
          </Row>
          <WalletBalance
            marginTop={16}
            currency={state.currency}
            onRenderAmount={(amount) => totalBalanceRef.current = amount}
          />

          <Row flex1 marginTop={16}>
            <Input
              inputProps={{
                placeholder: "+ Enter amount",
              }}
              value={state.depositBalance}
              onChangeText={updateState("depositBalance")}
              flex1
            />
            <Button
              title='Add funds'
              ml1
              disabled={!Number(state.depositBalance)}
              onPress={onPressAddBalance}
              height={50}
              width={120}
            />
          </Row>
          {/* <Row flex1 marginTop={12}>
            <Input
              inputProps={{
                placeholder: "+ Enter amount",
              }}
              value={state.withdrawAmount}
              onChangeText={updateState("withdrawAmount")}
              flex1
            />
            <Button
              title='Withdraw funds'
              ml1
              disabled={!Number(state.withdrawAmount)}
              onPress={withdrawFunds}
              height={50}
              width={120}
            />
          </Row> */}
        </Col>
      </Grid>
      <TransactionHistory />
      <DepositFundPopup
        ref={depositPopupRef}
        resellerId={user?.id}
        currency={state.currency || "GBP"}
      />
    </Col>
  )
};

const styles = StyleSheet.create({
  balanceContainer: {
    aspectRatio: 366 / 109,
    marginTop: 16,
    width: '100%',
    maxWidth: 366,
    paddingHorizontal: 16,
    justifyContent: 'center',
  }
})

export default TabBilling;
