import React from "react";
import { Button, Row } from "components";
import { useUserStore } from "store/User.Store";
import { useOrderStore } from "store/Order.Store";
import ToastHelper from "helpers/ToastHelper";
import { notification } from "antd";

const OrderActionButtons = (props: any) => {
  const { status, markAsAccepted, rejectJob, isLoading, isLoadingReject, selectedOrders, returnJob } = props;

  const { user } = useUserStore();
  const { reRunJob, getPipelinesOfAnOrder } = useOrderStore();
  
  const onPressRetry = async () => {
    try {
      ToastHelper.showProgress(0.1);
      for (let i = 0; i < selectedOrders.length; i++) {
        const _order = selectedOrders[i];
        if (_order.Status === "Failed") {
          const pipelines = await getPipelinesOfAnOrder(_order["Order ID"]);
          const failedPipeline = pipelines.find(i => i.Id === _order?.["Other Data"]?.failedPipeline);
          const failedJobId = Object.keys(failedPipeline?.Jobs || {}).find(i => failedPipeline?.Jobs?.[i]?.Status === "Failed");
          const failedJob = {
            ...failedPipeline?.Jobs?.[failedJobId],
            Id: failedJobId,
          }
          console.log("failedPipeline", failedPipeline);
          if (failedJob) {
            await reRunJob(failedJobId, failedPipeline.Id);
            notification.success({ message: "Success retry job"});
          }
        }
      }
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      ToastHelper.showProgress(100);
    }
  }

  const canReject = (["Pending", "Accepted"].includes(status) && user?.role === "admin") || (status === "Pending" && user?.role !== "admin");

  return (
    <Row>
      {status === 'Pending' && (
        <Button
          title={user?.role === "admin" ? "Send to Siteflow" : "Pay & Proceed"}
          height={36}
          onPress={markAsAccepted}
          isLoading={isLoading}
          mr1
        />
      )}
      {canReject && (
        <Button
          title="Reject Order"
          height={36}
          onPress={rejectJob}
          isLoading={isLoadingReject}
          mr1
        />
      )}
      {(status === "Accepted" && user?.role === "admin") && (
        <Button
          title="Order Returned"
          height={36}
          onPress={returnJob}
          isLoading={isLoading}
          mr1
        />
      )}
      {(selectedOrders.some(i => i.Status === "Failed") && user?.role === "admin") && (
        <Button
          title="Retry Job"
          height={36}
          onPress={onPressRetry}
          isLoading={isLoadingReject}
          mr1
        />
      )}
    </Row>
  )
}

export default OrderActionButtons;
