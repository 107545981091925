import Request from '../Request.utils'
export interface IListRequestQuery {
  createdByUserId?: string;
  customProduct?: number;
  excludeAll?: number;
  page?: number;
  printOnDemand?: number;
  wholeSale?: number;
}
export interface IUpsertRequestBody {
  availableForResellerIds?: object;
  brands?: {
    name?: string;
    storeId?: string;
  }[];
  customProduct?: boolean;
  data?: any;
  description?: string;
  designId?: string;
  height?: number;
  id?: string;
  image?: string;
  isCustomizable?: boolean;
  name?: string;
  printAreas?: {
    height?: number;
    left?: number;
    top?: number;
    width?: number;
  }[];
  printOnDemand?: boolean;
  productId?: string;
  products?: any;
  resalePrice?: number;
  tags?: string;
  variants?: {
    PPGSku?: string;
    attributes?: any;
    blender?: string;
    canvas?: '';
    code?: any;
    description?: string;
    height?: number;
    others?: any;
    previewUrl?: string;
    price?: string;
    resalePrice?: string;
    size?: string;
    sku?: string;
    url?: string;
    variantName?: string;
    width?: number;
  }[];
  wholeSale?: boolean;
  width?: number;
}
export interface IRemoveRequestParams {
  id: string;
}
export interface IDetailRequestParams {
  id: string;
}
export interface IDetailExcludeDataRequestParams {
  id: string;
}
export interface IAddCustomArtworkRequestBody {
  customArtwork?: string;
  id?: string;
  resellerId?: string;
}
export interface IArchiveRequestBody {
  id: string;
}


class DesignAPI {
  list = async (query: IListRequestQuery) => {
    const res = await Request.call('/api/designs', 'GET', undefined, query, undefined, );
    return res;
  }
  upsert = async (body: IUpsertRequestBody) => {
    const res = await Request.call('/api/designs', 'POST', undefined, undefined, body, );
    return res;
  }
  remove = async (params: IRemoveRequestParams) => {
    const res = await Request.call('/api/designs/:id', 'DELETE', params, undefined, undefined, );
    return res;
  }
  detail = async (params: IDetailRequestParams) => {
    const res = await Request.call('/api/designs/:id', 'GET', params, undefined, undefined, );
    return res;
  }
  detailExcludeData = async (params: IDetailExcludeDataRequestParams) => {
    const res = await Request.call('/api/designs/:id/exclude-data', 'GET', params, undefined, undefined, );
    return res;
  }
  addCustomArtwork = async (body: IAddCustomArtworkRequestBody) => {
    const res = await Request.call('/api/designs/add-custom-artwork', 'POST', undefined, undefined, body, );
    return res;
  }
  archive = async (body: IArchiveRequestBody) => {
    const res = await Request.call('/api/designs/archive', 'POST', undefined, undefined, body, );
    return res;
  }
  designTags = async () => {
    const res = await Request.call('/api/design-tags', 'GET', undefined, undefined, undefined, );
    return res;
  }
}
export default new DesignAPI()