import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef, useMemo } from 'react';
import { Col, Text, Button, } from 'components';
import { ActivityIndicator, useWindowDimensions } from 'react-native';
import { COLOR } from 'const';
import Store from 'store';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { ScrollView } from 'react-native';
import { Modal } from 'antd';
import { usePaymentStore } from 'store/Payment.Store';

interface IDataState {
  amount?: number,
  clientSecret?: string,
  savedPaymentMethods?: any,
}

const PaymentForm = ({ onPaid, amount, resellerId, queryString, onCancel }) => {
  const formPaymentRef = useRef<HTMLFormElement>(null);
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async e => {
    !!e && e.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // return_url: process.env.NODE_ENV === 'production'
        //     ? `https://dev.bg-production.personify.tech/resellers/${resellerId}/deposit-funds/complete${queryString}`
        //     : `http://localhost:19006/resellers/${resellerId}/deposit-funds/complete${queryString}`,
        return_url: `${window.location.protocol}//${window.location.host}/resellers/${resellerId}/deposit-funds/complete${queryString}`,
      },
    });

    if (result.error) {
      console.log(result.error.message);
    } else {
      onPaid(result);
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }
  return (
    <form ref={formPaymentRef} onSubmit={handleSubmit}>
      <PaymentElement />
      <Button
        mt1
        isLoading={isLoading}
        solid
        title={'Pay ' + amount}
        onPress={() => {
          setIsLoading(true);
          handleSubmit(undefined);
        }}
        height={40}
        width='100%'
      />
      <Button
        mt1
        height={50}
        title={"Cancel Payment"}
        backgroundColor="transparent"
        textProps={{ color: COLOR.MAIN }}
        borderColor={COLOR.MAIN}
        borderWidth={1}
        onPress={onCancel}
      />
    </form>
  );
}

const DepositFundPopup = forwardRef((props: any, ref) => {
  const { resellerId, currency } = props;
  const [data, setData] = useState<IDataState>({});
  const { width } = useWindowDimensions();
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const { stripePublishKey, getStripeConfig } = usePaymentStore();

  useEffect(() => {
    getStripeConfig();
  }, [])

  const stripePromise = useMemo(() => {
    if (!stripePublishKey) return;
    return loadStripe(stripePublishKey);
  }, [stripePublishKey]);

  useImperativeHandle(ref, () => ({
    open: (amount) => {
      setData(s => ({
        ...s,
        amount,
      }));
      setShow(true);
      createDeposit(amount);
    },
    hide: () => {
      setShow(false);
    },
  }))

  const createDeposit = async (amount) => {
    try {
      const res = await Store.Api.Payment.stripeCreateDeposit({
        amount: amount,
        currency: currency,
      });
      if (res.data.success && res.data.data?.client_secret) {
        setData(s => ({
          ...s,
          clientSecret: res.data.data?.client_secret,
        }));
        return;
      }
      if (res.data.success && res.data.extraConfirmStepData && res.data.extraConfirmStepData.paymentMethods) {
        setData(s => ({
          ...s,
          clientSecret: '',
          savedPaymentMethods: res.data.extraConfirmStepData.paymentMethods,
        }))
      }
    } catch (error) {
      setError(error.message || JSON.stringify(error));
    }
  }

  const onPayWithNewCard = async () => {
    const res = await Store.Api.Payment.stripeCreateDeposit({
      amount: data.amount,
      currency: currency,
      paymentMethodId: 'new',
    });
    if (res.data.success && res.data.data?.client_secret) {
      setData(s => ({
        ...s,
        clientSecret: res.data.data?.client_secret,
      }));
    }
  }

  const displayAmount = useMemo(() => `${data.amount} ${currency}`, [data.amount, currency]);

  const renderPayment = () => {
    if (!show) return null;
    if (!data.clientSecret && !data.savedPaymentMethods?.length) {
      return (
        <ActivityIndicator color={COLOR.MAIN} />
      )
    }
    if (error) {
      return <Text color="red">{error}</Text>
    }
    if (!data.clientSecret && data.savedPaymentMethods?.length > 0) {
      // val -> https://stripe.com/docs/api/payment_methods
      return (
        <Col flex1 middle>
          <Text bold mb2>Amount: {displayAmount}</Text>
          {data.savedPaymentMethods.map((val, i) => {
            return (
              <Button
                solid={i === 0}
                outline={i !== 0}
                height={40}
                width={320}
                title={`Pay with: ${val.card?.brand} ${val.card?.last4}`}
                onPress={async () => {
                  const res = await Store.Api.Payment.stripeCreateDeposit({
                    amount: data.amount,
                    currency: currency,
                    paymentMethodId: val.id,
                  });
                  if (!res.data.success) return alert(res.data.error);
                  const payment_intent_id = res.data.data.id;
                  const queryString = `?amount=${data?.amount}&f_amount=${data?.amount}&currency=${currency}&payment_intent=${payment_intent_id}`;
                  const return_url = `${window.location.protocol}//${window.location.host}/resellers/${resellerId}/deposit-funds/complete${queryString}`;
                  window.location.href = return_url;
                }}
                mb1
              />
            )
          })}
          <Button
            outline={true}
            height={40}
            width={320}
            title={`Pay with new card`}
            onPress={onPayWithNewCard}
          />
          <Col height={1} mv1 backgroundColor={COLOR.MAIN} width={320} />
          <Button
            outline={true}
            height={40}
            width={320}
            title={`Cancel Payment`}
            onPress={() => setShow(false)}
          />
        </Col>
      );
    }
    return (
      <Col flex1 middle>
        <Elements stripe={stripePromise} options={{ clientSecret: data.clientSecret }}>
          <PaymentForm
            onPaid={e => {
              console.log('onPaid', e);
            }}
            onCancel={() => setShow(false)}
            amount={displayAmount}
            resellerId={resellerId}
            queryString={`?amount=${data?.amount}&f_amount=${data?.amount}&currency=${currency}`}
          />
        </Elements>
      </Col>
    );
  }

  return (
    <Modal
      open={show}
      title="Payment methods"
      onOk={() => setShow(false)}
      onCancel={() => setShow(false)}
      footer={false}
      width={width * 0.7}
    >
      {renderPayment()}
    </Modal>
  );
});

export default DepositFundPopup;
