import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path d="M19.5 13.5h-6v6h-2v-6h-6v-2h6v-6h2v6h6v2z" fill={props.color || "#fff"} />
    </Svg>
  )
}

export default SvgComponent
