import { Col, Text } from "components";
import { COLOR, STYLES } from "const";
import React from "react";
import { StyleSheet, View } from "react-native";

const SummaryItemNew = ({ value, label, subTitle }: any) => {
  return (
    <Col mr2 ph3 pt2 pb2 style={styles.container}>
      <Text fontSize={14} marginBottom={8} fontWeight={'700'}>{label}</Text>
      <Text fontSize={24} marginBottom={8} fontWeight={'700'} color={'#E60064'}>{value}</Text>
      <Text fontSize={14} fontWeight={'500'} color="#676767" numberOfLines={1}>{subTitle || " "}</Text>
    </Col>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    borderColor: COLOR.GRAY100,
    borderWidth: 1,
    backgroundColor: COLOR.WHITE,
    ...STYLES.SHADOW,
  }
})

export default SummaryItemNew;
