import { Button as AButton } from "antd";
import { ColumnsType } from "antd/es/table";
import { SVG } from "assets";
import { Row, Table, Text, Touch } from "components";
import { SCREEN } from "const";
import moment from "moment";
import { useNavFunc } from "navigation";
import React from "react";
import { TCMSOrder } from "type";
import OrderStatus from "./components/OrderStatus";

const TablePending = ({ data, setSelectedOrders, selectedOrders, onPressProduct, onPressMarker, isLoading }) => {
  const { navigate } = useNavFunc();

  const onSelectChange = (newSelectedRowKeys: string[]) => {
    setSelectedOrders(newSelectedRowKeys);
  };

  const columns: ColumnsType<any> = [
    {
      title: 'Order No',
      dataIndex: 'Order Number',
      key: 'orderNumber',
    },
    {
      title: 'Reseller',
      key: 'reseller',
      render: () => <Text>Precision Proco</Text>
    },
    {
      title: 'Product',
      key: 'product',
      render: (record) => (
        <Text>{record['Raw Data']?.line_items?.map(i => i.name)?.join(',\n')}</Text>
      )
    },
    {
      title: 'Date Created',
      key: 'created',
      render: (record) => (
        <Text>{moment(record.CreatedAt).format('DD/MM/YYYY HH:mm')}</Text>
      ),
    },
    {
      title: 'Status',
      key: 'status',
      render: ({ Status }) => {
        return <OrderStatus status={Status} />
      },
    },
    {
      title: '',
      key: 'actions',
      width: 270,
      render: (record: TCMSOrder) => (
        <Row>
          <AButton onClick={() => {
            navigate(SCREEN.OrderDetailScreen, { id: record?.["Order ID"] });
          }}>Order detail</AButton>
          <Touch padding={12} marginLeft={12} onPress={() => onPressProduct(record)}>
            <SVG.Eye />
          </Touch>
          <Touch padding={12} onPress={() => onPressMarker(record)}>
            <SVG.Marker />
          </Touch>
          {/* <Touch padding={12}>
            <SVG.Trash />
          </Touch> */}
        </Row>
      ),
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      rowSelection={{
        selectedRowKeys: selectedOrders,
        onChange: onSelectChange,
      }}
      rowKey={record => `${record.Id}`}
      minWidth={800}
      loading={isLoading}
    />
  )
}

export default TablePending;
