import { useFocusEffect } from '@react-navigation/native';
import { SVG } from 'assets';
import { Button, Col, Input, Text } from 'components';
import { COLOR, SCREEN } from 'const';
import { SIDE_MENU_ITEMS, SIDE_MENU_ITEMS_RESELLER } from 'const/menu';
import { useUIState } from 'hooks';
import { useNavFunc } from 'navigation';
import React, { useCallback, useRef, useState } from 'react';
import { Image, ImageBackground, StyleSheet, useWindowDimensions, Animated, View } from 'react-native';
import Store from 'store';
import { useUserStore } from 'store/User.Store';
import { IScreen } from 'type';

const LoginScreen: IScreen = () => {
  const userStore = useUserStore();
  const { width } = useWindowDimensions();
  const { navigate, route } = useNavFunc();
  const { redirect }: any = route.params || {};
  const [username, setUsername] = useState('');
  const [pass, setPass] = useState('');
  const [{ loading, errorMes }, setUI] = useUIState();
  const popupWidth = Math.min(width - 48, 538);

  const onLoginSuccess = (role) => {
    const menus = role === "admin" ? SIDE_MENU_ITEMS : SIDE_MENU_ITEMS_RESELLER;
    if (redirect) {
      navigate(redirect);
    } else {
      navigate(menus[0].screen);
    }
  }

  const handleLogin = async () => {
    try {
      if (!username || !pass || loading) return;
      setUI({ loading: true, errorMes: '' });
      const res = await Store.Client.Api.User.login({
        email: username,
        password: pass,
      });
      if (res.data.success && res.data.data?.token) {
        setUI({ loading: false, errorMes: '' });
        const { publicInfo, token } = res.data.data;
        Store.Client.setToken(token);
        userStore.set({
          token,
          user: publicInfo,
        });
        onLoginSuccess(publicInfo?.role);
      } else {
        setUI({ loading: false, errorMes: res.data.error });
      }
    } catch (err) {
      setUI({ loading: false, errorMes: String(err) });
    } finally {
      setUI({ loading: false });
    }
  };

  return (
    <ImageBackground
      style={styles.container}
      source={require("assets/img/bg-login.png")}
    >
      <View
        style={[styles.loginForm, {
          width: popupWidth,
        }]}
      // source={require("assets/img/bg-popup.png")}
      >
        <Image source={require("assets/img/logo.png")} style={styles.logo} />
        <Input
          marginTop={58}
          height={70}
          inputProps={{
            placeholder: "Enter username",
          }}
          value={username}
          onChangeText={setUsername}
          left={<SVG.AccountBox width={24} height={24} />}
        />
        <Input
          marginTop={24}
          height={70}
          inputProps={{
            placeholder: "Enter password",
            secureTextEntry: true,
            onSubmitEditing: handleLogin,
          }}
          value={pass}
          onChangeText={setPass}
          left={<SVG.Lock width={24} height={24} />}
        />
        {!!errorMes &&
          <Text p1 color='white' bold mt2>{errorMes}</Text>
        }
        <Button
          isLoading={loading}
          title="LOGIN"
          marginTop={30}
          disabled={!username || !pass}
          onPress={handleLogin}
          textProps={{ color: COLOR.MAIN }}
          backgroundColor={COLOR.WHITE}
        />
        <Col marginTop={24} padding={8} alignItems="center" alignSelf="center" onPress={() => {
          navigate(SCREEN.ResellerRegister);
        }}>
          <Text colorWhite fontWeight="600">Reseller Register</Text>
        </Col>
      </View>
    </ImageBackground>
  )
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: COLOR.GREY_LIGHT,
  },
  loginForm: {
    borderWidth: 1,
    borderColor: "#FFFFFFCC",
    borderRadius: 8,
    paddingHorizontal: 40,
    paddingTop: 32,
    paddingBottom: 40,
    overflow: "hidden",
    backgroundColor: COLOR.MAIN
  },
  logo: {
    width: "50%",
    maxWidth: 263,
    aspectRatio: 263 / 83,
    alignSelf: "center",
  }
})

LoginScreen.routeInfo = {
  title: 'Login',
  path: '/login',
};

export default LoginScreen;
