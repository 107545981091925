import { Col } from "components/base";
import { COLOR } from "const";
import React from "react";
import { ImageBackground, StyleSheet } from "react-native";

const MainContentContainer = ({ children }) => {
  return (
    <Col style={styles.container}>
      {children}
    </Col>
  )
  return (
    <ImageBackground
      style={styles.container}
      source={require("assets/img/bg-main-page.png")}
    >
      {children}
    </ImageBackground>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: COLOR.GREY_LIGHT,
  }
})

export default MainContentContainer;
