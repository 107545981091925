import create from 'zustand';
import Client, { TNotification } from './api-client';

interface INotificationStore {
  notifications: TNotification[],
  page?: number,
  total?: number,
  readNoti(id: string): Promise<any>,
  getList(p: number): Promise<{ hasNext: boolean, list: Array<TNotification>, total: number, error?: string, }>,
  [otherKey: string]: any,
}

export const useNotificationStore = create<INotificationStore>((set, get) => ({
  notifications: [],
  getList: async (page = 1) => {
    const res = await Client.Api.Notification.list({ page });
    if (!res.data.success) {
      const data = {
        hasNext: false,
        list: [],
        total: 0,
        error: res.data.error,
      };
      set({ notifications: [], page: 1, total: 0 })
      return data;
    }
    if (res.data.data?.list) {
      const arr = page === 1 ? [] : [...get().notifications];
      set({ 
        notifications: arr.concat(res.data.data?.list), 
        page,
        total: res.data.data?.total || 0,
      });
    }
    return res.data.data;
  },
  readNoti: async (id) => {
    await Client.Api.Notification.read({ id });
    const arr = [...get().notifications];
    const idx = arr.findIndex(i => i.id === id);
    arr[idx].seen = true;
    set({ notifications: arr });
  },
  get,
  set,
}));
