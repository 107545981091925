import { Breadcrumb } from 'antd';
import { Col, Grid, ProductBillItem, Row, Text } from 'components';
import { SecuredLayout } from 'components/container';
import MainContentContainer from 'components/container/MainContentContainer';
import { COLOR } from 'const';
import { useNavFunc } from 'navigation';
import React, { useEffect, useMemo, useState } from 'react';
import Store from 'store';
import { IScreen, TCMSOrder } from 'type';
import OrderStatus from './components/OrderStatus';
import moment from 'moment';
import { HInfoItem } from './components/InfoItem';
import ShippingInfo from './components/ShippingInfo';
import { ValHelper } from 'helpers';
import apiClient from 'store/api-client';

const OrderDetailScreen: IScreen = () => {
  const OrderStore = Store.useOrderStore();
  const { navigation, route } = useNavFunc();
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState<TCMSOrder>();
  const [invoiceData, setInvoiceData] = useState<any>({});
  // @ts-ignore
  const { id } = route.params || {};

  const prices = useMemo(() => {
    let _productPrice = invoiceData?.total ? invoiceData?.total - invoiceData?.taxes : 0;
    let _shippingFee = invoiceData?.data?.shippingFee || 0;
    let _vat = invoiceData?.taxes;
    let _total = !invoiceData?.paidAt ? invoiceData?.total : invoiceData?.total + (_shippingFee || 0) * (invoiceData?.taxes ? 1.2 : 1);

    if (invoiceData?.stripeInvoice) {
      const item = invoiceData?.stripeInvoice;

      const shipItem = item.lines?.data?.find(i => i.description?.includes("Shipping fee"));
      const taxItem = item.lines?.data?.find(i => i.description?.includes("VAT "));

      if (!shipItem && !taxItem) {
        _productPrice = item.total / 100;
      } else {
        const productsOnly = item.total - (shipItem?.amount || 0) - (taxItem?.amount || 0);
        _productPrice = productsOnly / 100;
      }
      if (shipItem) {
        _shippingFee = shipItem?.amount / 100;
      }
      if (taxItem) {
        _vat = taxItem?.amount / 100;
      }
      _total = item.total / 100;
    }
    return {
      productPrice: _productPrice,
      shippingFee: _shippingFee,
      vat: _vat,
      total: _total,
    }
  }, [invoiceData]);

  const canBeProcessedItem = useMemo(() => {
    const pipelines = order?.Pipelines;
    if (!pipelines || pipelines.length === 0) return [];
    return pipelines[pipelines.length - 1].SharedData?.canBeProcessedItems || [];
  }, [order]);

  const getData = async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const res = await OrderStore.findByOrderNo({ orderId: id });
      setOrder(res[0]);
      if (res[0]) {
        let invoice = await apiClient.Api.Payment.getOrderInvoice({ orderId: id });
        setInvoiceData(invoice.data?.data);
      }
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <SecuredLayout>
      <MainContentContainer>
        <Col pt4 ph4>
          <Breadcrumb
            items={[
              {
                title: <a href="/orders">Orders</a>,
              },
              {
                title: 'Order detail',
              },
            ]}
          />
          <Text h2 fontWeight={'300'} mt2>Order #{order?.["Order Number"]}</Text>
          <Grid
            xs="100%"
            lg="3:1"
            marginBottom={24}
            marginHorizontal={-24}
          >
            <Col bgWhite marginHorizontal={8} paddingLeft={16} paddingBottom={80} marginTop={20}>
              <Text h4 bold marginVertical={24}>Order Detail</Text>
              <Row height={1} backgroundColor={COLOR.GRAY100} marginBottom={16} />
              <Grid
                xs="100%"
                lg="50%"
                marginHorizontal={-12}
                marginBottom={24}
                alignItems={"flex-start"}
              >
                <Col marginHorizontal={12}>
                  <HInfoItem title="Status">
                    <OrderStatus status={order?.Status} />
                  </HInfoItem>
                  <HInfoItem title="Created" value={moment(order?.CreatedAt).format('DD/MM/YYYY HH:mm')} />
                  <HInfoItem title="Paid" value={invoiceData?.paidAt ? moment(invoiceData?.paidAt).format('DD/MM/YYYY HH:mm') : ""} />
                </Col>
                <Col marginHorizontal={12}>
                  <HInfoItem title="Shipped" value={""} />
                  <HInfoItem title="Tracking No" value={""} />
                </Col>
              </Grid>

              <ShippingInfo order={order} />

            </Col>
            <Col bgWhite marginHorizontal={8} paddingBottom={16} marginTop={20}>
              <Text h4 bold margin={24}>Product & Price</Text>
              <Row height={1} backgroundColor={COLOR.GRAY100} marginBottom={16} />
              <Col padding={16}>
                {canBeProcessedItem?.map(item => (
                  <ProductBillItem
                    key={item.id}
                    img={item.previewUrl}
                    id={item.sku}
                    name={item.name}
                    price={item.price || invoiceData?.prices?.[item.id]}
                    quantity={item.quantity}
                  />
                ))}
                <Row height={1} backgroundColor={COLOR.GRAY100} marginBottom={8} />
                <Row justifyContent="space-between" marginTop={16}>
                  <Text p1 color={COLOR.GRAY300}>Price</Text>
                  <Text caption1 bold>£{ValHelper.formatMoney(prices?.productPrice)}</Text>
                </Row>
                <Row justifyContent="space-between" marginTop={16}>
                  <Text p1 color={COLOR.GRAY300}>Postage & packaging</Text>
                  <Text caption1 bold>£{ValHelper.formatMoney(prices?.shippingFee)}</Text>
                </Row>
                {/* {!!prices.total_discounts && prices.total_discounts !== '0.00' && (
                  <Row justifyContent="space-between" marginTop={16}>
                    <Text p1 color={COLOR.GRAY300}>Discount</Text>
                    <Text caption1 bold>£{ValHelper.formatMoney(prices?.total_discounts)}</Text>
                  </Row>
                )} */}
                {!!prices.vat && (
                  <Row justifyContent="space-between" marginTop={16}>
                    <Text p1 color={COLOR.GRAY300}>Tax</Text>
                    <Text caption1 bold>£{ValHelper.formatMoney(prices?.vat)}</Text>
                  </Row>
                )}
                <Row justifyContent="space-between" marginTop={36}>
                  <Text h3 bold>TOTAL</Text>
                  <Text h3 bold>£{ValHelper.formatMoney(prices?.total)}</Text>
                </Row>
              </Col>
            </Col>
          </Grid>
        </Col>
      </MainContentContainer>
    </SecuredLayout >
  )
};

OrderDetailScreen.routeInfo = {
  title: 'Order detail',
  path: '/orders/:id',
};

export default OrderDetailScreen;
