import * as React from "react"
import Svg, { Path } from "react-native-svg"

function SvgComponent(props) {
  return (
    <Svg
      width={32}
      height={32}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <Path
        d="M24 20v4h-4v2.667h4v4h2.667v-4h4V24h-4v-4H24zm-6.267 8H6.667A2.675 2.675 0 014 25.333V6.667C4 5.2 5.2 4 6.667 4h18.666C26.8 4 28 5.2 28 6.667v11.066c-.8-.266-1.733-.4-2.667-.4-1.466 0-2.933.4-4.133 1.2L19.333 16l-4.666 6-3.334-4-4.666 6h10.8c-.134.4-.134.933-.134 1.333 0 .934.134 1.867.4 2.667z"
        fill="#E60064"
      />
    </Svg>
  )
}

export default SvgComponent
