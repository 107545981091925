import React from "react";
import { Row } from "../base";
import { IRowProps } from "components/base/Row";
import Button, { ButtonProps } from "./Button";
import { COLOR } from "const";

export interface ButtonGroupProps extends IRowProps {
  buttons: { id: string, title: string, isLight?: boolean }[],
  buttonProps?: ButtonProps,
  selectedId: string,
  onChange?: (id: string) => void,
}

const ButtonGroup = (props: ButtonGroupProps) => {
  const { buttons, buttonProps, selectedId, onChange, ...restProps } = props;

  return (
    <Row {...restProps} flexWrap="wrap">
      {buttons.map(i => {
        const isSelected = selectedId === i.id;
        if (i.isLight) {
          return (
            <Button
              key={i.id}
              marginRight={8}
              marginTop={4}
              height={36}
              title={i.title}
              backgroundColor={isSelected ? COLOR.MAIN : "transparent"}
              onPress={() => onChange(i.id)}
              textProps={{ color: isSelected ? COLOR.WHITE : COLOR.MAIN }}
              borderColor={COLOR.MAIN}
              borderWidth={1}
              paddingHorizontal={2}
              {...buttonProps}
            />
          )
        }
        return (
          <Button
            key={i.id}
            marginRight={8}
            marginTop={4}
            height={36}
            backgroundColor={isSelected ? COLOR.MAIN : COLOR.GRAY100}
            textProps={{
              color: isSelected ? COLOR.WHITE : COLOR.GRAY300,
              fontWeight: isSelected ? "bold" : "400",
            }}
            onPress={() => onChange(i.id)}
            title={i.title}
            paddingHorizontal={2}
            {...buttonProps}
          />
        )
      })}
    </Row>
  )
}

export default ButtonGroup;
