
import { SVG } from "assets";
import { Col, ImageItem, Row, Text, Touch, UploadFile } from "components";
import { COLOR } from "const";
import React, { useRef, useState } from "react";
import { ActivityIndicator, Image, StyleSheet } from "react-native";
import { useProductForm } from "./FormContext";
import { useUserStore } from "store/User.Store";


const ProductImages = () => {
  const uploadRef = useRef<{ showDialog: Function }>(null);
  const [loading, setLoading] = useState(false);
  const [imageType, setImageType] = useState('main');
  const { product, setProduct } = useProductForm();
  const { user } = useUserStore();
  const isAdmin = user?.role === "admin";

  const onPressRemove = (idx) => {
    const arr = [...product?.galleries];
    arr.splice(idx, 1);
    setProduct(p => ({
      ...p,
      galleries: arr,
    }))
  }

  const onImageUploaded = urls => {
    if (urls.length === 0) return setLoading(false);
    let newProduct = { ...product };
    switch (imageType) {
      case 'main':
        newProduct.image = urls[0];
        break;
      case 'galleries':
        newProduct.galleries = [
          ...urls,
          ...(newProduct.galleries || []),
        ];
        break;
      case 'blueprint':
        newProduct.bluePrintImage = urls[0];
        break;
      case '3d-model':
        const previewData = (newProduct?.previewData || []).slice();
        const find3DPreviewIndex = previewData.findIndex(v => v.groupTitle === '3D preview');
        if (find3DPreviewIndex === -1) {
          previewData.push({
            groupTitle: '3D preview',
            previewType: '3d-model',
            previewItems: [
              {
                title: '360 View',
                data: `/gltf-viewer/index.html?model=${urls[0]}`,
                image: '',
              }
            ]
          });
        } else {
          previewData[find3DPreviewIndex].previewItems[0] = {
            title: '360 View',
            data: `/gltf-viewer/index.html?model=${urls[0]}`,
            image: '',
          }
        }
        newProduct.previewData = previewData;
        break;
    }
    setProduct(newProduct);
    setLoading(false);
  }

  return (
    <Col alignItems="center">
      <Text p1 color={COLOR.GRAY300} marginTop={24}>Main Image</Text>
      {(product?.image || !isAdmin) ?
        <Col style={styles.mainImageContainer}>
          <ImageItem
            uri={product?.image}
            style={styles.mainImage}
            onPressRemove={() => {
              setProduct(s => ({
                ...s,
                image: "",
              }))
            }}
            canRemove={isAdmin}
          />
        </Col>
        :
        <Col style={styles.addImageBtn} onPress={() => {
          setImageType('main');
          uploadRef.current?.showDialog();
        }}>
          {loading && imageType === 'main' ?
            <ActivityIndicator color={COLOR.MAIN} />
            :
            <SVG.ImagePlus width={32} height={32} />
          }
          <Text marginTop={12} p2 textAlign='center'>
            Drag and drop file or
            <Text color='#E60064' bold>
              {` browse computer`}
            </Text>
          </Text>
        </Col>
      }
      <Text p1 color={COLOR.GRAY300} marginTop={40}>Gallery</Text>
      <Row marginTop={24} flexWrap="wrap" alignSelf="center" middle paddingHorizontal={6}>
        {isAdmin &&
          <Col style={styles.addImageSm} onPress={() => {
            setImageType('galleries');
            uploadRef.current?.showDialog();
          }}>
            {loading && imageType === 'galleries' ?
              <ActivityIndicator color={COLOR.MAIN} />
              :
              <SVG.ImagePlus />
            }
          </Col>
        }
        {product?.galleries?.map((i, idx) => (
          <ImageItem
            uri={i}
            key={i}
            onPressRemove={() => onPressRemove(idx)}
            canRemove={isAdmin}
          />
        ))}
      </Row>
      <UploadFile
        ref={uploadRef}
        onUploaded={onImageUploaded}
        onUploading={() => setLoading(true)}
      />
    </Col>
  )
}

const styles = StyleSheet.create({
  addImageBtn: {
    width: '40%',
    aspectRatio: 1,
    minWidth: 100,
    backgroundColor: COLOR.MAIN100,
    borderWidth: 1,
    borderColor: '#DFD6F9',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    marginTop: 14,
    padding: 16,
  },
  mainImageContainer: {
    width: '40%',
    aspectRatio: 1,
    minWidth: 100,
    marginTop: 14,
  },
  imageItem: {
    width: 72,
    aspectRatio: 1,
    borderColor: COLOR.GRAY100,
    borderWidth: 1,
  },
  addImageSm: {
    width: 72,
    height: 72,
    backgroundColor: COLOR.MAIN100,
    borderWidth: 1,
    borderColor: '#DFD6F9',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 8,
    marginHorizontal: 6,
  },
  mainImage: {
    width: '100%',
    height: '100%',
  }
})

export default ProductImages;
