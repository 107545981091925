import React, { ReactElement, useRef, useState } from "react";
import { Col } from "../base";
import { IRowProps } from "../base/Row";
import { StyleSheet, TextInput, TextInputProps } from "react-native";
import { useHover } from "hooks/useHover";
import { COLOR } from "const";

export interface InputProps extends IRowProps {
  left?: ReactElement,
  right?: ReactElement,
  isMulti?: boolean,
  inputProps?: TextInputProps,
  inputStyle?: any,
  value?: string,
  placeholder?: string,
  onChangeText?: (str: string) => void,
}

const Input = (props: InputProps) => {
  const inputRef = useRef<TextInput>(null);
  const { height = 50, left, right, value, onChangeText, inputProps, isMulti, paddingHorizontal, inputStyle, placeholder, ...restProps } = props;
  const [hoverProps = {}, isHovered] = useHover();
  const [isFocused, setIsFocused] = useState(false);

  const onPress = () => {
    inputRef.current?.focus();
  }

  return (
    <Col
      height={height}
      {...restProps}
      {...hoverProps}
      style={[styles.container, {
        backgroundColor: isHovered ? 'rgb(247,247,247)' : 'white',
        borderColor: isFocused ? "black" : COLOR.GRAY200,
        alignItems: isMulti ? "flex-start" : "center",
        paddingHorizontal: paddingHorizontal ? Number(paddingHorizontal) : 4,
      }]}
      onFocus={onPress}
      onPress={onPress}
    >
      {left}
      <TextInput
        ref={inputRef}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        placeholderTextColor={COLOR.GRAY300}
        style={[styles.input, {
          // @ts-ignore
          outline: "none",
          marginTop: isMulti ? 16 : undefined,
          minHeight: isMulti ? (Number(height) - 24) : undefined,
          ...inputStyle,
        }]}
        value={value}
        onChangeText={onChangeText}
        multiline={isMulti}
        placeholder={inputProps?.placeholder || placeholder}
        {...inputProps}
      />
      {right}
    </Col>
  )
}

const styles = StyleSheet.create({
  container: {
    borderWidth: 1,
    // paddingHorizontal: 16,
    alignItems: "center",
    flexDirection: "row",
  },
  input: {
    flex: 1,
    marginLeft: 8,
    minWidth: 40,
  }
})

export default Input;
