import { Button, Col, FieldItem, Grid, Input, Row, Text } from 'components';
import { SecuredLayout } from 'components/container';
import MainContentContainer from 'components/container/MainContentContainer';
import React from 'react';
import { IScreen } from 'type';

const FIELDS: any = [
  { id: 'email', label: 'Email' },
  { id: 'password', label: 'Password' },
  [
    { id: 'firstName', label: 'First Name' },
    { id: 'lastName', label: 'Last Name' },
  ],
  { id: 'address1', label: 'Address Line 1' },
  { id: 'address2', label: 'Address Line 2' },
  [
    { id: 'town', label: 'Town' },
    { id: 'country', label: 'Country' },
  ],
  { id: 'postCode', label: 'Post Code' },
];

const ServiceScreen: IScreen = () => {
  return (
    <SecuredLayout>
      <MainContentContainer>
        <Col pt4 ph4>
          <Row justifyContent="space-between">
            <Text h2 fontWeight={'300'}>Order detail</Text>
            <Button
              title='Save'
              onPress={() => { }}
              height={46}
              paddingHorizontal={18}
            />
          </Row>
          <Col bgWhite marginLeft={-16} paddingHorizontal={16} marginTop={24} paddingTop={8} paddingBottom={24}>
            <Grid lg="50%" xs="100%" marginHorizontal={-16} alignItems="flex-start">
              <Col marginHorizontal={16} marginTop={16}>
                <Text h4 bold>Informations</Text>
                {FIELDS.map((i, idx) => {
                  if (i.length) {
                    return (
                      <Row marginRight={-16} key={idx} >
                        {i.map(field => (
                          <FieldItem
                            flex1
                            key={field.id}
                            label={field.label}
                            marginRight={16}
                          />
                        ))}
                      </Row>
                    )
                  }
                  return <FieldItem key={i.id} label={i.label} />
                })}
              </Col>
              <Col marginHorizontal={16} marginTop={16}>
                <Text h4 bold>BG Print & Ship service API:</Text>
                <Input
                  marginTop={8}
                  height={50}
                />
                <Text h4 bold marginTop={16}>Webhook for receive events</Text>
                <Input
                  marginTop={16}
                  height={50}
                />
                <Text h4 bold marginTop={16}>Webhook authentication headers</Text>
                <Row marginTop={8}>
                  <Input
                    flex1
                    height={50}
                    marginRight={16}
                  />
                  <Input
                    flex1
                    height={50}
                  />
                </Row>
              </Col>
            </Grid>
          </Col>
        </Col>
      </MainContentContainer>
    </SecuredLayout >
  )
};

ServiceScreen.routeInfo = {
  title: 'PPG Service',
  path: '/services',
};

export default ServiceScreen;
